import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField, CieTradeLogSchema, CieTradeLogEntrySchema } from '../../types';
import User from './User';

class CieTradeLog extends Table {

  static options: TableOptions = {
    name: 'CieTradeLog',
    slug: 'cietrade-logs',
    labelKey: 'id',
    valueKey: 'id',
    lookupKey: 'cieTradeLogs',
    icon: 'code',
    defaultOrder: '-created',
  };

  static labels: TableLabels = {
    description: 'All cieTrade Logs in the 4G Recycling cieTrade system.',
    pageTitle: 'cieTrade Log Manager',
    singular: 'cieTrade Log',
    plural: 'cieTrade Logs',
    viewSingular: 'View cieTrade Log',
    viewPlural: 'View cieTrade Logs',
    selectSingular: 'Select cieTrade Log',
    selectPlural: 'Select cieTrade Logs',
    addSingular: 'Add cieTrade Log',
    addPlural: 'Add cieTrade Logs',
    editSingular: 'Edit cieTrade Log',
    editPlural: 'Edit cieTrade Logs',
    addedSingular: 'cieTrade Log added',
    addedPlural: 'cieTrade Logs added',
    updatedSingular: 'cieTrade Log updated',
    updatedPlural: 'cieTrade Logs updated',
    deletedSingular: 'cieTrade Log deleted',
    deletedPlural: 'cieTrade Logs deleted',
    archivedSingular: 'cieTrade Log archived',
    archivedPlural: 'cieTrade Logs archived',
    restoredSingular: 'cieTrade Log restored',
    restoredPlural: 'cieTrade Logs restored',
    errorFetchingSingular: 'Error fetching cieTrade Log',
    errorFetchingPlural: 'Error fetching cieTrade Logs',
    errorAddingSingular: 'Error adding cieTrade Log',
    errorAddingPlural: 'Error adding cieTrade Logs',
    errorUpdatingSingular: 'Error updating cieTrade Log',
    errorUpdatingPlural: 'Error updating cieTrade Logs',
    errorDeletingSingular: 'Error deleting cieTrade Log',
    errorDeletingPlural: 'Error deleting cieTrade Logs',
    errorArchivingSingular: 'Error archiving cieTrade Log',
    errorArchivingPlural: 'Error archiving cieTrade Logs',
    errorRestoringSingular: 'Error restoring cieTrade Log',
    errorRestoringPlural: 'Error restoring cieTrade Logs',
    notFoundSingular: 'cieTrade Log not found',
    notFoundPlural: 'No cieTrade Logs found',
    loadingSingular: 'Loading cieTrade Log',
    loadingSingularEllipsis: 'Loading cieTrade Log...',
    loadingPlural: 'Loading cieTrade Logs',
    loadingPluralEllipsis: 'Loading cieTrade Logs...',
    search: 'Search cieTrade Logs',
    searchEllipsis: 'Search cieTrade Logs...',
    filter: 'Filter cieTrade Logs',
    settings: 'cieTrade Logs Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'cietrade/logs',
    readRecord: 'cietrade/log/:id',
    createRecord: 'cietrade/logs',
    updateRecord: 'cietrade/log/:id',
    updateRecords: 'cietrade/logs',
    patchRecord: 'cietrade/log/:id',
    deleteRecord: 'cietrade/log/:id',
    archiveRecord: 'cietrade/log/:id/archive',
    restoreRecord: 'cietrade/log/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
			isInfo: true,
      infoSize: 'large',
      // isTableColumn: true,
    },
		{
      name: 'action',
      label: 'Action',
			type: 'select',
			default: '',
			options: [
        { value: '', label: 'Unknown' },
        { value: 'task_sync', label: 'Task Sync' },
        { value: 'company_update', label: 'Company Update' },
        { value: 'location_update', label: 'Location Update' },
        { value: 'contact_update', label: 'Contact Update' },
			],
      isInfo: true,
      infoSize: 'small',
      isTableColumn: true,
    },
		{
			name: 'user',
			label: 'Performed By',
			type: 'lookup-input',
      model: User,
			default: '',
      readOnly: true,
      isInfo: true,
      infoSize: 'large',
      isTableColumn: true,
		},
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
      isTableColumn: true,
    },
  ];

	static warnings: Record<string, string> = {
		hasErrors: 'There were errors syncing to cieTrade.',
	};

	static getWarnings<T>(record: T): string[] {
		const warnings: string[] = [];
		const errorEntries = this.getErrorEntries(record as unknown as CieTradeLogSchema);
		if (errorEntries.length > 0) {
			errorEntries.forEach(entry => {
				warnings.push(`cieTrade sync error: ${entry.text}`);
			});
		}
		return warnings;
	}

	static getErrorEntries(record: CieTradeLogSchema): CieTradeLogEntrySchema[] {
		return record.entries.filter(entry => (entry.status !== 'success'));
	}
}

export default CieTradeLog;
