import React from 'react';
import classNames from 'classnames';
// @ts-ignore
import chimeURL from '../../assets/audio/chime.mp3';
import './Chime.scss';

export interface Props {
  id?: string;
  className?: string;
}

export interface State {

}

class Chime extends React.Component<Props, State> {

  private audio = React.createRef<HTMLAudioElement>();

  async play() {
    const audio = this.audio.current;
    try {
      await audio?.play();
    } catch(error) {
      console.warn(error);
    }
  }

  render() {
    const { className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-chime', className);
    return (
      <audio ref={this.audio} className={containerClass} {...restProps}>
        <source src={chimeURL} type="audio/mp3" />
      </audio>
    );
  }
}

export default Chime;
