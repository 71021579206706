import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import './FormActions.scss';

export interface Props {
  id?: string;
  className?: string;
  disabled?: boolean;
  submitLabel?: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  deleteLabel?: string;
  isValid?: boolean;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSecondary?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class FormActions extends React.Component<Props, State> {



  render() {
    const { disabled, isValid, submitLabel, secondaryLabel, cancelLabel, deleteLabel, onSubmit, onSecondary, onCancel, onDelete, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-form-actions', className);
    return (
      <div className={containerClass} {...restProps}>
        {(submitLabel || secondaryLabel || cancelLabel || deleteLabel) && (
          <React.Fragment>
            {cancelLabel && (
              <Button 
              variant={'outlined'}
              className="fourg-form-actions__action fourg-form-actions__action--cancel"
              type="button" 
              disabled={disabled} 
              onClick={onCancel}>
                {cancelLabel}
              </Button>
            )}
            {deleteLabel && (
              <Button
              className="fourg-form-actions__action fourg-form-actions__action--delete"
              type="button"
              variant="raised"
              disabled={disabled}
              onClick={onDelete}>
                {deleteLabel}
              </Button>
            )}
            {secondaryLabel && (
              <Button 
              className="fourg-form-actions__action fourg-form-actions__action--secondary"
              type="button" 
              variant="raised"
              disabled={(! isValid || disabled)} 
              onClick={onSecondary}>
                {secondaryLabel}
              </Button>
            )}
            {submitLabel && (
              <Button 
              className="fourg-form-actions__action fourg-form-actions__action--submit"
              type="button" 
              variant="raised"
              disabled={(! isValid || disabled)} 
              onClick={onSubmit}>
                {submitLabel}
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FormActions;
