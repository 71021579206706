import React from 'react';
import classNames from 'classnames';
import Loader from '../Loader/Loader';
import './LoaderPill.scss';

export interface Props {
  id?: string;
  className?: string;
  children: React.ReactNode;
}

export interface State {

}

class LoaderPill extends React.Component<Props, State> {

  render() {
    const { className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-loader-pill', className);
    return (
      <figure className={containerClass} {...restProps}>
        <Loader size={18} />
        <figcaption className="fourg-loader-pill__content">
          {children}
        </figcaption>
      </figure>
    );
  }
}

export default LoaderPill;
