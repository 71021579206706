import React from 'react';
import classNames from 'classnames';
import { Link, RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import FormField from '../../components/FormField/FormField';
import Input from '../../components/Input/Input';
import SusiFormContainer from '../../components/SusiFormContainer/SusiFormContainer';
import { Auth } from '../../types';
import { AuthContext, LayoutContext } from '../../contexts';
import Checkbox from '../../components/Checkbox/Checkbox';
import { State as LayoutState } from '../../components/Layout/Layout';
import './SignInPage.scss';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
	layout: LayoutState;
}

export interface State {
  username: string;
  password: string;
  rememberMe: boolean;
}

class SignInPageBase extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleRememberMeChange = this.handleRememberMeChange.bind(this);
    this.state = {
      username: '',
      password: '',
      rememberMe: true,
    };
  }

	componentDidMount() {
		const { layout } = this.props;
		layout.hideHeader();
		layout.makeBodyScrollable();
	}

	componentWillUnmount() {
		const { layout } = this.props;
		layout.showHeader();
		layout.makeBodyUnscrollable();
	}

  handleUsernameChange(value: string | number) {
    this.setState({ username: value.toString().toLowerCase() });
  }

  handlePasswordChange(value: string | number) {
    this.setState({ password: value.toString() });
  }

  handleRememberMeChange(checked: boolean) {
    this.setState({ rememberMe: checked });
  }

  render() {
    const { auth, className, match, location, history, staticContext, layout, ...restProps } = this.props;
    const { username, password, rememberMe } = this.state;
    const containerClass = classNames('fourg-sign-in-page', className);
    return (
      <Page title="Sign In" className={containerClass} {...restProps}>
				<SusiFormContainer>
					<Form
					title="Sign In"
					submitLabel="Sign In"
					isLoading={auth.isLoading}
					onSubmit={() => auth.signIn(username, password, rememberMe)}>
						<FormField size="large">
							<Input
							name="username"
							label="Username"
							required={true}
							type="text"
							value={username}
							disabled={auth.isLoading}
							onChange={this.handleUsernameChange} />
						</FormField>
						<FormField size="large">
							<Input
							name="password"
							label="Password"
							required={true}
							type="password"
							value={password}
							disabled={auth.isLoading}
							onChange={this.handlePasswordChange} />
						</FormField>
						<FormField className="fourg-sign-in-page__form-bottom">
							<Checkbox
							label="Remember Me"
							disabled={auth.isLoading}
							checked={rememberMe}
							onChange={this.handleRememberMeChange} />
							<Link to="/lost-password">Lost your password?</Link>
						</FormField>
					</Form>
				</SusiFormContainer>
      </Page>
    );
  }
}

const SignInPage: React.FC<Omit<Props, 'auth' | 'layout'>> = (props) => {
	return (
		<AuthContext.Consumer>
			{auth => (
				<LayoutContext.Consumer>
					{(layout) => (
						<SignInPageBase auth={auth} layout={layout} {...props} />
					)}
				</LayoutContext.Consumer>
			)}
		</AuthContext.Consumer>
	);
};

export default SignInPage;
