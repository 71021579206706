import React from 'react';
import classNames from 'classnames';
import Checkbox from '../Checkbox/Checkbox';
import ChipList from '../ChipList/ChipList';
import { ChipCheckboxValue, UIOption } from '../../types';
import './ChipCheckbox.scss';

export interface Props {
  id?: string;
  className?: string;
  label: string;
  description?: string;
  value: ChipCheckboxValue;
  options: UIOption[],
  checked: boolean;
  disabled?: boolean;
	readOnly?: boolean;
  onChange: (checked: boolean, value: ChipCheckboxValue, e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class ChipCheckbox extends React.Component<Props, State> {

  static defaultProps = {
    options: [],
    checked: false,
    onChange: console.info,
  }

  constructor(props: Props) {
    super(props);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChipListChange = this.handleChipListChange.bind(this);
  }

  handleCheckboxChange(checked: boolean, e: React.ChangeEvent<HTMLInputElement>) {
    const { onChange, value } = this.props;
    onChange(checked, value, e);
  }

  handleChipListChange(newValue: string[], e: React.MouseEvent<HTMLButtonElement>) {
    const { onChange, value, checked } = this.props;
    onChange(checked, { ...value, chipValues: newValue }, e);
  }

  render() {
    const { readOnly, disabled, checked, onChange, value, label, description, options, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-chip-checkbox', className);
    return (
      <div className={containerClass} {...restProps}>
        <Checkbox
        disabled={disabled}
        checked={checked}
        label={label}
        onChange={this.handleCheckboxChange}
				readOnly={readOnly} />
        {description && (
          <span>{description}</span>
        )}
        {(checked && options && (options.length > 0)) && (
          <ChipList
          disabled={disabled}
          variant="mini"
          options={options}
          value={value.chipValues || []}
          onChange={this.handleChipListChange}
					readOnly={readOnly} />
        )}
      </div>
    );
  }
}

export default ChipCheckbox;
