import React from 'react';
import classNames from 'classnames';
import LinkifyIt from 'linkify-it';
import { Editor as DraftEditor, EditorState, RichUtils, DraftHandleValue, CompositeDecorator, getDefaultKeyBinding, Modifier, ContentBlock, ContentState, SelectionState, KeyBindingUtil } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import ActionList from '../ActionList/ActionList';
import ActionListItem from '../ActionListItem/ActionListItem';
import User from '../../models/tables/User';
import { UserSchema, UIOption, Lookup, Auth } from '../../types';
import './Editor.scss';
const linkify = LinkifyIt();


interface DecoratorProps<T extends Record<string, any> = Record<string, any>> {
  children: React.ReactNode;
  contentState: ContentState;
  decoratedText: string;
  start: number;
  end: number;
  blockKey: string;
  entityKey: string;
  offsetKey: string;
  data?: T;
}

const UserMentionSpan = (props: DecoratorProps<UserSchema>) => {
  const { offsetKey, children } = props;
  return (
    <span className="fourg-editor__user-mention" data-offset-key={offsetKey}>
      {children}
    </span>
  );
};

const LinkAnchor = (props: DecoratorProps) => {
  const { entityKey, contentState, offsetKey, children } = props;
  const { url }: { url: string } = contentState.getEntity(entityKey).getData();
  return (
    <a
    className="fourg-editor__link"
    data-offset-key={offsetKey}
    target="_blank"
    rel="nofollow noopener noreferrer"
    href={url}>
      {children}
    </a>
  );
}

const LinkifyAnchor = (props: DecoratorProps) => {
  const { decoratedText, offsetKey, children } = props;
  const matches = linkify.match(decoratedText);
  const url = (matches && matches[0]) ? matches[0].url : undefined;
  return (
    <a
    className="fourg-editor__link"
    data-offset-key={offsetKey}
    target="_blank"
    rel="nofollow noopener noreferrer"
    href={url}>
      {children}
    </a>
  );
}

export interface Props {
  id?: string;
  className?: string;
  auth: Auth;
  name?: string;
  label: string;
  variant?: 'default' | 'quiet',
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  isScrollable?: boolean;
  placeholder?: string;
  value: string;
  lookup: Lookup;
  onEnter?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: string) => void;
}

export interface State {
  editorState: EditorState;
  users: UserSchema[];
  readUsersTimer?: number;
  isLoading: boolean;
  isUsersListExpanded: boolean,
  anchorOffset?: number;
}

class Editor extends React.Component<Props, State> {

  private editor = React.createRef<DraftEditor>();

  static defaultProps = {
    value: '',
    variant: 'default',
  };

  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleUserSearchChange = this.handleUserSearchChange.bind(this);
    this.handleUserOptionClick = this.handleUserOptionClick.bind(this);
    this.handleCustomKeyBindings = this.handleCustomKeyBindings.bind(this);
    this.handleBeforeInput = this.handleBeforeInput.bind(this);
    this.handleEditorFocus = this.handleEditorFocus.bind(this);
    this.handleEditorBlur = this.handleEditorBlur.bind(this);
    this.state = {
      editorState: EditorState.createWithContent(this.parseValue(props.value), this.getDecorator()),
      users: [],
      isLoading: false,
      isUsersListExpanded: false,
    };
  }

  setValue(value: string, isForced: boolean = true) {
    const newEditorState = EditorState.createWithContent(this.parseValue(value), this.getDecorator());
    const editorStateWithSelection = this.moveSelectionToEnd(newEditorState, isForced);
    this.setState({ editorState: editorStateWithSelection });
  }

  getValue() {
    const { editorState } = this.state;
    return this.getParsed(editorState.getCurrentContent());
  }

  moveSelectionToEnd(editorState: EditorState, isForced: boolean = true) {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    const newSelection = selection.merge({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    }) as SelectionState;

    if (isForced) {
      return EditorState.forceSelection(editorState, newSelection);
    } else {
      return EditorState.acceptSelection(editorState, newSelection);
    }
  }

  getDecorator() {
    return new CompositeDecorator([
      {
        strategy: this.findEntityRangesByType('USER_MENTION'),
        component: UserMentionSpan,
      },
      {
        strategy: this.findEntityRangesByType('LINK'),
        component: LinkAnchor,
      },
      {
        strategy: this.findWithLinkify,
        component: LinkifyAnchor,
      },
    ]);
  }

  findEntityRangesByType(entityType: string) {
    return (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) return false;
        const entity = contentState.getEntity(entityKey);
        const isSupported = (entity.getType() === entityType);
        return isSupported;
      }, callback);
    }
  }

  findWithLinkify(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) {
    const matches = linkify.match(contentBlock.getText());
    if (matches && (matches.length > 0)) {
      matches.forEach(match => callback(match.index, match.lastIndex));
    }
  }

  findWithRegex(regex: RegExp) {
    return (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
      let match = undefined;
      let start = undefined;
      const text = contentBlock.getText();
      while ((match = regex.exec(text)) !== null) {
        start = match.index;
        callback(start, start + match[0].length);
      }
    }
  }

  parseValue(value: string) {
    const { lookup } = this.props;
    let contentState: ContentState = stateFromMarkdown(value, { parserOptions: { gfm: true } });
    const contentBlocks = contentState.getBlocksAsArray();
    if (contentBlocks.length > 0) {
      contentBlocks.forEach(block => {
        let offset = 0;
        let selectionState = SelectionState.createEmpty(block.getKey());
        const encodedMentions = Array.from(block.getText().matchAll(/@{user:[\w-]+}/g));
        encodedMentions.forEach(encodedMention => {
          const idMatch = encodedMention[0].match(/[^@{user:](.*)(?=})/);
          if (idMatch && idMatch.length > 0) {
            const id = idMatch[0];
            const user = lookup.users?.find(user => user.id === id);
            if (user && typeof encodedMention.index !== 'undefined') {
              const mentionText = `@${User.getRecordLabel(user)}`;
              const delta = mentionText.length - encodedMention[0].length;
              const anchor = encodedMention.index + offset;
              const focus = anchor + encodedMention[0].length;
              selectionState = selectionState.merge({
                anchorOffset: anchor,
                focusOffset: focus,
              }) as SelectionState;
              contentState = Modifier.replaceText(contentState, selectionState, mentionText);
              selectionState = selectionState.merge({
                anchorOffset: anchor,
                focusOffset: (focus + delta),
              }) as SelectionState;
              contentState = contentState.createEntity('USER_MENTION', 'IMMUTABLE', user);
              const entityKey = contentState.getLastCreatedEntityKey();
              contentState = Modifier.applyEntity(contentState, selectionState, entityKey);
              offset = offset + delta;
            }
          }
        });
      });
    }
    return contentState;
  }

  getParsed(contentState: ContentState) {
    const contentBlocks = contentState.getBlocksAsArray();
    if (contentBlocks) {
      contentBlocks.forEach(contentBlock => {
        let offset: number = 0;
        contentBlock.findEntityRanges(
          (character) => {
            const entityKey = character.getEntity();
            if (entityKey === null) return false;
            const entity = contentState.getEntity(entityKey);
            const isSupported = (entity.getType() === 'USER_MENTION');
            return isSupported;
          },
          (start, end) => {
            const newStart = (start + offset);
            const newEnd = (end + offset);
            const entityKey = contentBlock.getEntityAt(start);
            const entity = entityKey ? contentState.getEntity(entityKey) : undefined;
            if (entity) {
              const user: UserSchema = entity.getData();
              const encodedMention = `@{user:${user.id}}`;
              const selectionState = SelectionState.createEmpty(contentBlock.getKey()).merge({
                anchorOffset: newStart,
                focusOffset: newEnd,
              }) as SelectionState;
              contentState = Modifier.replaceText(contentState, selectionState, encodedMention);
              const delta = (encodedMention.length - (end - start));
              offset = (offset + delta);
            }
          }
        );
      });
    }
    const markdown: string = stateToMarkdown(contentState, { gfm: true }) || '';
    return markdown.replace(/\u200B/g, '').trim();
  }

  handleChange(newEditorState: EditorState) {
    const { editorState, anchorOffset } = this.state;
    const contentState = editorState.getCurrentContent();
    const newContentState = newEditorState.getCurrentContent();
    if (contentState !== newContentState) {
      if (anchorOffset !== undefined) {
        const selectionState = newEditorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentAnchorOffset = selectionState.getAnchorOffset();
        const block = newContentState.getBlockForKey(anchorKey)
        const search = block.getText().slice(anchorOffset, currentAnchorOffset);
        this.readUsers(search);
      }
    }
    this.setState({ editorState: newEditorState });
  }

  handleCustomKeyBindings(e: React.KeyboardEvent) {
    const { onEnter } = this.props;
    const { isUsersListExpanded } = this.state;
    let keyBinding: string | null = getDefaultKeyBinding(e);
    if (isUsersListExpanded && (e.key === 'Enter')) {
      keyBinding = 'select-first-mention';
    } else if (onEnter && (e.key === 'Enter') && ! e.shiftKey && ! KeyBindingUtil.hasCommandModifier(e)) {
      keyBinding = 'enter';
    } else if (e.key === 'Escape') {
      keyBinding = 'escape';
    }
    return keyBinding;
  }

  handleKeyCommand(command: string, editorState: EditorState) {
    const { onChange, onEnter, disabled } = this.props;
    const { users } = this.state;
    let handled: DraftHandleValue = 'not-handled';
    switch (command) {
      case 'select-first-mention':
        if (users[0]) {
          this.handleUserOptionClick(users[0]);
        } else {
          this.setState({
            isUsersListExpanded: false,
            anchorOffset: undefined,
          });
        }
        handled = 'handled';
        break;
      case 'enter':
        if (onEnter && ! disabled) {
          const value = this.getParsed(editorState.getCurrentContent());
          onEnter(value);
        }
        handled = 'handled';
        break;
      case 'escape':
        this.setState({
          isUsersListExpanded: false,
          anchorOffset: undefined,
        });
        handled = 'handled';
        break;
      default:
        const newEditorState = RichUtils.handleKeyCommand(editorState, command) as EditorState | null;
        if (newEditorState) {
          this.setState({ editorState: newEditorState }, () => {
						if (onChange) onChange(this.getParsed(newEditorState.getCurrentContent()));
					});
          handled = 'handled';
        }
        break;
    }
    return handled;
  }

  toggleInlineStyle(style: string, e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const { onChange } = this.props;
    const { editorState } = this.state;
    const newEditorState = RichUtils.toggleInlineStyle(editorState, style);
    this.setState({ editorState: newEditorState });
    if (onChange) onChange(this.getParsed(newEditorState.getCurrentContent()));
  }

  toggleBlockType(type: string, e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const { onChange } = this.props;
    const { editorState } = this.state;
    const newEditorState = RichUtils.toggleBlockType(editorState, type);
    this.setState({ editorState: newEditorState });
    if (onChange) onChange(this.getParsed(newEditorState.getCurrentContent()));
  }

  handleUserSearchChange(value: string) {
    const { readUsersTimer } = this.state;
    if (readUsersTimer) window.clearTimeout(readUsersTimer);
    this.setState({
      readUsersTimer: window.setTimeout(() => this.readUsers(value), 500),
    });
  }

  async readUsers(search?: string) {
    const { auth } = this.props;
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { data } = await User.readRecords<UserSchema>(token, {
        limit: 5,
        search: search,
      });
      this.setState({
        isLoading: false,
        users: data,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        users: [],
      });
    }
  }

  getUserOptions() {
    const { users, isLoading } = this.state;
    const options: UIOption[] = [];
    if (isLoading) {
      options.push({
        value: '',
        label: User.getLabel('loadingPlural'),
      });
    } else if (users.length < 1) {
      options.push({
        value: '',
        label: User.getLabel('notFoundPlural'),
      });
    } else {
      users.forEach(user => options.push({
        value: User.getRecordValue<UserSchema>(user).toString(),
        label: User.getRecordLabel(user),
        icon: { icon: 'account_circle', cover: User.getRecordImage<UserSchema>(user) },
      }));
    }
    return options;
  }

  handleUserOptionClick(user?: UserSchema) {
    const { onChange } = this.props;
    if (user) {
      const { editorState, anchorOffset } = this.state;
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const mentionText = `@${User.getRecordLabel(user)}`;
      // Select the search text.
      const newFocusOffset = (anchorOffset !== undefined) ? anchorOffset - 1 : undefined;
      const newSelectionState = selectionState.merge({
        focusOffset: newFocusOffset,
        isBackward: true,
      }) as SelectionState;
      // Replace the search text with the plain text mention.
      const contentStateWithReplacement = Modifier.replaceText(contentState, newSelectionState, mentionText);
      const editorStateWithReplacement = EditorState.push(editorState, contentStateWithReplacement, 'insert-characters');
      // Select the plain text mention.
      const entityAnchorOffset = (newFocusOffset !== undefined) ? newFocusOffset + mentionText.length : undefined;
      const entitySelectionState = newSelectionState.set('anchorOffset', entityAnchorOffset) as SelectionState;
      // Create the entity and apply it to the plain text mention selection.
      const contentStateWithEntity = contentStateWithReplacement.createEntity('USER_MENTION', 'IMMUTABLE', user);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithMention = Modifier.applyEntity(contentStateWithEntity, entitySelectionState, entityKey);
      const editorStateWithEntity = EditorState.push(editorStateWithReplacement, contentStateWithMention, 'apply-entity');
      // Set the new cursor positon.
      const finishedAnchorOffset = (entityAnchorOffset !== undefined) ? entityAnchorOffset : undefined;
      const finishedSelectionState = entitySelectionState.merge({
        anchorOffset: finishedAnchorOffset,
        focusOffset: finishedAnchorOffset,
        isBackward: false,
      }) as SelectionState;
      const newEditorState = EditorState.forceSelection(editorStateWithEntity, finishedSelectionState);
      // Set the state.
      this.setState({
        anchorOffset: undefined,
        isUsersListExpanded: false,
        editorState: newEditorState,
      });
      if (onChange) onChange(this.getParsed(newEditorState.getCurrentContent()));
    }
  }

  focus() {
    const editor = this.editor.current;
    editor?.focus();
  }

  blur() {
    const editor = this.editor.current;
    editor?.blur();
  }

  handleBeforeInput(chars: string, editorState: EditorState, eventTimeStamp: number) {
    let handled: DraftHandleValue = 'not-handled';
    let selectionState: SelectionState;
    switch (chars) {
      case '@':
        selectionState = editorState.getSelection();
        this.setState({
          isUsersListExpanded: true,
          users: [],
          anchorOffset: (selectionState.getAnchorOffset() + 1),
        });
        this.readUsers();
        break;
      case ' ':
        selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const contentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());
        const anchorOffset = (selectionState.getAnchorOffset() - 1);
        const character = contentBlock.getText().charAt(anchorOffset);
        if (character === '@') {
          this.setState({
            anchorOffset: undefined,
            isUsersListExpanded: false,
          });
        }
        break;
      default:
        break;
    }
    return handled;
  }

  getBlockType() {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());
    return contentBlock.getType();
  }

  isDirty() {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    return contentState.hasText();
  }

  getBlockClasses(block: ContentBlock) {
    switch(block.getType()) {
      case 'unordered-list-item': return 'fourg-editor__list-item';
      case 'ordered-list-item': return 'fourg-editor__list-item';
      default: return 'fourg-editor__block';
    }
  }

  handleEditorFocus() {
    const { onFocus } = this.props;
    if (onFocus) onFocus();
  }

  handleEditorBlur() {
    const { onBlur } = this.props;
    if (onBlur) onBlur();
  }

  render() {
    const { auth, placeholder, label, value, variant, readOnly, required, disabled, lookup, isScrollable, onChange, onFocus, onBlur, onEnter, className, ...restProps } = this.props;
    const { isUsersListExpanded, editorState, isLoading, users } = this.state;
    const userOptions = this.getUserOptions();
    const blockType = this.getBlockType();
    const inlineStyle = editorState.getCurrentInlineStyle();
    const isDirty = this.isDirty();
    const containerClass = classNames('fourg-editor', `fourg-editor--variant-${variant}`, {
      'fourg-editor--disabled': disabled,
      'fourg-editor--read-only': readOnly,
      'fourg-editor--invalid': (required && ! isDirty),
      'fourg-editor--dirty': isDirty,
      'fourg-editor--scrollable': isScrollable,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        <label className="fourg-editor__label">{label}</label>
        <DraftEditor
        placeholder={placeholder}
        readOnly={readOnly}
        ref={this.editor}
        editorState={editorState}
        onChange={this.handleChange}
        handleKeyCommand={this.handleKeyCommand}
        keyBindingFn={this.handleCustomKeyBindings}
        blockStyleFn={this.getBlockClasses}
        handleBeforeInput={this.handleBeforeInput}
        onFocus={this.handleEditorFocus}
        onBlur={this.handleEditorBlur} />
        {! readOnly && (
          <ActionList isExpanded={isUsersListExpanded} anchor="bottom-left">
            {userOptions.map(option => (
              <ActionListItem
              key={`user_mention_${option.value}`}
              onClick={(value, e) => this.handleUserOptionClick(users.find(user => user.id === value))}
              {...option} />
            ))}
          </ActionList>
        )}
        {! readOnly && (
          <div className="fourg-editor__actions" onClick={() => this.focus()}>
            <Button
            disabled={disabled}
            isIconOnly={true}
            icon={{ icon: 'format_underline' }}
            isActive={inlineStyle.has('UNDERLINE')}
            onMouseDown={e => this.toggleInlineStyle('UNDERLINE', e)}>
              {'Underline'}
            </Button>
            <Button
            disabled={disabled}
            isIconOnly={true}
            icon={{ icon: 'format_bold' }}
            isActive={inlineStyle.has('BOLD')}
            onMouseDown={e => this.toggleInlineStyle('BOLD', e)}>
              {'Bold'}
            </Button>
            <Button
            disabled={disabled}
            isIconOnly={true}
            icon={{ icon: 'format_italic' }}
            isActive={inlineStyle.has('ITALIC')}
            onMouseDown={e => this.toggleInlineStyle('ITALIC', e)}>
              {'Italic'}
            </Button>
            <Button
            disabled={disabled}
            isIconOnly={true}
            icon={{ icon: 'format_strikethrough' }}
            isActive={inlineStyle.has('STRIKETHROUGH')}
            onMouseDown={e => this.toggleInlineStyle('STRIKETHROUGH', e)}>
              {'Strike Through'}
            </Button>
            <Button
            disabled={disabled}
            isIconOnly={true}
            icon={{ icon: 'code' }}
            isActive={inlineStyle.has('CODE')}
            onMouseDown={e => this.toggleInlineStyle('CODE', e)}>
              {'Code'}
            </Button>
            <Button
            disabled={disabled}
            isIconOnly={true}
            icon={{ icon: 'format_list_bulleted' }}
            isActive={(blockType === 'unordered-list-item')}
            onMouseDown={e => this.toggleBlockType('unordered-list-item', e)}>
              {'Unordered List Item'}
            </Button>
            <Button
            disabled={disabled}
            isIconOnly={true}
            icon={{ icon: 'format_list_numbered' }}
            isActive={(blockType === 'ordered-list-item')}
            onMouseDown={e => this.toggleBlockType('ordered-list-item', e)}>
              {'Ordered List Item'}
            </Button>
            {isLoading && (
              <Loader className="fourg-editor__loader" size={18} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Editor;
