import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';
import AutoParagraph from '../AutoParagraph/AutoParagraph';
import IconMessage from '../IconMessage/IconMessage';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Form from '../Form/Form';
import FormField from '../FormField/FormField';
import Button from '../Button/Button';
import Input from '../Input/Input';
import User from '../../models/tables/User';
import { Auth, UserSchema } from '../../types';
import './ResetPasswordDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  recordID?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isUpdating: boolean;
  isLoading: boolean;
  isPasswordReset: boolean;
  password: string;
  record?: UserSchema;
}

class ResetPasswordDialog extends React.Component<Props, State> {
  
  private passwordInput = React.createRef<Input>();

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleGeneratePassword = this.handleGeneratePassword.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSendPasswordClick = this.handleSendPasswordClick.bind(this);
    this.state = {
      isUpdating: false,
      isLoading: false,
      isPasswordReset: false,
      password: '',
      record: undefined,
    };
  }

  componentDidMount() {
    const { recordID, isOpen } = this.props;
    if (isOpen && recordID) {
      this.readRecord();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen, recordID } = this.props;
    if (! prevProps.isOpen && isOpen) {
      if (recordID) {
        this.readRecord();
      } else {
        this.setState({ record: undefined });
      }
    }
    if (prevProps.isOpen !== isOpen) {
      this.resetValidity();
    }
  }

  generatePassword(length: number = 24) {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789()[]{}_-+=?!@#%&^*';
		// ensure the password matches the pattern.
    let password = 'aA0!';
    for (var i = 0, n = characters.length; i < length; ++i) {
			password += characters.charAt(Math.floor(Math.random() * n));
    }
    return password;
  }

  resetValidity() {
    const passwordInput = this.passwordInput.current;
    passwordInput?.resetValidity();
  }

  async readRecord() {
    const { auth, recordID } = this.props;
    if (recordID) {
      this.setState({ isLoading: true });
      try {
        const token = await auth.getToken();
        const { data } = await User.readRecord<UserSchema>(token, recordID);
        this.setState({
          isLoading: false,
          record: data,
          isPasswordReset: false,
          password: '',
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ 
          isLoading: false,
          record: undefined, 
          isPasswordReset: false,
          password: '',
        });
      }
    }
  }

  async resetPassword() {
    const { auth, recordID } = this.props;
    const { password } = this.state;
    if (recordID) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        await User.forcePasswordReset(token, recordID, password);
        toast.success(User.getLabel('updatedSingular'));
        this.setState({ 
          isUpdating: false,
          isPasswordReset: true, 
        });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  handleFormSubmit() {
    this.resetPassword();
  }

  handlePasswordChange(value: string | number) {
    this.setState({ password: value.toString() });
  }
  
  handleGeneratePassword() {
    this.setState({ 
      password: this.generatePassword(24), 
    });
  }
  
  handleSendPasswordClick(e: React.MouseEvent<HTMLButtonElement>) {
    const { record, password } = this.state;
    if (record) {
      const subject = 'Your temporary password.';
      const body = `Your temporary password is: ${password}\n\nPlease login using this password, and reset your password to something secure when prompted.`;
      window.open(`mailto:${record.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
    }
  }

  render() {
    const { disabled, isOpen, auth, recordID, className, onFormCancel, ...restProps } = this.props;
    const { isLoading, isPasswordReset, password, isUpdating, record } = this.state;
    const containerClass = classNames('fourg-reset-password-dialog', className);
    const description = 'The password must be at least 8 characters long and must include at least one lowercase letter, uppercase letter, number, and special character.';
    return (
      <Dialog 
      className={containerClass}
      title={'Reset Password'}
      isOpen={isOpen}
      {...restProps}>
        {isPasswordReset ? (
          <IconMessage 
          className="fourg-reset-password-dialog__complete-message"
          icon={{ icon: 'lock' }}
          heading={'The password has been reset'}
          subheading={'The user will need to know their temporary password to login.'}>
            <Button 
            variant="raised" 
            onClick={onFormCancel}>
              {'Done'}
            </Button>
            <Button 
            variant="raised" 
            onClick={this.handleSendPasswordClick}>
              {'Send Password'}
            </Button>
          </IconMessage>
        ) : (
          <Form
          className="fourg-reset-password-dialog__form"
          disabled={(! record || isPasswordReset || disabled || isLoading || isUpdating || ! isOpen)}
          submitLabel={'Save'}
          deleteLabel={'Generate'}
          cancelLabel={'Close'}
          onCancel={onFormCancel}
          onDelete={this.handleGeneratePassword}
          onSubmit={this.handleFormSubmit}>
            <FormField size="large">
              <Input 
              ref={this.passwordInput}
              type="text" 
              required={true}
              label={'Password'}
              disabled={(! record || isPasswordReset || isLoading || disabled || isUpdating || ! isOpen)}
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$\x22*.[\]{}()?!@#%&/\\,><':;|_~])\S{8,99}"
              title={description}
              value={password}
              onChange={this.handlePasswordChange} />
              <AutoParagraph className="fourg-reset-password-dialog__description" text={description} />
            </FormField>
          </Form>
        )}
        {isLoading && (
          <Loader 
          position="absolute" 
          className="fourg-reset-password-dialog__loader" />
        )}
      </Dialog>
    );
  }
}

export default ResetPasswordDialog;
