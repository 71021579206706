import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../Button/Button';
import LoaderPill from '../LoaderPill/LoaderPill';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import CardList from '../CardList/CardList';
import CardListItem from '../CardListItem/CardListItem';
import NotificationCard from '../NotificationCard/NotificationCard';
import IconMessage from '../IconMessage/IconMessage';
import Notification from '../../models/tables/Notification';
import { Notifications, TaskSchema } from '../../types';
import './NotificationsDialog.scss';

export interface Props extends DialogProps, RouteComponentProps {
  notifications: Notifications;
}

export interface State {

}

class NotificationsDialog extends React.Component<Props, State> {

  private scroller = React.createRef<HTMLDivElement>();

  static defaultProps = {
    ...Dialog.defaultProps,
  };

  constructor(props: Props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSubtaskCardClick = this.handleSubtaskCardClick.bind(this);
  }

  handleScroll(e: React.UIEvent<HTMLDivElement>) {
    const { notifications } = this.props;
    const scroller = this.scroller.current;
    if (scroller 
    && (Math.ceil(scroller.scrollTop) >= (scroller.scrollHeight - scroller.clientHeight))
    && ! notifications.isPaginating  
    && (notifications.notifications.length < notifications.total)) {
      notifications.readMoreNotifications();
    }
  }

  handleSubtaskCardClick(subtask: TaskSchema) {
    const { history, notifications } = this.props;
    history.push(`/tasks/${subtask.id}`);
    notifications.closeDialog();
  }

  render() {
    const { notifications, isOpen, location, history, match, staticContext, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-notifications-dialog', className);
    return (
      <Dialog 
      isOpen={isOpen}
      className={containerClass}
      {...restProps}>
        <div className="fourg-notifications-dialog__cards">
          <div
          ref={this.scroller}
          className="fourg-notifications-dialog__scroller"
          onScroll={this.handleScroll}>
            {(notifications.notifications.length > 0) && (
              <CardList>
                {notifications.notifications.map(notification => (
                  <CardListItem key={`notification-${notification.id}`} >
                    <NotificationCard
                    record={notification}
                    disabled={! isOpen || notifications.isUpdating || notifications.isLoading}
                    onReadChange={(id, read) => read ? notifications.markAsRead(id) : notifications.markAsUnread(id)}
                    onSubtaskCardClick={this.handleSubtaskCardClick} />
                  </CardListItem>
                ))}
              </CardList>
            )}
          </div>
          <div className="fourg-notifications-dialog__end">
            {notifications.isPaginating && (
              <LoaderPill>{'Loading more...'}</LoaderPill>
            )}
          </div>
          {(! notifications.isUpdating && ! notifications.isLoading && (notifications.notifications.length < 1)) && (
            <IconMessage
            className="fourg-notifications-dialog__not-found-message"
            icon={{ icon: 'notifications_off' }}
            heading={Notification.getLabel('notFoundPlural')}
            subheading={'It looks like you\'re all caught up!'} />
          )}
        </div>
        <div className="fourg-notifications-dialog__actions">
          <Button 
          disabled={!isOpen || notifications.isUpdating || notifications.isLoading || (notifications.unreadCount < 1)}
          variant="raised"
          onClick={e => notifications.markManyAsRead()}>
            {'Mark All as Read'}
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withRouter(NotificationsDialog);
