import React from 'react';
import classNames from 'classnames';
import { Columns } from '../../types';
import './CardList.scss';

export interface Props {
  id?: string;
  className?: string;
  children?: React.ReactNode;
  columns: Columns;
  title?: React.ReactNode;
	count?: number;
	actions?: React.ReactNode;
	gutterSize: 's' | 'm' | 'stretch';
}

export interface State {

}

class CardList extends React.Component<Props, State> {

  static defaultProps = {
		gutterSize: 'm',
    columns: {
      desktop: 12,
      laptop: 12,
      tablet: 12,
      phone: 12,
    },
  };

  render() {
    const { title, columns, className, children, count, actions, gutterSize, ...restProps } = this.props;
    const containerClass = classNames('fourg-card-list', `fourg-card-list--gutter-size-${gutterSize}`, [
      `fourg-card-list--columns-desktop-${columns.desktop}`,
      `fourg-card-list--columns-laptop-${columns.laptop}`,
      `fourg-card-list--columns-tablet-${columns.tablet}`,
      `fourg-card-list--columns-phone-${columns.phone}`,
    ], className);
    return (
      <div className={containerClass} {...restProps}>
				{(title || (count !== undefined) || actions) && (
					<div className="fourg-card-list__header">
						{title && (
							<h3 className="fourg-card-list__title">{title}</h3>
						)}
						{(count !== undefined) && (
							<span className="fourg-card-list__count">{count}</span>
						)}
						{actions && (
							<div className="fourg-card-list__actions">
								{actions}
							</div>
						)}
					</div>
				)}
        <ul className="fourg-card-list__items">
          {children}
        </ul>
      </div>
    );
  }
}

export default CardList;
