import React from 'react';
import classNames from 'classnames';
import Table from '../Table/Table';
import { TableColumn } from '../../types';
import './TableGroup.scss';

export interface Props<T extends Record<string, any> = Record<string, any>> {
  id?: string;
  className?: string;
  isScrollable?: boolean;
  children: React.ReactElement | React.ReactElement[];
  columns?: TableColumn<T>[];
  order?: string;
  hasActions?: boolean;
	hasWarnings?: boolean;
  onOrderChange?: (order: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class TableGroup<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State> {

  static defaultProps = {
    isScrollable: false,
  };

  render() {
    const { hasActions, hasWarnings, isScrollable, columns, order, onOrderChange, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-table-group', {
      'fourg-table-group--scrollable': isScrollable,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-table-group__content">
          <div className="fourg-table-group__scroller">
            <div className="fourg-table-group__header">
              <Table
              getRowActions={hasActions ? () => [] : undefined}
              getRowWarnings={hasWarnings ? () => [] : undefined}
              columns={columns}
              onOrderChange={onOrderChange}
              order={order}
              notFoundHeading={'No records found'} />
            </div>
            <ul className="fourg-table-group__list">
              {children}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default TableGroup;
