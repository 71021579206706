import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './SidebarSection.scss';

export interface Props {
  id?: string;
  className?: string;
  label: string;
  count?: number;
  disabled?: boolean;
  isExpandedOnMount: boolean;
}

export interface State {
  isExpanded: boolean;
}

class SidebarSection extends React.Component<Props, State> {

  private toggle = React.createRef<HTMLButtonElement>();

  static defaultProps = {
    isExpandedOnMount: true,
  };

  constructor(props: Props) {
    super(props);
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.state = {
      isExpanded: props.isExpandedOnMount,
    };
  }

  handleToggleClick(e: React.MouseEvent<HTMLButtonElement>) {
    this.setState(({ isExpanded }) => {
      return {
        isExpanded: ! isExpanded,
      };
    });
  }

  render() {
    const { disabled, label, count, className, isExpandedOnMount, children, ...restProps } = this.props;
    const { isExpanded } = this.state;
    const containerClass = classNames('fourg-sidebar-section', {
      'fourg-sidebar-section--expanded': isExpanded,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        <button 
        disabled={disabled}
        ref={this.toggle} 
        className="fourg-sidebar-section__toggle"
        onClick={this.handleToggleClick}>
          <h2 className="fourg-sidebar-section__label">{label}</h2>
          {(count !== undefined) && (
            <span className="fourg-sidebar-section__count">{count}</span>
          )}
          <Icon
          className="fourg-sidebar-section__toggle-icon"
          src={{ icon: 'arrow_drop_down' }}
          label={'Expand'} />
        </button>
        <div className="fourg-sidebar-section__content">
          {children}
        </div>
        <hr className="fourg-sidebar-section__sep" />
      </div>
    );
  }
}

export default SidebarSection;
