import React from 'react';
import classNames from 'classnames';
import { ActionMeta, ControlProps, GroupBase, OptionProps, SingleValue, SingleValueProps } from 'react-select';
import SingleSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import Icon, { Props as IconProps } from '../Icon/Icon';
import SelectItem from '../SelectItem/SelectItem';
import { UIOption } from '../../types';
import Select from 'react-select/dist/declarations/src/Select';
import './SelectInput.scss';

export interface Props {
	id?: string;
  className?: string;
  name?: string;
  // variant?: 'default' | 'quiet' | 'mini' | 'link',
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  label: string;
  options: UIOption[];
  value: string;
	isCreatable?: boolean;
  icon?: IconProps['src'];
	isLoading?: boolean;
	loadingMessage?: (data: { inputValue: string }) => React.ReactNode;
	noOptionsMessage?: (data: { inputValue: string }) => React.ReactNode;
	onLoadOptions?: (inputValue: string) => Promise<UIOption[]>;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (value: string, e?: any) => void;
}

export interface State {
	isFocused: boolean;
	isValid: boolean;
}

class SelectInput extends React.Component<Props, State> {

	private select = React.createRef<Select<UIOption, false, GroupBase<UIOption>>>();
	private validator = React.createRef<HTMLInputElement>();

	static defaultProps = {
		options: [],
		onChange: console.info,
		value: '',
	};

	constructor(props: Props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			isFocused: false,
			isValid: true,
		};
	}

	componentDidUpdate(prevProps: Props) {
		const { value, required, readOnly, disabled } = this.props;
		const { isValid } = this.state;
		if ((prevProps.value !== value)
		|| (prevProps.required !== required)
		|| (prevProps.readOnly !== readOnly)
		|| (prevProps.disabled !== disabled)) {
			const newIsValid = this.isValid();
			if (isValid !== newIsValid) {
				this.setState({ isValid: newIsValid });
			}
		}
	}

	handleChange(newValue: SingleValue<UIOption>, _actionMeta: ActionMeta<UIOption>) {
		const { onChange } = this.props;
		onChange(newValue?.value || '');
	}

	focus() {
		const select = this.select.current;
		select?.focus();
	}

	blur() {
		const select = this.select.current;
		select?.blur();
	}

	getSelectedOption(): UIOption | undefined {
		const { value } = this.props;
		return this.getOptions().find(option => option.value === value);
	}

	parseNewOption(inputValue: string, prefix?: string, icon?: IconProps['src']): UIOption {
		return {
			label: Boolean(prefix) ? `${prefix}: "${inputValue}"` : inputValue,
			value: inputValue,
			icon: icon,
		};
	}

	getOptions(): UIOption[] {
		const { value, options, isCreatable } = this.props;
		const newOptions: UIOption[] = [];
		if (isCreatable && ! Boolean(options.find(o => o.value === value))) {
			newOptions.push(this.parseNewOption(value));
		}
		newOptions.push(...options);
		return newOptions;
	}

	setIsFocused(isFocused: boolean) {
		const { onFocus, onBlur } = this.props;
		this.setState({ isFocused: isFocused }, () => {
			if (isFocused && onFocus) onFocus();
			if (! isFocused && onBlur) onBlur();
		});
	}

	getComponent(): CreatableSelect | SingleSelect | AsyncSelect {
		const { isCreatable, onLoadOptions } = this.props;
		if (onLoadOptions) return AsyncSelect;
		if (isCreatable) return CreatableSelect;
		return SingleSelect;
	}

	isClearable() {
		const { value, required, readOnly } = this.props;
		return ! required && ! readOnly && Boolean(value) && Boolean(this.getOptions().find(o => ! o.value));
	}

	resetValidity() {
    this.setState({ isValid: true });
  }

  isValid() {
    const validator = this.validator.current;
    return validator?.checkValidity() || false;
  }

  render() {
    const { className, name, required, readOnly, disabled, label, options, value, icon, children, isCreatable, isLoading, noOptionsMessage, loadingMessage, onChange, onFocus, onBlur, onLoadOptions, ...restProps } = this.props;
    const { isFocused, isValid } = this.state;
		const containerClass = classNames('fourg-select-input', {
			'fourg-select-input--dirty': Boolean(value),
			'fourg-select-input--focused': isFocused,
			'fourg-select-input--disabled': (disabled || readOnly),
      'fourg-select-input--invalid': ! isValid,
			'fourg-select-input--has-icon': Boolean(icon),
		}, className);
		const Component = this.getComponent();
    return (
			<div className={containerClass} {...restProps}>
					<label className="fourg-select-input__label">
						{`${label}${required ? '*' : ''}`}
					</label>
					<SelectInputContext.Provider value={this.props}>
						<Component 
						ref={this.select}
						name={name}
						menuPosition="fixed"
						isDisabled={disabled}
						options={this.getOptions()}
						value={this.getSelectedOption()}
						getOptionValue={(option) => option.value}
						getOptionLabel={(option) => option.label}
						isOptionDisabled={(option) => Boolean(option.disabled || disabled || readOnly)}
						menuPortalTarget={document.body}
						closeMenuOnScroll={true}
						closeMenuOnSelect={true}
						menuShouldScrollIntoView={false}
						getNewOptionData={(newInputValue) => this.parseNewOption(newInputValue, 'Add custom', { icon: 'add_circle' })}
						onChange={this.handleChange}
						classNamePrefix="fourg-select-input__rs"
						onFocus={() => this.setIsFocused(true)}
						onBlur={() => this.setIsFocused(false)}
						loadOptions={onLoadOptions}
						loadingMessage={loadingMessage}
						noOptionsMessage={noOptionsMessage}
						cacheOptions={true}
						defaultOptions={true}
						isClearable={this.isClearable()}
						menuPlacement="auto"
						isLoading={isLoading}
						components={{
							Option: CustomOption,
							SingleValue: CustomSingleValue,
							Control: CustomControl,
						}} />
					</SelectInputContext.Provider>
					<input 
					ref={this.validator}
					className="fourg-select-input__validator" 
					value={value} 
					disabled={disabled}
					required={required} 
					readOnly={readOnly}
					onChange={() => {}}
					tabIndex={-1} />
				</div>
    );
  }
}

const SelectInputContext = React.createContext<Partial<Props>>({});

const CustomControl: React.FC<ControlProps<UIOption, false, GroupBase<UIOption>>> = (props) => {
	const { children } = props;
	return (
		<SelectInputContext.Consumer>
			{(contextProps) => (
				<components.Control {...props}>
					<React.Fragment>
						{contextProps.icon && (
							<Icon 
							className="fourg-select-input__control-icon"
							src={contextProps.icon} 
							label={contextProps.label || ''} /> 
						)}
						{children}
					</React.Fragment>
				</components.Control>
			)}
		</SelectInputContext.Consumer>
	);
}

const CustomOption: React.FC<OptionProps<UIOption, false, GroupBase<UIOption>>> = (props) => {
	const { data, children, isSelected, isFocused, isDisabled } = props;
	return (
		<components.Option {...props}>
			<SelectItem 
			label={data.label} 
			icon={data.icon} 
			disabled={isDisabled}
			isActive={isSelected} 
			isFocused={isFocused}>
				{data.label || children}
			</SelectItem>
		</components.Option>
	);
}

const CustomSingleValue: React.FC<SingleValueProps<UIOption, false, GroupBase<UIOption>>> = (props) => {
	const { data, children } = props;
	return (
		<components.SingleValue {...props}>
			{data.icon && (
				<Icon src={data.icon} label={data.label} />
			)}
			<span>{data.label || children}</span>
		</components.SingleValue>
	);
}

export default SelectInput;
