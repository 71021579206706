import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './PageNumbers.scss';

export interface Props {
  id?: string;
  className?: string;
  page: number;
  totalPages: number;
  onChange: (page: number, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class PageNumbers extends React.Component<Props, State> {

  static defaultProps = {
    onChange: console.info,
  };

  getPageNumbers() {
    const { page, totalPages } = this.props;
    const numbers: number[] = [];
    for (let i = (page - 2); i <= (page + 2); i++) {
      if ((i >= 1) && (i <= totalPages)) {
        numbers.push(i);
      }
    }
    return numbers;
  }

  render() {
    const { page, totalPages, onChange, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-page-numbers', className);
    const pageNumbers = this.getPageNumbers();
    return (
      <nav className={containerClass} {...restProps}>
        <ul className="fourg-page-numbers__list">
          <li className="fourg-page-numbers__list-item">
            <button 
            className="fourg-page-numbers__action fourg-page-numbers__action--start" 
            onClick={e => onChange(1, e)} 
            disabled={(page === 1)}>
              <Icon 
              className="fourg-page-numbers__action-icon" 
              src={{ icon: 'arrow_drop_down' }} 
              label={'Start'} />
              <span className="fourg-page-numbers__action-label">{'Start'}</span>
            </button>
          </li>
          <li className="fourg-page-numbers__list-item fourg-page-numbers__action--prev">
            <button 
            className="fourg-page-numbers__action" 
            onClick={e => onChange(page - 1, e)} 
            disabled={(page <= 1)}>
              <Icon 
              className="fourg-page-numbers__action-icon" 
              src={{ icon: 'arrow_drop_down' }} 
              label={'Previous'} />
              <span className="fourg-page-numbers__action-label">{'Previous'}</span>
            </button>
          </li>
          {(pageNumbers.length > 0) && pageNumbers.map((pageNumber) => (
            <li 
            key={`page-number-${pageNumber}`} 
            className="fourg-page-numbers__list-item">
              <button 
              className="fourg-page-numbers__action fourg-page-numbers__action--number" 
              onClick={e => onChange(pageNumber, e)}
              disabled={(page === pageNumber)}>
                <span className="fourg-page-numbers__action-label">{pageNumber.toString()}</span>
              </button>
            </li>
          ))}
          <li className="fourg-page-numbers__list-item fourg-page-numbers__action--next">
            <button 
            className="fourg-page-numbers__action" 
            onClick={e => onChange(page + 1, e)} 
            disabled={(page >= totalPages)}>
              <span className="fourg-page-numbers__action-label">{'Next'}</span>
              <Icon 
              className="fourg-page-numbers__action-icon" 
              src={{ icon: 'arrow_drop_down' }} 
              label={'Next'} />
            </button>
          </li>
          <li className="fourg-page-numbers__list-item">
            <button 
            className="fourg-page-numbers__action fourg-page-numbers__action--end" 
            onClick={e => onChange(totalPages, e)} 
            disabled={(page === totalPages)}>
              <span className="fourg-page-numbers__action-label">{'End'}</span>
              <Icon 
              className="fourg-page-numbers__action-icon" 
              src={{ icon: 'arrow_drop_down' }} 
              label={'End'} />
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default PageNumbers;
