import React from 'react';
import classNames from 'classnames';
import Input from '../Input/Input';
import TabBar from '../TabBar/TabBar';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import CardList from '../CardList/CardList';
import CardListItem from '../CardListItem/CardListItem';
import ReleaseCard from '../ReleaseCard/ReleaseCard';
import DocCard from '../DocCard/DocCard';
import releaseNotes from '../../assets/json/release-notes.json';
import companyDocs from '../../assets/json/company-docs.json';
import locationDocs from '../../assets/json/location-docs.json';
import contactDocs from '../../assets/json/contact-docs.json';
import locationAttachmentDocs from '../../assets/json/location-attachment-docs.json';
import taskDocs from '../../assets/json/task-docs.json';
import subtaskDocs from '../../assets/json/subtask-docs.json';
import taskBoardDocs from '../../assets/json/task-board-docs.json';
import taskAttachmentDocs from '../../assets/json/task-attachment-docs.json';
import reminderDocs from '../../assets/json/reminder-docs.json';
import dashboardDocs from '../../assets/json/dashboard-docs.json';
import accountDocs from '../../assets/json/account-docs.json';
import userDocs from '../../assets/json/user-docs.json';
import pnlDocs from '../../assets/json/pnl-docs.json';
import notificationDocs from '../../assets/json/notification-docs.json';
import outlookAddinDocs from '../../assets/json/outlook-addin-docs.json';
import { Doc, Release, UIOption } from '../../types';
import './AboutDialog.scss';

export interface Props extends DialogProps {
  id?: string;
  className?: string;
}

export interface State {
  tab: UIOption['value'];
  searchValue: string;
}

class AboutDialog extends React.Component<Props, State> {

  private scroller = React.createRef<HTMLDivElement>();

  static defaultProps = {
    ...Dialog.defaultProps,
  };

  constructor(props: Props) {
    super(props);
    this.handleTabBarChange = this.handleTabBarChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state ={
      tab: 'release-notes',
      searchValue: '',
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { tab, searchValue } = this.state;
    if ((prevState.tab !== tab)
    || (prevState.searchValue !== searchValue)) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const scroller = this.scroller.current;
    if (scroller) {
      scroller.scrollTo(0, 0);
    }
  }

  handleTabBarChange(value: UIOption['value']) {
    this.setState({ 
      tab: value,
      searchValue: '', 
    });
  }
  
  handleSearchChange(value: string) {
    this.setState({ 
      searchValue: value, 
    });
  }

  isDocRelevant(doc: Doc) {
    const { searchValue } = this.state;
    let isDocRelevant = true;
    if (searchValue) {
      isDocRelevant = (doc.title.toLowerCase().includes(searchValue.toLowerCase()) || doc.description.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return isDocRelevant;
  }

  render() {
    const { isOpen, className, children, ...restProps } = this.props;
    const { tab, searchValue } = this.state;
    const containerClass = classNames('fourg-about-dialog', className);
    const releases: Release[] = Array.isArray(releaseNotes) ? releaseNotes : [];
    return (
      <Dialog 
      className={containerClass} 
      isOpen={isOpen}
      {...restProps}>
        <TabBar
        className="fourg-about-dialog__tab-bar"
        value={tab}
        options={[
          { value: 'release-notes', label: 'Release Notes' },
          { value: 'account-docs', label: 'My Account' },
          { value: 'user-docs', label: 'Users' },
          { value: 'company-docs', label: 'Companies' },
          { value: 'location-docs', label: 'Locations' },
          { value: 'contact-docs', label: 'Contacts' },
          { value: 'location-attachment-docs', label: 'Location Attachments' },
          { value: 'task-docs', label: 'Tasks' },
          { value: 'task-board-docs', label: 'Task Boards' },
          { value: 'subtask-docs', label: 'Subtasks' },
          { value: 'reminder-docs', label: 'To-Dos' },
          { value: 'task-attachment-docs', label: 'Task Attachments' },
          { value: 'pnl-docs', label: 'Profit & Loss' },
          { value: 'dashboard-docs', label: 'Dashboard' },
          { value: 'notification-docs', label: 'Notifications' },
          { value: 'outlook-addin-docs', label: 'Outlook Add-in' },
        ]}
        onChange={this.handleTabBarChange} />
        <div ref={this.scroller} className="fourg-about-dialog__scroller">
          {(tab === 'release-notes') && (
            <CardList>
              {releases.map(release => (
                <CardListItem key={`release-${release.version}`} >
                  <ReleaseCard release={release} />
                </CardListItem>
              ))}
            </CardList>
          )}
          {(tab === 'account-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {accountDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`account-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'user-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {userDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`user-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'company-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {companyDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`company-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'location-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {locationDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`location-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'contact-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {contactDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`contact-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'location-attachment-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {locationAttachmentDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`location-attachment-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'task-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {taskDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`task-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'task-board-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {taskBoardDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`task-board-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'subtask-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {subtaskDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`task-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'reminder-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {reminderDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`reminder-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'task-attachment-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {taskAttachmentDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`task-attachment-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
					{(tab === 'pnl-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {pnlDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`pnl-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'dashboard-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {dashboardDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`dashboard-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'notification-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  icon={{ icon: 'search' }}
                  label={'Search'} 
                  disabled={! isOpen}
                  value={searchValue}
                  onChange={this.handleSearchChange} />
                </CardListItem>
              </CardList>
              <CardList>
                {notificationDocs.filter(doc => this.isDocRelevant(doc)).map(doc => (
                  <CardListItem key={`notification-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
          {(tab === 'outlook-addin-docs') && (
            <React.Fragment>
              <CardList className="fourg-about-dialog__tools">
                <CardListItem>
                  <Input
                  label={'Add-in Install URL'} 
                  readOnly={true}
                  disabled={! isOpen}
                  value={process.env.REACT_APP_OUTLOOK_ADDIN_MANIFEST_URL} />
                </CardListItem>
              </CardList>
              <CardList>
                {outlookAddinDocs.map(doc => (
                  <CardListItem key={`outlook-addin-doc-${doc.id}`} >
                    <DocCard doc={doc} />
                  </CardListItem>
                ))}
              </CardList>
            </React.Fragment>
          )}
        </div>
      </Dialog>
    );
  }
}

export default AboutDialog;
