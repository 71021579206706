import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import FormActions from '../FormActions/FormActions';
import { TableField, Auth, CompanySchema, LocationSchema } from '../../types';
import './CompanyQuickAddFormDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  submitLabel: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSecondary: (record: CompanySchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: CompanySchema, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  record: CompanySchema;
  isValid: boolean;
}

class CompanyQuickAddFormDialog extends React.Component<Props, State> {

  private formFields: React.RefObject<FormFieldByField<CompanySchema>>[] = [];
  private locationFormFields: React.RefObject<FormFieldByField<LocationSchema>>[] = [];

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSecondary: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleLocationFieldChange = this.handleLocationFieldChange.bind(this);
    this.state = {
      record: this.getDefaultRecord(),
      isValid: this.isValid(),
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isOpen } = this.props;
    const isValid = this.isValid();
    if (prevProps.isOpen && ! isOpen) {
      this.setDefaultRecord();
    }
    if (prevState.isValid !== isValid) {
      this.setState({ isValid: isValid });
    }
  }

  getDefaultRecord() {
    const company = Company.getDefaultRecord<CompanySchema>();
    const location = Location.getDefaultRecord<LocationSchema>();
    const defaultRecord: CompanySchema = {
      ...company,
      primaryLocation: location,
    };
    return defaultRecord;
  }

  setDefaultRecord() {
    this.setState({ record: this.getDefaultRecord() });
    this.resetValidity();
  }

  handleFieldChange(field: TableField<CompanySchema>, value: any, e: any) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          [field.name]: value,
        },
      });
    }
  }

  handleLocationFieldChange(field: TableField<LocationSchema>, value: any, e: any) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          primaryLocation: {
            ...record.primaryLocation,
            [field.name]: value,
          },
        },
      });
    }
  }

  resetValidity() {
    const formFields = [...this.formFields, ...this.locationFormFields];
    if (formFields.length > 0) {
      formFields.forEach(formField => formField.current?.resetValidity());
    }
  }

  isValid() {
    let isValid = true;
    const formFields = [...this.formFields, ...this.locationFormFields];
    if (formFields.find(formField => (formField.current && ! formField.current.isValid()))) {
      isValid = false;
    }
    return isValid;
  }

  render() {
    const { size, disabled, isOpen, title, auth, className, submitLabel, secondaryLabel, cancelLabel, onFormCancel, onFormSubmit, onFormSecondary, ...restProps } = this.props;
    const { record, isValid } = this.state;
    const containerClass = classNames('fourg-company-quick-add-form-dialog', className);
    const fields = Company.getFieldsBy<CompanySchema>('isQuickAddField', true);
    const locationFields = Location.getFieldsBy<LocationSchema>('isQuickAddField', true).filter(field => (field.name !== 'primary'));
    return ReactDOM.createPortal(
      <Dialog 
      className={containerClass}
      title={title}
      isOpen={isOpen}
      size="small"
      {...restProps}>
        {(fields.length > 0) && (
          <div className="fourg-company-quick-add-form-dialog__form">
            <div className="fourg-company-quick-add-form-dialog__form-fields">
              {fields.map((field, i) => (
                <FormFieldByField<CompanySchema>
                className={"fourg-company-quick-add-form-dialog__form-field"}
                formContext="quick-add"
                ref={formField => this.formFields[i] = { current: formField }}
                record={record}
								recordModel={Company}
                key={`form-field-${field.name}`} 
                field={field}
                value={record[field.name]}
                disabled={disabled || ! isOpen}
                onChange={(value, e) => this.handleFieldChange(field, value, e)} />
              ))}
              {locationFields.map((field, i) => (
                <FormFieldByField<LocationSchema>
                className={"fourg-company-quick-add-form-dialog__form-field"}
                formContext="quick-add"
                ref={formField => this.locationFormFields[i] = { current: formField }}
                record={record.primaryLocation}
								recordModel={Location}
                key={`form-field-${field.name}`} 
                field={field}
                value={record.primaryLocation[field.name]}
                disabled={disabled || ! isOpen}
                onChange={(value, e) => this.handleLocationFieldChange(field, value, e)} />
              ))}
            </div>
            <FormActions
            submitLabel={submitLabel}
            secondaryLabel={secondaryLabel}
            cancelLabel={cancelLabel}
            onCancel={onFormCancel}
            onSecondary={e => onFormSecondary(record, e)}
            onSubmit={e => onFormSubmit(record, e)}
            disabled={(disabled || ! isOpen)}
            isValid={isValid} />
          </div>
        )}
      </Dialog>,
			document.body
    );
  }
}

export default CompanyQuickAddFormDialog;
