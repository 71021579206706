import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './TableGroupItem.scss';

export interface Props {
  id?: string;
  className?: string;
  heading: string;
  total?: number;
  isExpandedOnMount: boolean;
  disabled?: boolean;
}

export interface State {
  isExpanded: boolean;
}

class TableGroupItem extends React.Component<Props, State> {

  static defaultProps = {
    isExpandedOnMount: true,
  };

  constructor(props: Props) {
    super(props);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      isExpanded: props.isExpandedOnMount,
    };
  }

  handleHeaderClick(e: React.MouseEvent<HTMLButtonElement>) {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: ! isExpanded,
    });
  }

  open() {
    this.setState({ isExpanded: true });
  }

  close() {
    this.setState({ isExpanded: false });
  }

  render() {
    const { isExpandedOnMount, disabled, total, heading, className, children, ...restProps } = this.props;
    const { isExpanded } = this.state;
    const containerClass = classNames('fourg-table-group-item', {
      'fourg-table-group-item--disabled': disabled,
      'fourg-table-group-item--expanded': isExpanded,
    }, className);
    return (
      <li className={containerClass} {...restProps}>
        <button 
        className="fourg-table-group-item__toggle"
        onClick={this.handleHeaderClick}>
          <Icon 
          className="fourg-table-group-item__caret"
          src={{ icon: 'arrow_drop_down' }} 
          label={isExpanded ? 'Expanded' : 'Collapsed'} />
          <h3 className="fourg-table-group-item__heading">{heading}</h3>
          {(total !== undefined) && (
            <span className="fourg-table-group-item__total">
              {`Count: ${total}`}
            </span>
          )}
        </button>
        <div className="fourg-table-group-item__content">
          {children}
        </div>
      </li>
    );
  }
}

export default TableGroupItem;
