import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';
import { Props as IconProps } from '../Icon/Icon';
import Input from '../Input/Input';
import SelectMultiple from '../SelectMultiple/SelectMultiple';
import Table from '../../models/Table';
import { UIOption, Auth } from '../../types';
import './LookupMultiple.scss';

export interface Props<T extends Record<string, any> = Record<string, any>>  {
  id?: string;
  className?: string;
  auth: Auth;
  variant?: 'default' | 'quiet';
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  title?: string;
  order?: string;
  filter?: string;
  limit?: number;
  model: typeof Table;
  value: string[];
  icon?: IconProps['src'];
  searchDelay: number;
  options: UIOption[];
  multiple?: boolean;
  loadOnMount?: boolean;
  hasCheckbox?: boolean;
  onChange?: (value: string[], e: React.MouseEvent<HTMLButtonElement>) => void;
  onSelectActionClick?: (value: string, isSelected: boolean, record: T | undefined, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State<T extends Record<string, any> = Record<string, any>> {
  records: T[];
  isLoading: boolean;
  total: number;
  searchValue: string;
  searchTimer?: number;
}

class LookupMultiple<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State<T>> {

  private input = React.createRef<Input>();
  private select = React.createRef<SelectMultiple>();

  static defaultProps = {
    options: [],
    value: '',
    limit: -1,
    variant: 'default',
    searchDelay: 500,
  };

  constructor(props: Props<T>) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchTimer = this.handleSearchTimer.bind(this);
    this.handleSelectActionClick = this.handleSelectActionClick.bind(this);
    this.state = {
      records: [],
      isLoading: false,
      total: 0,
      searchValue: '',
    };
  }

  componentDidMount() {
    const { value, loadOnMount } = this.props;
    if (value && loadOnMount) {
      this.readRecords();
    }
  }

  async readRecords() {
    const { auth, model, order, filter, limit } = this.props;
    const { searchValue } = this.state;
    const { defaultOrder, defaultFilter } = model.getOptions<T>();
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await model.readRecords<T>(token, {
        limit: limit,
        search: searchValue,
        order: order || defaultOrder,
        filter: filter || defaultFilter,
      });
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
      });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoading: false,
        records: [],
        total: 0,
      });
    }
  }

  handleSearchTimer() {
    this.readRecords();
  }

  handleSearchChange(value: string | number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { searchDelay } = this.props;
    const { searchTimer } = this.state;
    if (searchTimer) window.clearTimeout(searchTimer);
    const newTimer = window.setTimeout(() => this.handleSearchTimer(), searchDelay);
    this.setState({
      searchValue: value.toString(),
      searchTimer: newTimer,
    });
  }

  getOption(record: T) {
    const { model } = this.props;
    const modelOptions = model.getOptions<T>();
    const option: UIOption = {
      value: model.getRecordValue<T>(record).toString(),
      label: model.getRecordLabel<T>(record).toString(),
      icon: {
        icon: modelOptions.icon,
        cover: model.getRecordImage<T>(record),
      },
    };
    return option;
  }

  getOptions() {
    const { options } = this.props;
    const { records } = this.state;
    const newOptions: UIOption[] = [];
    options.forEach(option => {
      newOptions.push(option);
    });
    records.forEach(record => {
      newOptions.push(this.getOption(record));
    });
    return newOptions;
  }

  getRecordByValue(value: string) {
    const { model } = this.props;
    const { records } = this.state;
    return records.find(record => model.getRecordValue(record) === value);
  }

  handleSelectActionClick(newValue: string, isSelected: boolean, e: React.MouseEvent<HTMLButtonElement>) {
    const { value, onChange, onSelectActionClick } = this.props;
    if (onChange) {
      let updated: string[] = [];
      if (isSelected && ! value.includes(newValue)) {
        updated = [...value, newValue];
      } else {
        updated = value.filter(current => current !== newValue);
      }
      onChange(updated, e);
    }
    if (onSelectActionClick) {
      const record = this.getRecordByValue(newValue);
      onSelectActionClick(newValue, isSelected, record, e);
    }
  }

  resetValidity() {
    const select = this.select.current;
    select?.resetValidity();
  }

  reset() {
    const { searchTimer } = this.state;
    window.clearTimeout(searchTimer);
    this.setState({
      records: [],
      isLoading: false,
      total: 0,
      searchValue: '',
      searchTimer: undefined,
    });
    this.resetValidity();
  }

  render() {
    const { auth, options, variant, label, searchDelay, value, model, disabled, onChange, onSelectActionClick, loadOnMount, className, ...restProps } = this.props;
    const { searchValue, isLoading } = this.state;
    const containerClass = classNames('fourg-lookup-multiple', `fourg-lookup-multiple--variant-${variant}`, className);
    const modelOptions = model.getOptions<T>();
    const selectLabel = model.getLabel('selectPlural');
    const searchLabel = model.getLabel('search');
    return (
      <div className={containerClass}>
        <div className="fourg-lookup-multiple__header">
          <div className="fourg-lookup-multiple__search-container">
            <Input
            variant={variant}
            className="fourg-lookup-multiple__search"
            ref={this.input}
            disabled={disabled}
            type="text"
            value={searchValue}
            onChange={this.handleSearchChange}
            autoComplete="off"
            label={label || selectLabel}
            icon={{ icon: 'search' }}
            placeholder={searchLabel} />
            {isLoading && (
              <Loader
              className="fourg-lookup-multiple__loader"
              size={18} />
            )}
          </div>
        </div>
        <SelectMultiple
        className="fourg-lookup-multiple__select"
        ref={this.select}
        disabled={disabled}
        label={label || selectLabel}
        value={value}
        options={this.getOptions()}
        onActionClick={this.handleSelectActionClick}
        icon={{ icon: modelOptions.icon }}
        {...restProps} />
      </div>
    );
  }
}

export default LookupMultiple;
