import React from 'react';
import classNames from 'classnames';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Form from '../Form/Form';
import FormField from '../FormField/FormField';
import IconMessage from '../IconMessage/IconMessage';
import LookupInputNew, { Props as LookupInputNewProps } from '../LookupInputNew/LookupInputNew';
import User from '../../models/tables/User';
import { Auth, Lookup, TaskSchema, UserSchema } from '../../types';
import './TaskRemoveSelfDialog.scss';

export interface Props extends DialogProps {
  auth: Auth
  recordID: TaskSchema['id'];
  lookup: Lookup,
  isAssignedToSelf?: boolean;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (assignedTo: UserSchema['id'], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  assignedTo: UserSchema['id'],
}

class TaskRemoveSelfDialog extends React.Component<Props, State> {

  static defaultProps = {
    ...Dialog.defaultProps,
  };

  constructor(props: Props) {
    super(props);
    this.handleLookupInputChange = this.handleLookupInputChange.bind(this);
    this.state = {
      assignedTo: '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { recordID, isOpen } = this.props;
    if ((prevProps.isOpen !== isOpen)
    || (prevProps.recordID !== recordID)) {
      this.setState({ assignedTo: '' });
    }
  }

  handleLookupInputChange(value: LookupInputNewProps<UserSchema>['value']) {
    this.setState({ assignedTo: value });
  }

  render() {
    const { disabled, isOpen, recordID, auth, lookup, isAssignedToSelf, onFormCancel, onFormSubmit, className, ...restProps } = this.props;
    const { assignedTo } = this.state;
    const containerClass = classNames('fourg-task-remove-self-dialog', className);
    const heading = `Are you sure you want to remove yourself as the assignee and/or member of this task?`;
    const subheading = `If this task is assigned to you, you will be removed as the assignee, but you can optionally reassign this task to someone else. If you are a member of this task, you will be removed as a member.`;
    return (
      <Dialog 
      className={containerClass}
      title={'Remove Me'}
      isOpen={isOpen}
      {...restProps}>
        <Form 
        disabled={(disabled || ! isOpen)}
        className="fourg-task-remove-self-dialog__form"
        submitLabel={'Save'}
        cancelLabel={'Cancel'}
        onCancel={onFormCancel}
        onSubmit={e => onFormSubmit(assignedTo, e)}>
          <IconMessage 
          icon={{ icon: 'warning' }} 
          heading={heading}
          subheading={subheading} />
          {isAssignedToSelf && (
            <FormField className="fourg-task-remove-self-dialog__form-field" size="large">
              <LookupInputNew<UserSchema> 
              auth={auth}
              lookup={lookup}
              model={User}
              label={'Reassign To'}
              value={assignedTo}
              disabled={(disabled || ! isOpen)}
              onChange={this.handleLookupInputChange}
							options={[
								{ value: '', label: 'Do not reassign' }
							]}
							query={{
								ignoreIds: auth.user?.id || undefined,
							}} />
            </FormField>
          )}
        </Form>
      </Dialog>
    );
  }
}

export default TaskRemoveSelfDialog;
