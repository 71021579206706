import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';
import { Props as IconProps } from '../Icon/Icon';
import Input from '../Input/Input';
import ChipCheckboxList from '../ChipCheckboxList/ChipCheckboxList';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import { UIOption, Auth, LocationInfo, LocationSchema, CompanySchema, ChipCheckboxValue } from '../../types';
import './LocationInfoInput.scss';

export interface Props  {
  id?: string;
  className?: string;
  auth: Auth;
  variant?: 'default' | 'quiet';
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
  title?: string;
  order?: string;
  filter?: string;
  limit?: number;
  companyID: CompanySchema['id'];
  value: LocationInfo[];
  icon?: IconProps['src'];
  searchDelay: number;
  options: UIOption[];
  multiple?: boolean;
  loadOnMount?: boolean;
  onChange: (value: LocationInfo[], e: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>) => void;
}

export interface State {
  records: LocationSchema[];
  isLoading: boolean;
  total: number;
  searchValue: string;
  searchTimer?: number;
}

class LocationInfoInput extends React.Component<Props, State> {

  private input = React.createRef<Input>();

  static defaultProps = {
    options: [],
    value: [],
    limit: -1,
    variant: 'default', 
    searchDelay: 500,
    onChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchTimer = this.handleSearchTimer.bind(this);
    this.handleLabelClick = this.handleLabelClick.bind(this);
    this.state = {
      records: [],
      isLoading: false,
      total: 0,
      searchValue: '',
    };
  }

  componentDidMount() {
    const { value, loadOnMount } = this.props;
    if (value && loadOnMount) {
      this.readRecords();
    }
  }

  async readRecords() {
    const { companyID, auth, order, filter, limit } = this.props;
    const { searchValue } = this.state;
    const { defaultOrder, defaultFilter } = Location.getOptions<LocationSchema>();
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await Company.readLocations(token, companyID, {
        limit: limit,
        search: searchValue,
        order: order || defaultOrder,
        filter: filter || defaultFilter,
      });
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
      });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoading: false,
        records: [],
        total: 0,
      });
    }
  }

  handleSearchTimer() {
    this.readRecords();
  }

  handleSearchChange(value: string | number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { searchDelay } = this.props;
    const { searchTimer } = this.state;
    if (searchTimer) window.clearTimeout(searchTimer);
    const newTimer = window.setTimeout(() => this.handleSearchTimer(), searchDelay);
    this.setState({
      searchValue: value.toString(),
      searchTimer: newTimer,
    });
  }

  getRecordByValue(value: string) {
    const { records } = this.state;
    return records.find(record => Location.getRecordValue(record) === value);
  }

  getOptions() {
    const { records } = this.state;
    return records.map(record => {
      const option: UIOption = {
        value: Location.getRecordValue<LocationSchema>(record).toString(),
        label: Location.getRecordLabel<LocationSchema>(record),
      };
      return option;
    });
  }

  parseChipCheckboxValue(value: LocationInfo[]) {
    const chipCheckboxValue: ChipCheckboxValue[] = value.map(v => {
      return {
        checkboxValue: v.id.toString(),
        chipValues: v.types,
      }
    });
    return chipCheckboxValue;
  }

  parseLocationInfo(value: ChipCheckboxValue[]) {
    const locationInfo: LocationInfo[] = value.map(v => {
      return {
        id: parseInt(v.checkboxValue, 10),
        types: v.chipValues,
      }
    });
    return locationInfo;
  }

  handleLabelClick(e: React.MouseEvent<HTMLLabelElement>) {
    const input = this.input.current;
    input?.focus();
  }

  render() {
    const { required, companyID, auth, options, variant, label, searchDelay, value, disabled, onChange, loadOnMount, className, ...restProps } = this.props;
    const { searchValue, isLoading } = this.state;
    const containerClass = classNames('fourg-location-info-input', `fourg-location-info-input--variant-${variant}`, {
      'fourg-location-info-input--disabled': disabled,
    }, className);
    const modelOptions = Location.getOptions<LocationSchema>();
    const searchLabel = Location.getLabel('search');
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-location-info-input__header">
          <label className="fourg-location-info-input__label" onClick={this.handleLabelClick}>
            {`${label}${required ? '*' : ''}`}
          </label>
          <div className="fourg-location-info-input__search-container">
            <Input
            variant={variant}
            className="fourg-location-info-input__search"
            ref={this.input}
            disabled={disabled}
            type="text"
            value={searchValue}
            onChange={this.handleSearchChange}
            autoComplete="off"
            label={searchLabel}
            icon={{ icon: modelOptions.icon }} />
            {isLoading && (
              <Loader
              className="fourg-location-info-input__loader"
              size={18} />
            )}
          </div>
        </div>
        <ChipCheckboxList
        required={required}
        disabled={disabled}
        className="fourg-location-info-input__options"
        options={this.getOptions()}
        chipOptions={options}
        value={this.parseChipCheckboxValue(value)}
        onChange={(value, e) => onChange(this.parseLocationInfo(value), e)} />
      </div>
    );
  }
}

export default LocationInfoInput;
