import React from 'react';
import classNames from 'classnames';
import { ExpenseSchema, UIOption } from '../../types';
import './ExpenseCard.scss';
import { formatCurrency } from '../../utils';
import ActionToggle from '../ActionToggle/ActionToggle';

export interface Props {
  id?: string;
  className?: string;
	record: ExpenseSchema;
	disabled?: boolean;
	variant: 'default' | 'quiet';
  onActionsChange?: (value: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class ExpenseCard extends React.Component<Props, State> {

	static defaultProps = {
		variant: 'default',
	};

  render() {
    const { className, record, onActionsChange, disabled, variant, ...restProps } = this.props;
    const containerClass = classNames('fourg-expense-card', `fourg-expense-card--variant-${variant}`, className);
    return (
      <div className={containerClass} {...restProps}>
        <h3 className="fourg-expense-card__title">{record.name}</h3>
				<span className="fourg-expense-card__cost">{`${record.quantity.toLocaleString()} x ${formatCurrency(record.estCost)} ― ${formatCurrency(record.estCost * record.quantity)}/mo.`}</span>
				{onActionsChange && (
					<ActionToggle
					disabled={disabled}
					className="fourg-expense-card__actions"
					variant="mini"
					icon={{ icon: 'more_vert' }}
					label={'Actions'}
					isIconOnly={true}
					anchor="top-right"
					options={[
						{ label: 'Edit', value: 'edit' },
						{ label: 'Delete', value: 'delete' },
					]}
					value=""
					onChange={onActionsChange} />
				)}
      </div>
    );
  }
}

export default ExpenseCard;
