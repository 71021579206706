import React from 'react';
import classNames from 'classnames';
import AutoParagraph from '../AutoParagraph/AutoParagraph';
import { Doc } from '../../types';
import './DocCard.scss';

export interface Props {
  id?: string;
  className?: string;
  doc: Doc;
}

export interface State {

}

class DocCard extends React.Component<Props, State> {

  render() {
    const { doc, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-doc-card', className);
    return (
      <div className={containerClass} {...restProps}>
        <h3 className="fourg-doc-card__title">{doc.title}</h3>
        <AutoParagraph className="fourg-doc-card__description" text={doc.description} />
        {doc.image && (
          <a className="fourg-doc-card__image" href={doc.image} target="_blank" rel="noopener noreferrer">
            <img src={doc.image} alt={doc.title} />
          </a>
        )}
        {(doc.steps && (doc.steps.length > 0)) && (
          <div className="fourg-doc-card__list fourg-doc-card__list--steps">
            <h4 className="fourg-doc-card__list-heading">{'Steps'}</h4>
            <ol className="fourg-doc-card__list-items">
              {doc.steps.map((step, i) => (
                <li className="fourg-doc-card__list-item" key={`step-${(i + 1)}`}>
                  <AutoParagraph text={step} />
                </li>
              ))}
            </ol>
          </div>
        )}
        {(doc.notes && (doc.notes.length > 0)) && (
          <div className="fourg-doc-card__list fourg-doc-card__list--notes">
            <h4 className="fourg-doc-card__list-heading">{'Notes'}</h4>
            <ul className="fourg-doc-card__list-items">
              {doc.notes.map((note, i) => (
                <li className="fourg-doc-card__list-item" key={`note-${i}`}>
                  <AutoParagraph text={note} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {doc.lists?.map((list, i) => (
          <div key={`list-${i}`} className="fourg-doc-card__list fourg-doc-card__list--custom">
            <h4 className="fourg-doc-card__list-heading">{list.heading}</h4>
            <ul className="fourg-doc-card__list-items">
              {list.items.map((item, ii) => (
                <li className="fourg-doc-card__list-item" key={`list-${i}-item-${ii}`}>
                  <AutoParagraph text={item} />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }
}

export default DocCard;
