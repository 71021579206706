import React from 'react';
import classNames from 'classnames';
import { AuthContext, NotificationsContext } from '../../contexts';
import Menu from '../Menu/Menu';
import MenuItem from '../MenuItem/MenuItem';
import logoURL from '../../assets/images/logo.svg';
import './Header.scss';
// import User from '../../models/tables/User';
// import { UserSchema } from '../../types';

export interface Props {
  id?: string;
  className?: string;
}

export interface State {
  isExpanded?: boolean;
}

class Header extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.state = {
      isExpanded: false,
    };
  }

  handleExpandClick() {
    this.setState(({ isExpanded }) => {
      return {
        isExpanded: ! isExpanded,
      };
    });
  }

  render() {
    const { className, ...restProps } = this.props;
    const { isExpanded } = this.state;
    const containerClass = classNames('fourg-header', {
      'fourg-header--expanded': isExpanded,
    }, className);
    return (
      <AuthContext.Consumer>
        {auth => (
          <header className={containerClass} {...restProps}>
            <div className="fourg-header__branding">
              <Menu className="fourg-header__branding-menu">
                <MenuItem
                className="fourg-header__logo-link"
                to="/" 
                icon={{ icon: 'home', cover: logoURL }} 
                label={process.env.REACT_APP_TITLE || ''} />
              </Menu>
            </div>
            <div className="fourg-header__navigation">
              {auth.user && (
                <React.Fragment>
                  <Menu className="fourg-header__actions-menu">
                    {/* <MenuItem icon={{ icon: 'search' }} label="Search" /> */}
                    <MenuItem icon={{ icon: 'add' }} label="Add&hellip;" />
                  </Menu>
                  <Menu className="fourg-header__main-menu">
                    <MenuItem
                    icon={{ icon: isExpanded ? "menu_open" : "menu" }}
                    label={isExpanded ? "Collapse" : "Expand"}
                    onClick={this.handleExpandClick} />
                    <MenuItem icon={{ icon: 'assessment' }} to="/dashboard" label="Dashboard" />
                    <MenuItem icon={{ icon: 'assignment' }} to="/tasks" label="Tasks" />
                    <MenuItem icon={{ icon: 'business' }} to="/companies" label="Companies" />
                    <MenuItem icon={{ icon: 'supervisor_account' }} to="/users" label="Users" />
                  </Menu>
                  <Menu className="fourg-header__account-menu">
                    <MenuItem icon={{ icon: 'account_circle' }} label="Account" onClick={() => auth.openProfileFormDialog()} />
                    <NotificationsContext.Consumer>
                      {({ unreadCount, isLoading, openDialog }) => (
                        <MenuItem 
                        className={classNames('fourg-header__notifications', {
                          'fourg-header__notifications--dirty': (unreadCount && (unreadCount > 0)),
                        })}
                        icon={{ icon: (unreadCount && (unreadCount > 0)) ? 'notifications_active' : 'notifications_none' }} 
                        label="Notifications" 
                        count={unreadCount}
                        onClick={openDialog}
                        disabled={isLoading} />
                      )}
                    </NotificationsContext.Consumer>
                    <MenuItem icon={{ icon: 'help' }} label="Help" onClick={() => auth.openAboutDialog()} />
                  </Menu>
                </React.Fragment>
              )}
            </div>
          </header>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default Header;
