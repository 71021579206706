import React from 'react';
import classNames from 'classnames';
import './FormSection.scss';
import FormFieldHeading from '../FormFieldHeading/FormFieldHeading';

export interface Props {
  id?: string;
  className?: string;
  heading: string;
  isHidden?: boolean;
}

export interface State {

}

class FormSection extends React.Component<Props, State> {

  render() {
    const { isHidden, heading, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-form-section', {
      'fourg-form-section--hidden': isHidden,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        <FormFieldHeading className="fourg-form-section__heading">{heading}</FormFieldHeading>
        <div className="fourg-form-section__fields">
          {children}
        </div>
      </div>
    );
  }
}

export default FormSection;
