import React from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import Input from '../../components/Input/Input';
import FormField from '../../components/FormField/FormField';
import SusiFormContainer from '../../components/SusiFormContainer/SusiFormContainer';
import { AuthContext, LayoutContext } from '../../contexts';
import { State as LayoutState } from '../../components/Layout/Layout';
import { Auth } from '../../types';
import './PasswordResetPage.scss';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
	layout: LayoutState;
}

export interface State {
  password: string;
}

class PasswordResetPageBase extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.state = {
      password: '',
    };
  }

	componentDidMount() {
		const { layout } = this.props;
		layout.hideHeader();
		layout.makeBodyScrollable();
	}

	componentWillUnmount() {
		const { layout } = this.props;
		layout.showHeader();
		layout.makeBodyUnscrollable();
	}

  handlePasswordChange(value: string | number) {
    this.setState({ password: value.toString() });
  }

  render() {
    const { auth, className, match, location, history, staticContext, layout, ...restProps } = this.props;
    const { password } = this.state;
    const containerClass = classNames('fourg-password-reset-page', className);
    return (
      <Page title="Reset Password" className={containerClass} {...restProps}>
				<SusiFormContainer>
					<Form
					title="Reset Password"
					submitLabel="Reset Password"
					isLoading={auth.isLoading}
					onSubmit={() => auth.resetPassword(password)}>
						<FormField size="large">
							<Input
							label="Password"
							required={true}
							type="password"
							value={password}
							disabled={auth.isLoading}
							onChange={this.handlePasswordChange} />
						</FormField>
					</Form>
				</SusiFormContainer>
      </Page>
    );
  }
}

const PasswordResetPage: React.FC<Omit<Props, 'auth' | 'layout'>> = (props) => {
	return (
		<AuthContext.Consumer>
			{auth => (
				<LayoutContext.Consumer>
					{(layout) => (
						<PasswordResetPageBase auth={auth} layout={layout} {...props} />
					)}
				</LayoutContext.Consumer>
			)}
		</AuthContext.Consumer>
	);
};

export default PasswordResetPage;
