import React from 'react';
import { State as LayoutState } from './components/Layout/Layout';
import { Auth, Lookup, Notifications, Socket } from './types';

export const AuthContext = React.createContext<Auth>({
	cognitoUserPool: undefined,
	cognitoUser: undefined,
	cognitoUserAtts: undefined,
	isNewPasswordRequired: false,
	isLostPasswordCodeSent: false,
	isLostPasswordReset: false,
	isLoading: false,
	isUpdating: false,
	user: undefined,
	isProfileFormDialogOpen: false,
	isAboutDialogOpen: false,
	signIn: console.info,
	signOut: console.info,
	resetPassword: console.info,
	resetLostPassword: console.info,
	sendLostPasswordCode: console.info,
	sendVerificationCode: console.info,
	verifyAccount: console.info,
	reAuthenticate: console.info,
	resetLostPasswordRecovery: console.info,
	getToken: async () => '',
	openProfileFormDialog: console.info,
	closeProfileFormDialog: console.info,
	openAboutDialog: console.info,
	closeAboutDialog: console.info,
});

export const NotificationsContext = React.createContext<Notifications>({
	isLoading: false,
	isUpdating: false,
	isPaginating: false,
	isDialogOpen: false,
	total: 0,
	unreadCount: 0,
	page: 1,
	notifications: [],
	readNotifications: console.info,
	readMoreNotifications: console.info,
	markAsRead: console.info,
	markAsUnread: console.info,
	markManyAsRead: console.info,
	openDialog: console.info,
	closeDialog: console.info,
});

export const SocketContext = React.createContext<Socket>({
	current: undefined,
});

export const LookupContext = React.createContext<Lookup>({});

export const LayoutContext = React.createContext<LayoutState>({
	hasHeader: true,
	isBodyScrollable: false,
	showHeader: console.info,
	hideHeader: console.info,
	makeBodyScrollable: console.info,
	makeBodyUnscrollable: console.info,
});
