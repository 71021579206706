import React from 'react';
import classNames from 'classnames';
import CloseToast from '../CloseToast/CloseToast';
import { ToastContainer } from 'react-toastify';
import './Toaster.scss';

export interface Props {
  id?: string;
  className?: string;
}

export interface State {

}

class Toaster extends React.Component<Props, State> {

  render() {
    const { id, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-toaster', className);
    return (
      <ToastContainer
      containerId={id}
      className={containerClass}
      position="bottom-right"
      toastClassName="fourg-toast"
      bodyClassName="fourg-toast__body"
      progressClassName="fourg-toast__progress"
      draggablePercent={20}
      autoClose={3000}
      newestOnTop={true}
      closeButton={<CloseToast icon={{ icon: 'close' }} label="Close" />}
      {...restProps} />
    );
  }
}

export default Toaster;
