import mime from 'mime';
import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField, AttachmentSchema } from '../../types';
import User from './User';
import { APIError } from '../../errors';

class Attachment extends Table {

  static options: TableOptions = {
    name: 'Attachment',
    slug: 'attachments',
    labelKey: 'title',
    valueKey: 'id',
		imageKey: 'avatar',
		lookupKey: 'attachments',
    icon: 'attach_file',
    defaultOrder: '-created',
  };

  static labels: TableLabels = {
    description: 'All attachments in the 4G Recycling Site Manager.',
    pageTitle: 'Attachment Manager',
    singular: 'Attachment',
    plural: 'Attachments',
    viewSingular: 'View Attachment',
    viewPlural: 'View Attachments',
    selectSingular: 'Select Attachment',
    selectPlural: 'Select Attachments',
    addSingular: 'Add Attachment',
    addPlural: 'Add Attachments',
    editSingular: 'Edit Attachment',
    editPlural: 'Edit Attachments',
    addedSingular: 'Attachment added',
    addedPlural: 'Attachments added',
    updatedSingular: 'Attachment updated',
    updatedPlural: 'Attachments updated',
    deletedSingular: 'Attachment deleted',
    deletedPlural: 'Attachments deleted',
    archivedSingular: 'Attachment archived',
    archivedPlural: 'Attachments archived',
    restoredSingular: 'Attachment restored',
    restoredPlural: 'Attachments restored',
    errorFetchingSingular: 'Error fetching Attachment',
    errorFetchingPlural: 'Error fetching Attachments',
    errorAddingSingular: 'Error adding Attachment',
    errorAddingPlural: 'Error adding Attachments',
    errorUpdatingSingular: 'Error updating Attachment',
    errorUpdatingPlural: 'Error updating Attachments',
    errorDeletingSingular: 'Error deleting Attachment',
    errorDeletingPlural: 'Error deleting Attachments',
    errorArchivingSingular: 'Error archiving Attachment',
    errorArchivingPlural: 'Error archiving Attachments',
    errorRestoringSingular: 'Error restoring Attachment',
    errorRestoringPlural: 'Error restoring Attachments',
    notFoundSingular: 'Attachment not found',
    notFoundPlural: 'No Attachments found',
    loadingSingular: 'Loading Attachment',
    loadingSingularEllipsis: 'Loading Attachment...',
    loadingPlural: 'Loading Attachments',
    loadingPluralEllipsis: 'Loading Attachments...',
    search: 'Search Attachments',
    searchEllipsis: 'Search Attachments...',
    filter: 'Filter Attachments',
    settings: 'Attachments Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'attachments',
    readRecord: 'attachment/:id',
    createRecord: 'attachment',
    updateRecord: 'attachment/:id',
    updateRecords: 'attachments',
    patchRecord: 'attachment/:id',
    deleteRecord: 'attachment/:id',
    archiveRecord: 'attachment/:id/archive',
    restoreRecord: 'attachment/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
      default: 0,
      readOnly: true,
    },
    {
      name: 'path',
      label: 'Path',
      type: 'url',
      default: '',
      isTableColumn: true,
      readOnly: true,
    },
    {
      name: 'file',
      label: 'File',
      type: 'file',
      default: null,
      // isFormField: true,
      required: true,
      formFieldSize: 'large',
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      default: '',
      isSortable: true,
      isFormField: true,
      isTableColumn: true,
      formFieldSize: 'large',
		},
		{
			name: 'description',
			label: 'Description',
			type: 'textarea',
			default: '',
			isSortable: true,
			isFormField: true,
			isTableColumn: true,
		},
		{
			name: 'contentType',
			label: 'Type',
			type: 'text',
			default: '',
			readOnly: true,
			isSortable: true,
			isTableColumn: true,
		},
		{
			name: 'createdBy',
			label: 'Created By',
			type: 'lookup-input',
			model: User,
			default: '',
			readOnly: true,
			isTableColumn: true,
		},
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isTableColumn: true,
    },
    {
      name: 'updated',
      label: 'Updated', 
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isTableColumn: true,
    }
  ];

  static async uploadFile(id: AttachmentSchema['id'], signedUrl: string, file: File): Promise<boolean> {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type || mime.getType(file.name) || 'application/octet-stream',
      },
    });
    switch (response.status) {
      case 200: return true;
      default: throw new Error('Error uploading file');
    }
  }

  static async validateUpload(token: string, id: AttachmentSchema['id']): Promise<{ ok: boolean }> {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/attachment/${id}/validate`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Error validating upload');
    }
  }

  static async validateUploads(token: string, ids: AttachmentSchema['id'][]): Promise<PromiseSettledResult<{ ok: boolean }>> {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/attachments/validate`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids: ids,
      }),
    })
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Error validating uploads');
    }
  }
}

export default Attachment;
