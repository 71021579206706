import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './Dialog.scss';

export interface Props {
  id?: string;
  className?: string;
  isOpen?: boolean;
  title?: string;
  size?: 'small' | 'medium' | 'large';
  onCloseClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onBackdropClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onEscape: (e: KeyboardEvent) => void;
}

export interface State {

}

class Dialog extends React.Component<Props, State> {

  private close = React.createRef<HTMLButtonElement>();

  static defaultProps: Partial<Props> = {
    size: 'medium',
    onBackdropClick: console.info,
    onEscape: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleWindowKeyUp = this.handleWindowKeyUp.bind(this);
  }

  componentDidMount() {
    const { isOpen } = this.props;
    const close = this.close.current;
    if (isOpen) {
      window.addEventListener('keyup', this.handleWindowKeyUp);
      close?.focus();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen } = this.props;
    const close = this.close.current;
    if (! prevProps.isOpen && isOpen) {
      window.addEventListener('keyup', this.handleWindowKeyUp);
      close?.focus();
    }
    if (prevProps.isOpen && ! isOpen) {
      window.removeEventListener('keyup', this.handleWindowKeyUp);
      close?.blur();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleWindowKeyUp);
  }

  handleWindowKeyUp(e: KeyboardEvent) {
    const { onEscape } = this.props;
    if (e.key === 'Escape') {
      onEscape(e);
    }
  }

  render() {
    const { size, title, isOpen, onCloseClick, onBackdropClick, onEscape, className, children, ...restProps } = this.props;
		const containerClass = classNames('fourg-dialog', `fourg-dialog--size-${size}`, {
      'fourg-dialog--open': isOpen,
    }, className);
    return (
      <div 
      className={containerClass} 
      {...restProps}>
        <div className="fourg-dialog__backdrop" onClick={onBackdropClick} />
        <div className="fourg-dialog__surface">
          <div className="fourg-dialog__header">
            {title && (
              <h2 className="fourg-dialog__title">{title}</h2>
            )}
            {onCloseClick && (
              <button
              type="button" 
              className="fourg-dialog__close" 
              onClick={onCloseClick} 
              title={'Close'} 
              disabled={! isOpen}
              ref={this.close}>
                <Icon src={{ icon: 'close' }} label={'Close'} />
              </button>
            )}
          </div>
          <div className="fourg-dialog__content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Dialog;
