import React from 'react';
import classNames from 'classnames';
import Icon, { Props as IconProps } from '../Icon/Icon';
import Checkbox from '../Checkbox/Checkbox';
import './SelectItem.scss';

export interface Props {
  id?: string;
  className?: string;
  label: string;
  disabled?: boolean;
  isActive?: boolean;
	isFocused?: boolean;
  icon?: IconProps['src'];
  hasCheckbox?: boolean;
	children?: React.ReactNode;
}

export interface State {

}

class SelectItem extends React.Component<Props, State> {

  render() {
    const { hasCheckbox, icon, isActive, isFocused, disabled, label, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-select-item', {
      'fourg-select-item--active': isActive,
      'fourg-select-item--focused': isFocused,
      'fourg-select-item--disabled': disabled,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-select-item__action">
          {icon && (
            <Icon 
            className="fourg-select-item__icon" 
            src={icon} 
            label={label} />
          )}
          <span className="fourg-select-item__label">{children || label}</span>
          {hasCheckbox && (
            <Checkbox
            className="fourg-select-item__checkbox"
            checked={isActive} />
          )}
        </div>
      </div>
    );
  }
}

export default SelectItem;
