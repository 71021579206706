import React from 'react';
import classNames from 'classnames';
import Icon, { Props as IconProps } from '../Icon/Icon';
import Checkbox from '../Checkbox/Checkbox';
import './ActionListItem.scss';

export interface Props {
  id?: string;
  className?: string;
  label: string;
  disabled?: boolean;
  isActive?: boolean;
  value: string;
  icon?: IconProps['src'];
  tabIndex?: number;
  hasCheckbox?: boolean;
  onClick: (value: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class ActionListItem extends React.Component<Props, State> {

  private action = React.createRef<HTMLButtonElement>();

  static defaultProps = {
    onClick: console.info,
  };

  focus() {
    const action = this.action.current;
    action?.focus();
  }

  blur() {
    const action = this.action.current;
    action?.blur();
  }

  render() {
    const { hasCheckbox, icon, value, isActive, disabled, label, onClick, tabIndex, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-action-list-item', {
      'fourg-action-list-item--active': isActive,
      'fourg-action-list-item--disabled': disabled,
    }, className);
    return (
      <li className={containerClass} {...restProps}>
        <button
        ref={this.action}
        type="button"
        className="fourg-action-list-item__action"
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={e => onClick(value, e)}>
          {icon && (
            <Icon 
            className="fourg-action-list-item__icon" 
            src={icon} 
            label={label} />
          )}
          <span className="fourg-action-list-item__label">{label}</span>
          {hasCheckbox && (
            <Checkbox
            className="fourg-action-list-item__checkbox"
            checked={isActive} />
          )}
        </button>
      </li>
    );
  }
}

export default ActionListItem;
