import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField, NotificationSchema } from '../../types';
import { APIError } from '../../errors';

class Notification extends Table {

  static options: TableOptions = {
    name: 'Notification',
    slug: 'notifications',
    labelKey: 'id',
    valueKey: 'id',
		icon: 'notifications_active',
		defaultOrder: '-created',
  };

  static labels: TableLabels = {
    description: '4G Site Manager notifications.',
    pageTitle: 'Notification Manager',
    singular: 'Notification',
    plural: 'Notifications',
    viewSingular: 'View Notification',
    viewPlural: 'View Notifications',
    selectSingular: 'Select Notification',
    selectPlural: 'Select Notifications',
    addSingular: 'Add Notification',
    addPlural: 'Add Notifications',
    editSingular: 'Edit Notification',
    editPlural: 'Edit Notifications',
    addedSingular: 'Notification added',
    addedPlural: 'Notifications added',
    updatedSingular: 'Notification updated',
    updatedPlural: 'Notifications updated',
    deletedSingular: 'Notification deleted',
    deletedPlural: 'Notifications deleted',
    archivedSingular: 'Notification archived',
    archivedPlural: 'Notifications archived',
    restoredSingular: 'Notification restored',
    restoredPlural: 'Notifications restored',
    errorFetchingSingular: 'Error fetching Notification',
    errorFetchingPlural: 'Error fetching Notifications',
    errorAddingSingular: 'Error adding Notification',
    errorAddingPlural: 'Error adding Notifications',
    errorUpdatingSingular: 'Error updating Notification',
    errorUpdatingPlural: 'Error updating Notifications',
    errorDeletingSingular: 'Error deleting Notification',
    errorDeletingPlural: 'Error deleting Notifications',
    errorArchivingSingular: 'Error archiving Notification',
    errorArchivingPlural: 'Error archiving Notifications',
    errorRestoringSingular: 'Error restoring Notification',
    errorRestoringPlural: 'Error restoring Notifications',
    notFoundSingular: 'Notification not found',
    notFoundPlural: 'No Notifications found',
    loadingSingular: 'Loading Notification',
    loadingSingularEllipsis: 'Loading Notification...',
    loadingPlural: 'Loading Notifications',
    loadingPluralEllipsis: 'Loading Notifications...',
    search: 'Search Notifications',
    searchEllipsis: 'Search Notifications...',
    filter: 'Filter Notifications',
    settings: 'Notifications Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'notifications',
    readRecord: 'notification/:id',
    createRecord: 'notification',
    updateRecord: 'notification/:id',
    updateRecords: 'notifications',
    patchRecord: 'notification/:id',
    deleteRecord: 'notification/:id',
    archiveRecord: 'notification/:id/archive',
    restoreRecord: 'notification/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
    },
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isTableColumn: true,
    },
    {
      name: 'updated',
      label: 'Updated', 
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isTableColumn: true,
    }
  ];
  
  static async markAsRead(token: string, id: NotificationSchema['id']): Promise<{ ok: boolean }> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/read`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async markAsUnread(token: string, id: NotificationSchema['id']): Promise<{ ok: boolean }> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/unread`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }
  
  static async markManyAsRead(token: string, ids?: NotificationSchema['id'][]): Promise<{ ok: boolean }> {
    const body = ids ? { ids: ids } : { all: true };
    const response = await fetch(`${this.getEndpoint('updateRecords')}/read`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingPlural'));
    }
  }
}

export default Notification;