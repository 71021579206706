import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import Sidebar, { Props as SidebarProps } from '../Sidebar/Sidebar';
import SidebarSection from '../SidebarSection/SidebarSection';
import ActionToggle from '../ActionToggle/ActionToggle';
import ReminderCard from '../ReminderCard/ReminderCard';
import Info from '../Info/Info';
import InfoByField from '../InfoByField/InfoByField';
import CardList from '../CardList/CardList';
import CardListItem from '../CardListItem/CardListItem';
import AttachmentCard from '../AttachmentCard/AttachmentCard';
import TaskCard from '../TaskCard/TaskCard';
import UserCard from '../UserCard/UserCard';
import GradeCard from '../GradeCard/GradeCard';
import ExpenseCard from '../ExpenseCard/ExpenseCard';
import SidebarSectionActions from '../SidebarSectionActions/SidebarSectionActions';
import MetricNote from '../MetricNote/MetricNote';
import RecordWarnings from '../RecordWarnings/RecordWarnings';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import Task from '../../models/tables/Task';
import Reminder from '../../models/tables/Reminder';
import Attachment from '../../models/tables/Attachment';
import Grade from '../../models/tables/Grade';
import Expense from '../../models/tables/Expense';
import { LookupContext } from '../../contexts';
import { TaskSchema, AttachmentSchema, UIOption, ReminderSchema, UserSchema, GradeSchema, ExpenseSchema } from '../../types';
import './TaskSidebar.scss';

export interface Props extends SidebarProps {
  record: TaskSchema;
  disabled?: boolean;
  onManageMembersClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAddGradeClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAddExpenseClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAttachFileClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAddSubtaskActionChange: (value: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
  onAddReminderClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAttachmentCardActionsChange?: (value: UIOption['value'], record: AttachmentSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onReminderCardActionsChange?: (value: UIOption['value'], record: ReminderSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onReminderCardCompletedChange?: (value: boolean, record: ReminderSchema, e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubtaskCardClick?: (record: TaskSchema, e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  onRemoveMemberClick?: (record: UserSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onGradeCardActionsChange?: (value: UIOption['value'], record: GradeSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onExpenseCardActionsChange?: (value: UIOption['value'], record: ExpenseSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class TaskSidebar extends React.Component<Props, State> {

  static defaultProps = {
    ...Sidebar.defaultProps,
    onManageMembersClick: console.info,
    onAddReminderClick: console.info,
    onAttachFileClick: console.info,
    onAddGradeClick: console.info,
    onAddExpenseClick: console.info,
  };

  getModelByBoard(board: string): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  render() {
    const { record, className, children, disabled, onRemoveMemberClick, onReminderCardCompletedChange, onAddReminderClick, onReminderCardActionsChange, onGradeCardActionsChange, onExpenseCardActionsChange, onSubtaskCardClick, onAddSubtaskActionChange, onManageMembersClick, onAttachFileClick, onAttachmentCardActionsChange, onAddGradeClick, onAddExpenseClick, ...restProps } = this.props;
    const containerClass = classNames('fourg-task-sidebar', className);
    const model = this.getModelByBoard(record.board);
    const fields = model.getFieldsBy<TaskSchema>('isInfo', true);
    const attachmentOptions = Attachment.getOptions();
    const taskOptions = model.getOptions();
    const reminderOptions = Reminder.getOptions();
    const gradeOptions = Grade.getOptions();
    const expenseOptions = Expense.getOptions();
    const boardField = model.getField<TaskSchema>('board');
    return (
      <LookupContext.Consumer>
        {lookup => {
          const members = lookup.users?.filter(user => record.members?.includes(user.id)) || [];
          const subtasks = lookup.tasks?.filter(task => record.subtaskIds?.includes(task.id)) || [];
          const attachments = lookup.attachments?.filter(attachment => record.attachments?.includes(attachment.id)) || [];
          const grades = lookup.grades?.filter(grade => record.grades?.includes(grade.id)) || [];
          const expenses = lookup.expenses?.filter(expense => record.expenses?.includes(expense.id)) || [];
          const reminders = lookup.reminders?.filter(reminder => record.reminders?.includes(reminder.id)) || [];
          const recurringReminders = reminders.filter(reminder => Boolean(reminder.reminder?.interval));
          const singularReminders = reminders.filter(reminder => ! Boolean(reminder.reminder?.interval));
					const gradeTotals = Grade.getTotals(grades);
					const expenseTotals = Expense.getTotals(expenses);
					const warnings = model.getWarnings(record);
          return (
            <Sidebar className={containerClass} {...restProps}>
              <SidebarSection
              className="fourg-task-sidebar__details"
              label="Task Details"
              disabled={disabled}>
								{warnings.length > 0 && (
									<RecordWarnings warnings={warnings} />
								)}
                <Info>
                  {fields.map((field) => (
                    <InfoByField<TaskSchema>
                    size={field.infoSize}
                    variant="quiet"
                    key={`form-field-${field.name}`}
                    field={field}
                    value={record[field.name]} />
                  ))}
                </Info>
              </SidebarSection>
              {['new-business'].includes(record.board) && (
                <SidebarSection
                className="fourg-task-sidebar__pnl"
                label={'Profit & Loss'}
                disabled={disabled}>
									{(grades.length > 0) && (
                    <CardList
										title={Grade.getLabel('plural')}
										count={grades.length}
										actions={(
											<MetricNote
											isCurrency={true}
											label={'Est. Profit:'}
											per={'mo.'}
											metric={gradeTotals.profit}
											variance={gradeTotals.variance} />
										)}>
                      {grades.map(grade => (
                        <CardListItem key={grade.id}>
                          <GradeCard
                          record={grade}
													onActionsChange={onGradeCardActionsChange ? (value, e) => onGradeCardActionsChange(value, grade, e) : undefined} />
                        </CardListItem>
                      ))}
                    </CardList>
                  )}
									{(expenses.length > 0) && (
                    <CardList
										gutterSize="s"
										title={Expense.getLabel('plural')}
										count={expenses.length}
										actions={(
											<MetricNote
											isCurrency={true}
											label={'Est. Total:'}
											per={'mo.'}
											metric={expenseTotals} />
										)}>
                      {expenses.map(expense => (
                        <CardListItem key={expense.id}>
                          <ExpenseCard
													variant="quiet"
                          record={expense}
													onActionsChange={onExpenseCardActionsChange ? (value, e) => onExpenseCardActionsChange(value, expense, e) : undefined} />
                        </CardListItem>
                      ))}
                    </CardList>
                  )}
									{((expenses.length > 0) || (grades.length > 0)) && (
										<CardList
										gutterSize="s"
										title={'Totals'}
										actions={(
											<MetricNote
											isCurrency={true}
											label={'Est. Net Profit:'}
											per={'mo.'}
											metric={(gradeTotals.profit - expenseTotals)}
											variance={((gradeTotals.profit - expenseTotals) / (gradeTotals.buy + expenseTotals))} />
										)}>
											<CardListItem>
												<MetricNote label={'Total Spend'} metric={(gradeTotals.buy + expenseTotals)} per={'mo.'} isCurrency={true} />
											</CardListItem>
											<CardListItem>
												<MetricNote label={'Total Sale'} metric={gradeTotals.sell} per={'mo.'} isCurrency={true} />
											</CardListItem>
										</CardList>
									)}
                  <SidebarSectionActions>
                    <Button
                    variant="raised"
                    icon={{ icon: gradeOptions.icon }}
                    disabled={(disabled || record.archived)}
                    onClick={onAddGradeClick}>
                      {Grade.getLabel('addSingular')}
                    </Button>
                    <Button
                    variant="raised"
                    icon={{ icon: expenseOptions.icon }}
                    disabled={(disabled || record.archived)}
                    onClick={onAddExpenseClick}>
                      {Expense.getLabel('addSingular')}
                    </Button>
                  </SidebarSectionActions>
                </SidebarSection>
              )}
              {(! Boolean(record.parentId) && ! ['personal', 'targets'].includes(record.board)) && (
                <SidebarSection
                className="fourg-task-sidebar__subtasks"
                label="Subtasks"
                count={record.subtaskIds?.length || 0}
                disabled={disabled}>
                  {(subtasks.length > 0) && (
                    <CardList>
                      {subtasks.map(subtask => (
                        <CardListItem key={subtask.id}>
                          <TaskCard
                          variant="mini"
                          record={subtask}
                          onClick={onSubtaskCardClick} />
                        </CardListItem>
                      ))}
                    </CardList>
                  )}
                  <SidebarSectionActions>
                    <ActionToggle
                    label={model.getLabel('addSingular')}
                    variant="raised"
                    icon={{ icon: taskOptions.icon }}
                    options={boardField?.options?.filter(option => ! ['personal', 'new-business', 'targets'].includes(option.value)) || []}
                    value=""
                    disabled={(disabled || record.archived)}
                    onChange={onAddSubtaskActionChange} />
                  </SidebarSectionActions>
                </SidebarSection>
              )}
              <SidebarSection
              className="fourg-task-sidebar__reminders"
              label={Reminder.getLabel('plural')}
              count={reminders.length || 0}
              disabled={disabled}>
                {singularReminders.length > 0 && (
                  <CardList>
                    {singularReminders.map(reminder => (
                      <CardListItem key={reminder.id}>
                        <ReminderCard
                        disabled={(disabled || record.archived)}
                        record={reminder}
                        variant="mini"
                        onCompletedChange={onReminderCardCompletedChange ? (value, e) => onReminderCardCompletedChange(value, reminder, e) : undefined}
                        onActionsChange={onReminderCardActionsChange ? (value, e) => onReminderCardActionsChange(value, reminder, e) : undefined} />
                      </CardListItem>
                    ))}
                  </CardList>
                )}
                {recurringReminders.length > 0 && (
                  <CardList title={`Recurring ${Reminder.getLabel('plural')}`}>
                    {recurringReminders.map(reminder => (
                      <CardListItem key={reminder.id}>
                        <ReminderCard
                        disabled={(disabled || record.archived)}
                        record={reminder}
                        variant="mini"
                        onCompletedChange={onReminderCardCompletedChange ? (value, e) => onReminderCardCompletedChange(value, reminder, e) : undefined}
                        onActionsChange={onReminderCardActionsChange ? (value, e) => onReminderCardActionsChange(value, reminder, e) : undefined} />
                      </CardListItem>
                    ))}
                  </CardList>
                )}
                <SidebarSectionActions>
                  <Button
                  variant="raised"
                  icon={{ icon: reminderOptions.icon }}
                  disabled={(disabled || record.archived)}
                  onClick={onAddReminderClick}>
                    {Reminder.getLabel('addSingular')}
                  </Button>
                </SidebarSectionActions>
              </SidebarSection>
              <SidebarSection
              className="fourg-task-sidebar__attachments"
              label="Attachments"
              count={attachments.length || 0}
              disabled={disabled}>
                {(attachments.length > 0) && (
                  <CardList>
                    {attachments.map(attachment => (
                      <CardListItem key={attachment.id}>
                        <AttachmentCard
                        disabled={(disabled || record.archived)}
                        record={attachment}
                        variant="mini"
                        onActionsChange={onAttachmentCardActionsChange ? (value, e) => onAttachmentCardActionsChange(value, attachment, e) : undefined} />
                      </CardListItem>
                    ))}
                  </CardList>
                )}
                <SidebarSectionActions>
                  <Button
                  variant="raised"
                  icon={{ icon: attachmentOptions.icon }}
                  disabled={(disabled || record.archived)}
                  onClick={onAttachFileClick}>
                    {Attachment.getLabel('addPlural')}
                  </Button>
                </SidebarSectionActions>
              </SidebarSection>
              {(record.board !== 'personal') && (
                <SidebarSection
                className="fourg-task-sidebar__members"
                label="Members"
                count={record.members?.length || 0}
                disabled={disabled}>
                  {(members.length > 0) && (
                    <CardList>
                      {members.map(member => (
                        <CardListItem key={member.id}>
                          <UserCard
                          disabled={(disabled || record.archived)}
                          record={member}
                          onRemove={onRemoveMemberClick} />
                        </CardListItem>
                      ))}
                    </CardList>
                  )}
                  <SidebarSectionActions>
                    <Button
                    variant="raised"
                    icon={{ icon: 'person_add' }}
                    disabled={(disabled || record.archived)}
                    onClick={onManageMembersClick}>
                      {'Manage Members'}
                    </Button>
                  </SidebarSectionActions>
                </SidebarSection>
              )}
            </Sidebar>
          );
        }}
      </LookupContext.Consumer>
    );
  }
}

export default TaskSidebar;
