import React from 'react';
import classNames from 'classnames';
import './InputDescription.scss';

export interface Props {
  id?: string;
  className?: string;
  disabled?: boolean;
}

export interface State {

}

class InputDescription extends React.Component<Props, State> {

  render() {
    const { disabled, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-input-description', {
      'fourg-input-description--disabled': disabled,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        {children}
      </div>
    );
  }
}

export default InputDescription;
