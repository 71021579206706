import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField, CieTradeLocationSchema, LocationSchema } from '../../types';
import Location from './Location';

class CieTradeLocation extends Table {

  static options: TableOptions = {
    name: 'CieTradeLocation',
    slug: 'cietrade-locations',
    labelKey: 'label',
    valueKey: 'label',
    lookupKey: 'cieTradeLocations',
    icon: 'place',
    defaultOrder: 'label',
  };

  static labels: TableLabels = {
    description: 'All cieTrade Locations in the 4G Recycling cieTrade system.',
    pageTitle: 'cieTrade Location Manager',
    singular: 'cieTrade Location',
    plural: 'cieTrade Locations',
    viewSingular: 'View cieTrade Location',
    viewPlural: 'View cieTrade Locations',
    selectSingular: 'Select cieTrade Location',
    selectPlural: 'Select cieTrade Locations',
    addSingular: 'Add cieTrade Location',
    addPlural: 'Add cieTrade Locations',
    editSingular: 'Edit cieTrade Location',
    editPlural: 'Edit cieTrade Locations',
    addedSingular: 'cieTrade Location added',
    addedPlural: 'cieTrade Locations added',
    updatedSingular: 'cieTrade Location updated',
    updatedPlural: 'cieTrade Locations updated',
    deletedSingular: 'cieTrade Location deleted',
    deletedPlural: 'cieTrade Locations deleted',
    archivedSingular: 'cieTrade Location archived',
    archivedPlural: 'cieTrade Locations archived',
    restoredSingular: 'cieTrade Location restored',
    restoredPlural: 'cieTrade Locations restored',
    errorFetchingSingular: 'Error fetching cieTrade Location',
    errorFetchingPlural: 'Error fetching cieTrade Locations',
    errorAddingSingular: 'Error adding cieTrade Location',
    errorAddingPlural: 'Error adding cieTrade Locations',
    errorUpdatingSingular: 'Error updating cieTrade Location',
    errorUpdatingPlural: 'Error updating cieTrade Locations',
    errorDeletingSingular: 'Error deleting cieTrade Location',
    errorDeletingPlural: 'Error deleting cieTrade Locations',
    errorArchivingSingular: 'Error archiving cieTrade Location',
    errorArchivingPlural: 'Error archiving cieTrade Locations',
    errorRestoringSingular: 'Error restoring cieTrade Location',
    errorRestoringPlural: 'Error restoring cieTrade Locations',
    notFoundSingular: 'cieTrade Location not found',
    notFoundPlural: 'No cieTrade Locations found',
    loadingSingular: 'Loading cieTrade Location',
    loadingSingularEllipsis: 'Loading cieTrade Location...',
    loadingPlural: 'Loading cieTrade Locations',
    loadingPluralEllipsis: 'Loading cieTrade Locations...',
    search: 'Search cieTrade Locations',
    searchEllipsis: 'Search cieTrade Locations...',
    filter: 'Filter cieTrade Locations',
    settings: 'cieTrade Locations Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'cietrade/locations',
    readRecord: 'cietrade/location/:id',
    createRecord: 'cietrade/locations',
    updateRecord: 'cietrade/location/:id',
    updateRecords: 'cietrade/locations',
    patchRecord: 'cietrade/location/:id',
    deleteRecord: 'cietrade/location/:id',
    archiveRecord: 'cietrade/location/:id/archive',
    restoreRecord: 'cietrade/location/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
    },
		{
      name: 'cieTradeID',
      label: 'cieTrade ID',
      type: 'number',
      default: '',
    },
    {
      name: 'label',
      label: 'Label',
      type: 'unique',
      model: CieTradeLocation,
			default: '',
    },
		{
      name: 'loading',
      label: 'Loading Hours',
      type: 'text',
      default: '',
    },
		{
      name: 'directions',
      label: 'Directions',
      type: 'textarea',
      default: '',
    },
		{
      name: 'primary',
      label: 'Primary',
      type: 'checkbox',
      default: false,
    },
		{
      name: 'remit',
      label: 'Remittance',
      type: 'checkbox',
      default: false,
    },
  ];

	static getID(counterpartyID: number, addressLabel: string) {
		return `cieTrade-${counterpartyID}-${addressLabel.toLowerCase().replace(/[\W]+/g, '-')}`;
	}

	static getRecordValue<T extends Record<string, any> = Record<string, any>>(record: T): string | number {
		const cieTradeLocationRecord = record as any as CieTradeLocationSchema;
		return this.getID(cieTradeLocationRecord.cieTradeID, cieTradeLocationRecord.label);
	}

	static toLocationSchema(record: CieTradeLocationSchema): LocationSchema {
		const defaultRecord: LocationSchema = Location.getDefaultRecord<LocationSchema>();
		return {
			...defaultRecord,
			id: CieTradeLocation.getID(record.cieTradeID, record.label) as any,
			name: record.label,
			primary: Boolean(record.primary),
			remittance: Boolean(record.remit),
			loadingHours: record.loading,
			directions: record.directions,
			address: {
				...defaultRecord.address,
				country: record.country,
				address1: record.addr1,
				address2: record.addr2,
				city: record.city,
				state: record.state,
				zip: record.zip,
			},
		};
	}
}


export default CieTradeLocation;
