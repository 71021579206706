import { APIError } from '../errors';
import { TableOptions, TableLabels, TableEndpoints, TableField, TableColumn, ReadRecordsQuery, ReadRecordsResponse, ReadRecordResponse, TableFilter, TableFormPage, TableFormSection, UIOption } from '../types';

class Table {

    static options: TableOptions = {
        name: '',
        slug: '',
        labelKey: '',
        valueKey: '',
        icon: '',
    };

    static labels: TableLabels = {
        description: '',
        pageTitle: '',
        singular: '',
        plural: '',
        viewSingular: '',
        viewPlural: '',
        selectSingular: '',
        selectPlural: '',
        addSingular: '',
        addPlural: '',
        editSingular: '',
        editPlural: '',
        addedSingular: '',
        addedPlural: '',
        updatedSingular: '',
        updatedPlural: '',
        deletedSingular: '',
        deletedPlural: '',
        archivedSingular: '',
        archivedPlural: '',
        restoredSingular: '',
        restoredPlural: '',
        errorFetchingSingular: '',
        errorFetchingPlural: '',
        errorAddingSingular: '',
        errorAddingPlural: '',
        errorUpdatingSingular: '',
        errorUpdatingPlural: '',
        errorDeletingSingular: '',
        errorDeletingPlural: '',
        errorArchivingSingular: '',
        errorArchivingPlural: '',
        errorRestoringSingular: '',
        errorRestoringPlural: '',
        notFoundSingular: '',
        notFoundPlural: '',
        loadingSingular: '',
        loadingSingularEllipsis: '',
        loadingPlural: '',
        loadingPluralEllipsis: '',
        search: '',
        searchEllipsis: '',
        filter: '',
        settings: '',
    };

    static endpoints: TableEndpoints = {
        readRecords: '',
        readRecord: '',
        createRecord: '',
        updateRecord: '',
        updateRecords: '',
        patchRecord: '',
        deleteRecord: '',
        archiveRecord: '',
        restoreRecord: '',
    };

    static formPages: TableFormPage[] = [];

    static formSections: TableFormSection[] = [];

    static filters: TableFilter[] = [];

    static fields: TableField[] = [];

		static warnings: Record<string, string> = {};

		static getWarnings<T extends Record<string, any> = Record<string, any>>(record: T): string[] {
			return [];
		}

    static getFormPages() {
        return this.formPages;
    }

    static getRecordLink<T extends Record<string, any> = Record<string, any>>(record: T) {
        const { slug } = this.getOptions();
        return `/${encodeURIComponent(slug)}/${encodeURIComponent(this.getRecordValue<T>(record))}`;
    }

    static getFormSections() {
        return this.formSections;
    }

    static getFilters() {
        return this.filters;
    }

    static getOptions<T extends Record<string, any> = Record<string, any>>(): TableOptions<T> {
        return this.options;
    }

    static getEndpoint(key: keyof TableEndpoints) {
        return `${process.env.REACT_APP_API_URL}/${this.endpoints[key]}`;
    }

    static getLabel(key: keyof TableLabels = 'plural') {
        return this.labels[key];
    }

    static getField<T extends Record<string, any> = Record<string, any>>(name: keyof T) {
        return this.getFieldBy<T>('name', name);
    }

    static getFieldBy<T extends Record<string, any> = Record<string, any>>(key: keyof TableField<T>, value: any): TableField<T> | undefined {
        return this.getFieldsBy<T>(key, value, 1)[0];
    }

    static getFields<T extends Record<string, any> = Record<string, any>>() {
        return this.fields as TableField<T>[];
    }

    static getFieldsBy<T extends Record<string, any> = Record<string, any>>(key: keyof TableField<T>, value: any, count: number = 0) {
        const fields = this.getFields<T>();
        const found: TableField<T>[] = [];
        let foundCount = 0;
        if (fields.length > 0) {
            for (let i = 0; i < fields.length; i++) {
                if (fields[i][key] === value) {
                    found.push(fields[i]);
                    foundCount++;
                    if ((count > 0) && (foundCount >= count)) {
                        break;
                    }
                }
            }
        }
        return found;
    }

    static getFieldColumns<T extends Record<string, any> = Record<string, any>>() {
        const fields = this.getFieldsBy<T>('isTableColumn', true);
        const columns: TableColumn<T>[] = [];
        if (fields.length > 0) {
            fields.forEach(field => {
                columns.push({
                    key: field.name,
                    label: field.label,
                    isSortable: field.isSortable,
                    width: field.columnWidth,
                    icon: field.icon,
                });
            });
        }
        return columns;
    }

    static getFieldOption<T extends Record<string, any> = Record<string, any>>(fieldName: keyof T, value: string) {
        const field = this.getField<T>(fieldName);
        let option: UIOption | undefined = undefined;
        if (field) {
            const { options } = field;
            if (options && (options.length > 0)) {
                for (let i = 0; i < options.length; i++) {
                    if (options[i].value === value) {
                        option = options[i];
                        break;
                    }
                }
            }
        }
        return option;
    }

    static getFieldOptionLabel<T extends Record<string, any> = Record<string, any>>(fieldName: keyof T, value: string) {
        const option = this.getFieldOption<T>(fieldName, value);
        return option?.label || value;
    }

    static getDefaultRecord<T extends Record<string, any> = Record<string, any>>() {
        const fields = this.getFields<T>();
        const record: Record<string, any> = {};
        if (fields.length > 0) {
            fields.forEach(field => {
								if (field.options) {
									const firstOptionValue = field.options[0] ? field.options[0].value : '';
									record[field.name.toString()] = field.default ?? firstOptionValue;
								} else {
									record[field.name.toString()] = field.default ?? '';
								}
            });
        }
        return record as T;
    }

    static getOrderOptions<T extends Record<string, any> = Record<string, any>>() {
        const orderFields = this.getFieldsBy<T>('isSortable', true);
        let options: UIOption[] = [];
        orderFields.forEach(field => {
            options.push({
                value: field.name.toString(),
                label: `${field.label} (${this.getOrderOptionLabelSuffix<T>(field, 'ASC')})`,
            });
            options.push({
                value: `-${field.name.toString()}`,
                label: `${field.label} (${this.getOrderOptionLabelSuffix<T>(field, 'DESC')})`,
            });
        });
        return options;
    }

    static getOrderOptionLabelSuffix<T extends Record<string, any> = Record<string, any>>(field: TableField<T>, direction: 'ASC' | 'DESC' = 'ASC') {
        switch (field.type) {
            case 'date': return (direction === 'DESC') ? 'New to Old' : 'Old to New';
            case 'number': return (direction === 'DESC') ? '9-0' : '0-9';
            case 'select':
                switch (field.name) {
                    case 'priority': return (direction === 'DESC') ? 'High to Low' : 'Low to High';
                    default: return (direction === 'DESC') ? 'Z-A' : 'A-Z';
                }
            default: return (direction === 'DESC') ? 'Z-A' : 'A-Z';
        }
    }

    static parseQuery(url: string, query: ReadRecordsQuery) {
        const splitUrl = url.split('?');
        const params = new URLSearchParams(splitUrl[1]);
        Object.entries(query).forEach(([key, value]) => {
            switch (key) {
                case 'limit':
                    if (value !== undefined) {
                        const parsed = parseInt(value, 10);
                        if (parsed !== 0) {
                            params.set(key, parsed.toString());
                        }
                    }
                    break;
                case 'following':
                    if (value !== undefined) {
                        params.set(key, value);
                    }
                    break;
                case 'archived':
                    if (value !== undefined) {
                        params.set(key, value);
                    }
                    break;
                default:
                    if (value) {
                        params.set(key, value.toString());
                    }
                    break;
            }
        });
        return `${splitUrl[0]}?${params.toString()}`;
    }

    static getRecordLabel<T extends Record<string, any> = Record<string, any>>(record: T) {
        const options = this.getOptions<T>();
        const rawLabel = record[options.labelKey];
        let label: string | number = `${this.getLabel('singular')} ${this.getRecordValue<T>(record)}`;
        if ((typeof rawLabel === 'string') || (typeof rawLabel === 'number')) {
            label = String(rawLabel);
        }
        return label;
    }

    static getRecordValue<T extends Record<string, any> = Record<string, any>>(record: T) {
        const options = this.getOptions<T>();
        const rawValue = record[options.valueKey];
        let value: string | number = '';
        if ((typeof rawValue === 'string') || (typeof rawValue === 'number')) {
            value = rawValue;
        }
        return value;
    }

    static getRecordImage<T extends Record<string, any> = Record<string, any>>(record: T) {
        const options = this.getOptions<T>();
        const encodedLabel = encodeURIComponent(this.getRecordLabel<T>(record).toString().replace(/[^0-9a-zA-Z ]/g, '')).replace(/%2B/g, '+');
        let url = `https://ui-avatars.com/api/?name=${encodedLabel}&bold=true&font-size=0.42&background=417CDE&color=ffffff&size=256`;
        const rawValue = options.imageKey ? record[options.imageKey] : undefined;
        if (rawValue && (typeof rawValue === 'string')) {
            url = this.getS3URL(rawValue);
        }
        return url;
    }

    static getS3URL(path: string = '') {
        return path ? `${process.env.REACT_APP_S3_URL}${path}` : '';
    }

    static async readRecords<T extends Record<string, any> = Record<string, any>>(token: string, query?: ReadRecordsQuery): Promise<ReadRecordsResponse<T>> {
        let url = `${this.getEndpoint('readRecords')}`;
        url = query ? this.parseQuery(url, query) : url;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch(response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorFetchingPlural'));
        }
    }

    static async readRecord<T extends Record<string, any> = Record<string, any>>(token: string, id: string, query?: ReadRecordsQuery): Promise<ReadRecordResponse<T>> {
        let url = this.getEndpoint('readRecord').replace(':id', id);
        url = query ? this.parseQuery(url, query) : url;
        const response = await fetch(url, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch(response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorFetchingSingular'));
        }
    }

    static async createRecord<T extends Record<string, any> = Record<string, any>>(token: string, record: T): Promise<ReadRecordResponse<T>> {
        const response = await fetch(`${this.getEndpoint('createRecord')}`, {
            method: 'POST',
            body: JSON.stringify(record),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch (response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorAddingSingular'));
        }
    }

    static async updateRecord<T extends Record<string, any> = Record<string, any>>(token: string, id: string, record: T): Promise<ReadRecordResponse<T>> {
        const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id)}`, {
            method: 'POST',
            body: JSON.stringify(record),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch (response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
        }
    }

    static async patchRecord<T extends Record<string, any> = Record<string, any>>(token: string, id: string, record: Partial<T>): Promise<ReadRecordResponse<T>> {
        const response = await fetch(`${this.getEndpoint('patchRecord').replace(':id', id)}`, {
            method: 'PATCH',
            body: JSON.stringify(record),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch (response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
        }
    }

    static async deleteRecord(token: string, id: string): Promise<{ ok: boolean}> {
        const response = await fetch(`${this.getEndpoint('deleteRecord').replace(':id', id)}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch (response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorDeletingSingular'));
        }
    }

    static async archiveRecord(token: string, id: string): Promise<{ ok: boolean }> {
        const response = await fetch(`${this.getEndpoint('archiveRecord').replace(':id', id)}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch (response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorArchivingSingular'));
        }
    }

    static async restoreRecord(token: string, id: string): Promise<{ ok: boolean }> {
        const response = await fetch(`${this.getEndpoint('restoreRecord').replace(':id', id)}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        switch (response.status) {
            case 200: return await response.json();
            default: throw new APIError(response.status, this.getLabel('errorRestoringSingular'));
        }
    }
}

export default Table;
