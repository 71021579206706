import React from 'react';
import classNames from 'classnames';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Icon, { Props as IconProps } from '../Icon/Icon';
import Badge from '../Badge/Badge';
import './MenuItem.scss';

export interface Props extends RouteComponentProps {
  id?: string;
  className?: string;
  label: string;
  to?: string;
  href?: string;
  icon?: IconProps['src'];
  count?: number;
  disabled?: boolean;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
}

interface State {
  
}

class MenuItem extends React.Component<Props, State> {

  renderAction() {
    const { to, href, disabled, onClick } = this.props;
    const isExact = this.isExact();
    return to ? (
      <Link 
      to={to} 
      tabIndex={(disabled || isExact) ? -1 : 0} 
      className="fourg-menu-item__action fourg-menu-item__action--link" >
        {this.renderActionInternals()}
      </Link>
    ) : href ? (
      <a 
      href="fourg-menu-item__action fourg-menu-item__action--anchor"
      tabIndex={(disabled || isExact) ? -1 : 0}>
        {this.renderActionInternals()}
      </a>
    ) : onClick ? (
      <button 
      type="button"
      disabled={(disabled || isExact)}
      onClick={onClick} 
      className="fourg-menu-item__action fourg-menu-item__action--button">
        {this.renderActionInternals()}
      </button>
    ) : (
      <span className="fourg-menu-item__action fourg-menu-item__action--span">
        {this.renderActionInternals()}
      </span>
    );
  }

  renderActionInternals() {
    const { icon, label, count } = this.props;
    return (
      <React.Fragment>
        <Icon className="fourg-menu-item__icon" src={icon} label={label} />
        <div className="fourg-menu-item__content">
          <span className="fourg-menu-item__label">{label}</span>
          {Boolean(count) && (
            <Badge className="fourg-menu-item__count" variant="error">{count}</Badge>
          )}
        </div>
      </React.Fragment>
    );
  }

  isExact() {
    const { to, location } = this.props;
    return Boolean(to && (to === location.pathname));
  }

  isCurrent() {
    const { to, location } = this.props;
    return Boolean(to && (location.pathname.startsWith(to)));
  }

  render() {
    const { isActive, disabled, onClick, icon, label, to, href, count, className, children, location, history, match, staticContext, ...restProps } = this.props;
    const containerClass = classNames('fourg-menu-item', {
      'fourg-menu-item--active': (isActive || this.isCurrent()), 
      'fourg-menu-item--disabled': (disabled || this.isExact()), 
    }, className);
    return (
      <li className={containerClass} {...restProps}>
        {this.renderAction()}
      </li>
    );
  }
}

export default withRouter(MenuItem);
