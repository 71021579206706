import mime from 'mime';
import Table from '../Table';
import User from './User';
import Attachment from './Attachment';
import Reminder from './Reminder';
import Grade from './Grade';
import { APIError } from '../../errors';
import { TableOptions, TableLabels, TableEndpoints, TableField, UserSchema, TaskSchema, ActionSchema, ReadRecordResponse, AttachmentSchema, CreateAttachmentResponse, /* UIOption,  */TableFilter, ReminderSchema, SocketMessage, RemoveSelfActionContent, GradeSchema, ExpenseSchema, /* BoardFieldMap, BoardOptionMap, BoardFilterMap */ } from '../../types';
import Expense from './Expense';

class Task extends Table {

  static options: TableOptions = {
    name: 'Task',
    slug: 'tasks',
    labelKey: 'title',
    valueKey: 'id',
    lookupKey: 'tasks',
    icon: 'assignment',
    defaultOrder: '-dueDate',
  };

  static labels: TableLabels = {
    description: 'A centralized hub for creating and managing tasks.',
    pageTitle: 'Task Manager',
    singular: 'Task',
    plural: 'Tasks',
    viewSingular: 'View Task',
    viewPlural: 'View Tasks',
    selectSingular: 'Select Task',
    selectPlural: 'Select Tasks',
    addSingular: 'Add Task',
    addPlural: 'Add Tasks',
    editSingular: 'Edit Task',
    editPlural: 'Edit Tasks',
    addedSingular: 'Task added',
    addedPlural: 'Tasks added',
    updatedSingular: 'Task updated',
    updatedPlural: 'Tasks updated',
    deletedSingular: 'Task deleted',
    deletedPlural: 'Tasks deleted',
    archivedSingular: 'Task archived',
    archivedPlural: 'Tasks archived',
    restoredSingular: 'Task restored',
    restoredPlural: 'Tasks restored',
    errorFetchingSingular: 'Error fetching Task',
    errorFetchingPlural: 'Error fetching Tasks',
    errorAddingSingular: 'Error adding Task',
    errorAddingPlural: 'Error adding Tasks',
    errorUpdatingSingular: 'Error updating Task',
    errorUpdatingPlural: 'Error updating Tasks',
    errorDeletingSingular: 'Error deleting Task',
    errorDeletingPlural: 'Error deleting Tasks',
    errorArchivingSingular: 'Error archiving Task',
    errorArchivingPlural: 'Error archiving Tasks',
    errorRestoringSingular: 'Error restoring Task',
    errorRestoringPlural: 'Error restoring Tasks',
    notFoundSingular: 'Task not found',
    notFoundPlural: 'No Tasks found',
    loadingSingular: 'Loading Task',
    loadingSingularEllipsis: 'Loading Task...',
    loadingPlural: 'Loading Tasks',
    loadingPluralEllipsis: 'Loading Tasks...',
    search: 'Search Tasks',
    searchEllipsis: 'Search Tasks...',
    filter: 'Filter Tasks',
    settings: 'Tasks Settings',
  };

	static endpoints: TableEndpoints = {
		readRecords: 'tasks',
		readRecord: 'task/:id',
		createRecord: 'task',
    updateRecord: 'task/:id',
    updateRecords: 'tasks',
		patchRecord: 'task/:id',
    deleteRecord: 'task/:id',
    archiveRecord: 'task/:id/archive',
    restoreRecord: 'task/:id/restore',
  };

  static filters: TableFilter[] = [
    {
      name: 'updated',
      label: 'Task Activity',
      type: 'radio',
      options: [
        { value: '', label: 'Any time'},
        { value: 'today', label: 'Updated today' },
        { value: 'week', label: 'One week or less' },
        { value: 'month', label: 'One month or less' },
        { value: 'older', label: 'More than one month' },
      ],
    },
    {
      name: 'age',
      label: 'Task Age',
      type: 'radio',
      options: [
        { value: '', label: 'Any Age'},
        { value: 'today', label: 'Added today' },
        { value: 'week', label: 'One week or less' },
        { value: 'month', label: 'One month or less' },
        { value: 'older', label: 'Older than one month' },
      ],
    },
    {
      name: 'due',
      label: 'Due Date',
      type: 'radio',
      options: [
        { value: '', label: 'Any due date' },
        { value: 'overdue', label: 'Overdue' },
        { value: 'today', label: 'Due today' },
        { value: 'week', label: 'Due this week' },
        { value: 'month', label: 'Due this month' },
      ],
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'radio',
      options: [
        { value: '', label: 'Any Priority'},
        { value: 'unprioritized', label: 'Unprioritized' },
        { value: 'low', label: 'Low Priority' },
        { value: 'medium', label: 'Medium Priority' },
        { value: 'high', label: 'High Priority' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'radio',
      options: [
        { label: 'Any Status', value: '' },
        { label: 'Pending', value: 'pending' },
				{ label: 'In Progress', value: 'in-progress' },
        { label: 'On Hold', value: 'on-hold' },
				{ label: 'Completed', value: 'completed' },
      ],
    },
    {
      name: 'following',
      label: 'Following',
      type: 'radio',
      options: [
        { value: '', label: 'Any'},
        { value: 'true', label: 'Following' },
        { value: 'false', label: 'Not following' },
      ],
    },
    {
      name: 'archived',
      label: 'Archived',
      type: 'radio',
      options: [
        { value: '', label: 'Unarchived' },
        { value: 'true', label: 'Archived' },
      ],
    },
  ];

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
      default: 0,
      readOnly: true,
      isInfo: true,
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      default: '',
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
			isTableColumn: true,
      // formFieldSize: 'large',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
			default: '',
      isFormField: true,
      isInfo: true,
			// formFieldSize: 'large',
    },
    {
      name: 'board',
      label: 'Board',
      type: 'select',
      default: 'personal',
      options: [
        { label: 'Personal', value: 'personal' },
        { label: 'Targets', value: 'targets' },
        { label: 'Sales Pipeline', value: 'new-business' },
        { label: 'Finance', value: 'finance' },
        { label: 'Customer Service', value: 'customer-service' },
      ],
      required: true,
      // isFormField: true,
      isTableColumn: true,
      isInfo: true,
      infoSize: 'small',
      columnWidth: 180,
    },
    {
      name: 'status',
      label: 'Status',
			type: 'select',
			default: 'pending',
			options: [
        { label: 'Pending', value: 'pending', color: '#607D8B' },
				{ label: 'In Progress', value: 'in-progress', color: '#03A9F4' },
        { label: 'On Hold', value: 'on-hold', color: '#DB584F' },
				{ label: 'Completed', value: 'completed', color: '#4CAF50' },
			],
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'medium',
      isTableColumn: true,
      columnWidth: 160,
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'select',
      default: 'low',
      options: [
        // { label: 'Unprioritized', value: '' },
        { label: 'Low', value: 'low', color: '#2FA0B5' },
        { label: 'Medium', value: 'medium', color: '#E8AD55' },
        { label: 'High', value: 'high', color: '#D04B52' },
      ],
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'medium',
      isTableColumn: true,
      columnWidth: 130,
    },
		{
			name: 'dueDate',
			label: 'Due Date',
			type: 'date',
			default: '',
			isSortable: true,
      isFormField: true,
      isInfo: true,
      formFieldSize: 'medium',
      // infoSize: 'small',
      isTableColumn: true,
      columnWidth: 140,
		},
    {
      name: 'commentCount',
      label: 'Comments',
      type: 'number',
      default: 0,
      isSortable: true,
      isTableColumn: true,
      icon: { icon: 'chat_bubble_outline' },
      columnWidth: 80,
    },
		{
			name: 'createdBy',
			label: 'Created By',
			type: 'lookup-input',
      model: User,
			default: '',
      readOnly: true,
      isInfo: true,
		},
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      // isTableColumn: true,
      isInfo: true,
    },
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
    }
  ];

	static completedStatuses: string[] =  ['completed'];

	static warnings: Record<string, string> = {
		pastDue: 'The due date is past due.',
	};

	static getWarnings<T>(record: T): string[] {
		const warnings: string[] = [];
		if (this.isPastDue(record as unknown as TaskSchema)) {
			warnings.push(this.warnings.pastDue);
		}
		return warnings;
	}

  static isPastDue(record: TaskSchema): boolean {
    let isPastDue = false;
    if (record.dueDate && ! this.completedStatuses.includes(record.status) && ! record.archived) {
      const now = new Date();
      isPastDue = (now.getTime() > new Date(record.dueDate).getTime());
    }
    return isPastDue;
  }

  static getNewRecord(socketMessage: SocketMessage<ActionSchema>) {
    const { data } = socketMessage;
    const newRecord: TaskSchema = {
      ...this.getDefaultRecord<TaskSchema>(),
      ...JSON.parse(data.content),
      id: parseInt(data.resourceId.toString(), 10),
      created: data.created,
      updated: data.created,
    };
    return newRecord;
  }

  static orderRecords(records: TaskSchema[], order: string) {
    const newRecords = [...records];
    if (['created', '-created'].includes(order)) {
      newRecords.sort((a, b) => {
        const aTime = new Date(a.created).getTime();
        const bTime = new Date(b.created).getTime();
        return (order === '-created') ? (bTime - aTime) : (aTime - bTime);
      });
    } else if (['updated', '-updated'].includes(order)) {
      newRecords.sort((a, b) => {
        const aTime = new Date(a.updated).getTime();
        const bTime = new Date(b.updated).getTime();
        return (order === '-updated') ? (bTime - aTime) : (aTime - bTime);
      });
    } else if (['dueDate', '-dueDate'].includes(order)) {
      newRecords.sort((a, b) => {
        const aTime = a.dueDate ? new Date(a.dueDate).getTime() : 0;
        const bTime = b.dueDate ? new Date(b.dueDate).getTime() : 0;
        return (order === '-dueDate') ? (bTime - aTime) : (aTime - bTime);
      });
    } else if (['title', '-title'].includes(order)) {
      if (order === '-title') {
        newRecords.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) return 1;
          if (a.title.toLowerCase() > b.title.toLowerCase()) return -1;
          return 0;
        });
      } else {
        newRecords.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
          if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
          return 0;
        });
      }
    } else if (['priority', '-priority'].includes(order)) {
      if (order === '-priority') {
        newRecords.sort((a, b) => {
          if ((a.priority?.toLowerCase() || '') < (b.priority?.toLowerCase() || '')) return 1;
          if ((a.priority?.toLowerCase() || '') > (b.priority?.toLowerCase() || '')) return -1;
          return 0;
        });
      } else {
        newRecords.sort((a, b) => {
          if ((a.priority?.toLowerCase() || '') < (b.priority?.toLowerCase() || '')) return -1;
          if ((a.priority?.toLowerCase() || '') > (b.priority?.toLowerCase() || '')) return 1;
          return 0;
        });
      }
    } else if (['status', '-status'].includes(order)) {
      const statusOptionValues = this.getField('status')?.options?.map(option => option.value) || [];
      if (order === '-status') {
        newRecords.sort((a, b) => {
          if (statusOptionValues.indexOf(a.status) < statusOptionValues.indexOf(b.status)) return 1;
          if (statusOptionValues.indexOf(a.status) > statusOptionValues.indexOf(b.status)) return -1;
          return 0;
        });
      } else {
        newRecords.sort((a, b) => {
          if (statusOptionValues.indexOf(a.status) < statusOptionValues.indexOf(b.status)) return -1;
          if (statusOptionValues.indexOf(a.status) > statusOptionValues.indexOf(b.status)) return 1;
          return 0;
        });
      }
    } else if (['commentCount', '-commentCount'].includes(order)) {
      if (order === '-commentCount') {
        newRecords.sort((a, b) => {
          if ((a.commentCount || 0) < (b.commentCount || 0)) return 1;
          if ((a.commentCount || 0) > (b.commentCount || 0)) return -1;
          return 0;
        });
      } else {
        newRecords.sort((a, b) => {
          if ((a.commentCount || 0) < (b.commentCount || 0)) return -1;
          if ((a.commentCount || 0) > (b.commentCount || 0)) return 1;
          return 0;
        });
      }
    } /* else if (['estTons', '-estTons'].includes(order)) {
      if (order === '-estTons') {
        newRecords.sort((a, b) => {
          if ((a.estTons || 0) < (b.estTons || 0)) return 1;
          if ((a.estTons || 0) > (b.estTons || 0)) return -1;
          return 0;
        });
      } else {
        newRecords.sort((a, b) => {
          if ((a.estTons || 0) < (b.estTons || 0)) return -1;
          if ((a.estTons || 0) > (b.estTons || 0)) return 1;
          return 0;
        });
      }
    } else if (['estProfit', '-estProfit'].includes(order)) {
      if (order === '-estProfit') {
        newRecords.sort((a, b) => {
          if ((a.estProfit || 0) < (b.estProfit || 0)) return 1;
          if ((a.estProfit || 0) > (b.estProfit || 0)) return -1;
          return 0;
        });
      } else {
        newRecords.sort((a, b) => {
          if ((a.estProfit || 0) < (b.estProfit || 0)) return -1;
          if ((a.estProfit || 0) > (b.estProfit || 0)) return 1;
          return 0;
        });
      }
    } */
    return newRecords;
  }

  static getSyncedRecord(record: TaskSchema, socketMessage: SocketMessage<ActionSchema>) {
    const { message, data } = socketMessage;
    const parsed: any = ['comment', 'update-comment', 'delete-comment'].includes(message) ? data.content : JSON.parse(data.content);
    const updates: Partial<TaskSchema> = (message === 'update') ? parsed : (message === 'remove-self') ? { assignedTo: (parsed as RemoveSelfActionContent).assignedTo } : {};
    const parentId: TaskSchema['id'] | undefined = (message === 'assign-parent') ? (parsed as Partial<TaskSchema>).parentId || record.parentId : record.parentId;
    const addedSubtask: TaskSchema['id'] | undefined = (message === 'add-subtask') ? (parsed as { id: TaskSchema['id'] }).id || undefined : undefined;
    const removedSubtask: TaskSchema['id'] | undefined = (message === 'archive-subtask') ? (parsed as { id: TaskSchema['id'] }).id || undefined : undefined;
    const addedAttachment: AttachmentSchema['id'] | undefined = ['add-attachment', 'update-attachment'].includes(message) ? (parsed as { id: AttachmentSchema['id'] }).id || undefined : undefined;
    const removedAttachment: AttachmentSchema['id'] | undefined = (message === 'remove-attachment') ? (parsed as { id: AttachmentSchema['id'] }).id || undefined : undefined;
    const addedAttachments: AttachmentSchema['id'][] | undefined = (message === 'add-attachments') ? (parsed as AttachmentSchema[]).map(attachment => attachment.id) : undefined;
    const addedReminder: ReminderSchema['id'] | undefined = (message === 'add-reminder') ? (parsed as { id: ReminderSchema['id'] }).id || undefined : undefined;
    const removedReminder: ReminderSchema['id'] | undefined = (message === 'delete-reminder') ? (parsed as { id: ReminderSchema['id'] }).id || undefined : undefined;
		const addedGrade: GradeSchema['id'] | undefined = (message === 'add-grade') ? (parsed as GradeSchema).id || undefined : undefined;
    const removedGrade: GradeSchema['id'] | undefined = (message === 'remove-grade') ? (parsed as { before: GradeSchema }).before.id || undefined : undefined;
		const addedExpense: ExpenseSchema['id'] | undefined = (message === 'add-expense') ? (parsed as ExpenseSchema).id || undefined : undefined;
    const removedExpense: ExpenseSchema['id'] | undefined = (message === 'remove-expense') ? (parsed as { before: ExpenseSchema }).before.id || undefined : undefined;
    const addedMembers: UserSchema['id'][] = (message === 'add-members') ? (parsed as Partial<TaskSchema>).members || [] : (updates.assignedTo) ? [updates.assignedTo] : [];
    const removedMembers: UserSchema['id'][] = (message === 'remove-members') ? (parsed as Partial<TaskSchema>).members || [] : (message === 'remove-self' && (parsed as RemoveSelfActionContent).removedMember) ? [data.createdBy] : [];
    const consolidatedAddedAttachments = this.getConsolidatedAttachmentIDs(addedAttachments, addedAttachment);
    const archived: boolean = (message === 'restore') ? false : (message === 'archive') ? true : Boolean(record.archived);
    const isPushed: boolean | undefined = (message === 'cietrade-push') ? true : undefined;
		const newRecord: TaskSchema = {
      ...record,
      ...updates,
      members: Task.getSyncedMembers(record, addedMembers, removedMembers),
      subtaskIds: Task.getSyncedSubtaskIDs(record, addedSubtask, removedSubtask),
      attachments: Task.getSyncedAttachmentIDs(record, consolidatedAddedAttachments, removedAttachment),
      reminders: Task.getSyncedReminderIDs(record, addedReminder, removedReminder),
			grades: Task.getSyncedGradeIDs(record, addedGrade, removedGrade),
			expenses: Task.getSyncedExpenseIDs(record, addedExpense, removedExpense),
      commentCount: Task.getSyncedCommentCount(record, message),
      archived: archived,
      parentId: parentId,
      updated: data.updated,
    };
		newRecord.hasPNL = Boolean((newRecord.grades && (newRecord.grades.length > 0)) || (newRecord.expenses && (newRecord.expenses.length > 0)));
		if (isPushed !== undefined) newRecord.isPushed = isPushed;
    return newRecord;
  }

  static getConsolidatedAttachmentIDs(addedAttachmentIDs: AttachmentSchema['id'][] = [], addedAttachmentID?: AttachmentSchema['id']) {
    let attachmentIDs: AttachmentSchema['id'][] = [];
    if (addedAttachmentIDs.length > 0) {
      attachmentIDs = [...addedAttachmentIDs];
    }
    if (addedAttachmentID) {
      attachmentIDs.push(addedAttachmentID);
    }
    return attachmentIDs;
  }

  static getSyncedCommentCount(record: TaskSchema, message: SocketMessage['message']) {
    let count = record?.commentCount || 0;
    switch (message) {
      case 'comment': return (count + 1);
      case 'delete-comment': return (count > 0) ? (count - 1) : 0;
      default: return count;
    }
  }

  static getSyncedMembers(record: TaskSchema, added: UserSchema['id'][], removed: UserSchema['id'][]) {
    let newMembers = record?.members ? [...record.members] : [];
    if (added.length > 0) {
      added.forEach(memberID => {
        if (! newMembers.includes(memberID)) {
          newMembers.push(memberID);
        }
      })
    }
    if (removed.length > 0) {
      newMembers = newMembers.filter(memberID => ! removed.includes(memberID));
    }
    return newMembers;
  }

  static getSyncedSubtaskIDs(record: TaskSchema, added?: TaskSchema['id'], removed?: TaskSchema['id']) {
    let newSubtaskIDs = record?.subtaskIds ? [...record.subtaskIds] : [];
    if (added !== undefined) {
      if (!newSubtaskIDs.includes(added)) {
        newSubtaskIDs.push(added);
      }
    }
    if (removed !== undefined) {
      newSubtaskIDs = newSubtaskIDs.filter(subtaskID => (subtaskID !== removed));
    }
    return newSubtaskIDs;
  }

  static getSyncedAttachmentIDs(record: TaskSchema, added?: AttachmentSchema['id'][], removed?: AttachmentSchema['id']) {
    let newAttachmentIDs = record?.attachments ? [...record.attachments] : [];
    if (added !== undefined) {
      added.forEach(addedID => {
        if (!newAttachmentIDs.includes(addedID)) {
          newAttachmentIDs.push(addedID);
        }
      });
    }
    if (removed !== undefined) {
      newAttachmentIDs = newAttachmentIDs.filter(attachmentID => (attachmentID !== removed));
    }
    return newAttachmentIDs;
  }

  static getSyncedReminderIDs(record: TaskSchema, added?: ReminderSchema['id'], removed?: ReminderSchema['id']) {
    let newReminderIDs = record?.reminders ? [...record.reminders] : [];
    if (added !== undefined) {
      if (!newReminderIDs.includes(added)) {
        newReminderIDs.push(added);
      }
    }
    if (removed !== undefined) {
      newReminderIDs = newReminderIDs.filter(reminderID => (reminderID !== removed));
    }
    return newReminderIDs;
  }

	static getSyncedGradeIDs(record: TaskSchema, added?: GradeSchema['id'], removed?: GradeSchema['id']) {
    let newGradeIDs = record?.grades ? [...record.grades] : [];
    if (added !== undefined) {
      if (!newGradeIDs.includes(added)) {
        newGradeIDs.push(added);
      }
    }
    if (removed !== undefined) {
      newGradeIDs = newGradeIDs.filter(gradeID => (gradeID !== removed));
    }
    return newGradeIDs;
  }

	static getSyncedExpenseIDs(record: TaskSchema, added?: ExpenseSchema['id'], removed?: ExpenseSchema['id']) {
    let newExpenseIDs = record?.expenses ? [...record.expenses] : [];
    if (added !== undefined) {
      if (!newExpenseIDs.includes(added)) {
        newExpenseIDs.push(added);
      }
    }
    if (removed !== undefined) {
      newExpenseIDs = newExpenseIDs.filter(expenseID => (expenseID !== removed));
    }
    return newExpenseIDs;
  }

  static getGroupFields(board: string) {
    return [
      ...this.getFieldsBy<TaskSchema>('type', 'select').filter(field => (field.name !== 'board'/*  && this.isFieldVisible(field.name, board) */)),
      // ...this.getFieldsBy<TaskSchema>('type', 'lookup'),
    ];
  }

  static async followRecord(token: string, id: TaskSchema['id']): Promise<{ ok: boolean }> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/follow`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async unfollowRecord(token: string, id: TaskSchema['id']): Promise<{ ok: boolean }> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/follow`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async addMembers(token: string, id: TaskSchema['id'], memberIDs: UserSchema['id'][]): Promise<ReadRecordResponse<TaskSchema>> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-members`, {
      method: 'POST',
      body: JSON.stringify({ members: memberIDs }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async removeMembers(token: string, id: TaskSchema['id'], memberIDs: UserSchema['id'][]): Promise<ReadRecordResponse<TaskSchema>> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/remove-members`, {
      method: 'POST',
      body: JSON.stringify({ members: memberIDs }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async createComment(token: string, id: TaskSchema['id'], comment: string): Promise<ActionSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/comment`, {
      method: 'POST',
      body: JSON.stringify({ comment: comment }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async updateComment(token: string, id: TaskSchema['id'], action: ActionSchema): Promise<ActionSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/comment/${action.id}`, {
      method: 'POST',
      body: JSON.stringify({ comment: action.content }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async deleteComment(token: string, id: TaskSchema['id'], action: ActionSchema): Promise<ActionSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/comment/${action.id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async readReminder(token: string, id: TaskSchema['id'], reminderID: ReminderSchema['id']): Promise<ReadRecordResponse<ReminderSchema>> {
    const url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/reminder/${reminderID}`;
    const response = await fetch(url, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    switch(response.status) {
        case 200: return await response.json();
        default: throw new APIError(response.status, Reminder.getLabel('errorFetchingSingular'));
    }
}

  static async createReminder(token: string, id: TaskSchema['id'], record: ReminderSchema): Promise<ReminderSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/reminder`, {
      method: 'POST',
      body: JSON.stringify(record),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Reminder.getLabel('errorAddingSingular'));
    }
  }

  static async updateReminder(token: string, id: TaskSchema['id'], record: ReminderSchema): Promise<ReminderSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/reminder/${record.id}`, {
      method: 'POST',
      body: JSON.stringify(record),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Reminder.getLabel('errorUpdatingSingular'));
    }
  }

  static async deleteReminder(token: string, id: TaskSchema['id'], record: ReminderSchema): Promise<ReminderSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/reminder/${record.id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Reminder.getLabel('errorDeletingSingular'));
    }
  }

  static async markReminderAsComplete(token: string, id: TaskSchema['id'], record: ReminderSchema): Promise<ReminderSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/reminder/${record.id}/toggle`, {
      method: 'POST',
      body: JSON.stringify({ completed: true }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Reminder.getLabel('errorUpdatingSingular'));
    }
  }

  static async markReminderAsIncomplete(token: string, id: TaskSchema['id'], record: ReminderSchema): Promise<ReminderSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/reminder/${record.id}/toggle`, {
      method: 'POST',
      body: JSON.stringify({ completed: false }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Reminder.getLabel('errorUpdatingSingular'));
    }
  }

  static async createAttachment(token: string, id: TaskSchema['id'], record: AttachmentSchema): Promise<boolean> {
    const file = record.file;
    if (file) {
      const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-attachment`, {
        method: 'POST',
        body: JSON.stringify({
          title: record.title,
          description: record.description,
          contentType: file.type || mime.getType(file.name) || 'application/octet-stream',
          fileName: file.name,
        }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      switch (response.status) {
        case 200:
          const { signedUrl, id: attachmentID }: CreateAttachmentResponse = await response.json();
          await Attachment.uploadFile(attachmentID, signedUrl, file);
          await Attachment.validateUpload(token, attachmentID);
          return true;
        default: throw new APIError(response.status, Attachment.getLabel('errorAddingSingular'));
      }
    } else {
      throw new Error('No file');
    }
  }

  static async createAttachments(token: string, id: TaskSchema['id'], records: AttachmentSchema[]): Promise<boolean> {
    const recordsWithFiles = records.filter(record => Boolean(record.file)) as (Omit<AttachmentSchema, 'file'> & { file: File })[];
    const files: Record<string, any>[] = recordsWithFiles.map(record => ({
      title: record.title || '',
      description: record.description || '',
      contentType: record.file?.type || mime.getType(record.file?.name || '') || 'application/octet-stream',
      fileName: record.file?.name || '',
    }));
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-attachments`, {
      method: 'POST',
      body: JSON.stringify({
        files: files,
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200:
        const attachmentResponses: CreateAttachmentResponse[] = await response.json();
        const promises = attachmentResponses.map((response, i) => Attachment.uploadFile(response.id, response.signedUrl, recordsWithFiles[i].file));
        const resolvedPromises = await Promise.allSettled(promises);
        const attachmentIDs = attachmentResponses.filter((_response, i) => (resolvedPromises[i].status === 'fulfilled')).map(response => response.id);
        await Attachment.validateUploads(token, attachmentIDs);
        return true;
      default: throw new APIError(response.status, Attachment.getLabel('errorAddingSingular'));
    }
  }

  static async updateAttachment(token: string, id: TaskSchema['id'], record: AttachmentSchema): Promise<AttachmentSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/attachment/${record.id}`, {
      method: 'POST',
      body: JSON.stringify(record),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Attachment.getLabel('errorUpdatingSingular'));
    }
  }

  static async deleteAttachment(token: string, id: TaskSchema['id'], attachmentID: AttachmentSchema['id']): Promise<{ ok: boolean}> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/attachment/${attachmentID}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Attachment.getLabel('errorDeletingSingular'));
    }
  }

  static async createGrade(token: string, id: TaskSchema['id'], record: GradeSchema): Promise<GradeSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-grade`, {
      method: 'POST',
      body: JSON.stringify(record),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Grade.getLabel('errorAddingSingular'));
    }
  }

	static async updateGrade(token: string, id: TaskSchema['id'], record: GradeSchema): Promise<GradeSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/update-grade/${record.id}`, {
      method: 'POST',
      body: JSON.stringify(record),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Grade.getLabel('errorUpdatingSingular'));
    }
  }

	static async deleteGrade(token: string, id: TaskSchema['id'], gradeID: GradeSchema['id']): Promise<{ ok: boolean}> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/remove-grade/${gradeID}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Grade.getLabel('errorDeletingSingular'));
    }
  }

  static async createExpense(token: string, id: TaskSchema['id'], record: ExpenseSchema): Promise<ExpenseSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-expense`, {
      method: 'POST',
      body: JSON.stringify(record),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Expense.getLabel('errorAddingSingular'));
    }
  }

	static async updateExpense(token: string, id: TaskSchema['id'], record: ExpenseSchema): Promise<ExpenseSchema> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/update-expense/${record.id}`, {
      method: 'POST',
      body: JSON.stringify(record),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Expense.getLabel('errorUpdatingSingular'));
    }
  }

	static async deleteExpense(token: string, id: TaskSchema['id'], expenseID: ExpenseSchema['id']): Promise<{ ok: boolean}> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/remove-expense/${expenseID}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, Expense.getLabel('errorDeletingSingular'));
    }
  }

  static async convertToSubtask(token: string, id: TaskSchema['id'], parentID: TaskSchema['id']): Promise<{ ok: boolean }> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/assign-parent`, {
      method: 'POST',
      body: JSON.stringify({ parentId: parentID }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }

  static async removeSelf(token: string, id: TaskSchema['id'], assignedTo?: UserSchema['id']): Promise<{ ok: boolean }> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/remove-self`, {
      method: 'POST',
      body: JSON.stringify({ assignedTo: assignedTo }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
  }
}

export default Task;
