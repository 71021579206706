import React from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import Input from '../../components/Input/Input';
import SusiFormContainer from '../../components/SusiFormContainer/SusiFormContainer';
import { AuthContext, LayoutContext } from '../../contexts';
import { Auth } from '../../types';
import { State as LayoutState } from '../../components/Layout/Layout';
import './VerificationPage.scss';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
	layout: LayoutState;
}

export interface State {
  code: string;
}

class VerificationPageBase extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.state = {
      code: '',
    };
  }

  handleCodeChange(value: string | number) {
    this.setState({ code: value.toString() });
  }

	componentDidMount() {
		const { layout } = this.props;
		layout.hideHeader();
		layout.makeBodyScrollable();
	}

	componentWillUnmount() {
		const { layout } = this.props;
		layout.showHeader();
		layout.makeBodyUnscrollable();
	}

  render() {
    const { auth, className, match, location, history, staticContext, layout, ...restProps } = this.props;
    const { code } = this.state;
    const containerClass = classNames('fourg-verification-page', className);
    return (
      <Page title="Verify Account" className={containerClass} {...restProps}>
				<SusiFormContainer>
					<Form
					title="Verify Account"
					submitLabel="Verify Account"
					cancelLabel="Resend Verification Code"
					isLoading={auth.isLoading}
					onSubmit={() => auth.verifyAccount(code)}
					onCancel={() => auth.sendVerificationCode()}>
						<Input
						label="Code"
						required={true}
						type="text"
						value={code}
						disabled={auth.isLoading}
						onChange={this.handleCodeChange} />
					</Form>
				</SusiFormContainer>
      </Page>
    );
  }
}

const VerificationPage: React.FC<Omit<Props, 'auth' | 'layout'>> = (props) => {
	return (
		<AuthContext.Consumer>
			{auth => (
				<LayoutContext.Consumer>
					{(layout) => (
						<VerificationPageBase auth={auth} layout={layout} {...props} />
					)}
				</LayoutContext.Consumer>
			)}
		</AuthContext.Consumer>
	);
};

export default VerificationPage;
