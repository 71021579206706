import React from 'react';
import classNames from 'classnames';
import './MediaIcon.scss';

export interface Props {
  id?: string;
  className?: string;
  type: string;
  title?: string;
}

export interface State {

}

class MediaIcon extends React.Component<Props, State> {

  render() {
    const { title, type, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-media-icon', className);
    return (
      <svg viewBox="-1 -1 32 42" className={containerClass} {...restProps}>
        {title && (
          <title>{title}</title>
        )}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g>
            <polygon fillOpacity="0.2" fill="currentColor" points="22 8 22 -5.68434189e-14 30 8" />
            <g stroke="currentColor">
              <polygon points="1.42094669e-12 0 1.42094669e-12 40 30 40 30 8 22 0" fill="#ffffff" />
              <polyline points="22 0 22 8 30 8" />
            </g>
            <text x={4} y={24} textLength={22} lengthAdjust="spacingAndGlyphs" fontSize="11" fontStyle="condensed" fontWeight="bold" fill="currentColor">
              {type.toUpperCase()}
            </text>
          </g>
        </g>
      </svg>
    );
  }
}

export default MediaIcon;
