import React from 'react';
import classNames from 'classnames';
import RecurrenceInput from '../RecurrenceInput/RecurrenceInput';
import { RecurrenceValue } from '../../types';
import './RecurrenceSpan.scss';

export interface Props {
  id?: string;
  className?: string;
  value: RecurrenceValue;
}

export interface State {

}

class RecurrenceSpan extends React.Component<Props, State> {

  getText() {
    const { value } = this.props;
    const date = value.date ? new Date(value.date) : undefined;
    const intervalOption = value.interval ? RecurrenceInput.intervalOptions.find(option => (option.value === value.interval)) : undefined;
    let text = '';
    if (value && date) {
      switch (value.interval) {
        case undefined:
          const now = new Date();
          if (date.getFullYear() === now.getFullYear()) {
            text = date.toLocaleString(undefined, {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            });
          } else {
            text = date.toLocaleString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            });
          }
          break;
        case 'w':
          text = `Repeats ${intervalOption?.label} on ${date.toLocaleDateString(undefined, { weekday: 'long' })} at ${date.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
          break;
        case 'm':
          text = `Repeats ${intervalOption?.label} on the ${RecurrenceInput.getOrdinal(date.getDate())} at ${date.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
          break;
        case 'y':
          text = `Repeats ${intervalOption?.label} on ${date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} at ${date.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
          break;
        default:
          text = `Repeats ${intervalOption?.label} at ${date.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
          break;
      }
    }
    return text;
  }

  getSubtext() {
    const { value } = this.props;
    const nextOccurrenceDate = value.nextOccurrence ? new Date(value.nextOccurrence) : undefined;
    let text = '';
    if (nextOccurrenceDate) {
      const dateString = nextOccurrenceDate.toLocaleString(undefined, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      });
      text = `Next occurrence: ${dateString}`;
    }
    return text;
  }

  render() {
    const { value, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-recurrence-span', className);
    const text = this.getText();
    const subtext = this.getSubtext();
    return (
      <span className={containerClass} {...restProps}>
        <span className="fourg-recurrence-span__text">{text}</span>
        {subtext && (
          <span className="fourg-recurrence-span__subtext">{subtext}</span>
        )}
      </span>
    );
  }
}

export default RecurrenceSpan;
