import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Badge from '../Badge/Badge';
import DateSpan from '../DateSpan/DateSpan';
import { LookupContext } from '../../contexts';
import { TableField, Lookup } from '../../types';
import ModelRecordInfo from '../ModelRecordInfo/ModelRecordInfo';
import RecurrenceSpan from '../RecurrenceSpan/RecurrenceSpan';
import Table from '../../models/Table';
import Task from '../../models/tables/Task';
import Reminder from '../../models/tables/Reminder';
import './TableCellByField.scss';

export interface Props<T extends Record<string, any> = Record<string, any>> {
  id?: string;
  className?: string;
  field: TableField<T>;
  value: any;
  record: T;
}

export interface State {

}

class TableCellByField<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State> {

  renderDefault() {
    const { value } = this.props;
    return value;
  }

  renderDate() {
    const { value } = this.props;
    return ( <DateSpan date={new Date(value)} /> );
  }

  renderRecurrence() {
    const { value } = this.props;
    return ( <RecurrenceSpan value={value} /> );
  }

  renderSelect() {
    const { field, value } = this.props;
    const option = field.options ? field.options.find(option => (option.value === (value || ''))) : undefined;
    const optionLabel = option?.label || value
    return (! option?.color) ? optionLabel : (
      <Badge color={option.color}>{optionLabel}</Badge>
    );
  }

  renderSelectMultiple() {
    const { field, value } = this.props;
    let labels = '';
    if (value && field.options && (field.options.length > 0)) {
      labels = field.options
        .filter(option => value.includes(option.value))
        .map(option => option.label)
        .join(', ');
    }
    return labels;
  }

  renderNumber() {
    const { value } = this.props;
    return parseFloat(value).toLocaleString();
  }

  renderAddress() {
    const { value } = this.props;
    let string = '';
    if (value && (typeof value === 'object')) {
      string = Object.values(value).filter(piece => Boolean(piece)).join(', ');
    }
    return ( <address>{string}</address> );
  }

  renderCheckbox() {
    const { value } = this.props;
    return ( <Icon src={{ icon: value ? 'check' : 'clear' }} label={value ? 'True' : 'False'} /> );
  }

  renderEmpty() {
    return ( <span className="fourg-table-cell-by-field__empty">&#x2015;</span> );
  }

  renderURL() {
    const { value } = this.props;
    return (
      <a
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={value}>
        {value}
      </a>
    );
  }

  renderEmail() {
    const { value } = this.props;
    return (
      <a
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={`mailto:${value}`}>
        {value}
      </a>
    );
  }

  renderTel() {
    const { value } = this.props;
    const cleanValue = (value.match(/(\+|[0-9]|x)/g) || []).join('').replace(/x/g, ',');
    return (
      <a
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={`tel:${cleanValue}`}>
        {value}
      </a>
    );
  }

  renderLookup(lookup: Lookup) {
    const { field, value } = this.props;
    return field.model ? <ModelRecordInfo model={field.model} value={value} /> : value;
  }

	renderChipList(lookup: Lookup) {
    const { field, value } = this.props;
    let labels = '';
    if (value && field.options && (field.options.length > 0)) {
      labels = field.options
        .filter(option => value.includes(option.value))
        .map(option => option.label)
        .join(', ');
    }
    return labels;
  }

  renderResource(lookup: Lookup) {
    const { value, record } = this.props;
    const typedRecord = record as Record<string, any>;
    const model = typedRecord.resourceType ? this.getModelByResourceType(typedRecord.resourceType) : undefined;
    return model ? <ModelRecordInfo model={model} value={value} /> : value;
  }

  getModelByResourceType(type: string): typeof Table {
    switch (type) {
      case 'reminder': return Reminder;
      default: return Task;
    }
  }

  renderByType(lookup: Lookup) {
    const { field } = this.props;
    switch (field.type) {
      case 'date': return this.renderDate();
      case 'recurrence': return this.renderRecurrence();
      case 'select-input': return this.renderSelect();
      case 'select': return this.renderSelect();
      case 'select-multiple': return this.renderSelectMultiple();
      case 'email': return this.renderEmail();
      case 'number': return this.renderNumber();
      case 'address': return this.renderAddress();
      case 'checkbox': return this.renderCheckbox();
      case 'url': return this.renderURL();
      case 'tel': return this.renderTel();
      case 'lookup-input': return this.renderLookup(lookup);
      case 'lookup': return this.renderLookup(lookup);
      case 'resource': return this.renderResource(lookup);
      case 'chip-list': return this.renderChipList(lookup);
      default: return this.renderDefault();
    }
  }

  render() {
    const { field, value, className, record, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-table-cell-by-field', `fourg-table-cell-by-field--type-${field.type}`, className);
    return (
      <LookupContext.Consumer>
        {lookup => (
          <div className={containerClass} {...restProps}>
            {(value || (typeof value === 'number')) ? this.renderByType(lookup) : this.renderEmpty()}
          </div>
        )}
      </LookupContext.Consumer>
    );
  }
}

export default TableCellByField;
