import React from 'react';
import classNames from 'classnames';
import PageHeader from '../PageHeader/PageHeader';
import AnnouncementBar from '../AnnouncementBar/AnnouncementBar';
import { Breadcrumb, UIOption } from '../../types';
import './Page.scss';

export interface Props {
  id?: string;
  className?: string;
  title: string;
  description: string;
  thumbnail: string;
  lastActive?: Date;
  breadcrumbs?: Breadcrumb[];
  headerTitle?: React.ReactNode;
  headerDescription?: React.ReactNode;
  actions?: React.ReactNode;
  announcementBarTitle?: string;
  announcementBarMessage?: React.ReactNode;
  announcementBarActions?: UIOption[];
  disabled?: boolean;
  onAnnouncementBarActionClick?: (value: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  
}

class Page extends React.Component<Props, State> {

  static defaultProps = {
    title: process.env.REACT_APP_TITLE || '',
    description: process.env.REACT_APP_DESCRIPTION || '',
    thumbnail: process.env.REACT_APP_THUMBNAIL || '',
  };

  componentDidMount() {
    this.setDocTitle();
    this.setDocDescription();
    this.setDocThumbnail();
  }

  componentDidUpdate(prevProps: Props) {
    const { title, description, thumbnail } = this.props;
    if (prevProps.title !== title) {
      this.setDocTitle();
    }
    if (prevProps.description !== description) {
      this.setDocDescription();
    }
    if (prevProps.thumbnail !== thumbnail) {
      this.setDocThumbnail();
    }
  }

  setDocTitle() {
    const { title } = this.props;
    const { REACT_APP_TITLE } = process.env;
    document.title = (REACT_APP_TITLE === title) ? title : `${title} | ${REACT_APP_TITLE}`;
  }

  setDocDescription() {
    const { description } = this.props;
    const docDescription = document.querySelector('head > meta[name="description"]');
    docDescription?.setAttribute('content', description);
  }

  setDocThumbnail() {
    const { thumbnail } = this.props;
    const docThumbnail = document.querySelector('head > meta[name="thumbnail"]');
    docThumbnail?.setAttribute('content', thumbnail);
  }

  render() {
    const { disabled, announcementBarTitle, announcementBarMessage, announcementBarActions, onAnnouncementBarActionClick, lastActive, actions, headerTitle, headerDescription, breadcrumbs, title, description, thumbnail, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-page', className);
    return (
      <main className={containerClass} {...restProps}>
        <PageHeader 
        lastActive={lastActive}
        breadcrumbs={breadcrumbs}
        title={headerTitle || title} 
        description={headerDescription || description}>
          {actions}
        </PageHeader>
        {announcementBarTitle && (
          <AnnouncementBar 
          title={announcementBarTitle}
          actions={announcementBarActions}
          onActionClick={onAnnouncementBarActionClick}
          disabled={disabled}>
            {announcementBarMessage}
          </AnnouncementBar>
        )}
        <div className="fourg-page__content">
          {children}
        </div>
      </main>
    );
  }
}

export default Page;
