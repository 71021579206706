import React from 'react';
import classNames from 'classnames';
import { GradeSchema, UIOption } from '../../types';
import ActionToggle from '../ActionToggle/ActionToggle';
import Grade from '../../models/tables/Grade';
import { formatCurrency, formatVariance, formatWeight } from '../../utils';
import './GradeCard.scss';

export interface UnitLabels {
	singular: string;
	plural: string;
}

export interface Props {
  id?: string;
  className?: string;
	record: GradeSchema;
	disabled?: boolean;
  onActionsChange?: (value: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class GradeCard extends React.Component<Props, State> {
	static unitLabels: { [key: string]: UnitLabels } = {
		lbs: {
			singular: 'lb',
			plural: 'lbs',
		},
		tons: {
			singular: 'ton',
			plural: 'tons',
		},
	};

	getUnitLabel(unit: string, label: keyof UnitLabels): string {
		const unitLabels = GradeCard.unitLabels[unit];
		return (unitLabels && unitLabels[label]) ? unitLabels[label] : unit;
	}

  render() {
    const { className, record, disabled, onActionsChange, ...restProps } = this.props;
		const profit: number = (record.estSell - record.estBuy);
    const containerClass = classNames('fourg-grade-card', {
      'fourg-grade-card--disabled': disabled,
      'fourg-grade-card--positive': profit >= 0,
      'fourg-grade-card--negative': profit < 0,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
				<div className="fourg-grade-card__header">
					<div className="fourg-grade-card__titles">
						<h3 className="fourg-grade-card__title">{Grade.getRecordLabel(record)}</h3>
						<h4 className="fourg-grade-card__subtitle">{`${formatWeight(record.estWeight, 2, this.getUnitLabel(record.unit, 'plural'))}/mo.`}</h4>
					</div>
					{onActionsChange && (
						<ActionToggle
						disabled={disabled}
						className="fourg-grade-card__actions"
						variant="mini"
						icon={{ icon: 'more_vert' }}
						label={'Actions'}
						isIconOnly={true}
						anchor="top-right"
						options={[
							{ label: 'Edit', value: 'edit' },
							{ label: 'Delete', value: 'delete' },
						]}
						value=""
						onChange={onActionsChange} />
					)}
				</div>
				<div className="fourg-grade-card__metrics">
					<table cellPadding={4} cellSpacing={0}>
						<tbody>
							<tr>
								<td>
									<span className="fourg-grade-card__metric-title">{'Buy'}</span>
								</td>
								<td style={{ textAlign: 'end' }}>
									<span className="fourg-grade-card__metric-value">{`${formatCurrency(record.estBuy)}/${this.getUnitLabel(record.unit, 'singular')}`}</span>
								</td>
								<td style={{ textAlign: 'end' }}>
									<span className="fourg-grade-card__metric-comparison">{`${formatCurrency((record.estBuy * record.estWeight))}/mo.`}</span>
								</td>
								<td style={{ textAlign: 'end' }}></td>
							</tr>
							<tr>
								<td>
									<span className="fourg-grade-card__metric-title">{'Sell'}</span>
								</td>
								<td style={{ textAlign: 'end' }}>
									<span className="fourg-grade-card__metric-value">{`${formatCurrency(record.estSell)}/${this.getUnitLabel(record.unit, 'singular')}`}</span>
								</td>
								<td style={{ textAlign: 'end' }}>
									<span className="fourg-grade-card__metric-comparison">{`${formatCurrency((record.estSell * record.estWeight))}/mo.`}</span>
								</td>
								<td style={{ textAlign: 'end' }}></td>
							</tr>
							<tr>
								<td>
									<span className="fourg-grade-card__metric-title">{'Profit'}</span>
								</td>
								<td style={{ textAlign: 'end' }}>
									<span className="fourg-grade-card__metric-value">{`${formatCurrency(profit)}/${this.getUnitLabel(record.unit, 'singular')}`}</span>
								</td>
								<td style={{ textAlign: 'end' }}>
									<span className="fourg-grade-card__metric-comparison">{`${formatCurrency((profit * record.estWeight))}/mo.`}</span>
								</td>
								<td style={{ textAlign: 'end' }}>
									<span className="fourg-grade-card__metric-variance">{`${formatVariance((profit / record.estBuy), 2)}`}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
      </div>
    );
  }
}

export default GradeCard;
