import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Loader from '../Loader/Loader';
import Form from '../Form/Form';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import Reminder from '../../models/tables/Reminder';
import Task from '../../models/tables/Task';
import { TableField, Auth, ReminderSchema, TaskSchema } from '../../types';
import './ReminderFormDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  resourceID: TaskSchema['id'];
  resourceModel: typeof Task;
  recordID?: ReminderSchema['id'];
  initialValues?: Partial<ReminderSchema>;
  enforcedValues?: Partial<ReminderSchema>;
  submitLabel: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSecondary: (record: ReminderSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: ReminderSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isLoading: boolean;
  record: ReminderSchema;
}

class ReminderFormDialog extends React.Component<Props, State> {

  private formFields: React.RefObject<FormFieldByField<ReminderSchema>>[] = [];

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSecondary: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.state = {
      isLoading: false,
      record: this.getDefaultRecord(),
    };
  }

  componentDidMount() {
    const { recordID, isOpen } = this.props;
    if (isOpen && recordID) {
      this.readRecord();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { recordID, isOpen } = this.props;
    if (prevProps.isOpen && ! isOpen) {
      this.setDefaultRecord();
    }
    if (! prevProps.isOpen && isOpen) {
      if (recordID) {
        this.readRecord();
      } else {
        this.setDefaultRecord();
      }
    }
  }

  async readRecord() {
    const { auth, resourceModel, resourceID, recordID } = this.props;
    if (recordID) {
      this.setState({ isLoading: true });
      try {
        const token = await auth.getToken();
        const { data } = await resourceModel.readReminder(token, resourceID, recordID);
        this.setState({
          isLoading: false,
          record: data,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ 
          isLoading: false,
          record: this.getDefaultRecord(), 
        });
      }
    }
  }

  getDefaultRecord() {
    const { enforcedValues, initialValues } = this.props;
    const record: ReminderSchema = {
      ...Reminder.getDefaultRecord<ReminderSchema>(),
      ...initialValues,
      ...enforcedValues,
    };
    return record;
  }

  setDefaultRecord() {
    this.setState({ record: this.getDefaultRecord() });
    this.resetValidity();
  }

  handleFieldChange(field: TableField<ReminderSchema>, value: any) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          [field.name]: value,
        }
      });
    }
  }

  isFieldEnforced(field: TableField<ReminderSchema>) {
    const { enforcedValues, recordID } = this.props;
    return Boolean((enforcedValues && Object.keys(enforcedValues).includes(field.name.toString())) || (recordID && (field.name === 'type')));
  }

  resetValidity() {
    if (this.formFields.length > 0) {
      this.formFields.forEach(formField => formField.current?.resetValidity());
    }
  }

  render() {
    const { enforcedValues, initialValues, disabled, isOpen, title, auth, resourceModel, resourceID, recordID, className, submitLabel, secondaryLabel, cancelLabel, onFormCancel, onFormSubmit, onFormSecondary, ...restProps } = this.props;
    const { record, isLoading } = this.state;
    const containerClass = classNames('fourg-reminder-form-dialog', className);
    const fields = Reminder.getFieldsBy<ReminderSchema>('isFormField', true);
    return (
      <Dialog 
      className={containerClass}
      title={title}
      isOpen={isOpen}
      {...restProps}>
        {(fields.length > 0) && (
          <Form
          className="fourg-reminder-form-dialog__form"
          submitLabel={submitLabel}
          secondaryLabel={secondaryLabel}
          disabled={disabled || isLoading || ! isOpen}
          cancelLabel={cancelLabel}
          onCancel={onFormCancel}
          onSubmit={e => onFormSubmit(record, e)}
          onSecondary={e => onFormSecondary(record, e)}>
            {fields.map((field, i) => (
              <FormFieldByField<ReminderSchema>
              ref={formField => this.formFields[i] = { current: formField }}
              record={record}
							recordModel={Reminder}
              key={`form-field-${field.name}`} 
              field={field}
              value={record[field.name]}
              disabled={disabled || isLoading || ! isOpen || this.isFieldEnforced(field)}
              onChange={(value, e) => this.handleFieldChange(field, value)} />
            ))}
          </Form>
        )}
        {isLoading && (
          <Loader 
          position="absolute" 
          className="fourg-reminder-form-dialog__loader" />
        )}
      </Dialog>
    );
  }
}

export default ReminderFormDialog;
