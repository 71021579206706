import React from 'react';
import classNames from 'classnames';
import './ActionList.scss';

export interface Props {
  id?: string;
  className?: string;
  isExpanded?: boolean;
  role?: string;
  anchor: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  triggerRef?: React.RefObject<HTMLElement>;
  isOverlaid?: boolean;
  children?: React.ReactElement[];
}

export interface State {
  style?: React.CSSProperties;
}

class ActionList extends React.Component<Props, State> {

  private list = React.createRef<HTMLUListElement>();

  static defaultProps = {
    isExpanded: true,
    role: 'menu',
    anchor: 'top-left',
    isOverlaid: true,
  };

  constructor(props: Props) {
    super(props);
    this.setStyle = this.setStyle.bind(this);
    this.state = {
      style: {
        position: props.triggerRef ? 'fixed' : undefined,
      },
    };
  }

  componentDidMount() {
    const { anchor } = this.props;
    this.setStyle(anchor);
  }

  componentDidUpdate(prevProps: Props) {
    const { isExpanded, anchor } = this.props;
    if (! prevProps.isExpanded && isExpanded) {
      this.setStyle(anchor);
    }
  }

  setStyle(anchor: Props['anchor']) {
    const { triggerRef, isOverlaid } = this.props;
    const trigger = triggerRef?.current;
    if (trigger) {
      const { top, bottom, left, right, width } = trigger.getBoundingClientRect();
      const listTop = isOverlaid ? top : (bottom + 4);
      const listBottom = isOverlaid ? (window.innerHeight - bottom) : ((window.innerHeight - top) + 12);
      const listTopHeight = ((window.innerHeight - listTop) - 16);
      const listBottomHeight = ((window.innerHeight - listBottom) - 16);
      switch (anchor) {
        case 'bottom-left':
          if ((window.innerHeight / 4) > (window.innerHeight - listBottom)) {
            this.setStyle('top-left');
          } else {
            this.setState({
              style: {
                position: 'fixed',
                top: 'unset',
                bottom: listBottom,
                left: left,
                right: 'unset',
                minWidth: width,
                maxHeight: listBottomHeight,
              },
            });
          }
          break;
        case 'bottom-right':
          if ((window.innerHeight / 4) > (window.innerHeight - listBottom)) {
            this.setStyle('top-right');
          } else {
            this.setState({
              style: {
                position: 'fixed',
                top: 'unset',
                bottom: listBottom,
                left: 'unset',
                right: (window.innerWidth - right),
                minWidth: width,
                maxHeight: listBottomHeight,
              },
            });
          }
          break;
        case 'top-right':
          if ((window.innerHeight / 4) > (window.innerHeight - listTop)) {
            this.setStyle('bottom-right');
          } else {
            this.setState({
              style: {
                position: 'fixed',
                top: listTop,
                bottom: 'unset',
                left: 'unset',
                right: (window.innerWidth - right),
                minWidth: width,
                maxHeight: listTopHeight,
              },
            });
          }
          break;
        default:
          if ((window.innerHeight / 4) > (window.innerHeight - listTop)) {
            this.setStyle('bottom-left');
          } else {
            this.setState({
              style: {
                position: 'fixed',
                top: listTop,
                bottom: 'unset',
                left: left,
                right: 'unset',
                minWidth: width,
                maxHeight: listTopHeight,
              },
            });
          }
          break;
      }
      
    }
  }

  render() {
    const { isOverlaid, triggerRef, isExpanded, className, anchor, children, ...restProps } = this.props;
    const { style } = this.state;
    const containerClass = classNames('fourg-action-list', `fourg-action-list--anchor-${anchor}`, {
      'fourg-action-list--expanded': isExpanded,
    }, className);
    return (
      <ul ref={this.list} className={containerClass} style={style} aria-hidden={! isExpanded} {...restProps}>
        {children && children.map(child => {
          return React.cloneElement(child, {
            tabIndex: isExpanded ? 0 : -1,
          });
        })}
      </ul>
    );
  }
}

export default ActionList;
