import React from 'react';
import classNames from 'classnames';
import Sidebar, { Props as SidebarProps } from '../Sidebar/Sidebar';
import SidebarSection from '../SidebarSection/SidebarSection';
import InfoByField from '../InfoByField/InfoByField';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import { CompanySchema, LocationSchema } from '../../types';
import './CompanySidebar.scss';
import Info from '../Info/Info';
import Button from '../Button/Button';
import SidebarSectionActions from '../SidebarSectionActions/SidebarSectionActions';

export interface Props extends SidebarProps {
  record: CompanySchema;
  disabled?: boolean;
}

export interface State {

}

class CompanySidebar extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    ...Sidebar.defaultProps,
  };

  render() {
    const { disabled, record, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-company-sidebar', className);
    const fields = Company.getFieldsBy<CompanySchema>('isInfo', true);
    const locationFields = Location.getFieldsBy<LocationSchema>('isInfo', true).filter(field => (field.name !== 'primary'));
    const locationOptions = Location.getOptions();
    return (
      <Sidebar className={containerClass} {...restProps}>
        <SidebarSection 
        className="fourg-company-sidebar__details"
        label={'Company Details'}
        disabled={disabled}>
          <Info>
            {fields.map((field) => (
              <InfoByField<CompanySchema>
              size={field.infoSize}
              variant="quiet"
              key={`form-field-${field.name}`}
              field={field}
              value={record[field.name]} />
            ))}
          </Info>
        </SidebarSection>
        <SidebarSection 
        className="fourg-company-sidebar__location-details"
        label={'Primary Location Details'}
        disabled={disabled}>
          <Info>
            {locationFields.map((field) => (
              <InfoByField<LocationSchema>
              size={field.infoSize}
              variant="quiet"
              key={`form-field-${field.name}`}
              field={field}
              value={record.primaryLocation[field.name]} />
            ))}
          </Info>
          <SidebarSectionActions>
            <Button 
            icon={{ icon: locationOptions.icon }}
            variant={'raised'} 
            to={Location.getRecordLink<LocationSchema>(record.primaryLocation)}>
              {Location.getLabel('viewSingular')}
            </Button>
          </SidebarSectionActions>
        </SidebarSection>
      </Sidebar>
    );
  }
}

export default CompanySidebar;
