import React from 'react';
import classNames from 'classnames';
import './FormFieldHeading.scss';
import Checkbox from '../Checkbox/Checkbox';

export interface Props {
  id?: string;
  className?: string;
  children: React.ReactNode;
  isExpanded?: boolean;
  onExpandChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void
}

export interface State {

}

class FormFieldHeading extends React.Component<Props, State> {

  render() {
    const { onExpandChange, isExpanded, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-form-field-heading', className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-form-field-heading__content">
          <h3 className="fourg-form-field-heading__label">
            {children}
          </h3>
          {onExpandChange && (
            <Checkbox
            label={'Expand'}
            checked={isExpanded}
            onChange={onExpandChange} />
          )}
        </div>
      </div>
    );
  }
}

export default FormFieldHeading;
