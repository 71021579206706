
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon, { Props as IconProps } from '../Icon/Icon';
import './Button.scss';

export interface Props {
  id?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  to?: string;
  variant?: 'default' | 'raised' | 'outlined' | 'mini' | 'link';
  icon?: IconProps['src'];
  isIconOnly?: boolean;
  children: string | number;
  isActive?: boolean;
  title?: string;
  onMouseUp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class Button extends React.Component<Props, State> {

  private anchor = React.createRef<HTMLAnchorElement>();
  private button = React.createRef<HTMLButtonElement>();

  static defaultProps = {
    type: 'button',
    variant: 'default',
  }

  renderInternal() {
    const { icon, children, title } = this.props;
    return (
      <React.Fragment>
        {icon && (
          <Icon
          className="fourg-button__icon"
          src={icon}
          label={title || children.toString()} />
        )}
        <span className="fourg-button__label">
          {children.toString()}
        </span>
      </React.Fragment>
    )
  }

  focus() {
    const anchor = this.anchor.current;
    const button = this.button.current;
    anchor?.focus();
    button?.focus();
  }

  blur() {
    const anchor = this.anchor.current;
    const button = this.button.current;
    anchor?.blur();
    button?.blur();
  }

  getInnerRef() {
    const anchorRef = this.anchor;
    const buttonRef = this.button;
    let ref = undefined;
    if (buttonRef.current) {
      ref = buttonRef;
    } else if (anchorRef.current) {
      ref = anchorRef;
    }
    return ref;
  }

  render() {
    const { title, isActive, isIconOnly, variant, onClick, onMouseDown, onMouseUp, to, type, icon, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-button', `fourg-button--variant-${variant}`, {
      'fourg-button--has-icon': Boolean(icon),
      'fourg-button--icon-only': isIconOnly,
      'fourg-button--active': isActive,
    }, className);
    return to ? (
      <Link innerRef={(ref) => Object.assign(this.anchor, { current: ref })} to={to} className={containerClass} {...restProps}>
        {this.renderInternal()}
      </Link>
    ) : (
      <button
      ref={this.button}
      className={containerClass}
      type={type}
      onClick={onClick}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      title={(isIconOnly || (title && title !== children.toString())) ? title || children.toString() || undefined : undefined}
      {...restProps}>
        {this.renderInternal()}
      </button>
    );
  }
}

export default Button;
