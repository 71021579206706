import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { RouteComponentProps, Route } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import Page from '../../components/Page/Page';
import Button from '../../components/Button/Button';
import ActionToggle from '../../components/ActionToggle/ActionToggle';
import IconMessage from '../../components/IconMessage/IconMessage';
import FilterBar from '../../components/FilterBar/FilterBar';
import Table from '../../components/Table/Table';
import TableCellByField from '../../components/TableCellByField/TableCellByField';
import Pagination from '../../components/Pagination/Pagination';
import FormDialog from '../../components/FormDialog/FormDialog';
import TaskFormDialog from '../../components/TaskFormDialog/TaskFormDialog';
import CardList from '../../components/CardList/CardList';
import CardListItem from '../../components/CardListItem/CardListItem';
import AttachmentCard from '../../components/AttachmentCard/AttachmentCard';
import CompanyFormDialog from '../../components/CompanyFormDialog/CompanyFormDialog';
import CreateAttachmentsDialog from '../../components/CreateAttachmentsDialog/CreateAttachmentsDialog';
import IconMessageDialog from '../../components/IconMessageDialog/IconMessageDialog';
import TabBar from '../../components/TabBar/TabBar';
import ContactFormDialog from '../../components/ContactFormDialog/ContactFormDialog';
import ErrorPage from '../ErrorPage/ErrorPage';
import CompanySidebar from '../../components/CompanySidebar/CompanySidebar';
import CieTradeLogDialog from '../../components/CieTradeLogDialog/CieTradeLogDialog';
import TableModel from '../../models/Table';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import Contact from '../../models/tables/Contact';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import Task from '../../models/tables/Task';
import Attachment from '../../models/tables/Attachment';
import CieTradeLog from '../../models/tables/CieTradeLog';
import { LookupContext } from '../../contexts';
import { APIError } from '../../errors';
import { CompanySchema, Auth, Socket, ContactSchema, LocationSchema, ReadRecordsQuery, TaskSchema, Lookup, AttachmentSchema, Breadcrumb, UIOption, UserSchema, ReminderSchema, GradeSchema, ExpenseSchema, CieTradeLogSchema } from '../../types';
import { mergeRecords } from '../../utils';
import './CompanyPage.scss';

export type sectionRecordSchema = LocationSchema | ContactSchema | TaskSchema | AttachmentSchema | CieTradeLogSchema;

export interface RouteParams {
  id: string;
  section?: string;
}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
  socket: Socket;
}

export interface State {
  isUpdating: boolean;
  isLoading: boolean;
  isLoadingSection: boolean;
  isCreateDialogOpen: boolean;
  isUpdateDialogOpen: boolean;
  isDeleteConfirmDialogOpen: boolean;
  deletingSectionRecord?: sectionRecordSchema;
  record?: CompanySchema;
  sectionRecords: sectionRecordSchema[];
  sectionTotal: number;
	cieTradeLogRecord?: CieTradeLogSchema;
  lookup: Lookup;
  error?: APIError;
}

class CompanyPage extends React.Component<Props, State> {

  private createDialog = React.createRef<FormDialog<sectionRecordSchema>>();
  private createNewBusinessTaskDialog = React.createRef<TaskFormDialog>();
  private createFinanceTaskDialog = React.createRef<TaskFormDialog>();
  private createCustomerServiceTaskDialog = React.createRef<TaskFormDialog>();
  private createContactDialog = React.createRef<ContactFormDialog>();

  constructor(props: Props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleTableRowDoubleClick = this.handleTableRowDoubleClick.bind(this);
    this.handleCreateAttachmentsDialogSubmit = this.handleCreateAttachmentsDialogSubmit.bind(this);
    this.handleFormDialogSubmit = this.handleFormDialogSubmit.bind(this);
    this.handleFormDialogDelete = this.handleFormDialogDelete.bind(this);
    this.handleFormDialogClose = this.handleFormDialogClose.bind(this);
    this.handleCreateDialogSubmit = this.handleCreateDialogSubmit.bind(this);
    this.handleCreateDialogSecondary = this.handleCreateDialogSecondary.bind(this);
    this.handleCreateDialogClose = this.handleCreateDialogClose.bind(this);
    this.handleUpdateDialogSubmit = this.handleUpdateDialogSubmit.bind(this);
    this.handleUpdateDialogClose = this.handleUpdateDialogClose.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleCreateTaskActionChange = this.handleCreateTaskActionChange.bind(this)
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.handlePageActionsChange = this.handlePageActionsChange.bind(this);
    this.handleTabBarChange = this.handleTabBarChange.bind(this);
    this.handleAttachmentCardActionsChange = this.handleAttachmentCardActionsChange.bind(this);
    this.handleDeleteConfirmDialogDelete = this.handleDeleteConfirmDialogDelete.bind(this);
    this.handleDeleteConfirmDialogClose = this.handleDeleteConfirmDialogClose.bind(this);
    this.handleSectionRecordDeleteConfirmDialogDelete = this.handleSectionRecordDeleteConfirmDialogDelete.bind(this);
    this.handleSectionRecordDeleteConfirmDialogClose = this.handleSectionRecordDeleteConfirmDialogClose.bind(this);
    this.handleIsTableRowAlternate = this.handleIsTableRowAlternate.bind(this);
    this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this);
    this.handleGetTableRowWarnings = this.handleGetTableRowWarnings.bind(this);
		this.handleCieTradeLogDialogClose = this.handleCieTradeLogDialogClose.bind(this);
    this.state = {
      sectionRecords: [],
      sectionTotal: 0,
      isUpdating: false,
      isLoading: false,
      isLoadingSection: false,
      isCreateDialogOpen: false,
      isUpdateDialogOpen: false,
      isDeleteConfirmDialogOpen: false,
      lookup: {},
    };
  }

  componentDidMount() {
    this.readRecord();
  }

  componentDidUpdate(prevProps: Props) {
    const { location, match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.readRecord();
    }
    if ((this.getPage(prevProps.location) !== this.getPage(location))
    || (this.getLimit(prevProps.location, prevProps.match) !== this.getLimit(location, match))
    || (this.getOrder(prevProps.location) !== this.getOrder(location))
    || (this.getSearch(prevProps.location) !== this.getSearch(location))
    || (this.getSection(prevProps.match) !== this.getSection(match))) {
      this.readSectionRecords();
    }
  }

  getSectionModel(): typeof TableModel {
    const { match } = this.props;
    switch (this.getSection(match)) {
			case 'tasks': return Task;
      case 'contacts': return Contact;
      case 'attachments': return Attachment;
      case 'logs': return CieTradeLog;
      default: return Location;
    }
  }

  getSectionTableColumns() {
    const { match } = this.props;
    const section = this.getSection(match);
    const sectionModel = this.getSectionModel();
    switch (section) {
      default: return sectionModel.getFieldColumns<sectionRecordSchema>();
    }
  }

  syncSectionRecord(newRecord: sectionRecordSchema) {
    const { sectionRecords } = this.state;
    if (sectionRecords) {
      this.setState({
        sectionRecords: sectionRecords.map(record => {
          return (record.id === newRecord.id) ? newRecord : record;
        }),
      });
    }
  }

  async readRecord() {
    const { match, auth } = this.props;
		const { lookup } = this.state;
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { data, meta } = await Company.readRecord<CompanySchema>(token, match.params.id);
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
			const newCompanies = mergeRecords<CompanySchema>(meta?.companies || [], data ? [data] : []);
      this.setState({
        isLoading: false,
        record: data,
        error: undefined,
        lookup: {
					...lookup,
					users: mergeRecords<UserSchema>(newUsers, lookup.users),
					companies: mergeRecords<CompanySchema>(newCompanies, lookup.companies),
					locations: mergeRecords<LocationSchema>(meta?.locations, lookup.locations),
					tasks: mergeRecords<TaskSchema>(meta?.tasks, lookup.tasks),
					attachments: mergeRecords<AttachmentSchema>(meta?.attachments, lookup.attachments),
					reminders: mergeRecords<ReminderSchema>(meta?.reminders, lookup.reminders),
					grades: mergeRecords<GradeSchema>(meta?.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta?.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta?.contacts, lookup.contacts),
        },
      });
      this.readSectionRecords();
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoading: false,
        record: undefined,
        error: error as Error,
        lookup: {},
      });
    }
  }

  async readRecordsBySection(token: string, id: number, query: ReadRecordsQuery) {
    const { match } = this.props;
    switch (this.getSection(match)) {
			case 'tasks': return await Company.readTasks(token, id, query);
      case 'contacts': return await Company.readContacts(token, id, query);
      case 'attachments': return await Company.readAttachments(token, id, query);
      case 'logs': return await Company.readCieTradeLogs(token, id, query);
      default: return await Company.readLocations(token, id, query);
    }
  }

  async readSectionRecords() {
    const { location, match, auth } = this.props;
    const { record, lookup } = this.state;
    if (record) {
      this.setState({ isLoadingSection: true });
      try {
        const token = await auth.getToken();
        const { meta, data } = await this.readRecordsBySection(token, record.id, {
          page: this.getPage(location),
          limit: this.getLimit(location, match),
          order: this.getOrder(location),
          search: this.getSearch(location),
        });
				const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
        this.setState({
          isLoadingSection: false,
          sectionRecords: data,
          sectionTotal: meta.total,
          lookup: {
            ...lookup,
						users: mergeRecords<UserSchema>(newUsers, lookup.users),
						companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
						locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
						tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
						attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
						reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
						grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
						expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
						contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
          },
        });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isLoadingSection: false,
          sectionRecords: [],
          sectionTotal: 0,
          lookup: {},
        });
      }
    }
  }

  async createRecordBySection(token: string, sectionRecord: sectionRecordSchema) {
    const { match } = this.props;
    const { record } = this.state;
    if (record) {
      switch (this.getSection(match)) {
        case 'attachments': return await Location.createAttachment(token, record.primaryLocationId, sectionRecord as AttachmentSchema);
        default:
          const newSectionRecord: sectionRecordSchema = {
            ...sectionRecord,
            companyId: record.id,
          };
          const sectionModel = this.getSectionModel();
          return await sectionModel.createRecord<sectionRecordSchema>(token, newSectionRecord);
      }
    }
  }

  async createSectionRecord(sectionRecord: sectionRecordSchema, isCreateDialogOpen: boolean = false) {
    const { auth, match, location, history } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const section = this.getSection(match);
        const sectionModel = this.getSectionModel();
        await this.createRecordBySection(token, sectionRecord);
        const newRecord: CompanySchema = {
          ...record,
          contactsCount: (section === 'contacts') ? ((record.contactsCount || 0) + 1) : record.contactsCount,
          locationsCount: (section === 'locations') ? ((record.locationsCount || 0) + 1) : record.locationsCount,
          tasksCount: (section === 'tasks') ? ((record.tasksCount || 0) + 1) : record.tasksCount,
          attachmentsCount: (section === 'attachments') ? ((record.attachmentsCount || 0) + 1) : record.attachmentsCount,
        };
        toast.success(sectionModel.getLabel('addedSingular'));
        this.setState({
          isCreateDialogOpen: isCreateDialogOpen,
          isUpdating: false,
          record: newRecord,
        });
        this.readSectionRecords();
        const createContactDialog = this.createContactDialog.current;
        const createNewBusinessTaskDialog = this.createNewBusinessTaskDialog.current;
        const createFinanceTaskDialog = this.createFinanceTaskDialog.current;
        const createCustomerServiceTaskDialog = this.createCustomerServiceTaskDialog.current;
        const createDialog = this.createDialog.current;
        createContactDialog?.setDefaultRecord();
        createNewBusinessTaskDialog?.setDefaultRecord();
        createFinanceTaskDialog?.setDefaultRecord();
        createCustomerServiceTaskDialog?.setDefaultRecord();
        createDialog?.setDefaultRecord();
        if (! isCreateDialogOpen) {
          const params = new URLSearchParams(location.search);
          params.delete('create');
          history.push(`${location.pathname}?${params.toString()}`);
        }
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async createAttachments(attachments: AttachmentSchema[], isCreateDialogOpen: boolean = false) {
    const { auth, location, history } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        await Location.createAttachments(token, record.primaryLocationId, attachments)
        const newRecord: CompanySchema = {
          ...record,
          attachmentsCount: ((record.attachmentsCount || 0) + attachments.length),
        };
        toast.success(Attachment.getLabel('addedPlural'));
        this.setState({
          isCreateDialogOpen: isCreateDialogOpen,
          isUpdating: false,
          record: newRecord,
        });
        this.readSectionRecords();
        if (! isCreateDialogOpen) {
          const params = new URLSearchParams(location.search);
          params.delete('create');
          history.push(`${location.pathname}?${params.toString()}`);
        }
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async updateRecord(newRecord: CompanySchema, isUpdateDialogOpen: boolean = false) {
    const { auth } = this.props;
    const { record, lookup } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const { data, meta } = await Company.updateRecord<CompanySchema>(token, record.id.toString(), newRecord);
				const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
        toast.success(Company.getLabel('updatedSingular'));
        this.setState({
          record: data,
          isUpdating: false,
          isUpdateDialogOpen: isUpdateDialogOpen,
					lookup: {
            ...lookup,
						users: mergeRecords<UserSchema>(newUsers, lookup.users),
						companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
						locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
						tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
						attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
						reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
						grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
						expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
						contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
          },
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async updateSectionRecord(newRecord: sectionRecordSchema) {
    const { location, history, auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const sectionModel = this.getSectionModel();
      const { data } = await sectionModel.updateRecord<sectionRecordSchema>(token, newRecord.id.toString(), newRecord);
      toast.success(sectionModel.getLabel('updatedSingular'));
      this.setState({
        isUpdating: false,
        sectionRecords: this.getSyncedSectionRecords(data),
      });
      const params = new URLSearchParams(location.search);
      params.delete('record');
      history.push(`${location.pathname}?${params.toString()}`);
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isUpdating: false,
      });
    }
  }

  async deleteSectionRecord(sectionRecord: sectionRecordSchema) {
    const { location, history, match, auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const section = this.getSection(match);
        const sectionModel = this.getSectionModel();
        await sectionModel.deleteRecord(token, sectionRecord.id.toString());
        const newRecord: CompanySchema = {
          ...record,
          contactsCount: (section === 'contacts') ? ((record.contactsCount || 0) - 1) : record.contactsCount,
          locationsCount: (section === 'locations') ? ((record.locationsCount || 0) - 1) : record.locationsCount,
          tasksCount: (section === 'tasks') ? ((record.tasksCount || 0) - 1) : record.tasksCount,
          attachmentsCount: (section === 'attachments') ? ((record.attachmentsCount || 0) - 1) : record.attachmentsCount,
        };
        toast.success(sectionModel.getLabel('deletedSingular'));
        this.setState({
          isUpdating: false,
          record: newRecord,
          deletingSectionRecord: undefined,
        });
        this.readSectionRecords();
        const params = new URLSearchParams(location.search);
        params.delete('record');
        history.push(`${location.pathname}?${params.toString()}`);
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  getSyncedSectionRecords(newRecord: sectionRecordSchema) {
    const { sectionRecords } = this.state;
    return sectionRecords.map(record => {
      return (record.id === newRecord.id) ? newRecord : record;
    })
  }

  async deleteRecord() {
    const { auth, history } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        await Company.deleteRecord(token, record.id.toString());
        toast.success(Company.getLabel('deletedSingular'));
        history.push('/companies');
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  getSection(match: RouteComponentProps<RouteParams>['match']) {
    return match.params.section || 'locations';
  }

  getPage(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page, 10) : 1;
  }

  getLimit(location: RouteComponentProps<RouteParams>['location'], match: RouteComponentProps<RouteParams>['match']) {
    const defaultLimit = (this.getSection(match) === 'attachments') ? 12 : 20;
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit');
    return limit ? parseInt(limit, 10) : defaultLimit;
  }

  getOrder(location: RouteComponentProps<RouteParams>['location']) {
    const sectionModel = this.getSectionModel();
    const { defaultOrder } = sectionModel.getOptions();
    const params = new URLSearchParams(location.search);
    return params.get('order') || defaultOrder;
  }

  getSearch(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('search') || undefined;
  }

  getCreate(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('create') || undefined;
  }

  getRecord(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('record') || undefined;
  }

  handlePageChange(page: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleLimitChange(limit: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('limit', limit.toString());
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleOrderChange(order: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('order', order);
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleTableRowDoubleClick(record: sectionRecordSchema) {
    const { location, history, match } = this.props;
    const section = this.getSection(match);
    switch (section) {
      case 'tasks':
        history.push(`/tasks/${record.id}`);
        break;
      case 'contacts':
        const params = new URLSearchParams(location.search);
        params.set('record', record.id.toString());
        history.push(`${location.pathname}?${params.toString()}`);
        break;
			case 'logs':
				this.setState({ cieTradeLogRecord: record as CieTradeLogSchema });
				break;
      default:
        history.push(`/companies/${match.params.id}/${section}/${record.id}`);
        break;
    }
  }

  handleFormDialogClose() {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('record');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleSearchChange(search: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('search', search);
    } else {
      params.delete('search');
    }
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleCreateDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('create');
    this.setState({ isCreateDialogOpen: false });
    history.push(`${location.pathname}?${params.toString()}`);
  }

	handleCieTradeLogDialogClose() {
		this.setState({ cieTradeLogRecord: undefined });
	}

  getTaskModelByBoard(board: string): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  handleCreateDialogSubmit(record: sectionRecordSchema) {
    this.createSectionRecord(record);
  }

  handleCreateAttachmentsDialogSubmit(records: AttachmentSchema[]) {
    this.createAttachments(records);
  }

  handleCreateDialogSecondary(record: sectionRecordSchema) {
    this.createSectionRecord(record, true);
  }

  handleUpdateDialogClose() {
    this.setState({ isUpdateDialogOpen: false });
  }

  handleUpdateDialogSubmit(record: CompanySchema) {
    this.updateRecord(record);
  }

  handleCreateClick() {
    this.setState({ isCreateDialogOpen: true });
  }

  handleUpdateClick() {
    this.setState({ isUpdateDialogOpen: true });
  }

  handlePageActionsChange(value: string) {
    switch (value) {
      case 'delete':
        this.setState({ isDeleteConfirmDialogOpen: true });
        break;
    }
  }

  handleTabBarChange(value: string) {
    const { match, history } = this.props;
    this.setState({
      sectionRecords: [],
      sectionTotal: 0,
    });
    history.push(`/companies/${match.params.id}/${value}`);
  }

  handleFormDialogSubmit(record: sectionRecordSchema) {
    this.updateSectionRecord(record);
  }

  handleFormDialogDelete(record: sectionRecordSchema) {
    const { match } = this.props;
    const section = this.getSection(match);
    if (section === 'contacts') {
      this.setState({ deletingSectionRecord: record });
    } else {
      this.deleteSectionRecord(record);
    }
  }

  handleAttachmentCardActionsChange(value: string, record: AttachmentSchema) {
    const { location, history } = this.props;
    switch (value) {
      case 'edit':
        const params = new URLSearchParams(location.search);
        params.set('record', record.id.toString());
        history.push(`${location.pathname}?${params.toString()}`);
        break;
      case 'delete':
        this.setState({ deletingSectionRecord: record });
        break;
    }
  }

  handleDeleteConfirmDialogDelete() {
    this.deleteRecord();
  }

  handleDeleteConfirmDialogClose() {
    this.setState({ isDeleteConfirmDialogOpen: false });
  }

  handleSectionRecordDeleteConfirmDialogDelete() {
    const { deletingSectionRecord } = this.state;
    if (deletingSectionRecord) {
      this.deleteSectionRecord(deletingSectionRecord);
    }
  }

  handleSectionRecordDeleteConfirmDialogClose() {
    this.setState({ deletingSectionRecord: undefined });
  }

  handleCreateTaskActionChange(value: UIOption['value']) {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('create', value);
    history.push(`${location.pathname}?${params.toString()}`);
  }

  getCreateTaskOptions() {
    const { location } = this.props;
    const createParam = this.getCreate(location);
    const taskModel = this.getTaskModelByBoard(createParam || '')
    const boardField = taskModel.getField<TaskSchema>('board');
    return boardField?.options?.filter(option => ! ['personal', 'targets'].includes(option.value)) || [];
  }

  getBreadcrumbs() {
    const { record } = this.state;
    let breadcrumbs: Breadcrumb[] = [];
    if (record) {
      breadcrumbs = [
        { label: Company.getLabel('pageTitle'), to: '/companies' },
        { label: Company.getRecordLabel<CompanySchema>(record) },
      ];
    }
    return breadcrumbs;
  }

  handleIsTableRowAlternate(record: sectionRecordSchema) {
    const { match } = this.props;
    let isAlternate = false;
    switch (this.getSection(match)) {
      case 'tasks':
        isAlternate = (record as TaskSchema).archived || false;
        break
    }
    return isAlternate;
  }

  handleGetTableRowWarnings(record: sectionRecordSchema): string[] {
    const { match } = this.props;
    switch (this.getSection(match)) {
      case 'tasks':
				const taskModel = this.getTaskModelByBoard((record as TaskSchema).board);
				return taskModel.getWarnings(record as TaskSchema);
			default:
				const model = this.getSectionModel();
				return model.getWarnings(record);
    };
  }

  handleRefreshButtonClick() {
    this.readSectionRecords();
  }

  render() {
    const { socket, className, match, location, history, staticContext, auth, ...restProps } = this.props;
    const { error, record, sectionRecords, sectionTotal, isUpdating, isLoading, isLoadingSection, isCreateDialogOpen, isUpdateDialogOpen, isDeleteConfirmDialogOpen, deletingSectionRecord, cieTradeLogRecord, lookup } = this.state;
    const containerClass = classNames('fourg-company-page', className);
    const section = this.getSection(match);
    const createParam = this.getCreate(location);
    const sectionModel = this.getSectionModel();
    const sectionModelOptions = sectionModel.getOptions<sectionRecordSchema>();
    const recordID = this.getRecord(location);
    const companyLabel = record ? Company.getRecordLabel<CompanySchema>(record) : `${Company.getLabel('singular')} ${match.params.id}`;
    const page = this.getPage(location);
    const limit = this.getLimit(location, match);
    const startNumber = (((page * limit) - limit) + 1);
    let endNumber = ((startNumber + limit) - 1);
    endNumber = (endNumber <= sectionTotal) ? endNumber : sectionTotal;
    return isLoading ? (
      <Loader position="absolute" />
    ) : ! record ? (
      <Route render={(routeProps) => (
        <ErrorPage status={error?.status} message={error?.message} {...routeProps} />
      )} />
    ) : (
      <LookupContext.Provider value={lookup}>
        <Page
        breadcrumbs={[
          { label: Company.getLabel('pageTitle'), to: '/companies' },
          { label: companyLabel },
        ]}
        title={companyLabel}
        description={''}
        headerDescription={isLoadingSection ? sectionModel.getLabel('loadingPluralEllipsis') : `Viewing ${(sectionTotal > 0) ? `${startNumber}-${endNumber} of ` : ''}${sectionTotal} ${sectionModel.getLabel('plural')}`}
        actions={(
          <React.Fragment>
            <Button
            isIconOnly={true}
            variant="raised"
            disabled={isUpdating}
            icon={{ icon: 'edit' }}
            onClick={this.handleUpdateClick}>
              {Company.getLabel('editSingular')}
            </Button>
            <ActionToggle
            isIconOnly={true}
            variant={'raised'}
            label={'More Actions'}
            disabled={isUpdating}
            icon={{ icon: 'more_vert' }}
            onChange={this.handlePageActionsChange}
            anchor="top-right"
            options={[
              { value: 'delete', label: 'Delete' },
            ]}
            value="" />
          </React.Fragment>
        )}
        lastActive={new Date(record.updated)}
        className={containerClass}
        {...restProps}>
          <CompanySidebar
          record={record}
          disabled={isUpdating} />
          <div className="fourg-company-page__aux">
            <TabBar
            disabled={(isUpdating || isLoadingSection)}
            value={section}
            onChange={this.handleTabBarChange}
            options={[
              { label: Location.getLabel('plural'), value: 'locations', count: record.locationsCount },
              { label: Contact.getLabel('plural'), value: 'contacts', count: record.contactsCount },
              { label: Task.getLabel('plural'), value: 'tasks', count: record.tasksCount },
              { label: Attachment.getLabel('plural'), value: 'attachments', count: record.attachmentsCount },
              { label: CieTradeLog.getLabel('plural'), value: 'logs' },
            ]} />
            <FilterBar
            disabled={(isUpdating || isLoadingSection)}
            searchLabel={sectionModel.getLabel('searchEllipsis')}
            searchValue={this.getSearch(location)}
            onRefreshButtonClick={this.handleRefreshButtonClick}
            onSearchChange={this.handleSearchChange}>
              {(section === 'logs') ? (
								null
							) : (section === 'tasks') ? (
                <ActionToggle
                label={sectionModel.getLabel('addSingular')}
                variant="raised"
                icon={{ icon: 'add' }}
                options={this.getCreateTaskOptions()}
                value=""
                anchor="top-right"
                onChange={this.handleCreateTaskActionChange} />
              ) : (
                <Button
                icon={{ icon: 'add' }}
                variant="raised"
                onClick={this.handleCreateClick}>
                  {(section === 'attachments') ? Attachment.getLabel('addPlural') : sectionModel.getLabel('addSingular')}
                </Button>
              )}
            </FilterBar>
            {(section === 'attachments') ? (
              <div className="fourg-company-page__attachments">
                {isLoadingSection ? (
                  <Loader position="absolute" />
                ) : (sectionRecords.length <= 0) ? (
                  <IconMessage
                  icon={{ icon: sectionModelOptions.icon }}
                  heading={sectionModel.getLabel('notFoundPlural')} />
                ) : (
                  <CardList columns={{ desktop: 3, laptop: 4, tablet: 6, phone: 12 }}>
                    {sectionRecords.map((sectionRecord) => (
                      <CardListItem key={`attachment-card-${sectionRecord.id}`}>
                        <AttachmentCard
                        variant="card"
                        record={sectionRecord as AttachmentSchema}
                        onActionsChange={value => this.handleAttachmentCardActionsChange(value, sectionRecord as AttachmentSchema)} />
                      </CardListItem>
                    ))}
                  </CardList>
                )}
              </div>
            ) : (
              <Table<sectionRecordSchema>
              isScrollable={true}
              columns={this.getSectionTableColumns()}
              records={sectionRecords}
              order={this.getOrder(location)}
              isLoading={(isLoading || isLoadingSection)}
              onOrderChange={this.handleOrderChange}
              onRowDoubleClick={this.handleTableRowDoubleClick}
              notFoundIcon={{ icon: sectionModelOptions.icon }}
              notFoundHeading={sectionModel.getLabel('notFoundPlural')}
              getRowWarnings={this.handleGetTableRowWarnings}
              isRowAlternate={this.handleIsTableRowAlternate}
              renderCell={(value, column, record) => {
                let field = sectionModel.getField<sectionRecordSchema>(column.key);
                if (section === 'tasks') {
                  const taskModel = this.getTaskModelByBoard((record as TaskSchema).board);
                  field = taskModel.getField<sectionRecordSchema>(column.key);
                }
                return (! field) ? value.toString() : (
                  <TableCellByField<sectionRecordSchema>
                  field={field}
                  value={value}
                  record={record} />
                );
              }} />
            )}
            <Pagination
            page={page}
            limit={limit}
            total={sectionTotal}
            onPageChange={this.handlePageChange}
            onLimitChange={this.handleLimitChange}
            limitOptions={(section === 'attachments') ? [12, 24, 48, 96] : undefined} />
          </div>
          <CompanyFormDialog
          disabled={(isUpdating || isLoadingSection)}
          auth={auth}
          title={Company.getLabel('editSingular')}
          recordID={record.id.toString()}
          isOpen={isUpdateDialogOpen}
          submitLabel={'Save'}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleUpdateDialogSubmit}
          onCloseClick={this.handleUpdateDialogClose}
          // onBackdropClick={this.handleUpdateDialogClose}
          onFormCancel={this.handleUpdateDialogClose}
          onEscape={this.handleUpdateDialogClose} />
          <IconMessageDialog
          className="fourg-company-page__delete-confirm-dialog"
          disabled={(isUpdating || isLoadingSection)}
          title={'Delete Company'}
          isOpen={isDeleteConfirmDialogOpen}
          heading={`Are you sure you want to delete "${companyLabel}?"`}
          subheading={`All associated records will also be deleted, including:\n${Location.getLabel('plural')}, ${Contact.getLabel('plural')}, ${Attachment.getLabel('plural')}, and ${Task.getLabel('plural')}.`}
          deleteLabel={'Delete'}
          cancelLabel={'Cancel'}
          onDelete={this.handleDeleteConfirmDialogDelete}
          onEscape={this.handleDeleteConfirmDialogClose}
          onCloseClick={this.handleDeleteConfirmDialogClose}
          onBackdropClick={this.handleDeleteConfirmDialogClose}
          onCancel={this.handleDeleteConfirmDialogClose} />
          {(section === 'contacts') ? (
            <React.Fragment>
              <ContactFormDialog
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              title={Contact.getLabel('editSingular')}
              recordID={recordID ? parseInt(recordID, 10) : undefined}
              companyID={record.id}
              isOpen={Boolean(recordID)}
              submitLabel={'Save'}
              cancelLabel={'Cancel'}
              deleteLabel={'Delete'}
              onFormSubmit={this.handleFormDialogSubmit}
              onFormDelete={this.handleFormDialogDelete}
              onCloseClick={this.handleFormDialogClose}
              // onBackdropClick={this.handleFormDialogClose}
              onFormCancel={this.handleFormDialogClose}
              onEscape={this.handleFormDialogClose} />
              <ContactFormDialog
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              ref={this.createContactDialog}
              title={Contact.getLabel('addSingular')}
              companyID={record.id}
              isOpen={isCreateDialogOpen}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateDialogSubmit}
              onFormSecondary={this.handleCreateDialogSecondary}
              onCloseClick={this.handleCreateDialogClose}
              // onBackdropClick={this.handleCreateDialogClose}
              onFormCancel={this.handleCreateDialogClose}
              onEscape={this.handleCreateDialogClose} />
            </React.Fragment>
          ) : (section === 'attachments') ? (
            <React.Fragment>
              <FormDialog<AttachmentSchema>
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              title={Attachment.getLabel('editSingular')}
              model={Attachment}
              recordID={recordID}
              isOpen={Boolean(recordID)}
              submitLabel={'Save'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleFormDialogSubmit}
              onCloseClick={this.handleFormDialogClose}
              // onBackdropClick={this.handleFormDialogClose}
              onFormCancel={this.handleFormDialogClose}
              onEscape={this.handleFormDialogClose} />
              <CreateAttachmentsDialog
              auth={auth}
              title={Attachment.getLabel('addPlural')}
              isOpen={isCreateDialogOpen}
              disabled={(isUpdating || isLoadingSection)}
              multiple={true}
              // onBackdropClick={this.handleCreateDialogClose}
              onCloseClick={this.handleCreateDialogClose}
              onEscape={this.handleCreateDialogClose}
              onSubmit={this.handleCreateAttachmentsDialogSubmit} />
            </React.Fragment>
          ) : (section === 'tasks') ? (
            <React.Fragment>
              <TaskFormDialog
              model={NewBusinessTask}
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              ref={this.createNewBusinessTaskDialog}
              title={NewBusinessTask.getLabel('addSingular')}
              isOpen={(createParam === 'new-business')}
              initialValues={{
                assignedTo: auth.user?.id,
              }}
              enforcedValues={{
                companyId: record.id,
                board: 'new-business',
              }}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateDialogSubmit}
              onFormSecondary={this.handleCreateDialogSecondary}
              onCloseClick={this.handleCreateDialogClose}
              // onBackdropClick={this.handleCreateDialogClose}
              onFormCancel={this.handleCreateDialogClose}
              onEscape={this.handleCreateDialogClose} />
              <TaskFormDialog
              model={FinanceTask}
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              ref={this.createFinanceTaskDialog}
              title={FinanceTask.getLabel('addSingular')}
              isOpen={(createParam === 'finance')}
              initialValues={{
                assignedTo: auth.user?.id,
              }}
              enforcedValues={{
                companyId: record.id,
                board: 'finance',
              }}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateDialogSubmit}
              onFormSecondary={this.handleCreateDialogSecondary}
              onCloseClick={this.handleCreateDialogClose}
              // onBackdropClick={this.handleCreateDialogClose}
              onFormCancel={this.handleCreateDialogClose}
              onEscape={this.handleCreateDialogClose} />
              <TaskFormDialog
              model={CustomerServiceTask}
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              ref={this.createCustomerServiceTaskDialog}
              title={CustomerServiceTask.getLabel('addSingular')}
              isOpen={(createParam === 'customer-service')}
              initialValues={{
                assignedTo: auth.user?.id,
              }}
              enforcedValues={{
                companyId: record.id,
                board: 'customer-service',
              }}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateDialogSubmit}
              onFormSecondary={this.handleCreateDialogSecondary}
              onCloseClick={this.handleCreateDialogClose}
              // onBackdropClick={this.handleCreateDialogClose}
              onFormCancel={this.handleCreateDialogClose}
              onEscape={this.handleCreateDialogClose} />
            </React.Fragment>
          ) : (section === 'logs') ? (
						<CieTradeLogDialog
						title={CieTradeLog.getLabel('singular')}
						isOpen={Boolean(cieTradeLogRecord)}
						record={cieTradeLogRecord}
						onCloseClick={this.handleCieTradeLogDialogClose}
						// onBackdropClick={this.handleCieTradeLogDialogClose}
						onEscape={this.handleCieTradeLogDialogClose}
						onCancelClick={this.handleCieTradeLogDialogClose} />
          ) : (
            <React.Fragment>
              <FormDialog<sectionRecordSchema>
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              title={sectionModel.getLabel('editSingular')}
              model={sectionModel}
              recordID={recordID}
              isOpen={Boolean(recordID)}
              submitLabel={'Save'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleFormDialogSubmit}
              onCloseClick={this.handleFormDialogClose}
              // onBackdropClick={this.handleFormDialogClose}
              onFormCancel={this.handleFormDialogClose}
              onEscape={this.handleFormDialogClose} />
              <FormDialog<sectionRecordSchema>
              disabled={(isUpdating || isLoadingSection)}
              auth={auth}
              ref={this.createDialog}
              title={sectionModel.getLabel('addSingular')}
              model={sectionModel}
              isOpen={isCreateDialogOpen}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateDialogSubmit}
              onFormSecondary={this.handleCreateDialogSecondary}
              onCloseClick={this.handleCreateDialogClose}
              // onBackdropClick={this.handleCreateDialogClose}
              onFormCancel={this.handleCreateDialogClose}
              onEscape={this.handleCreateDialogClose}
              enforcedValues={{ companyId: record.id }} />
            </React.Fragment>
          )}
          <IconMessageDialog
          className="fourg-location-page__delete-confirm-dialog"
          disabled={(isUpdating || isLoadingSection)}
          title={`Delete ${sectionModel.getLabel('singular')}`}
          isOpen={Boolean(deletingSectionRecord)}
          heading={`Are you sure you want to delete "${deletingSectionRecord ? sectionModel.getRecordLabel<sectionRecordSchema>(deletingSectionRecord) : 'this record'}?"`}
          deleteLabel={'Delete'}
          cancelLabel={'Cancel'}
          onDelete={this.handleSectionRecordDeleteConfirmDialogDelete}
          onEscape={this.handleSectionRecordDeleteConfirmDialogClose}
          onCloseClick={this.handleSectionRecordDeleteConfirmDialogClose}
          onBackdropClick={this.handleSectionRecordDeleteConfirmDialogClose}
          onCancel={this.handleSectionRecordDeleteConfirmDialogClose} />
        </Page>
      </LookupContext.Provider>
    );
  }
}

export default CompanyPage;
