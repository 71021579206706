import React from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page/Page';
import './ErrorPage.scss';
import IconMessage from '../../components/IconMessage/IconMessage';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  status: number;
  message?: string;
}

export interface State {

}

class ErrorPage extends React.Component<Props, State> {

  static defaultProps = {
    status: 404,
		message: 'Page not found',
  };

  render() {
    const { message, status, className, match, location, history, staticContext, ...restProps } = this.props;
    const containerClass = classNames('fourg-error-page', className);
    return (
      <Page 
      title={`Error ${status}`} 
      description={message}
      className={containerClass} {...restProps}>
        <IconMessage
        icon={{ icon: 'error' }}
        heading={'It looks like something went wrong.'}>
          <strong>{`${status}:`}</strong>
          &nbsp;
          <span>{message}</span>
        </IconMessage>
      </Page>
    );
  }
}

export default ErrorPage;
