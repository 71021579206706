import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import Menu from '../Menu/Menu';
import MenuItem from '../MenuItem/MenuItem';
import { TableFormPage } from '../../types';
import './Form.scss';

export interface Props {
  id?: string;
  className?: string;
  variant?: 'default' | 'quiet';
  title?: string;
  submitLabel?: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  deleteLabel?: string;
  isLoading?: boolean;
  disabled?: boolean;
  pages: TableFormPage[];
	ignoreSecondaryValidity?: boolean;
	forceInvalid?: boolean;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSecondary: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPageChange?: (pageName: TableFormPage['name'], e: React.MouseEvent<HTMLButtonElement>) => void;
  renderPage?: (pageName: TableFormPage['name']) => React.ReactNode;
}

export interface State {
  isValid: boolean;
  currentPage: TableFormPage['name'];
}

class Form extends React.Component<Props, State> {

  private form = React.createRef<HTMLFormElement>();

  static defaultProps = {
    variant: 'default',
    pages: [],
    onSubmit: console.info,
    onSecondary: console.info,
    onCancel: console.info,
    onDelete: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSecondary = this.handleSecondary.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    const { pages } = props;
    this.state = {
      isValid: true,
      currentPage: (pages.length > 0) ? pages[0].name : '',
    };
  }

  componentDidMount() {
    const form = this.form.current;
    if (form) {
      this.setState({ isValid: form.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const form = this.form.current;
    const isValid = Boolean(form && form.checkValidity());
    if (prevState.isValid !== isValid) {
      this.setState({ isValid: isValid });
    }
  }

  handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    const { onSubmit } = this.props;
    e.preventDefault();
    onSubmit(e);
  }

  handleSecondary(e: React.MouseEvent<HTMLButtonElement>) {
    const { onSecondary } = this.props;
    e.preventDefault();
    onSecondary(e);
  }

  handleCancel(e: React.MouseEvent<HTMLButtonElement>) {
    const { onCancel } = this.props;
    e.preventDefault();
    onCancel(e);
  }

  handleDelete(e: React.MouseEvent<HTMLButtonElement>) {
    const { onDelete } = this.props;
    e.preventDefault();
    onDelete(e);
  }

  handleChange(e: React.FormEvent<HTMLFormElement>) {
    const form = this.form.current;
    if (form) {
      this.setState({ isValid: form.checkValidity() });
    }
  }

  handlePageChange(name: TableFormPage['name'], e: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ currentPage: name });
  }

  render() {
    const { renderPage, onPageChange, pages, variant, disabled, isLoading, title, deleteLabel, onDelete, submitLabel, onSubmit, secondaryLabel, ignoreSecondaryValidity, forceInvalid, onSecondary, cancelLabel, onCancel, className, children, ...restProps } = this.props;
    const { currentPage, isValid } = this.state;
    const containerClass = classNames('fourg-form', `fourg-form--variant-${variant}`, {
      'fourg-form--has-sidebar': (pages.length > 0),
    }, className);
    return (
      <form
      ref={this.form}
      className={containerClass}
      onChange={this.handleChange}
      {...restProps}>
        {title && (
          <div className="fourg-form__header">
            <h3 className="fourg-form__title">{title}</h3>
            {isLoading && (
              <Loader size={18} />
            )}
          </div>
        )}
        <div className="fourg-form__content">
          {(pages.length > 0) && (
            <div className="fourg-form__sidebar">
              <Menu>
                {pages.map(page => (
                  <MenuItem
                  disabled={(disabled || isLoading)}
                  key={`form-page-${page.name}`}
                  icon={page.icon}
                  label={page.label}
                  isActive={(currentPage === page.name)}
                  onClick={e => this.handlePageChange(page.name, e)} />
                ))}
              </Menu>
            </div>
          )}
          {renderPage ? (
            <div className="fourg-form__sections">
              {renderPage(currentPage)}
            </div>
           ) : (
            <div className="fourg-form__fields">
              {children}
            </div>
          )}
        </div>
        {(submitLabel || secondaryLabel || cancelLabel || deleteLabel) && (
          <div className="fourg-form__actions">
            {cancelLabel && (
              <Button
              variant={'outlined'}
              className="fourg-form__action fourg-form__action--cancel"
              type="button"
              disabled={(isLoading || disabled)}
              onClick={this.handleCancel}>
                {cancelLabel}
              </Button>
            )}
            {deleteLabel && (
              <Button
              className="fourg-form__action fourg-form__action--delete"
              type="submit"
              variant="raised"
              disabled={(isLoading || disabled)}
              onClick={this.handleDelete}>
                {deleteLabel}
              </Button>
            )}
            {secondaryLabel && (
              <Button
              className="fourg-form__action fourg-form__action--secondary"
              type="button"
              variant="raised"
              disabled={((! ignoreSecondaryValidity && (! isValid || forceInvalid)) || isLoading || disabled)}
              onClick={this.handleSecondary}>
                {secondaryLabel}
              </Button>
            )}
            {submitLabel && (
              <Button
              className="fourg-form__action fourg-form__action--submit"
              type="submit"
              variant="raised"
              disabled={(forceInvalid || ! isValid || isLoading || disabled)}
              onClick={this.handleSubmit}>
                {submitLabel}
              </Button>
            )}
          </div>
        )}
      </form>
    );
  }
}

export default Form;
