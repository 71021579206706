import React from 'react';
import classNames from 'classnames';
import './AnnouncementBar.scss';
import { UIOption } from '../../types';
import Button from '../Button/Button';

export interface Props {
  id?: string;
  className?: string;
  title: string;
  actions: UIOption[];
  disabled?: boolean;
  onActionClick: (value: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class AnnouncementBar extends React.Component<Props, State> {

  static defaultProps = {
    actions: [],
    onActionClick: console.info,
  };

  render() {
    const { disabled, onActionClick, title, actions, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-announcement-bar', className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-announcement-bar__content">
          <div className="fourg-announcement-bar__header">
            <h3 className="fourg-announcement-bar__title">{title}</h3>
            {(actions.length > 0) && (
              <div className="fourg-announcement-bar__actions">
                {actions.map(action => (
                  <Button 
                  key={`action-${action.value}`}
                  disabled={(disabled || action.disabled)}
                  variant={'raised'}
                  icon={action.icon}
                  onClick={e => onActionClick(action.value, e)}>
                    {action.label}
                  </Button>
                ))}
              </div>
            )}
          </div>
          {children && (
            <div className="fourg-announcement-bar__message">
              {children}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AnnouncementBar;
