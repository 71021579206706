import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import './SusiFormContainer.scss';

export interface Props {
  id?: string;
  className?: string;
}

export interface State {

}

class SusiFormContainer extends React.Component<Props, State> {

  render() {
    const { className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-susi-form-container', className);
    return (
      <div className={containerClass} {...restProps}>
				<div className="fourg-susi-form-container__content">
					<Logo className="fourg-susi-form-container__logo" />
					{children}
				</div>
      </div>
    );
  }
}

export default SusiFormContainer;
