import React from 'react';
import classNames from 'classnames';
import Radio from '../Radio/Radio';
import { UIOption } from '../../types';
import './RadioGroup.scss';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  value: UIOption['value'];
  label?: UIOption['label'];
  options: UIOption[];
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  title?: string;
  onChange: (value: UIOption['value'], e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface State {

}

class RadioGroup extends React.Component<Props, State> {

  static defaultProps = {
    value: '',
    options: [],
    onChange: console.info,
  }

  render() {
    const { readOnly, name, options, required, disabled, label, value, onChange, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-radio-group', className);
    return (
      <fieldset className={containerClass} {...restProps}>
        {label && (
          <legend className="foug-radio-group__label">
            {`${label}${required ? '*' : ''}`}
          </legend>
        )}
        {(options.length > 0) && options.map(option => (
          <Radio
          readOnly={readOnly}
          disabled={(disabled || option.disabled)}
          key={`radio-${option.value}`}
          name={name}
          label={option.label}
          value={option.value}
          checked={(value === option.value)}
          onChange={(checked, e) => onChange(option.value, e)} />
        ))}
        <input
				tabIndex={-1}
        className="fourg-radio-group__validator"
        type="checkbox"
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        checked={Boolean(value)}
        onChange={() => {}} />
      </fieldset>
    );
  }
}

export default RadioGroup;
