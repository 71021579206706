import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import { Props as IconProps } from '../Icon/Icon';
import ActionList from '../ActionList/ActionList';
import ActionListItem from '../ActionListItem/ActionListItem';
import { UIOption } from '../../types';
import './SelectMultiple.scss';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  label: string;
  title?: string;
  options: UIOption[];
  value: string[];
  icon?: IconProps['src'];
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: string[], e: React.ChangeEvent<HTMLSelectElement>) => void;
  onActionClick?: (value: string, isSelected: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isValid: boolean;
  internalID: string;
}

class SelectMultiple extends React.Component<Props, State> {

  private select = React.createRef<HTMLSelectElement>();

  static defaultProps = {
    options: [],
    value: [],
  };

  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.state = {
      isValid: true,
      internalID: props.id || uniqid('SelectMultiple-'),
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const select = this.select.current;
    if (value && select) {
      this.setState({ isValid: select.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { id, value, required } = this.props;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('SelectMultiple-') });
    }
    if ((prevProps.value !== value)
    || (prevProps.required !== required)) {
      const select = this.select.current;
      if (select) {
        this.setState({ isValid: select.checkValidity() });
      }
    }
  }

  handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { onChange } = this.props;
    const value = Array.from(e.target.options).filter(({ selected }) => selected).map(({ value }) => value);
    if (onChange) onChange(value, e);
  }

  handleActionClick(optionValue: string, e: React.MouseEvent<HTMLButtonElement>) {
    const { value, onActionClick } = this.props;
    const select = this.select.current;
    if (select) {
      const clickedOption = Array.from(select.options).find(option => ((option.value) === optionValue));
      if (clickedOption) clickedOption.selected = ! clickedOption.selected;
      const changeEvent = new Event('change', { bubbles: true });
      select.dispatchEvent(changeEvent);
    }
    if (onActionClick) onActionClick(optionValue, ! value.includes(optionValue), e);
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  render() {
    const { required, id, label, icon, value, disabled, options, onChange, onActionClick, className, ...restProps } = this.props;
    const { internalID, isValid } = this.state;
    const containerClass = classNames('fourg-select-multiple', {
      'fourg-select-multiple--is-valid': isValid,
    }, className);
    return (
      <div className={containerClass}>
        <div className="fourg-select-multiple__ui">
          <label className="fourg-select-multiple__ui-label" htmlFor={internalID}>
            {`${label}${required ? '*' : ''}`}
          </label>
          {(options.length > 0) && (
            <ActionList
            className="fourg-select-multiple__ui-actions"
            isExpanded={true}>
              {options.map(option => (
                <ActionListItem
                hasCheckbox={true}
                key={`action-${option.value}`}
                disabled={(disabled || option.disabled)}
                onClick={this.handleActionClick}
                isActive={value.includes(option.value)}
                {...option} />
              ))}
            </ActionList>
          )}
        </div>
        <div className="fourg-select-multiple__native">
          <label className="fourg-select-multiple__label" htmlFor={internalID}>
            {`${label}${required ? '*' : ''}`}
          </label>
          <select 
          multiple={true}
          ref={this.select}
          id={internalID}
          value={value}
          disabled={disabled}
          onChange={this.handleChange}
          tabIndex={-1}
          className="fourg-select-multiple__select" 
          required={required}
          {...restProps}>
            {(options.length > 0) && options.map(option => (
              <option 
              key={`option-${option.value}`} 
              value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default SelectMultiple;
