import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './ArtifactComparison.scss';

export interface Props {
  id?: string;
  className?: string;
	before: React.ReactNode;
	after: React.ReactNode;
}

export interface State {

}

class ArtifactComparison extends React.Component<Props, State> {

  render() {
    const { className, before, after, ...restProps } = this.props;
    const containerClass = classNames('fourg-artifact-comparison', className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-artifact-comparison__card-a">
					{before}
				</div>
				<Icon src={{ icon: 'arrow_forward' }} label={'Old → New'} />
				<div className="fourg-artifact-comparison__card-b">
					{after}
				</div>
      </div>
    );
  }
}

export default ArtifactComparison;
