import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField, CieTradeCounterpartySchema, CompanySchema } from '../../types';
import Company from './Company';

class CieTradeCounterparty extends Table {

  static options: TableOptions = {
    name: 'CieTradeCounterparty',
    slug: 'cietrade-counterparties',
    labelKey: 'name',
    valueKey: 'cieTradeID',
    lookupKey: 'cieTradeCounterparties',
    icon: 'business',
    defaultOrder: 'name',
  };

  static labels: TableLabels = {
    description: 'All cieTrade Counterparties in the 4G Recycling cieTrade system.',
    pageTitle: 'cieTrade Counterparty Manager',
    singular: 'cieTrade Counterparty',
    plural: 'cieTrade Counterparties',
    viewSingular: 'View cieTrade Counterparty',
    viewPlural: 'View cieTrade Counterparties',
    selectSingular: 'Select cieTrade Counterparty',
    selectPlural: 'Select cieTrade Counterparties',
    addSingular: 'Add cieTrade Counterparty',
    addPlural: 'Add cieTrade Counterparties',
    editSingular: 'Edit cieTrade Counterparty',
    editPlural: 'Edit cieTrade Counterparties',
    addedSingular: 'cieTrade Counterparty added',
    addedPlural: 'cieTrade Counterparties added',
    updatedSingular: 'cieTrade Counterparty updated',
    updatedPlural: 'cieTrade Counterparties updated',
    deletedSingular: 'cieTrade Counterparty deleted',
    deletedPlural: 'cieTrade Counterparties deleted',
    archivedSingular: 'cieTrade Counterparty archived',
    archivedPlural: 'cieTrade Counterparties archived',
    restoredSingular: 'cieTrade Counterparty restored',
    restoredPlural: 'cieTrade Counterparties restored',
    errorFetchingSingular: 'Error fetching cieTrade Counterparty',
    errorFetchingPlural: 'Error fetching cieTrade Counterparties',
    errorAddingSingular: 'Error adding cieTrade Counterparty',
    errorAddingPlural: 'Error adding cieTrade Counterparties',
    errorUpdatingSingular: 'Error updating cieTrade Counterparty',
    errorUpdatingPlural: 'Error updating cieTrade Counterparties',
    errorDeletingSingular: 'Error deleting cieTrade Counterparty',
    errorDeletingPlural: 'Error deleting cieTrade Counterparties',
    errorArchivingSingular: 'Error archiving cieTrade Counterparty',
    errorArchivingPlural: 'Error archiving cieTrade Counterparties',
    errorRestoringSingular: 'Error restoring cieTrade Counterparty',
    errorRestoringPlural: 'Error restoring cieTrade Counterparties',
    notFoundSingular: 'cieTrade Counterparty not found',
    notFoundPlural: 'No cieTrade Counterparties found',
    loadingSingular: 'Loading cieTrade Counterparty',
    loadingSingularEllipsis: 'Loading cieTrade Counterparty...',
    loadingPlural: 'Loading cieTrade Counterparties',
    loadingPluralEllipsis: 'Loading cieTrade Counterparties...',
    search: 'Search cieTrade Counterparties',
    searchEllipsis: 'Search cieTrade Counterparties...',
    filter: 'Filter cieTrade Counterparties',
    settings: 'cieTrade Counterparties Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'cietrade/counterparties',
    readRecord: 'cietrade/counterparty/:id',
    createRecord: 'cietrade/counterparties',
    updateRecord: 'cietrade/counterparty/:id',
    updateRecords: 'cietrade/counterparties',
    patchRecord: 'cietrade/counterparty/:id',
    deleteRecord: 'cietrade/counterparty/:id',
    archiveRecord: 'cietrade/counterparty/:id/archive',
    restoreRecord: 'cietrade/counterparty/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
    },
		{
      name: 'cieTradeID',
      label: 'cieTrade ID',
      type: 'number',
      default: '',
    },
    {
      name: 'name',
      label: 'Company Name',
      type: 'unique',
      model: CieTradeCounterparty,
			default: '',
    },
		{
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
      default: 'active',
    },
  ];

	static getID(counterpartyID: number) {
		return `cieTrade-${counterpartyID}`;
	}

	static toCompanySchema(record: CieTradeCounterpartySchema): CompanySchema {
		return {
			...Company.getDefaultRecord<CompanySchema>(),
			id: CieTradeCounterparty.getID(record.cieTradeID) as any,
			name: record.name,
			status: record.status,
			// role: record.role,
			website: record.website,
		};
	}
}

export default CieTradeCounterparty;
