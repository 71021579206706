import React from 'react';
import classNames from 'classnames';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import SelectInput from '../SelectInput/SelectInput';
import LookupInputNew from '../LookupInputNew/LookupInputNew';
import Form from '../Form/Form';
import FormField from '../FormField/FormField';
import Task from '../../models/tables/Task';
import TargetTask from '../../models/tables/tasks/TargetTask';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import { Auth, TaskSchema, UIOption } from '../../types';
import './ConvertToSubtaskDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  recordID: TaskSchema['id'];
  disabled?: boolean;
  initialBoard: string;
  disabledBoards: string[];
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (parentID: TaskSchema['id'], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isUpdating: boolean;
  board: string;
  parentID?: TaskSchema['id'];
}

class ConvertToSubtaskDialog extends React.Component<Props, State> {
  
  static defaultProps = {
    ...Dialog.defaultProps,
    initialBoard: 'new-business',
    disabledBoards: [],
    onFormCancel: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleBoardChange = this.handleBoardChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.state = {
      isUpdating: false,
      board: props.initialBoard,
      parentID: undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { initialBoard, isOpen, recordID } = this.props;
    if ((! prevProps.isOpen && isOpen)
    || (prevProps.recordID !== recordID)) {
      this.setState({
        board: initialBoard,
        parentID: undefined,
      });
    }
  }

  handleBoardChange(value: UIOption['value']) {
    this.setState({ 
      board: value,
      parentID: undefined, 
    });
  }

  handleParentChange(value: UIOption['value']) {
    this.setState({ 
      parentID: value ? parseInt(value, 10) : undefined, 
    });
  }

  getModelByBoard(board: string = 'peronal'): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  render() {
    const { disabledBoards, initialBoard, disabled, isOpen, auth, recordID, className, onFormSubmit, onFormCancel, ...restProps } = this.props;
    const { board, parentID } = this.state;
    const containerClass = classNames('fourg-convert-to-subtask-dialog', className);
    const boardField = Task.getField('board');
    const model = this.getModelByBoard(board);
    return (
      <Dialog 
      className={containerClass}
      title={'Make subtask of...'}
      isOpen={isOpen}
      {...restProps}>
        <Form
        className="fourg-convert-to-subtask-dialog__form"
        disabled={(disabled || ! isOpen)}
        submitLabel={'Save'}
        cancelLabel={'Cancel'}
        onCancel={onFormCancel}
        onSubmit={parentID ? e => onFormSubmit(parentID, e) : undefined}>
          {boardField && (
            <FormField size="large">
              <SelectInput
              disabled={(disabled || ! isOpen)}
              options={boardField.options?.filter(option => ! ['personal', 'targets', ...disabledBoards].includes(option.value))}
              value={board || boardField?.default}
              label={boardField.label}
              onChange={this.handleBoardChange} />
            </FormField>
          )}
          <FormField size="large">
            <LookupInputNew
            disabled={(disabled || ! isOpen)}
            required={true}
            auth={auth}
            lookup={{}}
            model={model}
            value={parentID?.toString() || ''}
            label={'Parent'}
            onChange={this.handleParentChange}
            options={[
              { value: '', label: 'Unassigned' },
            ]}
						query={{ 
							board: board,
							ignoreSubtasks: true,
							ignoreIds: recordID.toString(),
							archived: false,
						}} />
          </FormField>
        </Form>
      </Dialog>
    );
  }
}

export default ConvertToSubtaskDialog;
