import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import Icon from '../Icon/Icon';
import './Radio.scss';
import { UIOption } from '../../types';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  value?: UIOption['value'];
  label?: UIOption['label'];
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  title?: string;
  checked: boolean;
  onChange: (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface State {
  internalID: string;
  isFocused: boolean;
}

class Radio extends React.Component<Props, State> {

  private input = React.createRef<HTMLInputElement>();

  static defaultProps = {
    checked: true,
    onChange: console.info,
  }

  constructor(props: Props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      isFocused: false,
      internalID: props.id || uniqid('Radio-'),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { id, disabled } = this.props;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('Radio-') });
    }
    if (!prevProps.disabled && disabled) {
      this.setState({ isFocused: false });
    }
  }

  handleFocus() {
    this.setState({ isFocused: true });
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  focus() {
    const input = this.input.current;
    if (input) {
      input.focus();
    } 
  }

  blur() {
    const input = this.input.current;
    if (input) {
      input.blur();
    } 
  }

  render() {
    const { readOnly, id, value, disabled, label, checked, onChange, className, ...restProps } = this.props;
    const { internalID, isFocused } = this.state;
    const containerClass = classNames('fourg-radio', {
      'fourg-radio--focused': isFocused,
      'fourg-radio--readOnly': readOnly,
      'fourg-radio--disabled': disabled,
      'fourg-radio--checked': checked,
    }, className);
    return (
      <label className={containerClass}>
        <input
        id={internalID}
        ref={this.input}
        type="radio"
        value={value}
        className="fourg-radio__input"
        checked={checked}
        readOnly={readOnly}
        disabled={disabled}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={e => onChange(! checked, e)}
        {...restProps} />
        <Icon 
        src={{ icon: 'radio_button_checked' }} 
        className="fourg-radio__icon fourg-radio__icon--checked"
        label="Checked" />
        <Icon 
        src={{ icon: 'radio_button_unchecked' }} 
        className="fourg-radio__icon fourg-radio__icon--unchecked"
        label="Unchecked" />
        {label && (
          <span className="fourg-radio__label">{label}</span>
        )}
      </label>
    );
  }
}

export default Radio;
