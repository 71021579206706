import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';
import { Props as IconProps } from '../Icon/Icon';
import Input from '../Input/Input';
import Select, { Props as SelectProps } from '../Select/Select';
import CompanyQuickAddFormDialog from '../CompanyQuickAddFormDialog/CompanyQuickAddFormDialog';
import InputDescription from '../InputDescription/InputDescription';
import Table from '../../models/Table';
import { UIOption, Auth, Lookup } from '../../types';
import './LookupInput.scss';

export interface Props  {
  id?: string;
  auth: Auth;
  lookup: Lookup;
  className?: string;
  variant?: 'default' | 'quiet';
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
  title?: string;
  order?: string;
  filter?: string;
  limit?: number;
  board?: string;
  ignoreSubtasks?: boolean,
  ignoreIds?: string;
  archived?: boolean;
  model: typeof Table;
  value: SelectProps['value'];
  icon?: IconProps['src'];
  searchDelay: number;
  options: UIOption[];
  onChange: SelectProps['onChange'];
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface State<T extends Record<string, any> = Record<string, any>> {
  records: T[];
  selectedRecord?: T;
  isLoading: boolean;
  isCreating: boolean;
  total: number;
  searchValue: string;
  searchTimer?: number;
  isQuickAddFormDialogOpen: boolean;
}

class LookupInput<T extends Record<string, any> = Record<string, any>> extends React.Component<Props, State<T>> {

  private input = React.createRef<Input>();
  private select = React.createRef<Select>();

  static defaultProps = {
    options: [],
    value: '',
    limit: 20,
    variant: 'default',
    searchDelay: 500,
    onChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchTimer = this.handleSearchTimer.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleQuickAddDialogClose = this.handleQuickAddDialogClose.bind(this);
    this.handleQuickAddDialogSubmit = this.handleQuickAddDialogSubmit.bind(this);
    this.state = {
      records: [],
      isLoading: false,
      isCreating: false,
      total: 0,
      searchValue: '',
      selectedRecord: this.getInitialRecord(),
      isQuickAddFormDialogOpen: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;
    const { selectedRecord } = this.state;
    if (prevProps.value !== value) {
      this.setState({
        selectedRecord: this.getInitialRecord() || selectedRecord,
        records: [],
        total: 0,
        searchValue: '',
      });
    }
    if (prevProps.value && ! value) {
      this.setState({ selectedRecord: undefined });
    }
  }

  getInitialRecord() {
    const { model, lookup, value } = this.props;
    const modelOptions = model.getOptions();
    const lookupRecords: T[] = (modelOptions.lookupKey ? lookup[modelOptions.lookupKey] || [] : []) as any[];
    const record = lookupRecords.find(record => model.getRecordValue<T>(record).toString() === value);
    return record;
  }

  async readRecords() {
    const { archived, auth, model, order, filter, limit, board, ignoreIds, ignoreSubtasks } = this.props;
    const { searchValue } = this.state;
    const { defaultOrder, defaultFilter } = model.getOptions<T>();
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await model.readRecords<T>(token, {
        limit: limit,
        search: searchValue,
        order: order || defaultOrder,
        filter: filter || defaultFilter,
        board: board,
        ignoreSubtasks: ignoreSubtasks,
        ignoreIds: ignoreIds,
        archived: archived,
      });
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
      });
      const select = this.select.current;
      select?.open();
    } catch (error) {
      console.error(error);
      // toast.error(error.message);
      this.setState({
        isLoading: false,
        records: [],
        total: 0,
      });
    }
  }

  async createRecord(record: T, isQuickAddFormDialogOpen: boolean = false) {
    const { auth, model } = this.props;
    const { records, total } = this.state;
    this.setState({ isCreating: true });
    try {
      const token = await auth.getToken();
      const { data } = await model.createRecord<T>(token, record);
      this.setState({
        isCreating: false,
        isQuickAddFormDialogOpen: isQuickAddFormDialogOpen,
        records: [
          ...records,
          data,
        ],
        selectedRecord: data,
        total: (total + 1),
      });
      const select = this.select.current;
      select?.triggerChange(model.getRecordValue<T>(data).toString());
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isCreating: false });
    }
  }

  handleSearchTimer() {
    const { searchValue } = this.state;
    if (searchValue) {
      this.readRecords();
    } else {
      this.setState({
        records: [],
        total: 0,
      });
    }
  }

  handleSearchChange(value: string | number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { searchDelay } = this.props;
    const { searchTimer } = this.state;
    if (searchTimer) window.clearTimeout(searchTimer);
    const newTimer = window.setTimeout(() => this.handleSearchTimer(), searchDelay);
    this.setState({
      searchValue: value.toString(),
      searchTimer: newTimer,
    });
  }

  getOption(record: T) {
    const { model } = this.props;
    const { icon } = model.getOptions<T>();
    const option: UIOption = {
      value: model.getRecordValue<T>(record).toString(),
      label: model.getRecordLabel<T>(record),
      icon: {
        icon: icon,
        cover: model.getRecordImage<T>(record),
      },
    };
    return option;
  }

  getOptions() {
    const { auth, model, value, options } = this.props;
    const { records, selectedRecord } = this.state;
    const modelOptions = model.getOptions();
    const newOptions: UIOption[] = [];
    const newRecords = selectedRecord ? records.filter(record => (model.getRecordValue<T>(record) !== model.getRecordValue<T>(selectedRecord))) : records;
    options.forEach(option => {
      newOptions.push(option);
    });
    if (selectedRecord) {
      newOptions.push(this.getOption(selectedRecord));
    }
    newRecords.forEach(record => {
      if (model.getRecordValue<T>(record).toString() !== value) {
        newOptions.push(this.getOption(record));
      }
    });
    if ((modelOptions.name === 'User') && (value === auth.user?.id) && ! newOptions.find(option => option.value === auth.user?.id)) {
      newOptions.push(this.getOption(auth.user as any as T));
    }
    return newOptions;
  }

  handleSelectChange(newValue: string, e: React.ChangeEvent<HTMLSelectElement>) {
    const { value, model, onChange } = this.props;
    const { records } = this.state;
    if (newValue !== value) {
      this.setState({
        selectedRecord: records.find(record => (model.getRecordValue<T>(record).toString() === newValue)),
      });
      onChange(newValue, e);
    }
  }

  handleSelectClick() {
    const input = this.input.current;
    input?.focus();
  }

  handleBlur() {
    const { onBlur } = this.props;
    const input = this.input.current;
    const select = this.select.current;
    if (onBlur && ! input?.hasFocus() && ! select?.hasFocus()) {
      onBlur();
    }
  }

  resetValidity() {
    const select = this.select.current;
    select?.resetValidity();
  }

  isValid() {
    const select = this.select.current;
    select?.isValid();
  }

  handleAddButtonClick() {
    this.setState({ isQuickAddFormDialogOpen: true });
  }

  handleQuickAddDialogClose() {
    this.setState({ isQuickAddFormDialogOpen: false });
  }

  handleQuickAddDialogSubmit(record: T) {
    this.createRecord(record);
  }

  render() {
    const { archived, board, order, filter, limit, required, auth, lookup, options, variant, label, searchDelay, value, model, disabled, onChange, onBlur, ignoreSubtasks, ignoreIds, className, children, ...restProps } = this.props;
    const { searchValue, selectedRecord, isLoading, isCreating, isQuickAddFormDialogOpen } = this.state;
    const selectOptions = this.getOptions();
    const containerClass = classNames('fourg-lookup-input', className);
    const selectedRecordLabel = selectedRecord ? model.getRecordLabel<T>(selectedRecord) : selectOptions.find(selectOption => selectOption.value === value)?.label || value;
    const modelOptions = model.getOptions<T>();
    return (
      <div className={containerClass}>
        <div className="fourg-lookup-input__content">
          <Input
          variant={variant}
          className="fourg-lookup-input__search"
          ref={this.input}
          disabled={disabled}
          type="search"
          value={searchValue}
          onChange={this.handleSearchChange}
          autoComplete="off"
          label={`${label}${required ? '*' : ''}`}
          placeholder={selectedRecordLabel}
          tabIndex={-1}
          onBlur={this.handleBlur}
          icon={{ icon: 'search' }} />
          <Select
          variant={variant}
          className="fourg-lookup-input__select"
          ref={this.select}
          disabled={disabled}
          required={required}
          label={label}
          value={value}
          options={selectOptions}
          onChange={this.handleSelectChange}
          onClick={this.handleSelectClick}
          onBlur={this.handleBlur}
          isListOverlaid={false}
          icon={{ icon: modelOptions.icon }}
          {...restProps} />
          {isLoading && (
            <Loader
            className="fourg-lookup-input__loader"
            size={18} />
          )}
        </div>
        {modelOptions.hasQuickAdd && (
          <InputDescription disabled={disabled}>
            <span>{`Don't see the ${model.getLabel('singular').toLowerCase()} listed? `}</span>
            <button
            className="fourg-lookup-input__add-button"
            onClick={this.handleAddButtonClick}
            type="button"
            disabled={(disabled || isQuickAddFormDialogOpen || isLoading)}>
              {'Add a new one'}
            </button>
          </InputDescription>
        )}
        {(modelOptions.hasQuickAdd && (modelOptions.name === 'Company')) && (
          <CompanyQuickAddFormDialog
          disabled={(disabled || isCreating)}
          auth={auth}
          submitLabel={'Save'}
          cancelLabel={'Cancel'}
          title={model.getLabel('addSingular')}
          isOpen={isQuickAddFormDialogOpen}
          onFormCancel={this.handleQuickAddDialogClose}
          // onBackdropClick={this.handleQuickAddDialogClose}
          onEscape={this.handleQuickAddDialogClose}
          onCloseClick={this.handleQuickAddDialogClose}
          onFormSubmit={record => this.handleQuickAddDialogSubmit(record as any as T)} />
        )}
      </div>
    );
  }
}

export default LookupInput;
