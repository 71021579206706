import React from 'react';
import classNames from 'classnames';
import { DragDropContext, DropResult, /* DragStart,  */ResponderProvided } from 'react-beautiful-dnd';
import './Board.scss';

export interface Props {
  id?: string;
  className?: string;
  disabled?: boolean;
  // onDragStart: (initial: DragStart, provided: ResponderProvided) => void,
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void,
}

export interface State {

}

class Board extends React.Component<Props, State> {

  static defaultProps = {
    onDragStart: console.info,
    onDragEnd: console.info,
  };

  render() {
    const { disabled, /* onDragStart,  */onDragEnd, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-board', {
      'fourg-board--disabled': disabled,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        <DragDropContext
        // onDragStart={onDragStart}
        onDragEnd={onDragEnd}>
          <div className="fourg-board__scroller">
            <div className="fourg-board__board">
              <div className="fourg-board__columns">
                {children}
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>
    );
  }
}

export default Board;
