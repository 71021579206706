import React from 'react';
import classNames from 'classnames';
import './RecordWarnings.scss';
import Icon from '../Icon/Icon';

export interface Props {
  id?: string;
  className?: string;
	warnings: string[];
}

export interface State {

}

class RecordWarnings extends React.Component<Props, State> {

	static defaultProps = {
		warnings: [],
	};

	getTitle() {
		const { warnings } = this.props;
		return `${warnings.length.toLocaleString()} ${warnings.length === 1 ? 'Warning' : 'Warnings'}`;
	}

  render() {
    const { className, warnings, ...restProps } = this.props;
    const containerClass = classNames('fourg-record-warnings', className);
		const title = this.getTitle();
    return (
      <div className={containerClass} {...restProps}>
				<div className="fourg-record-warnings__header">
					<Icon src={{ icon: 'warning_amber' }} label={title} />
					<h3 className="fourg-record-warnings__title">{title}</h3>
				</div>
				<div className="fourg-record-warnings__content">
					<ul className="fourg-record-warnings__list">
						{warnings.map((warning, i) => (
							<li key={`warning-${i}`} className="fourg-record-warnings__list-item">
								<p>{warning}</p>
							</li>
						))}
					</ul>
				</div>
      </div>
    );
  }
}

export default RecordWarnings;
