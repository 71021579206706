import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { DropResult, DragStart } from 'react-beautiful-dnd';
import Page from '../../components/Page/Page';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import FilterBar from '../../components/FilterBar/FilterBar';
import TaskFormDialog from '../../components/TaskFormDialog/TaskFormDialog';
import ShareTaskDialog from '../../components/ShareTaskDialog/ShareTaskDialog';
import TaskRemoveSelfDialog from '../../components/TaskRemoveSelfDialog/TaskRemoveSelfDialog';
import Board from '../../components/Board/Board';
import BoardColumn from '../../components/BoardColumn/BoardColumn';
import BoardHandle from '../../components/BoardHandle/BoardHandle';
import TaskCard from '../../components/TaskCard/TaskCard';
import Table from '../../components/Table/Table';
import TableCellByField from '../../components/TableCellByField/TableCellByField';
import TableGroup from '../../components/TableGroup/TableGroup';
import TableGroupItem from '../../components/TableGroupItem/TableGroupItem';
import TaskFilters, { FilterValues } from '../../components/TaskFilters/TaskFilters';
import TaskQuickView from '../../components/TaskQuickView/TaskQuickView';
import Task from '../../models/tables/Task';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import User from '../../models/tables/User';
import { LookupContext } from '../../contexts';
import { TaskSchema, TableBoardColumn, Lookup, Socket, SocketMessage, ActionSchema, Auth, UIOption, ReadRecordsQuery, UserSchema, CompanySchema, LocationSchema, AttachmentSchema, ReminderSchema, GradeSchema, ExpenseSchema, ContactSchema } from '../../types';
import { mergeRecords } from '../../utils';
import './TasksPage.scss';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
  socket: Socket;
}

export interface State {
  isLoading: boolean;
  isCreateDialogOpen: boolean;
  isFilterOpen: boolean;
  isInfoOpen: boolean;
  records: TaskSchema[];
  lookup: Lookup;
  total: number;
  columns: TableBoardColumn<TaskSchema>[];
  dragging?: string;
  isUpdating: boolean;
  view: string;
  group: string;
  selectedRecordID?: TaskSchema['id'];
  shareRecordID?: TaskSchema['id'];
  removeSelfRecordID?: TaskSchema['id'],
  removeSelfAssignedToSelf?: boolean,
}

class TasksPage extends React.Component<Props, State> {

  private createDialog = React.createRef<TaskFormDialog>();

  constructor(props: Props) {
    super(props);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleCreateDialogSubmit = this.handleCreateDialogSubmit.bind(this);
    this.handleCreateDialogSecondary = this.handleCreateDialogSecondary.bind(this);
    this.handleCreateDialogClose = this.handleCreateDialogClose.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleClearFiltersClick = this.handleClearFiltersClick.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleBoardDragEnd = this.handleBoardDragEnd.bind(this);
    this.handleBoardDragStart = this.handleBoardDragStart.bind(this);
    this.handleCardFollowChange = this.handleCardFollowChange.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleCardDoubleClick = this.handleCardDoubleClick.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.handleTableRowDoubleClick = this.handleTableRowDoubleClick.bind(this);
    this.handleTableRowClick = this.handleTableRowClick.bind(this);
    this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
    this.handleInfoButtonClick = this.handleInfoButtonClick.bind(this);
    this.handleSubtaskCardClick = this.handleSubtaskCardClick.bind(this);
    this.handleArchivedToggleChange = this.handleArchivedToggleChange.bind(this);
    this.handleBoardChange = this.handleBoardChange.bind(this);
    this.handleTaskActionsChange = this.handleTaskActionsChange.bind(this);
    this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this);
    this.handleShareDialogClose = this.handleShareDialogClose.bind(this);
    this.handleRemoveSelfDialogSubmit = this.handleRemoveSelfDialogSubmit.bind(this);
    this.handleRemoveSelfDialogClose = this.handleRemoveSelfDialogClose.bind(this);
    this.getTaskActions = this.getTaskActions.bind(this);
    const initialGroup = this.getInitialGroup();
    const initialView = props.auth.settings?.application?.defaultBoardView || Cookies.get('tasks_page_view') || 'board';
    this.state = {
      records: [],
      total: 0,
      isLoading: false,
      isCreateDialogOpen: false,
      isFilterOpen: false,
      isInfoOpen: false,
      columns: this.getColumns(initialGroup as keyof TaskSchema),
      isUpdating: false,
      view: initialView,
      group: initialGroup,
      lookup: {},
    };
  }

  componentDidMount() {
    this.readRecords();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { location } = this.props;
    const { group } = this.state;
    const prevBoard = this.getBoard(prevProps.location);
    const board = this.getBoard(location);
    const prevFilter = this.getFilter(prevProps.location);
    const filter = this.getFilter(location);
    const prevSearch = this.getSearch(prevProps.location);
    const search = this.getSearch(location);
    const prevOrder = this.getOrder(prevProps.location);
    const order = this.getOrder(location);
    if ((prevSearch !== search)
    || (prevBoard !== board)
    || (prevFilter !== filter)) {
      this.setState({
        isInfoOpen: false,
        selectedRecordID: undefined,
        shareRecordID: undefined,
        removeSelfRecordID: undefined,
        removeSelfAssignedToSelf: undefined,
      });
    }
    if ((prevOrder !== order)
    || (prevSearch !== search)
    || (prevBoard !== board)
    || (prevFilter !== filter)) {
      this.readRecords(false);
    }
    if (prevBoard !== board) {
      Cookies.set('tasks_page_board', board);
    }
    if (prevState.group !== group) {
      Cookies.set(`tasks_page_group`, group);
      Cookies.set(`tasks_page_group_${board}`, group);
    }
  }

  componentWillUnmount() {
    this.socketDisconnect();
  }

  socketConnect() {
    const { socket, auth } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: 'tasks:connect' }));
			if (auth.user) {
				socket.current?.send(JSON.stringify({ action: `personal-tasks/${auth.user.id}:connect` }));
			}
		}
    window.addEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  socketDisconnect() {
    const { socket, auth } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: 'tasks:disconnect' }));
			if (auth.user) {
				socket.current?.send(JSON.stringify({ action: `personal-tasks/${auth.user.id}:disconnect` }));
			}
		}
    window.removeEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  isSocketMessageValid(socketMessage?: SocketMessage) {
    const { auth } = this.props;
    return (socketMessage && (socketMessage.type === 'task') && ['tasks', `personal-tasks/${auth.user?.id}`].includes(socketMessage.stream));
  }

  handleSocketMessage(e: CustomEventInit<SocketMessage>) {
    if (this.isSocketMessageValid(e.detail)) {
      const { location, auth } = this.props;
			const { lookup } = this.state;
      const socketMessage = { ...e.detail } as SocketMessage<ActionSchema>;
      const { message, meta, data } = socketMessage;
      const board = this.getBoard(location);
      const model = this.getModelByBoard(board);
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      if (['create', 'archive', 'restore'].includes(message)) {
        const newRecord = model.getNewRecord(socketMessage);
        this.addOrRemoveRecord(newRecord);
      } else {
        const record = this.getRecordByID(parseInt(data.resourceId.toString(), 10));
        if (record) {
          const newRecord = model.getSyncedRecord(record, socketMessage);
          this.syncRecord(newRecord);
        }
      }
      this.setState({
        lookup: {
					...lookup,
					users: mergeRecords<UserSchema>(newUsers, lookup.users),
					companies: mergeRecords<CompanySchema>(meta?.companies, lookup.companies),
					locations: mergeRecords<LocationSchema>(meta?.locations, lookup.locations),
					tasks: mergeRecords<TaskSchema>(meta?.tasks, lookup.tasks),
					attachments: mergeRecords<AttachmentSchema>(meta?.attachments, lookup.attachments),
					reminders: mergeRecords<ReminderSchema>(meta?.reminders, lookup.reminders),
					grades: mergeRecords<GradeSchema>(meta?.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta?.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta?.contacts, lookup.contacts),
        },
      });
    }
  }

  getModelByBoard(board: string): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  getInitialGroup(board?: string) {
    const { auth, location } = this.props;
    board = board || this.getBoard(location);
    const model = this.getModelByBoard(board);
    const cookieGroup = auth.settings?.application?.rememberBoardGroup ? Cookies.get(`tasks_page_group_${board}`) : Cookies.get('tasks_page_group');
    const fields = model.getGroupFields(board);
    const firstValue = fields[0] ? fields[0].name : undefined;
    return (cookieGroup && fields.find(field => (field.name === cookieGroup))) ? cookieGroup : firstValue || 'status';
  }

  async readRecords(isInitialLoad: boolean = true) {
    const { auth, location, socket } = this.props;
    const { group, lookup } = this.state;
    const filters = this.parseFiltersForQuery(location);
    this.setState({
      isLoading: true,
      records: [],
      columns: this.getColumns(group as keyof TaskSchema, []),
    });
    try {
      const token = await auth.getToken();
      const board = this.getBoard(location);
      const model = this.getModelByBoard(board);
      const filterParams = this.parseFiltersForQuery(location);
      const { meta, data } = await model.readRecords<TaskSchema>(token, {
        order: this.getOrder(location),
        search: this.getSearch(location),
        archived: false,
        board: this.getBoard(location),
        limit: -1,
        ...filterParams,
      });
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      if (filters.createdBy && ! newUsers.find(user => (user.id === filters.createdBy))) {
        const { data: creator } = await User.readRecord<UserSchema>(token, filters.createdBy);
        newUsers.push(creator);
      }
      if (filters.assignedTo && ! newUsers.find(user => (user.id === filters.assignedTo))) {
        const { data: assignee } = await User.readRecord<UserSchema>(token, filters.assignedTo);
        newUsers.push(assignee);
      }
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
        columns: this.getColumns(group as keyof TaskSchema, data),
        lookup: {
          ...lookup,
					users: mergeRecords<UserSchema>(newUsers, lookup.users),
					companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
					locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
					tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
					attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
					reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
					grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
        },
      });
      if (isInitialLoad && (socket.current?.readyState === WebSocket.OPEN)) {
        this.socketConnect();
      }
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoading: false,
        records: [],
        lookup: {},
        columns: this.getColumns(group as keyof TaskSchema),
      });
    }
  }

  async createRecord(record: TaskSchema, isCreateDialogOpen: boolean = false) {
    const { auth, location } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const board = this.getBoard(location);
      const model = this.getModelByBoard(board);
      await model.createRecord<TaskSchema>(token, record);
      toast.success(model.getLabel('addedSingular'));
      this.setState({
        isUpdating: false,
        isCreateDialogOpen: isCreateDialogOpen,
      });
      const createDialog = this.createDialog.current;
      createDialog?.setDefaultRecord();
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async updateRecord(id: TaskSchema['id'], record: TaskSchema) {
    const { auth, location, history } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const board = this.getBoard(location);
      const model = this.getModelByBoard(board);
      const { data } = await model.updateRecord<TaskSchema>(token, id.toString(), record);
      toast.success(model.getLabel('updatedSingular'));
      this.syncRecord(data);
      this.setState({ isUpdating: false });
      const params = new URLSearchParams(location.search);
      if (params.has('record')) {
        params.delete('record');
        history.push(`${location.pathname}?${params.toString()}`);
      }
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async patchRecord(id: string, record: Partial<TaskSchema>) {
    const { auth, location } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const board = this.getBoard(location);
      const model = this.getModelByBoard(board);
      const { data } = await model.patchRecord<TaskSchema>(token, id, record);
      toast.success(model.getLabel('updatedSingular'));
      this.syncRecord(data);
      this.setState({ isUpdating: false });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async removeSelf(assignedTo: TaskSchema['assignedTo']) {
    const { auth, location } = this.props;
    const { removeSelfRecordID } = this.state;
    if (removeSelfRecordID) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const board = this.getBoard(location);
        const model = this.getModelByBoard(board);
        await model.removeSelf(token, removeSelfRecordID, assignedTo);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({
          isUpdating: false,
          removeSelfRecordID: undefined,
        });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  isRecordVisible(record: TaskSchema) {
    const { auth, location } = this.props;
    const board = this.getBoard(location);
    const search = this.getSearch(location);
    const { following, dueDate, unprioritized, priority, status, uncategorized, category, unassigned, assignedTo, createdBy, created, updated, archived } = this.parseFiltersForQuery(location);
    if (search && ! record.title.includes(search) && ! record.description?.includes(search)) {
      return false;
    }
    if (board) {
      if (board !== record.board) {
        return false;
      }
      if ((board === 'personal') && (record.createdBy !== auth.user?.id)) {
        return false;
      }
    }
    if (archived && ! record.archived) {
      return false;
    }
    if (! archived && record.archived) {
      return false;
    }
    if (following && ! record.following) {
      return false;
    }
    if (unassigned && record.assignedTo) {
      return false;
    }
    if (assignedTo && (assignedTo !== record.assignedTo)) {
      return false;
    }
    if (createdBy && (createdBy !== record.createdBy)) {
      return false;
    }
    if (unprioritized && record.priority) {
      return false;
    }
    if (priority && (priority !== record.priority)) {
      return false;
    }
    if (status && (status !== record.status)) {
      return false;
    }
    if (uncategorized && record.category) {
      return false;
    }
    if (category && (category !== record.category)) {
      return false;
    }
    if (created || updated || dueDate) {
      const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/g;
      if (created) {
        const createdTime = new Date(created).getTime();
        const matches = created.match(regex);
        if (matches) {
          const from = ! created.startsWith('-') ? matches[0] : undefined;
          const to = ! created.endsWith('-') ? matches[1] || matches[0] : undefined;
          const fromTime = from ? new Date(from).getTime() : undefined;
          const toTime = to ? new Date(to).getTime() : undefined;
          if ((fromTime && (createdTime < fromTime))
          || (toTime && (createdTime > toTime))) {
            return false;
          }
        }
      }
      if (updated) {
        const updatedTime = new Date(updated).getTime();
        const matches = updated.match(regex);
        if (matches) {
          const from = ! updated.startsWith('-') ? matches[0] : undefined;
          const to = ! updated.endsWith('-') ? matches[1] || matches[0] : undefined;
          const fromTime = from ? new Date(from).getTime() : undefined;
          const toTime = to ? new Date(to).getTime() : undefined;
          if ((fromTime && (updatedTime < fromTime))
          || (toTime && (updatedTime > toTime))) {
            return false;
          }
        }
      }
      if (dueDate) {
        const dueDateTime = new Date(dueDate).getTime();
        const matches = dueDate.match(regex);
        if (matches) {
          const from = ! dueDate.startsWith('-') ? matches[0] : undefined;
          const to = ! dueDate.endsWith('-') ? matches[1] || matches[0] : undefined;
          const fromTime = from ? new Date(from).getTime() : undefined;
          const toTime = to ? new Date(to).getTime() : undefined;
          if ((fromTime && (dueDateTime < fromTime))
          || (toTime && (dueDateTime > toTime))) {
            return false;
          }
        }
      }
    }
    return true;
  }

  addOrRemoveRecord(record: TaskSchema) {
    if (this.isRecordVisible(record)) {
      this.addRecord(record);
    } else {
      this.removeRecord(record.id);
    }
  }

  addRecord(newRecord: TaskSchema) {
    const { location } = this.props;
    const { records, group } = this.state;
    const board = this.getBoard(location);
    const model = this.getModelByBoard(board);
    let newRecords = [...records];
    newRecords.push(newRecord);
    newRecords = model.orderRecords(newRecords, this.getOrder(location));
    this.setState({
      records: newRecords,
      columns: this.getColumns(group as keyof TaskSchema, newRecords),
    });
  }

  removeRecord(id: TaskSchema['id']) {
    const { records, group } = this.state;
    const newRecords = records.filter(record => (record.id !== id));
    this.setState({
      records: newRecords,
      columns: this.getColumns(group as keyof TaskSchema, newRecords),
    });
  }

  syncRecord(newRecord: TaskSchema) {
    const { location } = this.props;
    const { records, group } = this.state;
    const board = this.getBoard(location);
    const model = this.getModelByBoard(board);
    let newRecords = records.map(record => {
      return (record.id === newRecord.id) ? newRecord : record;
    });
    newRecords = model.orderRecords(newRecords, this.getOrder(location));
    this.setState({
      records: newRecords,
      columns: this.getColumns(group as keyof TaskSchema, newRecords),
    });
  }

  getBoard(location: RouteComponentProps['location']) {
    const { auth } = this.props;
    const boardField = Task.getField<TaskSchema>('board');
    let defaultBoard = 'personal';
    if (boardField?.default && (typeof boardField.default === 'string')) {
      defaultBoard = boardField.default;
    }
    const params = new URLSearchParams(location.search);
    return params.get('board') || auth.settings?.application?.defaultBoard || Cookies.get('tasks_page_board') || defaultBoard;
  }

  getOrder(location: RouteComponentProps['location']) {
    const board = this.getBoard(location);
    const model = this.getModelByBoard(board);
    const { defaultOrder } = model.getOptions<TaskSchema>();
    const params = new URLSearchParams(location.search);
    return params.get('order') || defaultOrder || '-created';
  }

  getSearch(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('search') || undefined;
  }

  getFilter(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('filter') || undefined;
  }

  handleOrderChange(order: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('order', order);
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleSearchChange(search: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('search', search);
    } else {
      params.delete('search');
    }
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  parseFiltersForQuery(location: RouteComponentProps['location']) {
    const { auth } = this.props;
    const filter = this.getFilter(location);
    const filterArray = filter ? filter.split(',') : [];
    const query: Partial<ReadRecordsQuery> = {};
    // updated
    if (filterArray.includes('updated:today')) {
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      const end = new Date();
      end.setHours(23, 59, 59, 999);
      query['updated'] = `${start.toISOString()}-${end.toISOString()}`;
    } else if (filterArray.includes('updated:week')) {
      const now = new Date();
      const start = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000));
      query['updated'] = `${start.toISOString()}-`;
    } else if (filterArray.includes('updated:month')) {
      const now = new Date();
      const start = new Date(now.getTime() - (31 * 24 * 60 * 60 * 1000));
      query['updated'] = `${start.toISOString()}-`;
    } else if (filterArray.includes('updated:older')) {
      const now = new Date();
      const end = new Date(now.getTime() - (31 * 24 * 60 * 60 * 1000));
      query['updated'] = `-${end.toISOString()}`;
    }
    // age
    if (filterArray.includes('age:today')) {
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      const end = new Date();
      end.setHours(23, 59, 59, 999);
      query['created'] = `${start.toISOString()}-${end.toISOString()}`;
    } else if (filterArray.includes('age:week')) {
      const now = new Date();
      const start = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000));
      query['created'] = `${start.toISOString()}-`;
    } else if (filterArray.includes('age:month')) {
      const now = new Date();
      const start = new Date(now.getTime() - (31 * 24 * 60 * 60 * 1000));
      query['created'] = `${start.toISOString()}-`;
    } else if (filterArray.includes('age:older')) {
      const now = new Date();
      const end = new Date(now.getTime() - (31 * 24 * 60 * 60 * 1000));
      query['created'] = `-${end.toISOString()}`;
    }
    // due
    if (filterArray.includes('due:overdue')) {
      const now = new Date();
      query['dueDate'] = `-${now.toISOString()}`;
    } else if (filterArray.includes('due:today')) {
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      const end = new Date();
      end.setHours(23, 59, 59, 999);
      query['dueDate'] = `${start.toISOString()}-${end.toISOString()}`;
    } else if (filterArray.includes('due:week')) {
      const now = new Date();
      const first = (now.getDate() - (now.getDay() - 1));
      const last = (first + 6);
      const start = new Date(now.setDate(first));
      start.setHours(0, 0, 0, 0);
      const end = new Date(now.setDate(last));
      end.setHours(23, 59, 59, 999);
      query['dueDate'] = `${start.toISOString()}-${end.toISOString()}`;
    } else if (filterArray.includes('due:month')) {
      var now = new Date();
      var start = new Date(now.getFullYear(), now.getMonth(), 1);
      var end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      query['dueDate'] = `${start.toISOString()}-${end.toISOString()}`;
    }
    // priority
    if (filterArray.includes('priority:unprioritized')) {
      query['unprioritized'] = true;
    } else if (filterArray.includes('priority:high')) {
      query['priority'] = 'high';
    } else if (filterArray.includes('priority:medium')) {
      query['priority'] = 'medium';
    } else if (filterArray.includes('priority:low')) {
      query['priority'] = 'low';
    }
    // status
    if (filterArray.includes('status:pending')) {
      query['status'] = 'pending';
    } else if (filterArray.includes('status:targets')) {
      query['status'] = 'targets';
    } else if (filterArray.includes('status:in-progress')) {
      query['status'] = 'in-progress';
    } else if (filterArray.includes('status:leads')) {
      query['status'] = 'leads';
    } else if (filterArray.includes('status:qualified')) {
      query['status'] = 'qualified';
    } else if (filterArray.includes('status:proposal')) {
      query['status'] = 'proposal';
    } else if (filterArray.includes('status:under-review')) {
      query['status'] = 'under-review';
    } else if (filterArray.includes('status:completed')) {
      query['status'] = 'completed';
    } else if (filterArray.includes('status:closed-won')) {
      query['status'] = 'closed-won';
    } else if (filterArray.includes('status:closed-lost')) {
      query['status'] = 'closed-lost';
    } else if (filterArray.includes('status:on-hold')) {
      query['status'] = 'on-hold';
    } else if (filterArray.includes('status:research')) {
      query['status'] = 'research';
    } else if (filterArray.includes('status:prospect')) {
      query['status'] = 'prospect';
    } else if (filterArray.includes('status:connect')) {
      query['status'] = 'connect';
    } else if (filterArray.includes('status:do-not-call')) {
      query['status'] = 'do-not-call';
    } else if (filterArray.includes('status:not-interested')) {
      query['status'] = 'not-interested';
    } else if (filterArray.includes('status:unqualified')) {
      query['status'] = 'unqualified';
    }
    // category
    if (filterArray.includes('category:uncategorized')) {
      query['uncategorized'] = true;
    } else if (filterArray.includes('category:unapplied-cash')) {
      query['category'] = 'unapplied-cash';
    } else if (filterArray.includes('category:payment-inquiries')) {
      query['category'] = 'payment-inquiries';
    } else if (filterArray.includes('category:issues-escalations')) {
      query['category'] = 'issues-escalations';
    } else if (filterArray.includes('category:freight-quotes')) {
      query['category'] = 'freight-quotes';
    } else if (filterArray.includes('category:marketing-requests')) {
      query['category'] = 'marketing-requests';
    } else if (filterArray.includes('category:to-be-scheduled')) {
      query['category'] = 'to-be-scheduled';
    } else if (filterArray.includes('category:service-issues')) {
      query['category'] = 'service-issues';
    } else if (filterArray.includes('category:billing-issues')) {
      query['category'] = 'billing-issues';
    } else if (filterArray.includes('category:mill-direct')) {
      query['category'] = 'mill-direct';
    } else if (filterArray.includes('category:recycling')) {
      query['category'] = 'recycling';
    } else if (filterArray.includes('category:trash')) {
      query['category'] = 'trash';
    } else if (filterArray.includes('category:grocery-retail')) {
      query['category'] = 'grocery-retail';
    } else if (filterArray.includes('category:non-grocery-retail')) {
      query['category'] = 'non-grocery-retail';
    } else if (filterArray.includes('category:printing')) {
      query['category'] = 'printing';
    } else if (filterArray.includes('category:manufacturing')) {
      query['category'] = 'manufacturing';
    } else if (filterArray.includes('category:converting')) {
      query['category'] = 'converting';
    }
    // following
    if (filterArray.includes('following:true')) {
      query['following'] = true;
    } else if (filterArray.includes('following:false')) {
      query['following'] = false;
    }
    // archived
    if (filterArray.includes('archived:true')) {
      query['archived'] = true;
    }

    // users
    filterArray.forEach(filterNode => {
      // assignee
      if (filterNode.startsWith('assignee:')) {
        if (filterNode === 'assignee:unassigned') {
          query['unassigned'] = true;
        } else if (filterNode === 'assignee:assigned-to-me') {
          query['assignedTo'] = auth.user?.id;
        } else {
          const assigneeID = filterNode.split(':')[1];
          query['assignedTo'] = assigneeID;
        }
      }
      // creator
      if (filterNode.startsWith('creator:')) {
        if (filterNode === 'creator:created-by-me') {
          query['createdBy'] = auth.user?.id;
        } else {
          const creatorID = filterNode.split(':')[1];
          query['createdBy'] = creatorID;
        }
      }
    });
    return query;
  }

  parseFiltersForUI(filter?: string) {
    let parsed: FilterValues = {};
    if (filter) {
      const filterArray = filter.split(',');
      filterArray.forEach(filterString => {
        const pieces = filterString.split(':');
        if (pieces[0] && pieces[1]) {
          parsed[pieces[0]] = pieces[1];
        }
      });
    }
    return parsed;
  }

  handleClearFiltersClick() {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('filter');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleFilterChange(filterValues: FilterValues) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    const filter = Object.entries(filterValues)
      .filter(([key, value]) => (key && value))
      .map(([key, value]) => `${key}:${value}`);
    if (filter.length > 0) {
      params.set('filter', filter.join(','));
    } else {
      params.delete('filter');
    }
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleViewChange(view: UIOption['value']) {
    Cookies.set('tasks_page_view', view);
    this.setState({ view: view });
  }

  handleGroupChange(group: UIOption['value']) {
    const { records } = this.state;
    this.setState({
      group: group,
      columns: this.getColumns(group as keyof TaskSchema, records),
    });
  }

  handleCreateDialogClose() {
    this.setState({ isCreateDialogOpen: false });
  }

  handleCreateDialogSubmit(record: TaskSchema) {
    this.createRecord(record);
  }

  handleCreateDialogSecondary(record: TaskSchema) {
    this.createRecord(record, true);
  }

  handleCreateClick() {
    this.setState({ isCreateDialogOpen: true });
  }

  getColumns(group: keyof TaskSchema, records: TaskSchema[] = []) {
    const { location } = this.props;
    const board = this.getBoard(location);
    const model = this.getModelByBoard(board);
    const field = model.getField<TaskSchema>(group);
    const columns: TableBoardColumn<TaskSchema>[] = [];
    if (field?.options && (field?.options.length > 0)) {
      field.options.forEach(option => {
          const newRecords = (records.length > 0) ? records.filter(record => (record[group] === option.value)) : [];
          columns.push({
            key: option.value || 'undefined',
            label: option.label,
            total: newRecords.length,
            records: newRecords,
          });
        // }
      });
    }
    return columns;
  }

  handleBoardDragStart(initial: DragStart) {
    const { source } = initial;
    this.setState({ dragging: source.droppableId });
  }

  handleBoardDragEnd(result: DropResult) {
    const { group } = this.state;
    const { source, destination, draggableId } = result;
    this.setState({ dragging: undefined });
    if (destination && (source.droppableId !== destination.droppableId)) {
      const record = this.getRecordByID(parseInt(draggableId, 10));
      if (record) {
        const dropValue = (destination.droppableId === 'undefined') ? '' : destination.droppableId;
        const changes: Partial<TaskSchema> = { [group]: dropValue };
        const newRecord: TaskSchema = {
          ...record,
          ...changes,
          updated: new Date().toISOString(),
        };
        this.syncRecord(newRecord);
        this.patchRecord(record.id.toString(), changes);
      }
    }
  }

  handleCardFollowChange(record: TaskSchema, following: boolean) {
    if (following) {
      this.followRecord(record);
    } else {
      this.unfollowRecord(record);
    }
  }

  handleShareDialogClose() {
    this.setState({ shareRecordID: undefined });
  }

  async followRecord(record: TaskSchema) {
    const { auth, location } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const board = this.getBoard(location);
      const model = this.getModelByBoard(board);
      await model.followRecord(token, record.id);
      toast.success(model.getLabel('updatedSingular'));
      this.syncRecord({ ...record, following: true });
      this.setState({ isUpdating: false });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async unfollowRecord(record: TaskSchema) {
    const { auth, location } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const board = this.getBoard(location);
      const model = this.getModelByBoard(board);
      await model.unfollowRecord(token, record.id);
      toast.success(model.getLabel('updatedSingular'));
      this.syncRecord({ ...record, following: false });
      this.setState({ isUpdating: false });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async archiveRecord(record: TaskSchema) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getModelByBoard(record.board);
      await model.archiveRecord(token, record.id.toString());
      toast.success(model.getLabel('archivedSingular'));
      // this.syncRecord({ ...record, archived: true });
      this.setState({ isUpdating: false });
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async restoreRecord(record: TaskSchema) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getModelByBoard(record.board);
      await model.restoreRecord(token, record.id.toString());
      toast.success(model.getLabel('restoredSingular'));
      // this.syncRecord({ ...record, archived: false });
      this.setState({ isUpdating: false });
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  getRecordByID(id: TaskSchema['id']) {
    const { records } = this.state;
    return records.find(record => record.id === id);
  }

  getColumnByKey(key: string) {
    const { columns } = this.state;
    return columns.find(column => (column.key === key));
  }

  routeToRecord(record: TaskSchema) {
    const { history } = this.props;
    history.push(Task.getRecordLink<TaskSchema>(record));
  }

  handleCardClick(record: TaskSchema) {
    this.setState({
      selectedRecordID: record.id,
      isInfoOpen: true,
      isFilterOpen: false,
    });
  }

  handleCardDoubleClick(record: TaskSchema) {
    this.routeToRecord(record);
  }

  handleTableRowClick(record: TaskSchema) {
    this.setState({
      selectedRecordID: record.id,
      isInfoOpen: true,
      isFilterOpen: false,
    });
  }

  handleTableRowDoubleClick(record: TaskSchema) {
    this.routeToRecord(record);
  }

  getGroupOptions() {
    const { location } = this.props;
    const board = this.getBoard(location);
    const model = this.getModelByBoard(board);
    const fields = model.getGroupFields(board);
    return fields.map(field => {
      const option: UIOption = {
        label: field.label,
        value: field.name,
      };
      return option;
    })
  }

  handleFilterButtonClick(isFilterOpen: boolean) {
    this.setState({
      isFilterOpen: isFilterOpen,
      isInfoOpen: false,
    });
  }

  handleInfoButtonClick(isInfoOpen: boolean) {
    this.setState({
      isInfoOpen: isInfoOpen,
      isFilterOpen: false,
    });
  }

  handleSubtaskCardClick(subtask: TaskSchema) {
    this.routeToRecord(subtask);
  }

  handleArchivedToggleChange(checked: boolean) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    if (checked) {
      params.set('archived', 'true');
    } else {
      params.delete('archived');
    }
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleBoardChange(value: UIOption['value']) {
    const { location, history } = this.props;
    const initialGroup = this.getInitialGroup(value);
    this.setState({
      group: initialGroup,
      isFilterOpen: false,
    });
    const params = new URLSearchParams(location.search);
    params.delete('filter');
    params.set('board', value);
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleTaskActionsChange(value: UIOption['value'], record: TaskSchema) {
    const { auth } = this.props;
    switch (value) {
      case 'follow': return this.followRecord(record);
      case 'unfollow': return this.unfollowRecord(record);
      case 'archive': return this.archiveRecord(record);
      case 'restore': return this.restoreRecord(record);
      case 'share': return this.setState({ shareRecordID: record.id });
      case 'remove-self':
        return this.setState({
          removeSelfRecordID: (record as TaskSchema).id,
          removeSelfAssignedToSelf: ((record as TaskSchema).assignedTo === auth.user?.id)
        });
    }
  }

  getTaskActions(record: TaskSchema) {
    const { auth } = this.props;
    const actions: UIOption[] = [
      {
        value: record.following ? 'unfollow' : 'follow',
        label: record.following ? 'Unfollow' : 'Follow',
        // icon: { icon: record.following ? 'bookmark' : 'bookmark_border' },
      },
      {
        value: record.archived ? 'restore' : 'archive',
        label: record.archived ? 'Restore' : 'Archive',
      },
      {
        value: 'share',
        label: 'Share',
      },
    ];
    if (auth.user && ((auth.user.id === record.assignedTo) || record.members?.includes(auth.user.id))) {
      actions.push({
        value: 'remove-self',
        label: 'Remove Me',
      });
    }
    return actions;
  }

  handleRefreshButtonClick() {
    this.readRecords();
  }

  handleRemoveSelfDialogSubmit(assignedTo: TaskSchema['assignedTo']) {
    this.removeSelf(assignedTo);
  }

  handleRemoveSelfDialogClose() {
    this.setState({
      removeSelfRecordID: undefined,
      removeSelfAssignedToSelf: undefined,
    });
  }

  render() {
    const { auth, socket, className, match, location, history, staticContext, ...restProps } = this.props;
    const { shareRecordID, selectedRecordID, group, isLoading, isUpdating, isCreateDialogOpen, isFilterOpen, isInfoOpen, view, columns, dragging, lookup, total, removeSelfRecordID, removeSelfAssignedToSelf } = this.state;
    const containerClass = classNames('fourg-tasks-page', className);
    const filter = this.getFilter(location);
    const filterValues = this.parseFiltersForUI(filter);
    const order = this.getOrder(location);
    const board = this.getBoard(location);
    const model = this.getModelByBoard(board);
    const assignedToField = model.getField('assignedTo');
    const boardField = model.getField('board');
    const taskOptions = model.getOptions<TaskSchema>();
    const tableColumns = model.getFieldColumns<TaskSchema>().filter(column => ! [group, 'board'].includes(column.key));
    return (
      <LookupContext.Provider value={lookup}>
        <Page
        title={model.getLabel('pageTitle')}
        headerTitle={(
          <Select
          variant="link"
          label={boardField?.label || ''}
          options={boardField?.options || []}
          value={board}
          disabled={(isLoading || isUpdating)}
          onChange={this.handleBoardChange} />
        )}
        description={model.getLabel('description')}
        headerDescription={isLoading ? model.getLabel('loadingPluralEllipsis') : `Viewing ${total} ${model.getLabel('plural')} from the ${model.getFieldOptionLabel('board', board)} board.`}
        className={containerClass}
        {...restProps}>
          <FilterBar
          lookup={lookup}
          disabled={(isLoading || isUpdating)}
          searchLabel={model.getLabel('searchEllipsis')}
          searchValue={this.getSearch(location)}
          viewValue={view}
          isFilterOpen={isFilterOpen}
          isInfoOpen={isInfoOpen}
          onSearchChange={this.handleSearchChange}
          onViewChange={this.handleViewChange}
          onFilterButtonClick={this.handleFilterButtonClick}
          onInfoButtonClick={this.handleInfoButtonClick}
          onRefreshButtonClick={this.handleRefreshButtonClick}
          filters={model.getFilters()}
          filterValues={filterValues}
          orderOptions={model.getOrderOptions()}
          orderValue={order}
          groupOptions={this.getGroupOptions()}
          groupValue={group}
          onGroupChange={this.handleGroupChange}
          onOrderChange={this.handleOrderChange}
          onFilterChange={this.handleFilterChange}
          onClearFiltersClick={this.handleClearFiltersClick}
          viewOptions={[
            { value: 'board', label: 'Board', icon: { icon: 'dashboard' } },
            { value: 'table', label: 'Table', icon: { icon: 'list_alt' } },
          ]}>
            <Button
            icon={{ icon: 'add' }}
            variant="raised"
            onClick={this.handleCreateClick}>
              {model.getLabel('addSingular')}
            </Button>
          </FilterBar>
          <div className="fourg-tasks-page__content">
            <div className="fourg-tasks-page__data">
              {(view === 'board') ? (
                <Board
                // onDragStart={this.handleBoardDragStart}
                onDragEnd={this.handleBoardDragEnd}
                disabled={(isLoading || isUpdating)}>
                  {(columns.length > 0) && columns.map(column => (
                    <BoardColumn
                    isLoading={isLoading}
                    key={`board-column-${column.key}`}
                    value={column.key}
                    label={column.label}
                    disabled={(isLoading || isUpdating || (dragging === column.key))}
                    total={column.total}
                    isHighlighted={Boolean(dragging && (dragging !== column.key))}>
                      {(! isLoading && (column.records.length > 0)) && column.records.map((record, i) => (
                        <BoardHandle
                        disabled={(isLoading || isUpdating || column.disabled)}
                        key={`board-handle-${record.id.toString()}`}
                        id={record.id.toString()}
                        index={i}>
                          <TaskCard
                          radioName="selected-record"
                          isActive={Boolean(selectedRecordID && (record.id === selectedRecordID))}
                          disabled={(isLoading || isUpdating || column.disabled)}
                          onFollowChange={this.handleCardFollowChange}
                          record={record}
                          onClick={this.handleCardClick}
                          onDoubleClick={this.handleCardDoubleClick}
                          getActions={this.getTaskActions}
                          onActionsChange={this.handleTaskActionsChange} />
                        </BoardHandle>
                      ))}
                    </BoardColumn>
                  ))}
                </Board>
              ) : (
                <TableGroup<TaskSchema>
                isScrollable={true}
                columns={tableColumns}
                order={order}
                hasActions={true}
                hasWarnings={true}
                onOrderChange={this.handleOrderChange}>
                  {columns.map(column => (
                    <TableGroupItem
                    key={`table-group-item-${column.key}`}
                    heading={column.label}
                    total={column.total}
                    disabled={(isLoading || isUpdating || column.disabled)}>
                      <Table<TaskSchema>
                      rowRadioName="selected-record"
                      isScrollable={false}
                      columns={tableColumns}
                      records={column.records}
                      order={order}
                      disabled={(isLoading || isUpdating || column.disabled)}
                      isLoading={isLoading}
                      onOrderChange={this.handleOrderChange}
                      onRowDoubleClick={this.handleTableRowDoubleClick}
                      onRowClick={this.handleTableRowClick}
                      notFoundIcon={{ icon: taskOptions.icon }}
                      notFoundHeading={model.getLabel('notFoundPlural')}
                      isRowActive={record => Boolean(selectedRecordID && (record.id === selectedRecordID))}
                      getRowWarnings={record => model.getWarnings(record)}
                      getRowActions={this.getTaskActions}
                      onRowActionsChange={this.handleTaskActionsChange}
                      renderCell={(value, column, record) => {
                        const field = model.getField<TaskSchema>(column.key);
                        return (!field) ? value.toString() : (
                          <TableCellByField<TaskSchema>
                          field={field}
                          value={value}
                          record={record} />
                        );
                      }} />
                    </TableGroupItem>
                  ))}
                </TableGroup>
              )}
            </div>
            {(isFilterOpen || isInfoOpen) && (
              <div className="fourg-tasks-page__sidebars">
                {isFilterOpen && (
                  <TaskFilters
                  auth={auth}
                  lookup={lookup}
                  model={model}
                  board={board}
                  disabled={(isLoading || isUpdating)}
                  value={filterValues}
                  onChange={this.handleFilterChange} />
                )}
                {isInfoOpen && (
                  <TaskQuickView
                  auth={auth}
                  socket={socket}
                  recordID={selectedRecordID}
                  onSubtaskCardClick={this.handleSubtaskCardClick} />
                )}
              </div>
            )}
          </div>
          <TaskFormDialog
          model={model}
          disabled={(isLoading || isUpdating)}
          auth={auth}
          ref={this.createDialog}
          enforcedValues={{ board: board }}
          title={model.getLabel('addSingular')}
          isOpen={isCreateDialogOpen}
          submitLabel={'Save'}
          secondaryLabel={'Save and Add Another'}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCreateDialogSubmit}
          onFormSecondary={this.handleCreateDialogSecondary}
          onCloseClick={this.handleCreateDialogClose}
          // onBackdropClick={this.handleCreateDialogClose}
          onFormCancel={this.handleCreateDialogClose}
          onEscape={this.handleCreateDialogClose}
          initialValues={Boolean(assignedToField) ? { assignedTo: auth.user?.id } : undefined} />
          <ShareTaskDialog
          isOpen={Boolean(shareRecordID)}
          recordID={shareRecordID || 0}
          onCloseClick={this.handleShareDialogClose}
          onEscape={this.handleShareDialogClose}
          onBackdropClick={this.handleShareDialogClose} />
          <TaskRemoveSelfDialog
          disabled={(isLoading || isUpdating)}
          auth={auth}
          lookup={lookup}
          recordID={removeSelfRecordID || 0}
          isOpen={Boolean(removeSelfRecordID)}
          isAssignedToSelf={removeSelfAssignedToSelf}
          onFormSubmit={this.handleRemoveSelfDialogSubmit}
          onCloseClick={this.handleRemoveSelfDialogClose}
          onBackdropClick={this.handleRemoveSelfDialogClose}
          onFormCancel={this.handleRemoveSelfDialogClose}
          onEscape={this.handleRemoveSelfDialogClose} />
        </Page>
      </LookupContext.Provider>
    );
  }
}

export default TasksPage;
