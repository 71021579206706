import React from 'react';
import classNames from 'classnames';
import Select, { Props as SelectProps } from '../Select/Select';
import DateInput, { Props as DateInputProps } from '../DateInput/DateInput';
import ChipList, { Props as ChipListProps } from '../ChipList/ChipList';
import DatePicker from 'react-datepicker';
import InputDescription from '../InputDescription/InputDescription';
import { RecurrenceValue, UIOption } from '../../types';
import './RecurrenceInput.scss';

export interface Props {
  id?: string;
  className?: string;
  label: string;
  value: RecurrenceValue;
  variant: 'default' | 'quiet';
  icon?: SelectProps['icon'];
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  name?: string;
  onChange: (value: RecurrenceValue, e: React.ChangeEvent<HTMLSelectElement> | React.SyntheticEvent<DatePicker> | React.MouseEvent<HTMLButtonElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface State {

}

class RecurrenceInput extends React.Component<Props, State> {

  static defaultProps = {
    onChange: console.info,
    variant: 'default',
    icon: { icon: 'alarm' },
    value: {
      interval: undefined,
      date: '',
    },
  };

  static intervalOptions: UIOption[] = [
    { value: 'd', label: 'Daily' },
    { value: 'w', label: 'Weekly' },
    { value: 'm', label: 'Monthly' },
    { value: 'y', label: 'Yearly' },
  ];

  static weekDayOptions: UIOption[] = [
    { value: '0', label: 'Sunday' },
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
  ];

  constructor(props: Props) {
    super(props);
    this.handleRecurrenceChange = this.handleRecurrenceChange.bind(this);
    this.handleWeekDayChange = this.handleWeekDayChange.bind(this);
    this.handleAddRecurrenceClick = this.handleAddRecurrenceClick.bind(this);
    this.handleRemoveRecurrenceClick = this.handleRemoveRecurrenceClick.bind(this);
    this.handleMonthDayChange = this.handleMonthDayChange.bind(this);
    this.handleDateChange= this.handleDateChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getDateInputType(): DateInputProps['type'] {
    const { value } = this.props;
    switch (value.interval) {
      case undefined: return 'datetime';
      case 'y': return 'monthdaytime';
      default: return 'time';
    }
  }

  getDateInputLabel(): DateInputProps['label'] {
    const { value, label } = this.props;
    switch (value.interval) {
      case undefined: return label;
      case 'y': return 'Date and Time';
      default: return 'Time';
    }
  }

  getDateOptions() {
    let options: SelectProps['options'] = [];
    for (let i = 1; i <= 31; i++) {
      options.push({
        value: i.toString(),
        label: RecurrenceInput.getOrdinal(i),
      });
    }
    return options;
  }

  static getOrdinal(n: number) {
    let s = ['th', 'st', 'nd', 'rd'];
    let v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  handleAddRecurrenceClick(e: React.MouseEvent<HTMLButtonElement>) {
    const { value, onChange } = this.props;
    const now = new Date();
    now.setHours(9);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    const newValue: RecurrenceValue = {
      ...value,
      interval: 'd',
      date: value.date ? value.date : now.toISOString(),
    };
    onChange(newValue, e);
  }

  handleRecurrenceChange(recurrenceValue: SelectProps['value'], e: React.ChangeEvent<HTMLSelectElement>) {
    const { value, onChange } = this.props;
    const newValue: RecurrenceValue = {
      ...value,
      interval: recurrenceValue as RecurrenceValue['interval'],
    };
    onChange(newValue, e);
  }

  handleRemoveRecurrenceClick(e: React.MouseEvent<HTMLButtonElement>) {
    const { value, onChange } = this.props;
    const newValue: RecurrenceValue = {
      ...value,
      interval: undefined,
    };
    onChange(newValue, e);
  }

  handleWeekDayChange(weekDayValue: ChipListProps['value'], e: React.MouseEvent<HTMLButtonElement>) {
    if (weekDayValue[0]) {
      const { value, onChange } = this.props;
      const date = new Date(value.date);
      const currentDay = date.getDay();
      let distance = (((parseInt(weekDayValue[0], 10)) - currentDay) % 7);
      date.setDate(date.getDate() + distance);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const newValue: RecurrenceValue = {
        ...value,
        date: date.toISOString(),
      };
      onChange(newValue, e);
    }
  }

  handleDateChange(dateValue: string, e: React.SyntheticEvent<DatePicker>) {
    const { onChange, value } = this.props;
    const date = new Date(dateValue);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const newValue: RecurrenceValue = {
      ...value,
      date: dateValue,
    };
    onChange(newValue, e);
  }

  handleMonthDayChange(monthDayValue: string, e: React.ChangeEvent<HTMLSelectElement>) {
    const { value, onChange } = this.props;
    const date = new Date(value.date);
    const monthDay = parseInt(monthDayValue, 10);
    let totalMonthDays = this.getTotalMonthDays(date);
    while (monthDay > totalMonthDays) {
      date.setMonth(date.getMonth() - 1);
      totalMonthDays = this.getTotalMonthDays(date);
    }
    date.setDate(monthDay);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const newValue: RecurrenceValue = {
      ...value,
      date: date.toISOString(),
    };
    onChange(newValue, e);
  }

  getTotalMonthDays(date: Date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  renderDateInput() {
    const { name, variant, disabled, required, readOnly, value } = this.props;
    return (
      <DateInput
      className="fourg-recurrence-input__date"
      name={name ? `${name}[date]` : undefined}
      variant={variant}
      disabled={disabled}
      label={this.getDateInputLabel()}
      type={this.getDateInputType()}
      required={Boolean(required || value.interval)}
      readOnly={readOnly}
      value={value.date}
      onChange={this.handleDateChange}
      isPastAllowed={Boolean(value.interval)}
      onFocus={this.handleFocus}
      onBlur={this.handleBlur} />
    );
  }

  handleFocus() {
    const { onFocus } = this.props;
    if (onFocus) onFocus();
  }
  
  handleBlur() {
    const { onBlur } = this.props;
    if (onBlur) onBlur();
  }

  render() {
    const { onFocus, onBlur, onChange, disabled, readOnly, required, name, variant, icon, label, value, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-recurrence-input', {
      'fourg-recurrence-input--disabled': disabled,
    }, className);
    const date = new Date(value.date);
    return (
      <div className={containerClass} {...restProps}>
        {/* <div style={{ marginBottom: '10px' }}>{date.toLocaleString()}</div> */}
        {! value.interval ? this.renderDateInput() : (
          <React.Fragment>
            <Select
            className="fourg-recurrence-input__interval"
            name={name ? `${name}[interval]` : undefined}
            variant={variant}
            label={label}
            value={value.interval}
            required={required}
            readOnly={readOnly}
            disabled={disabled}
            icon={icon}
            onChange={this.handleRecurrenceChange}
            options={RecurrenceInput.intervalOptions}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur} />
              <div className="fourg-recurrence-input__aux">
                {(value.interval === 'w') && (
                  <div className="fourg-recurrence-input__week-day">
                    <ChipList
                    disabled={disabled}
                    variant={(variant === 'quiet') ? 'mini' : 'default'}
                    multiple={false}
                    label={'Weekdays'}
                    value={[date.getDay().toString()]}
                    onChange={this.handleWeekDayChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    required={Boolean(required || value.interval)}
                    options={RecurrenceInput.weekDayOptions.filter(option => ! ['0', '6'].includes(option.value))} />
                    <ChipList
                    disabled={disabled}
                    variant={(variant === 'quiet') ? 'mini' : 'default'}
                    multiple={false}
                    label={'Weekends'}
                    value={[date.getDay().toString()]}
                    onChange={this.handleWeekDayChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    required={Boolean(required || value.interval)}
                    options={RecurrenceInput.weekDayOptions.filter(option => ['0', '6'].includes(option.value)).reverse()} />
                  </div >
                )}
                {(value.interval === 'm') && (
                  <div className="fourg-recurrence-input__month-day">
                    <span>{'On the'}&nbsp;</span>
                    <Select
                    disabled={disabled}
                    required={Boolean(required || value.interval)}
                    readOnly={readOnly}
                    variant={'link'}
                    label={label}
                    value={date.getDate().toString()}
                    options={this.getDateOptions()}
                    onChange={this.handleMonthDayChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur} />
                    <span>&nbsp;{'of each month'}</span>
                  </div>
                )}
                {this.renderDateInput()}
              </div>
          </React.Fragment>
        )}
        {! value.interval ? (
          <InputDescription disabled={disabled}>
            <span>{'Want this to happen more than once? '}</span>
            <button 
            className="fourg-recurrence-input__add-recurrence"
            onClick={this.handleAddRecurrenceClick} 
            type="button"
            disabled={(disabled)}>
              {'Add Recurrence'}
            </button>
          </InputDescription>
        ) : (
          <InputDescription disabled={disabled}>
            <span>{'Want this to happen just once? '}</span>
            <button 
            className="fourg-recurrence-input__remove-recurrence"
            onClick={this.handleRemoveRecurrenceClick} 
            type="button"
            disabled={(disabled)}>
              {'Remove Recurrence'}
            </button>
          </InputDescription>
        )}
      </div>
    );
  }
}

export default RecurrenceInput;
