import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import AttachmentCard from '../AttachmentCard/AttachmentCard';
import ActionValueByField from '../ActionValueByField/ActionValueByField';
import Editor from '../Editor/Editor';
import TaskCard from '../TaskCard/TaskCard';
import ReminderCard from '../ReminderCard/ReminderCard';
import Task from '../../models/tables/Task';
import User from '../../models/tables/User';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import { LookupContext, NotificationsContext, AuthContext } from '../../contexts';
import { Lookup, TaskSchema, AttachmentSchema, NotificationSchema, UserSchema, ReminderSchema, RemoveSelfActionContent, GradeSchema, ExpenseSchema } from '../../types';
import Reminder from '../../models/tables/Reminder';
import Attachment from '../../models/tables/Attachment';
import Grade from '../../models/tables/Grade';
import GradeCard from '../GradeCard/GradeCard';
import Expense from '../../models/tables/Expense';
import ExpenseCard from '../ExpenseCard/ExpenseCard';
import ArtifactComparison from '../ArtifactComparison/ArtifactComparison';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import './NotificationCard.scss';

export interface Props {
  id?: string;
  className?: string;
  record: NotificationSchema;
  disabled?: boolean;
  onSubtaskCardClick?: (record: TaskSchema, e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  onReadChange: (id: NotificationSchema['id'], read: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class NotificationCard extends React.Component<Props, State> {

  renderAddMembersContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: Partial<TaskSchema> = JSON.parse(action.content);
    const membersCount = parsed?.members?.length || 0;
    return (
      <p>
        {parsed.members && parsed.members.map((memberID, i) => {
          const member = lookup.users?.find(user => (user.id === memberID));
          return (
            <React.Fragment key={`add-members-${memberID}`}>
              <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
              {(membersCount > 1) && ((i + 1) < membersCount) && (
                <span>{((i + 1) === (membersCount - 1)) ? ' and ' : ', '}</span>
              )}
            </React.Fragment>
          );
        })}
        <span>{(membersCount > 1) ? ` were added as members of this ${Task.getLabel('singular')}.` : ` was added as a member of this ${Task.getLabel('singular')}.`}</span>
      </p>
    );
  }

  renderRemoveMembersContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: Partial<TaskSchema> = JSON.parse(action.content);
    const membersCount = parsed?.members?.length || 0;
    return (
      <p>
        {parsed.members && parsed.members.map((memberID, i) => {
          const member = lookup.users?.find(user => (user.id === memberID));
          return (
            <React.Fragment key={`remove-members-${memberID}`}>
              <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
              {(membersCount > 1) && ((i + 1) < membersCount) && (
                <span>{((i + 1) === (membersCount - 1)) ? ' and ' : ', '}</span>
              )}
            </React.Fragment>
          );
        })}
        <span>{(membersCount > 1) ? ` were removed as members of this ${Task.getLabel('singular')}.` : ` was removed as a member of this ${Task.getLabel('singular')}.`}</span>
      </p>
    );
  }

  renderAddRemoveMembersContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { add?: UserSchema['id'][], remove?: UserSchema['id'][] } | undefined = JSON.parse(action.content);
    const addedCount = parsed?.add?.length || 0;
    const removedCount = parsed?.remove?.length || 0;
    return (
      <p>
        {(addedCount > 0) && (
          <React.Fragment>
            {parsed?.add?.map((memberID, i) => {
              const member = lookup.users?.find(user => (user.id === memberID));
              return (
                <React.Fragment key={`add-members-${memberID}`}>
                  <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
                  {(addedCount > 1) && ((i + 1) < addedCount) && (
                    <span>{((i + 1) === (addedCount - 1)) ? ' and ' : ', '}</span>
                  )}
                </React.Fragment>
              );
            })}
            <span>{(addedCount > 1) ? ` were added as members of this ${Task.getLabel('singular')}.` : ` was added as a member of this ${Task.getLabel('singular')}.`}</span>
          </React.Fragment>
        )}
        {((addedCount > 0) && (removedCount > 0)) && (
          <br />
        )}
        {(removedCount > 0) && (
          <React.Fragment>
            {parsed?.remove?.map((memberID, i) => {
              const member = lookup.users?.find(user => (user.id === memberID));
              return (
                <React.Fragment key={`remove-members-${memberID}`}>
                  <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
                  {(removedCount > 1) && ((i + 1) < removedCount) && (
                    <span>{((i + 1) === (removedCount - 1)) ? ' and ' : ', '}</span>
                  )}
                </React.Fragment>
              );
            })}
            <span>{(removedCount > 1) ? ` were removed as members of this ${Task.getLabel('singular')}.` : ` was removed as a member of this ${Task.getLabel('singular')}.`}</span>
          </React.Fragment>
        )}
      </p>
    );
  }

  renderCreateContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: Partial<TaskSchema> = JSON.parse(action.content);
    return (
      <p>
        <span>{`This ${Task.getLabel('singular')} was created`}</span>
        {parsed.title && (
          <React.Fragment>
            <span>{' as '}</span>
            <strong>{parsed.title}</strong>
          </React.Fragment>
        )}
        <span>{'.'}</span>
      </p>
    );
  }

  getModelByBoard(board: string = 'peronal'): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  renderUpdateContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { assignedFrom?: string } & Partial<TaskSchema> = JSON.parse(action.content);
    delete parsed.id;
    delete parsed.assignedFrom;
    const changedFields = Object.keys(parsed) as (keyof TaskSchema)[];
    const task = (action.resourceType === 'task') ? lookup.tasks?.find(task => (task.id === action.resourceId)) : undefined;
    const taskModel = task ? this.getModelByBoard(task.board) : Task;
    return (
      <p>
        {(changedFields.length > 0) && changedFields.map((fieldName, i) => {
          const field = taskModel.getField<TaskSchema>(fieldName);
          const value = parsed[fieldName]/* ?.toString() */;
          return (
            <React.Fragment key={`update-${fieldName}`}>
              <strong>{field?.label || fieldName}</strong>
              {value ? (
                <React.Fragment>
                  <span>{' was changed to '}</span>
                  <strong>
                    {(! field) ? value : (
                      <ActionValueByField<TaskSchema> field={field} value={value} />
                    )}
                  </strong>
                </React.Fragment>
              ) : (
                <span>{' was removed'}</span>
              )}
              {(changedFields.length > 1) && ((i + 1) < changedFields.length) && (
                <span>{((i + 1) === (changedFields.length - 1)) ? ' and ' : ', '}</span>
              )}
            </React.Fragment>
          );
        })}
        <span>{'.'}</span>
      </p>
    );
  }

  renderArchiveContent(lookup: Lookup) {
    const { action } = this.props.record;
    const creator = lookup.users?.find(user => (user.id === action.createdBy));
    return (
      <p>
        <span>{`This ${Task.getLabel('singular')} was archived by `}</span>
        <strong>{creator ? User.getRecordLabel(creator) : action.createdBy}</strong>
        <span>{'.'}</span>
      </p>
    );
  }

  renderRestoreContent(lookup: Lookup) {
    return (
      <p>
        <span>{`This ${Task.getLabel('singular')} was restored.`}</span>
      </p>
    );
  }

  renderAddAttachmentsContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: AttachmentSchema[] = JSON.parse(action.content);
    return (
      <React.Fragment>
        <p>
          <span>{`${parsed.length} ${(parsed.length === 1) ? `${Attachment.getLabel('singular')} was` : `${Attachment.getLabel('plural')} were`} added.`}</span>
        </p>
        {parsed.map(attachment => (
          <AttachmentCard 
          key={attachment.id}
          variant="mini"
          className="fourg-notification-card__attachment" 
          record={attachment} />
        ))}
        {this.renderArtifactInfo('Showing the attachment in its current state.')}
      </React.Fragment>
    );
  }

  renderAddAttachmentContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { id: AttachmentSchema['id'] } = JSON.parse(action.content);
    const attachment = lookup.attachments?.find(attachment => (attachment.id === parsed.id));
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Attachment.getLabel('singular')} was added.`}</span>
        </p>
        {attachment && (
          <AttachmentCard 
          variant="mini"
          className="fourg-notification-card__attachment" 
          record={attachment} />
        )}
        {this.renderArtifactInfo('Showing the attachment in its current state.')}
      </React.Fragment>
    );
  }

  renderRemoveAttachmentContent(lookup: Lookup) { 
    const { action } = this.props.record;
    const parsed: AttachmentSchema = JSON.parse(action.content);
    const pathArray = parsed.path.split('/');
    const fileName = pathArray[(pathArray.length - 1)] || '';
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Attachment.getLabel('singular')} called `}</span>
          <strong>{parsed.title || fileName}</strong>
          <span>{` was removed from this ${Task.getLabel('singular')}.`}</span>
        </p>
      </React.Fragment>
    );
  }

  renderUpdateAttachmentContent(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { id: AttachmentSchema['id'] } = JSON.parse(action.content);
    const attachment = lookup.attachments?.find(attachment => (attachment.id === parsed.id));
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Attachment.getLabel('singular')} was edited.`}</span>
        </p>
        {attachment && (
          <AttachmentCard 
          variant="mini"
          className="fourg-notification-card__attachment" 
          record={attachment} />
        )}
        {this.renderArtifactInfo('Showing the attachment in its current state.')}
      </React.Fragment>
    );
  }

  renderComment(lookup: Lookup) {
    const { action } = this.props.record;
    return (
      <AuthContext.Consumer>
        {auth => (
          <Editor
          auth={auth}
          label="Comment"
          lookup={lookup}
          value={action.content}
          readOnly={true} />
        )}
      </AuthContext.Consumer>
    );
  }

  renderAddSubtask(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: { id: TaskSchema['id'] } = JSON.parse(record.action.content);
    const subtask = lookup.tasks?.find(task => (task.id === parsed.id));
    return (
      <React.Fragment>
        <p>
          <span>{`A subtask was added to this ${Task.getLabel('singular')}.`}</span>
        </p>
        {subtask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-notification-card__subtask"
          record={subtask} />
        )}
        {this.renderArtifactInfo('Showing the task in its current state.')}
      </React.Fragment>
    );
  }

  renderArchiveSubtask(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: { id: TaskSchema['id'] } = JSON.parse(record.action.content);
    const subtask = lookup.tasks?.find(task => (task.id.toString() === parsed.id.toString()));
    return (
      <React.Fragment>
        <p>
          <span>{'A subtask was archived.'}</span>
        </p>
        {subtask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-notification-card__subtask"
          record={subtask} />
        )}
        {this.renderArtifactInfo('Showing the task in its current state.')}
      </React.Fragment>
    );
  }

  renderRestoreSubtask(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: { id: TaskSchema['id'] } = JSON.parse(record.action.content);
    const subtask = lookup.tasks?.find(task => (task.id.toString() === parsed.id.toString()));
    return (
      <React.Fragment>
        <p>
          <span>{'A subtask was restored.'}</span>
        </p>
        {subtask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-notification-card__subtask"
          record={subtask} />
        )}
        {this.renderArtifactInfo('Showing the task in its current state.')}
      </React.Fragment>
    );
  }

  renderAssignParent(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: Partial<TaskSchema> = JSON.parse(record.action.content);
    const parentTask = lookup.tasks?.find(task => (task.id === parsed.parentId));
    return (
      <React.Fragment>
        <p>
          <span>{`This ${Task.getLabel('singular')} was made a subtask of another ${Task.getLabel('singular')}.`}</span>
        </p>
        {parentTask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-notification-card__subtask"
          record={parentTask} />
        )}
        {this.renderArtifactInfo('Showing the task in its current state.')}
      </React.Fragment>
    );
  }

  renderAddReminder(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { id: ReminderSchema['id'] } = JSON.parse(action.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')} was added to this ${Task.getLabel('singular')}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderDeleteReminder(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { id: ReminderSchema['id'] } = JSON.parse(action.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')} was removed from this ${Task.getLabel('singular')}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderToggleReminder(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { id: ReminderSchema['id'], completed: ReminderSchema['completed'] } = JSON.parse(action.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')} was marked as ${parsed.completed ? 'complete' : 'incomplete'}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderReminderLapsed(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { id: ReminderSchema['id'] } = JSON.parse(action.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A reminder from a ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderRemoveSelf(lookup: Lookup) {
    const { action } = this.props.record;
    const task = lookup.tasks?.find(task => (task.id === action.resourceId));
    if (task) {
      const model = this.getModelByBoard(task.board);
      const assignedToField = model.getField<TaskSchema>('assignedTo');
      const parsed: RemoveSelfActionContent = JSON.parse(action.content);
      const user = lookup.users?.find(user => (user.id === action.createdBy));
      return (
        <p>
          {parsed.removedMember && (
            <React.Fragment>
              <strong>{User.getRecordLabel(user)}</strong>
              {(assignedToField && (parsed.assignedTo !== undefined)) ? (
                <span>{` was removed as a member of this ${Task.getLabel('singular')}, and `}</span>
              ) : (
                <span>{` was removed as a member of this ${Task.getLabel('singular')}.`}</span>
              )}
            </React.Fragment>
          )}
          {(assignedToField && (parsed.assignedTo !== undefined)) && (
            <React.Fragment>
              {parsed.assignedTo ? (
                <React.Fragment>
                  <strong>{assignedToField.label}</strong>
                  <span>{' was changed to '}</span>
                  <strong>
                    <ActionValueByField<TaskSchema> field={assignedToField} value={parsed.assignedTo} />
                    {'.'}
                  </strong>
                </React.Fragment>
              ) : (
                <span><strong>{assignedToField.label}</strong> was removed.</span>
              )}
            </React.Fragment>
          )}
          
        </p>
      );
    }
  }

	renderAddGrade(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: GradeSchema = JSON.parse(action.content);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${Grade.getLabel('singular')} was added to this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        {parsed && (
          <GradeCard
          className="fourg-comment-card__grade"
          record={parsed} />
        )}
        {this.renderArtifactInfo('Showing the state of the grade at the time it was added.')}
      </React.Fragment>
    );
  }

	renderUpdateGrade(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { before: GradeSchema, after: GradeSchema } = JSON.parse(action.content);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${Grade.getLabel('singular')} was updated in this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <ArtifactComparison
				before={(
					<GradeCard
					className="fourg-comment-card__grade"
					record={parsed.before} />
				)}
				after={(
					<GradeCard
					className="fourg-comment-card__grade"
					record={parsed.after} />
				)} />
        {this.renderArtifactInfo('Showing the state of the grade as "before" → "after" at the time it was updated.')}
      </React.Fragment>
    );
  }

	renderRemoveGrade(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { before: GradeSchema } = JSON.parse(action.content);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${Grade.getLabel('singular')} was removed from this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <GradeCard
				className="fourg-comment-card__expense"
				record={parsed.before} />
        {this.renderArtifactInfo('Showing the state of the grade at the time it was removed.')}
      </React.Fragment>
    );
  }

	renderAddExpense(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: ExpenseSchema = JSON.parse(action.content);
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Expense.getLabel('singular')} was added to this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        {parsed && (
          <ExpenseCard
          className="fourg-comment-card__expense"
          record={parsed} />
        )}
        {this.renderArtifactInfo('Showing the state of the expense at the time it was added.')}
      </React.Fragment>
    );
  }

	renderUpdateExpense(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { before: ExpenseSchema, after: ExpenseSchema } = JSON.parse(action.content);
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Expense.getLabel('singular')} was updated in this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <ArtifactComparison
				before={(
					<ExpenseCard
					className="fourg-comment-card__expense"
					record={parsed.before} />
				)}
				after={(
					<ExpenseCard
					className="fourg-comment-card__expense"
					record={parsed.after} />
				)} />
        {this.renderArtifactInfo('Showing the state of the expense as "before" → "after" at the time it was updated.')}
      </React.Fragment>
    );
  }

	renderRemoveExpense(lookup: Lookup) {
    const { action } = this.props.record;
    const parsed: { before: ExpenseSchema } = JSON.parse(action.content);
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Expense.getLabel('singular')} was removed from this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <ExpenseCard
				className="fourg-comment-card__expense"
				record={parsed.before} />
        {this.renderArtifactInfo('Showing the state of the expense at the time it was removed.')}
      </React.Fragment>
    );
  }

	renderCieTradePush(lookup: Lookup) {
    return (
			<p>
				<span>{`This ${Task.getLabel('singular')}'s ${Company.getLabel('singular')} and ${Location.getLabel('singular')} were pushed to cieTrade.`}</span>
			</p>
    );
  }

  renderContentByAction(lookup: Lookup) {
    const { action } = this.props.record;
    switch (action.action) {
      case 'create': return this.renderCreateContent(lookup);
      case 'update': return this.renderUpdateContent(lookup);
      case 'archive': return this.renderArchiveContent(lookup);
      case 'restore': return this.renderRestoreContent(lookup);
      case 'add-remove-members': return this.renderAddRemoveMembersContent(lookup);
      case 'add-members': return this.renderAddMembersContent(lookup);
      case 'remove-members': return this.renderRemoveMembersContent(lookup);
      case 'add-attachments': return this.renderAddAttachmentsContent(lookup);
      case 'add-attachment': return this.renderAddAttachmentContent(lookup);
      case 'update-attachment': return this.renderUpdateAttachmentContent(lookup);
      case 'remove-attachment': return this.renderRemoveAttachmentContent(lookup);
      case 'comment': return this.renderComment(lookup);
      case 'add-subtask': return this.renderAddSubtask(lookup);
      case 'archive-subtask': return this.renderArchiveSubtask(lookup);
      case 'restore-subtask': return this.renderRestoreSubtask(lookup);
      case 'add-reminder': return this.renderAddReminder(lookup);
      case 'delete-reminder': return this.renderDeleteReminder(lookup);
      case 'toggle-reminder': return this.renderToggleReminder(lookup);
      case 'reminder-lapsed': return this.renderReminderLapsed(lookup);
      case 'remove-self': return this.renderRemoveSelf(lookup);
      case 'add-grade': return this.renderAddGrade(lookup);
      case 'update-grade': return this.renderUpdateGrade(lookup);
      case 'remove-grade': return this.renderRemoveGrade(lookup);
      case 'add-expense': return this.renderAddExpense(lookup);
      case 'update-expense': return this.renderUpdateExpense(lookup);
      case 'remove-expense': return this.renderRemoveExpense(lookup);
      case 'cietrade-push': return this.renderCieTradePush(lookup);
      default: return action.content;
    }
  }

  getTitle(lookup: Lookup) {
    const { action } = this.props.record;
    const creator = lookup.users?.find(user => (user.id === action.createdBy));
    const creatorName = creator ? User.getRecordLabel(creator) : 'System Message';
    const verb = creator ? 'updated' : 'from';
    switch(action.resourceType) {
      case 'task': 
        const task = lookup.tasks?.find(task => (task.id === action.resourceId));
        return (
          <React.Fragment>
            <strong>{creatorName}</strong>
            <span> {verb} </span>
            {task && (
              <NotificationsContext.Consumer>
                {({ closeDialog }) => (
                  <Link to={`/tasks/${task.id}`} onClick={closeDialog}>{Task.getRecordLabel(task)}</Link>
                )}
              </NotificationsContext.Consumer>
            )}
          </React.Fragment>
        );
      default: return creatorName;
    }
  }

  renderArtifactInfo(message: string) {
    return (
      <div className="fourg-notification-card__info">
        <Icon src={{ icon: 'info' }} label={'Info'} />
        <p>{message}</p>
      </div>
    );
  }

  render() {
    const { disabled, record, className, onReadChange, onSubtaskCardClick, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-notification-card', `fourg-notification-card--action-${record.action.action}`, {
      'fourg-notification-card--read': record.read,
    }, className);
    return (
      <LookupContext.Consumer>
        {lookup => {
          const creator = lookup.users?.find(user => (user.id === record.action.createdBy));
          const creatorName = creator ? User.getRecordLabel(creator) : 'System Message';
          const avatar = creator ? User.getRecordImage(creator) : undefined;
          return (
            <div className={containerClass} {...restProps}>
              <Icon 
              className="fourg-notification-card__avatar"
              label={creatorName} 
              src={{ 
                icon: creator ? 'account_circle' : 'notifications', 
                cover: avatar, 
              }} />
              <div className="fourg-notification-card__content">
                <div className="fourg-notification-card__header">
                  <h3 className="fourg-notification-card__title">{this.getTitle(lookup)}</h3>
                  <span className="fourg-notification-card__date">
                    {new Date(record.created).toLocaleString(undefined, {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric', 
                      minute: '2-digit',
                    })}
                  </span>
                </div>
                <div className="fourg-notification-card__body">
                  {this.renderContentByAction(lookup)}
                </div>
              </div>
              <Button
              disabled={disabled}
              className="fourg-notification-card__action"
              onClick={e => onReadChange(record.id, !record.read, e)}
              isIconOnly={true}
              icon={{ icon: record.read ? 'drafts' : 'markunread' }}>
                {record.read ? 'Mark as Unread' : 'Mark as Read'}
              </Button>
            </div>
          );
        }}
      </LookupContext.Consumer>
    );
  }
}

export default NotificationCard;
