import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import { Breadcrumb } from '../../types';
import './Breadcrumbs.scss';

export interface Props {
  id?: string;
  className?: string;
  items: Breadcrumb[];
}

export interface State {

}

class Breadcrumbs extends React.Component<Props, State> {

  static defaultProps = {
    items: [],
  };

  render() {
    const { items, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-breadcrumbs', className);
    return (
      <nav className={containerClass} {...restProps}>
        {(items.length > 0) && (
          <ul className="fourg-breadcrumbs__items">
            {items.map((item, i) => (
              <li key={`breadcrumb-item-${i}`} className="fourg-breadcrumbs__item">
                {item.to ? (
                  <Link className="fourg-breadcrumbs__action" to={item.to}>{item.label}</Link>
                ) : (
                  <span className="fourg-breadcrumbs__action">{item.label}</span>
                )}
                {(i !== (items.length - 1)) && (
                  <Icon
                  className="fourg-breadcrumbs__sep" 
                  src={{ icon: 'keyboard_arrow_right' }}
                  label={item.label} />
                )}
              </li>
            ))}
          </ul>
        )}
      </nav>
    );
  }
}

export default Breadcrumbs;
