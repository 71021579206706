import React from 'react';
import classNames from 'classnames';
import Attachment from '../../models/tables/Attachment';
import { AttachmentSchema } from '../../types';
import './AttachmentCard.scss';
import MediaIcon from '../MediaIcon/MediaIcon';
import Icon from '../Icon/Icon';
import placeholderURL from '../../assets/images/placeholder.jpg';
import ActionToggle, { Props as ActionToggleProps } from '../ActionToggle/ActionToggle';

export interface Props {
  id?: string;
  className?: string;
  variant?: 'default' | 'mini' | 'card',
  record: AttachmentSchema;
  disabled?: boolean;
  onActionsChange?: ActionToggleProps['onChange'];
}

export interface State {

}

class AttachmentCard extends React.Component<Props, State> {

  static defaultProps = {
    variant: 'default',
  };

  getFileName() {
    const { record } = this.props;
    const pathArray = record.path.split('/');
    return pathArray[(pathArray.length - 1)] || '';
  }

  getFileSuffix() {
    const fileName = this.getFileName();
    const nameArray = fileName.split('.');
    return nameArray[(nameArray.length - 1)] || '';
  }

  renderImage() {
    const { record } = this.props;
    const src = Attachment.getS3URL(record.path);
    return (
      <a 
      className="fourg-attachment-card__media fourg-attachment-card__media--type-image"
      style={{ backgroundImage: `url('${src}')`}}
      href={src}
      target="_blank"
      rel="nofollow noopener noreferrer">
        <span>{Attachment.getLabel('viewSingular')}</span>
      </a>
    );
  }

  renderVideo() {
    const { record, variant } = this.props;
    const src = Attachment.getS3URL(record.path);
    return (variant === 'mini') ? (
      <a
      className="fourg-attachment-card__media fourg-attachment-card__media--type-video"
      href={src}
      target="_blank"
      rel="nofollow noopener noreferrer">
        <Icon src={{ icon: 'play_circle_outline', cover: placeholderURL }} label={record.title || this.getFileName()} />
      </a>
    ) : (
      <div className="fourg-attachment-card__media fourg-attachment-card__media--type-video">
        <video 
        preload="metadata"
        controls={true}>
          <source src={src} type={record.contentType} />
          <span>{`Your browser does not support video as ${record.contentType}`}</span>
        </video>
      </div>
    );
  }

  renderAudio() {
    const { record, variant } = this.props;
    const src = Attachment.getS3URL(record.path);
    return (variant === 'mini') ? (
      <a
      className="fourg-attachment-card__media fourg-attachment-card__media--type-audio"
      href={src}
      target="_blank"
      rel="nofollow noopener noreferrer">
        <Icon src={{ icon: 'volume_up', cover: placeholderURL }} label={record.title || this.getFileName()} />
      </a>
    ) : (
      <div className="fourg-attachment-card__media fourg-attachment-card__media--type-audio">
        <audio
        preload="metadata"
        controls={true}>
          <source src={src} type={record.contentType} />
          <span>{`Your browser does not support audio as ${record.contentType}`}</span>
        </audio>
      </div>
    );
  }

  renderFile() {
    const { record } = this.props;
    const src = Attachment.getS3URL(record.path);
    return (
      <a 
      className="fourg-attachment-card__media fourg-attachment-card__media--type-file"
      style={{ backgroundImage: `url('${src}')`}}
      href={src}
      target="_blank"
      rel="nofollow noopener noreferrer">
        <MediaIcon type={this.getFileSuffix()} /> 
      </a>
    );
  }

  renderMediaByType() {
    const { record } = this.props;
    const typeBase = record.contentType.split('/')[0];
    switch (typeBase) {
      case 'audio': return this.renderAudio();
      case 'video': return this.renderVideo();
      case 'image': return this.renderImage();
      default: return this.renderFile();
    }
  }

  isBrowserViewable() {
    const { record } = this.props;
    const typeBase = record.contentType.split('/')[0];
    switch (typeBase) {
      case 'audio': return true;
      case 'video': return true;
      case 'image': return true;
      default: return (record.contentType === 'application/pdf') ? true : false;
    }
  }

  renderDescription() {
    const { record } = this.props;
    const dateString = new Date(record.created).toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
    return (
      <div className="fourg-attachment-card__description">
        {record.description?.trim() || `Added ${dateString}`}
      </div>
    );
  }

  getTitle() {
    const { record } = this.props;
    return record.title || this.getFileName();
  }

  renderHeader() {
    const { disabled, record, onActionsChange } = this.props;
    const url = Attachment.getS3URL(record.path);
    return (
      <div className="fourg-attachment-card__header">
        <a
        className="fourg-attachment-card__title-link"
        href={url}
        target="_blank"
        rel="nofollow noopener noreferrer">
          <h4 className="fourg-attachment-card__title">{this.getTitle()}</h4>
        </a>
        {onActionsChange && (
          <ActionToggle
          disabled={disabled}
          className="fourg-attachment-card__actions"
          anchor="top-right"
          variant="mini"
          icon={{ icon: 'more_vert' }}
          isIconOnly={true}
          label={'Attachment Actions'}
          value=""
          onChange={onActionsChange}
          options={[
            { value: 'edit', label: 'Edit' },
            { value: 'delete', label: 'Delete' },
          ]} />
        )}
      </div>
    );
  }

  render() {
    const { onActionsChange, record, className, children, variant, disabled, ...restProps } = this.props;
    const containerClass = classNames('fourg-attachment-card', `fourg-attachment-card--variant-${variant}`, className);
    const url = Attachment.getS3URL(record.path);
    return (
      <figure className={containerClass} {...restProps}>
        {(variant === 'default') && this.renderHeader()}
        {this.renderMediaByType()}
        <figcaption className="fourg-attachment-card__content">
          {(variant === 'default') ? (
            <React.Fragment>
              {this.renderDescription()}
              <div className="fourg-attachment-card__links">
                {this.isBrowserViewable() && (
                  <a
                  href={url}
                  target="_blank"
                  rel="nofollow noopener noreferrer">
                    {Attachment.getLabel('viewSingular')}
                  </a>
                )}
                <a
                download={this.getFileName()}
                href={url}>
                  {`Download ${Attachment.getLabel('singular')}`}
                </a>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.renderHeader()}
              {this.renderDescription()}
            </React.Fragment>
          )}
        </figcaption>
      </figure>
    );
  }
}

export default AttachmentCard;
