import React from 'react';
import classNames from 'classnames';
import { Props as IconProps } from '../Icon/Icon';
import SelectInput from '../SelectInput/SelectInput';
import Table from '../../models/Table';
import { UIOption, Auth, Lookup, ReadRecordsQuery } from '../../types';
import './LookupInputNew.scss';

export interface Props<T extends Record<string, any> = Record<string, any>>  {
  id?: string;
  auth: Auth;
  lookup: Lookup;
  className?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
	query?: ReadRecordsQuery;
  model: typeof Table;
  value: string;
  icon?: IconProps['src'];
  options: UIOption[];
  onChange: (value: string, e?: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
	isOptionDisabled: (record: T) => boolean;
}

export interface State<T extends Record<string, any> = Record<string, any>> {
  records: T[];
}

class LookupInputNew<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State<T>> {

	private selectInput = React.createRef<SelectInput>();

  static defaultProps = {
    options: [],
    value: '',
    onChange: console.info,
		isOptionDisabled: () => false,
  };

  constructor(props: Props<T>) {
    super(props);
		this.handleLoadOptions = this.handleLoadOptions.bind(this);
    this.state = {
			records: [],
		};
  }

	handleLoadOptions(inputValue: string): Promise<UIOption[]> {
		return this.readRecords(inputValue);
	}

  async readRecords(search: string): Promise<UIOption[]> {
    const { auth, model, query } = this.props;
		if (search) {
			try {
				const token = await auth.getToken();
				const { data } = await model.readRecords<T>(token, {
					...query,
					search: search,
				});
				this.setState({ records: data });
				return this.parseOptions(data);
			} catch (error) {
				console.warn(error);
				this.setState({ records: [] });
				return [];
			}
		} else {
			return Promise.resolve<UIOption[]>(this.getOptions());
		}
  }

	parseOptions(records: T[]): UIOption[] {
		return records.map(record => this.parseOption(record));
	}

  parseOption(record: T): UIOption {
    const { model, isOptionDisabled } = this.props;
    const { icon } = model.getOptions<T>();
    const option: UIOption = {
      value: model.getRecordValue<T>(record).toString(),
      label: model.getRecordLabel<T>(record),
			disabled: isOptionDisabled(record),
      icon: {
        icon: icon,
        cover: model.getRecordImage<T>(record),
      },
    };
    return option;
  }

	getInitialRecord(): T | undefined {
    const { model, lookup, value } = this.props;
    const modelOptions = model.getOptions();
    const lookupRecords: T[] = (modelOptions.lookupKey ? lookup[modelOptions.lookupKey] || [] : []) as any as T[];
    const record = lookupRecords.find(record => model.getRecordValue<T>(record).toString() === value);
    return record;
  }

	getOptions(): UIOption[] {
		const { model, options } = this.props;
		const { records } = this.state;
		const newOptions: UIOption[] = [...options];
		const initialRecord = this.getInitialRecord();
		if (initialRecord && (records.findIndex(r => (model.getRecordValue<T>(r) === model.getRecordValue<T>(initialRecord))) === -1)) {
			newOptions.push(this.parseOption(initialRecord));
		}
		newOptions.push(...this.parseOptions(records));
		return newOptions;
	}

	resetValidity() {
		const selectInput = this.selectInput.current;
		selectInput?.resetValidity();
	}

  render() {
    const { name, required, readOnly, auth, lookup, options, label, value, model, disabled, onChange, onBlur, className, children, isOptionDisabled, ...restProps } = this.props;
    const containerClass = classNames('fourg-lookup-input-new', className);
		const modelOptions = model.getOptions();
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-lookup-input-new__content">
          <SelectInput
					ref={this.selectInput}
					name={name}
					onLoadOptions={this.handleLoadOptions}
					required={required}
					readOnly={readOnly}
					disabled={disabled}
					label={label}
					value={value}
					icon={{ icon: modelOptions.icon }}
					options={this.getOptions()}
					loadingMessage={() => model.getLabel('loadingPluralEllipsis')}
					noOptionsMessage={(data) => data.inputValue ? model.getLabel('notFoundPlural') : model.getLabel('searchEllipsis')}
					onChange={onChange} />
        </div>
      </div>
    );
  }
}

export default LookupInputNew;
