import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import User from '../../models/tables/User';
import { UserSchema } from '../../types';
import './UserCard.scss';
import Button from '../Button/Button';

export interface Props {
  id?: string;
  className?: string;
  record: UserSchema;
  disabled?: boolean;
  onRemove?: (record: UserSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class UserCard extends React.Component<Props, State> {

  render() {
    const { disabled, record, onRemove, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-user-card', className);
    const modelOptions = User.getOptions<UserSchema>();
    const fullName = User.getRecordLabel(record);
    return (
      <div className={containerClass} {...restProps}>
        <Icon 
        className="fourg-user-card__avatar"
        label={fullName}
        src={{ 
          icon: modelOptions.icon, 
          cover: User.getRecordImage<UserSchema>(record)
        }} />
        <h3 className="fourg-user-card__name">{fullName}</h3>
        {onRemove && (
          <Button
          disabled={disabled}
          className="fourg-user-card__remove"
          variant="mini"
          isIconOnly={true}
          icon={{ icon: 'close' }}
          onClick={e => onRemove(record, e)}>
            {'Remove'}
          </Button>
        )}
      </div>
    );
  }
}

export default UserCard;
