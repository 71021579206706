import React from 'react';
import classNames from 'classnames';
import { mergeRecords } from '../../utils';
import { toast } from 'react-toastify';
import { Props as IconProps } from '../Icon/Icon';
import SelectInput from '../SelectInput/SelectInput';
import Table from '../../models/Table';
import { UIOption, Auth, Lookup, ReadRecordsQuery, CompanySchema, ContactSchema, LocationSchema, ReadRecordsResponse, BoardTaskMetaRecord } from '../../types';
import InputDescription from '../InputDescription/InputDescription';
import ResourceSectionQuickAddFormDialog from '../ResourceSectionQuickAddFormDialog/ResourceSectionQuickAddFormDialog';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import './LookupResourceSectionInputNew.scss';

export type sectionRecordSchema = LocationSchema | ContactSchema;
export interface LookupResourceSectionInputNewMeta {
	tasks?: BoardTaskMetaRecord[];
}

export interface Props {
  id?: string;
  auth: Auth;
  lookup: Lookup;
  className?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
	query?: ReadRecordsQuery;
  model: typeof Table;
  value: string;
  icon?: IconProps['src'];
  options: UIOption[];
	resourceID?: CompanySchema['id'] | LocationSchema['id'];
	resourceModel: typeof Company | typeof Location;
  onChange: (value: string, e?: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
	isOptionDisabled: (record: sectionRecordSchema) => boolean;
}

export interface State {
	isLoading: boolean;
	isCreating: boolean;
  records: sectionRecordSchema[];
	meta: LookupResourceSectionInputNewMeta;
	isQuickAddFormDialogOpen: boolean;
}

class LookupResourceSectionInputNew extends React.Component<Props, State> {

	private selectInput = React.createRef<SelectInput>();

  static defaultProps = {
    options: [],
    value: '',
    onChange: console.info,
		isOptionDisabled: () => false,
  };

  constructor(props: Props) {
    super(props);
		this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
		this.handleQuickAddDialogClose = this.handleQuickAddDialogClose.bind(this);
		this.handleQuickAddDialogSubmit = this.handleQuickAddDialogSubmit.bind(this);
    this.state = {
			isLoading: false,
			isCreating: false,
			records: [],
			meta: {},
			isQuickAddFormDialogOpen: false,
		};
  }

	componentDidMount() {
		this.readRecords();
	}

	componentDidUpdate(prevProps: Props) {
    const { resourceID, query } = this.props;
    if ((prevProps.resourceID !== resourceID)
    || (prevProps.query?.board !== query?.board)
    || (prevProps.query?.limit !== query?.limit)
    || (prevProps.query?.filter !== query?.filter)
    || (prevProps.query?.order !== query?.order)) {
      if (resourceID) {
        this.readRecords();
      } else {
        this.setState({
          records: [],
					meta: {},
        });
      }
    }
  }

	async readRecordsByModel(token: string, id: CompanySchema['id'], query: ReadRecordsQuery): Promise<ReadRecordsResponse<sectionRecordSchema>> {
    const { model, resourceModel } = this.props;
		const modelOptions = model.getOptions();
		const resourceModelOptions = resourceModel.getOptions();
		if ((modelOptions.name === 'Location') && (resourceModelOptions.name === 'Company')) {
			return await (resourceModel as typeof Company).readLocations(token, id, query);
		} else if (modelOptions.name === 'Contact') {
			return await resourceModel.readContacts(token, id, query);
		} else {
			return await model.readRecords<sectionRecordSchema>(token, query);
		}
  }

  async readRecords() {
    const { model, auth, query, resourceID } = this.props;
		if (resourceID) {
			this.setState({ isLoading: true });
			try {
				const { defaultOrder, defaultFilter } = model.getOptions<sectionRecordSchema>();
				const token = await auth.getToken();
				const { data, meta } = await this.readRecordsByModel(token, resourceID, {
					order: defaultOrder,
          filter: defaultFilter,
					...query,
				});
				this.setState({
					isLoading: false,
					records: data,
					meta: {
						tasks: meta.boardTasks?.tasks || [],
					},
				}, () => this.setValueAfterRead());
			} catch (error) {
				console.warn(error);
				this.setState({
					isLoading: false,
					records: [],
					meta: {
						tasks: [],
					},
				}, () => this.setValueAfterRead());
				return [];
			}
		}
  }

	async createRecord(record: sectionRecordSchema, isQuickAddFormDialogOpen: boolean = false) {
    const { auth, model, onChange } = this.props;
    const { records } = this.state;
    this.setState({ isCreating: true });
    try {
      const token = await auth.getToken();
      const { data } = await model.createRecord<sectionRecordSchema>(token, record);
			const newValue = model.getRecordValue<sectionRecordSchema>(data).toString();
      this.setState({
        isCreating: false,
        isQuickAddFormDialogOpen: isQuickAddFormDialogOpen,
        records: mergeRecords([data], records),
      }, () => onChange(newValue));
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isCreating: false });
    }
  }

	setValueAfterRead() {
		const { model, value, onChange } = this.props;
		const { records } = this.state;
		const modelOptions = model.getOptions<sectionRecordSchema>();
		if (modelOptions.name === 'Location' && ! value) {
			const primaryLocation = records.find(record => (record as LocationSchema).primary) as LocationSchema | undefined;
			const newValue = (primaryLocation && ! this.isOptionDisabled(primaryLocation)) ? model.getRecordValue<LocationSchema>(primaryLocation) : '';
			onChange(newValue.toString());
		} else {
			onChange(value);
		}
	}

	isOptionDisabled(record: sectionRecordSchema): boolean {
		const { model } = this.props;
		const { meta } = this.state;
		const modelOptions = model.getOptions();
		if (modelOptions.name === 'Location') {
			const locationRecord = record as LocationSchema;
			const found = meta.tasks?.find((t) => t.locationId === locationRecord.id);
			return found ? true : false;
		}
		return false;
	}

	parseOptions(records: sectionRecordSchema[]): UIOption[] {
		return records.map(record => this.parseOption(record));
	}

  parseOption(record: sectionRecordSchema): UIOption {
    const { model, isOptionDisabled } = this.props;
    const { icon } = model.getOptions<sectionRecordSchema>();
    const option: UIOption = {
      value: model.getRecordValue<sectionRecordSchema>(record).toString(),
      label: model.getRecordLabel<sectionRecordSchema>(record),
			disabled: this.isOptionDisabled(record) || isOptionDisabled(record),
      icon: {
        icon: icon,
        cover: model.getRecordImage<sectionRecordSchema>(record),
      },
    };
    return option;
  }

	getInitialRecord(): sectionRecordSchema | undefined {
    const { model, lookup, value } = this.props;
    const modelOptions = model.getOptions();
    const lookupRecords: sectionRecordSchema[] = (modelOptions.lookupKey ? lookup[modelOptions.lookupKey] || [] : []) as any as sectionRecordSchema[];
    const record = lookupRecords.find(record => model.getRecordValue<sectionRecordSchema>(record).toString() === value);
    return record;
  }

	getOptions(): UIOption[] {
		const { model, options } = this.props;
		const { records } = this.state;
		const newOptions: UIOption[] = [...options];
		const initialRecord = this.getInitialRecord();
		if (initialRecord && (records.findIndex(r => (model.getRecordValue<sectionRecordSchema>(r) === model.getRecordValue<sectionRecordSchema>(initialRecord))) === -1)) {
			newOptions.push(this.parseOption(initialRecord));
		}
		newOptions.push(...this.parseOptions(records));
		return newOptions;
	}

	resetValidity() {
		const selectInput = this.selectInput.current;
		selectInput?.resetValidity();
	}

	handleAddButtonClick() {
		this.setState({ isQuickAddFormDialogOpen: true });
	}

	handleQuickAddDialogClose() {
    this.setState({ isQuickAddFormDialogOpen: false });
  }

  handleQuickAddDialogSubmit(record: sectionRecordSchema) {
    this.createRecord(record);
  }

  render() {
    const { resourceID, name, required, readOnly, auth, lookup, options, label, value, model, disabled, onChange, onBlur, className, children, isOptionDisabled, resourceModel, ...restProps } = this.props;
    const { isLoading, isQuickAddFormDialogOpen, isCreating } = this.state;
		const containerClass = classNames('fourg-lookup-resource-section-input-new', className);
		const modelOptions = model.getOptions();
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-lookup-resource-section-input-new__content">
          <SelectInput
					ref={this.selectInput}
					name={name}
					required={required}
					readOnly={readOnly}
					disabled={disabled || ! resourceID || isLoading}
					label={label}
					value={value}
					icon={{ icon: modelOptions.icon }}
					options={this.getOptions()}
					isLoading={isLoading}
					loadingMessage={() => model.getLabel('loadingPluralEllipsis')}
					noOptionsMessage={(data) => data.inputValue ? model.getLabel('notFoundPlural') : model.getLabel('searchEllipsis')}
					onChange={onChange} />
        </div>
				{modelOptions.hasQuickAdd && (
          <InputDescription disabled={(! resourceID || disabled || isQuickAddFormDialogOpen || isLoading)}>
            <span>{`Don't see the ${model.getLabel('singular').toLowerCase()} listed? `}</span>
            <button
            className="fourg-lookup-resource-section-input-new__add-button"
            onClick={this.handleAddButtonClick}
            type="button"
            disabled={(! resourceID || disabled || isQuickAddFormDialogOpen || isLoading)}>
              {'Add a new one'}
            </button>
          </InputDescription>
        )}
				{(resourceID && modelOptions.hasQuickAdd) && (
          <ResourceSectionQuickAddFormDialog
					model={model}
          resourceID={resourceID}
					resourceModel={resourceModel}
          disabled={(disabled || isCreating)}
          auth={auth}
          submitLabel={'Save'}
          cancelLabel={'Cancel'}
          title={model.getLabel('addSingular')}
          isOpen={isQuickAddFormDialogOpen}
          onFormCancel={this.handleQuickAddDialogClose}
          // onBackdropClick={this.handleQuickAddDialogClose}
          onEscape={this.handleQuickAddDialogClose}
          onCloseClick={this.handleQuickAddDialogClose}
          onFormSubmit={record => this.handleQuickAddDialogSubmit(record)} />
        )}
      </div>
    );
  }
}

export default LookupResourceSectionInputNew;
