import Task from '../Task';
import { TableOptions } from '../../../types';

class PersonalTask extends Task {

  static options: TableOptions = {
    name: 'PersonalTask',
    slug: 'tasks',
    labelKey: 'title',
    valueKey: 'id',
    lookupKey: 'tasks',
    icon: 'assignment',
    defaultOrder: '-created',
  };

}

export default PersonalTask;
