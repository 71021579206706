import React from 'react';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import IconMessage from '../IconMessage/IconMessage';
import Form from '../Form/Form';
import Table from '../Table/Table';
import Badge from '../Badge/Badge';
import DateSpan from '../DateSpan/DateSpan';
import ModelRecordInfo from '../ModelRecordInfo/ModelRecordInfo';
import CieTradeLog from '../../models/tables/CieTradeLog';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import User from '../../models/tables/User';
import { CieTradeLogEntrySchema, CieTradeLogSchema, TableColumn } from '../../types';
import './CieTradeLogDialog.scss';

export interface Props extends Omit<DialogProps, 'size'> {
	disabled?: boolean;
	record?: CieTradeLogSchema;
	onCancelClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class CieTradeLogDialog extends React.Component<Props, State> {

  static defaultProps = {
    ...Dialog.defaultProps,
		onCancelClick: console.info,
  };

	constructor(props: Props) {
		super(props);
		this.handleEntryRowActionsChange = this.handleEntryRowActionsChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	renderEntryValue(value: any, column: TableColumn<CieTradeLogEntrySchema>, record: CieTradeLogEntrySchema) {
		switch (column.key) {
			case 'companyId': return (
				<ModelRecordInfo
				model={Company}
				value={value} />
			);
			case 'locationId': return (
				<ModelRecordInfo
				model={Location}
				value={value} />
			);
			case 'status': return (value === 'success') ? (
				<Badge color="#4CAF50">{'Success'}</Badge>
			) : (
				<Badge color="#B00020">{'Failure'}</Badge>
			);
			case 'created': return (
				<DateSpan date={new Date(value)} hasTime={true} />
			)
			default: return (
				<span>{value}</span>
			);
		}
	}

	handleEntryRowActionsChange(value: string, record: CieTradeLogEntrySchema) {
		switch (value) {
			case 'copy-data':
				const obj = JSON.parse(record.responseData);
				copy(JSON.stringify(obj, null, '\t'), {
					format: 'text/plain',
					onCopy: () => toast.success('Log entry data copied'),
				});
				break;
		}
	}

	handleFormSubmit() {
		const { record } = this.props;
		if (record) {
			copy(record.id, {
				format: 'text/plain',
				onCopy: () => toast.success('Log ID copied'),
			});
		}
	}

  render() {
    const { isOpen, disabled, record, className, onCancelClick, ...restProps } = this.props;
    const containerClass = classNames('fourg-cietrade-log-dialog', className);
    return (
      <Dialog
			className={containerClass}
			isOpen={isOpen}
			{...restProps}
			size="large">
				{record ? (
					<div className="fourg-cietrade-log-dialog__content">
						<div className="fourg-cietrade-log-dialog__header">
							<div className="fourg-cietrade-log-dialog__log-meta">
								<div className="fourg-cietrade-log-dialog__log-meta-node">
									<span className="fourg-cietrade-log-dialog__log-meta-node-label">{'Log ID:'}</span>
									<code>{record.id}</code>
								</div>
								<div className="fourg-cietrade-log-dialog__log-meta-node">
									<span className="fourg-cietrade-log-dialog__log-meta-node-label">{'Action:'}</span>
									<span>{CieTradeLog.getFieldOptionLabel('action', record.action)}</span>
								</div>
								<div className="fourg-cietrade-log-dialog__log-meta-node">
									<span className="fourg-cietrade-log-dialog__log-meta-node-label">{'Performed by:'}</span>
									<ModelRecordInfo
									model={User}
									value={record.user} />
								</div>
							</div>
						</div>
						<Table<CieTradeLogEntrySchema>
						columns={[
							{ key: 'text', label: 'Message' },
							{ key: 'companyId', label: 'Company' },
							{ key: 'locationId', label: 'Location' },
							{ key: 'status', label: 'Status' },
							{ key: 'created', label: 'Created' },
						]}
						records={record.entries}
						notFoundHeading={'No log entries found'}
						rowRadioName={`selected-cietrade-log-entry`}
						getRowWarnings={entry => (entry.status !== 'success') ? [`The "${entry.text}" action failed.`] : []}
						getRowActions={entry => [
							{ value: 'copy-data', label: 'Copy Data' },
						]}
						onRowActionsChange={this.handleEntryRowActionsChange}
						renderCell={(value, column, record) => (
							<div className="fourg-cietrade-log-dialog__entry-cell">
								{(value || (typeof value === 'number')) ? this.renderEntryValue(value, column, record) : ( <span className="fourg-cietrade-log-dialog__entry-cell-empty">&#x2015;</span> )}
							</div>
						)} />
					</div>
				) : (
					<IconMessage
					heading={CieTradeLog.getLabel('notFoundSingular')}
					icon={{ icon: 'warning' }} />
				)}
				<Form
        disabled={(disabled || ! isOpen || ! record)}
        submitLabel={'Copy Log ID'}
        cancelLabel={'Close'}
        onSubmit={this.handleFormSubmit}
        onCancel={onCancelClick} />
      </Dialog>
    );
  }
}

export default CieTradeLogDialog;
