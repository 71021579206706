import React from 'react';
import classNames from 'classnames';
import Icon, { Props as IconProps } from '../Icon/Icon';
import './IconMessage.scss';

export interface Props {
  id?: string;
  className?: string;
  heading: string;
  subheading?: string;
  icon: IconProps['src'];
}

export interface State {

}

class IconMessage extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    icon: { icon: 'comment' },
  };

  render() {
    const { heading, subheading, icon, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-icon-message', className);
    return (
      <div className={containerClass} {...restProps}>
        <Icon 
        className="fourg-icon-message__icon" 
        src={icon} 
        label={heading} />
        <h3 className="fourg-icon-message__heading">{heading}</h3>
        {subheading && (
          <h4 className="fourg-icon-message__subheading">{subheading}</h4>
        )}
        {children && (
          <div className="fourg-icon-message__message">
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default IconMessage;
