import React from 'react';
import classNames from 'classnames';
import { Props as IconProps } from '../Icon/Icon';
import IconMessage from '../IconMessage/IconMessage';
import Form from '../Form/Form';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import './IconMessageDialog.scss';

export interface Props extends DialogProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  icon: IconProps['src'];
  heading: string;
  subheading?: string;
  submitLabel?: string;
  secondaryLabel?: string;
  deleteLabel?: string;
  cancelLabel?: string;
  onSubmit?: () => void;
  onSecondary?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
}

export interface State {

}

class IconMessageDialog extends React.Component<Props, State> {

  static defaultProps = {
    ...Dialog.defaultProps,
    icon: { icon: 'report' },
  };

  render() {
    const { onSubmit, onSecondary, onDelete, onCancel, icon, submitLabel, secondaryLabel, cancelLabel, deleteLabel, heading, subheading, disabled, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-icon-message-dialog', className);
    return (
      <Dialog className={containerClass} {...restProps}>
        <IconMessage 
        icon={icon} 
        heading={heading}
        subheading={subheading} />
        <Form
        disabled={disabled}
        submitLabel={submitLabel}
        secondaryLabel={secondaryLabel}
        cancelLabel={cancelLabel}
        deleteLabel={deleteLabel}
        onSubmit={onSubmit}
        onSecondary={onSecondary}
        onDelete={onDelete}
        onCancel={onCancel} />
      </Dialog>
    );
  }
}

export default IconMessageDialog;
