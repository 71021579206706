import React from 'react';
import classNames from 'classnames';
import './Icon.scss';

export interface IconSrc {
  icon: string;
  cover?: string;
}

export interface Props {
  id?: string;
  className?: string;
  src: IconSrc;
  label: string;
}

export interface State {

}

class Icon extends React.Component<Props, State> {

  static defaultProps = {
    src: 'star',
  };

  render() {
    const { src, label, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-icon', {
      'fourg-icon--has-cover': Boolean(src.cover),
    }, className);
    return (
      <figure className={containerClass} {...restProps}>
        <i 
        className="fourg-icon__icon material-icons" 
        aria-label={label} 
        aria-hidden={true}
        title={label}
        role="presentation">
          {src.icon}
        </i>
        {src.cover && (
          <i 
          className="fourg-icon__cover" 
          aria-label={label} 
          title={label}
          style={{ backgroundImage: `url('${src.cover}')`}} />
        )}
      </figure>
    );
  }
}

export default Icon;
