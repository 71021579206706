import React from 'react';
import classNames from 'classnames';
import Badge from '../Badge/Badge';
import { Release } from '../../types';
import packageData from '../../../package.json';
import './ReleaseCard.scss';
import AutoParagraph from '../AutoParagraph/AutoParagraph';

export interface Props {
  id?: string;
  className?: string;
  release: Release;
}

export interface State {

}

class ReleaseCard extends React.Component<Props, State> {

  render() {
    const { release, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-release-card', className);
    return (
      <div className={containerClass} {...restProps}>
        {(packageData.version === release.version) && (
          <Badge className="fourg-release-card__badge fourg-release-card__badge--current">
            {'Current Version'}
          </Badge>
        )}
        <h3 className="fourg-release-card__version">{`v${release.version}`}</h3>
        <p className="fourg-release-card__date">{`Released ${new Date(release.date).toLocaleString(undefined, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}`}</p>
        {(release.features.length > 0) && (
          <div className="fourg-release-card__list fourg-release-card__list--features">
            <h4 className="fourg-release-card__list-heading">{'Features'}</h4>
            <ul className="fourg-release-card__list-items">
              {release.features.map((feature, i) => (
                <li className="fourg-release-card__list-item" key={`feature-${i}`}>
                  <AutoParagraph text={feature} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {(release.bugFixes.length > 0) && (
          <div className="fourg-release-card__list fourg-release-card__list--bug-fixes">
            <h4 className="fourg-release-card__list-heading">{'Bug Fixes'}</h4>
            <ul className="fourg-release-card__list-items">
              {release.bugFixes.map((bugFix, i) => (
                <li className="fourg-release-card__list-item" key={`bug-fix-${i}`}>
                  <AutoParagraph text={bugFix} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {(release.notes.length > 0) && (
          <div className="fourg-release-card__list fourg-release-card__list--notes">
            <h4 className="fourg-release-card__list-heading">{'Notes'}</h4>
            <ul className="fourg-release-card__list-items">
              {release.notes.map((note, i) => (
                <li className="fourg-release-card__list-item" key={`note-${i}`}>
                  <AutoParagraph text={note} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default ReleaseCard;
