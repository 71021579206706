import React from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import { UIOption, ChipCheckboxValue } from '../../types';
import ChipCheckbox from '../ChipCheckbox/ChipCheckbox';
import './ChipCheckboxList.scss';

export interface Props {
  id?: string;
  className?: string;
  options: UIOption[];
  chipOptions: UIOption[];
  value: ChipCheckboxValue[];
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  onChange: (value: ChipCheckboxValue[], e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isValid: boolean;
  internalID: string;
}

class ChipCheckboxList extends React.Component<Props, State> {

  private input = React.createRef<HTMLInputElement>();

  static defaultProps = {
    options: [],
    chipOptions: [],
    value: [],
    onChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleChipCheckboxChange = this.handleChipCheckboxChange.bind(this);
    this.state = {
      isValid: true,
      internalID: props.id || uniqid('ChipCheckboxList-'),
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const input = this.input.current;
    if (value && input) {
      this.setState({ isValid: input.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { id, value, required } = this.props;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('ChipCheckboxList-') });
    }
    if ((prevProps.value !== value)
    || (prevProps.required !== required)) {
      const input = this.input.current;
      if (input) {
        this.setState({ isValid: input.checkValidity() });
      }
    }
  }

  handleChipCheckboxChange(checked: boolean, chipCheckboxValue: ChipCheckboxValue, e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) {
    const { onChange, value } = this.props;
    let newValue = [...value];
    if (checked) {
      if (newValue.find(v => v.checkboxValue === chipCheckboxValue.checkboxValue)) {
        newValue = newValue.map(v => (v.checkboxValue === chipCheckboxValue.checkboxValue) ? chipCheckboxValue : v);
      } else {
        newValue.push(chipCheckboxValue);
      }
    } else {
      newValue = newValue.filter(v => (v.checkboxValue !== chipCheckboxValue.checkboxValue));
    }
    onChange(newValue, e);
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  render() {
    const { required, readOnly, disabled, value, options, chipOptions, className, onChange, ...restProps } = this.props;
    const containerClass = classNames('fourg-chip-checkbox-list', className);
    return (
      <div className={containerClass} {...restProps}>
        {options.map(option => {
          const optionValue = value.find(v => (v.checkboxValue === option.value));
          return (
            <ChipCheckbox
            disabled={(disabled || option.disabled)}
            key={`chip-checkbox-${option.value}`}
            label={option.label}
            description={option.description}
            options={chipOptions}
            checked={Boolean(optionValue)}
						readOnly={readOnly}
            value={{
              checkboxValue: option.value,
              chipValues: optionValue?.chipValues || [],
            }}
            onChange={this.handleChipCheckboxChange} />
          );
        })}
        <input
				tabIndex={-1}
        ref={this.input}
        className="fourg-chip-checkbox-list__validator"
        type="checkbox"
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        checked={(value.length > 0)}
        onChange={() => {}} />
      </div>
    );
  }
}

export default ChipCheckboxList;
