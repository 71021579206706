import React from 'react';
import classNames from 'classnames';
import Select from '../Select/Select';
import PageNumbers from '../PageNumbers/PageNumbers';
import { UIOption } from '../../types';
import './Pagination.scss';

export interface Props {
  id?: string;
  className?: string;
  page: number;
  limit: number;
  total: number;
  limitOptions: number[];
  disabled?: boolean;
  onPageChange: (page: number) => void;
  onLimitChange: (limit: number) => void;
}

export interface State {

}

class Pagination extends React.Component<Props, State> {

  static defaultProps = {
    page: 1,
    limit: 20,
    total: 0,
    limitOptions: [20, 50, 100, 200],
    onPageChange: console.info,
    onLimitChange: console.info,
  };

  getTotalPages() {
    const { total, limit } = this.props;
    return (total > 0) ? Math.ceil(total / limit) : 1;
  }

  getNumericOptions(numbers: number[]) {
    const options: UIOption[] = [];
    if (numbers.length > 0) {
      numbers.sort((a, b) => (a - b));
      numbers.forEach(number => {
        options.push({
          label: number.toString(),
          value: number.toString(),
        });
      });
    }
    return options;
  }

  getPageOptions() {
    const totalPages = this.getTotalPages();
    const pageOptions: number[] = [];
    for (let i = 1; i <= totalPages; i++) pageOptions.push(i);
    return this.getNumericOptions(pageOptions);
  }

  getLimitOptions() {
    const { limit, limitOptions } = this.props;
    ! limitOptions.includes(limit) && limitOptions.push(limit);
    return this.getNumericOptions(limitOptions);
  }

  render() {
    const { page, limit, total, limitOptions, onPageChange, onLimitChange, disabled, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-pagination', className);
    const totalPages = this.getTotalPages();
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-pagination__page">
          <label className="fourg-pagination__label" htmlFor="pagination-page">
            <span className="fourg-pagination__label-text">
              {'Page'}
            </span>
            <Select
            anchor="bottom-left"
            variant="link"
            label="Page"
            id="pagination-page"
            options={this.getPageOptions()}
            value={page.toString()}
            disabled={disabled || (totalPages < 2)}
            onChange={value => onPageChange(parseInt(value, 10))} />
            <span className="fourg-pagination__label-text">
              {`of ${totalPages} (${total} total items)`}
            </span>
          </label>
        </div>
        <div className="fourg-pagination__limit">
          <label className="fourg-pagination__label" htmlFor="pagination-limit">
            <span className="fourg-pagination__label-text">
              {'Show'}
            </span>
            <Select
            anchor="bottom-left"
            variant="link"
            label="Limit"
            id="pagination-limit"
            options={this.getLimitOptions()}
            value={limit.toString()}
            disabled={disabled || ! total}
            onChange={value => onLimitChange(parseInt(value, 10))} />
            <span className="fourg-pagination__label-text">
              {`items per page`}
            </span>
          </label>
        </div>
        <div className="fourg-pagination__links">
          <PageNumbers
          page={page}
          totalPages={totalPages}
          onChange={value => onPageChange(value)} />
        </div>
      </div>
    );
  }
}

export default Pagination;
