import React from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import './BoardHandle.scss';

export interface Props {
  id: string;
  className?: string;
  index: number;
  tabIndex: number;
  disabled?: boolean;
}

export interface State {

}

class BoardHandle extends React.Component<Props, State> {

  static defaultProps = {
    tabIndex: -1,
  };

  render() {
    const { disabled, id, index, children, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-board-handle', {
      'fourg-board-handle--disabled': disabled, 
    }, className);
    return (
      <Draggable 
      draggableId={id}
      index={index}
      isDragDisabled={disabled}>
        {(provided, snapshot) => (
          <div 
          ref={provided.innerRef}
          id={id}
          onFocus={e => { if (e.target.classList.contains('fourg-board-handle')) e.target.blur() }}
          className={classNames(containerClass, {
            'fourg-board-handle--dropping': snapshot.isDropAnimating,
            'fourg-board-handle--dragging': snapshot.isDragging, 
          })} 
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...restProps}>
            {children}
          </div>
        )}
      </Draggable>
    );
  }
}

export default BoardHandle;
