import React from 'react';
import classNames from 'classnames';
import './Sidebar.scss';

export interface Props {
  id?: string;
  className?: string;
}

export interface State {

}

class Sidebar extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {

  };

  render() {
    const { className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-sidebar', className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-sidebar__scroller">
          {children}
        </div>
      </div>
    );
  }
}

export default Sidebar;
