import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Loader from '../Loader/Loader';
import Form from '../Form/Form';
import FormField from '../FormField/FormField';
import FormFieldHeading from '../FormFieldHeading/FormFieldHeading';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import SelectInput from '../SelectInput/SelectInput';
import LookupInputNew from '../LookupInputNew/LookupInputNew';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import CieTradeCounterparty from '../../models/tables/CieTradeCounterparty';
import CieTradeLocation from '../../models/tables/CieTradeLocation';
import { TableField, Auth, CompanySchema, LocationSchema, UIOption, CieTradeLocationSchema, CieTradeCounterpartySchema, Lookup, Address } from '../../types';
import './CieTradeFormDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  companyID?: string;
  locationID?: string;
  submitLabel: string;
  cancelLabel?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (company: CompanySchema, location: LocationSchema, cieTradeCounterparty?: CieTradeCounterpartySchema, cieTradeLocation?: CieTradeLocationSchema, e?: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
	isLoading: boolean;
	isLoadingCieTradeRecords: boolean;
	isValidatingSelection: boolean;
	isCompanyNameValid: boolean;
	isLocationNameValid: boolean;
  companyRecord: CompanySchema;
	locationRecord: LocationSchema;
	editType?: 'new' | 'existing';
	cieTradeCounterpartyID?: number;
	selectedCieTradeCounterparty?: CieTradeCounterpartySchema;
	selectedCieTradeLocation?: CieTradeLocationSchema;
	cieTradeLocationRecords: CieTradeLocationSchema[];
	isCieTradeCounterpartyInputDisabled: boolean;
	lookup: Lookup;
}

class CieTradeFormDialog extends React.Component<Props, State> {

  private formFields: React.RefObject<FormFieldByField<CompanySchema>>[] = [];
  private locationFormFields: React.RefObject<FormFieldByField<LocationSchema>>[] = [];
	private cieTradeCounterpartyInput = React.createRef<LookupInputNew<CieTradeCounterpartySchema>>();
	private cieTradeLocationInput = React.createRef<SelectInput>();
	private validationTimer: number | undefined = undefined;
	private ignoredCompanyFields: (keyof CompanySchema)[] = ['primaryContactId', 'payTo', 'paymentMethod', 'paymentTerms'];
	private ignoredLocationFields: (keyof LocationSchema)[] = ['notes', 'schedulingContactId', 'alternateContactId'];

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleCompanyFieldChange = this.handleCompanyFieldChange.bind(this);
    this.handleLocationFieldChange = this.handleLocationFieldChange.bind(this);
    this.handleCieTradeCounterpartyChange = this.handleCieTradeCounterpartyChange.bind(this);
    this.handleCieTradeLocationChange = this.handleCieTradeLocationChange.bind(this);
    this.state = {
      isLoading: false,
			isLoadingCieTradeRecords: false,
			isValidatingSelection: false,
			isCompanyNameValid: false,
			isLocationNameValid: false,
      companyRecord: this.getDefaultCompanyRecord(),
			locationRecord: this.getDefaultLocationRecord(),
			editType: undefined,
			cieTradeLocationRecords: [],
			cieTradeCounterpartyID: undefined,
			selectedCieTradeCounterparty: undefined,
			selectedCieTradeLocation: undefined,
			isCieTradeCounterpartyInputDisabled: false,
			lookup: {},
    };
  }

	componentDidMount() {
		const { isOpen } = this.props;
		const { editType } = this.state;
		if (isOpen && Boolean(editType)) {
			this.readRecords();
		}
	}

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isOpen } = this.props;
		const { editType, cieTradeCounterpartyID, companyRecord, locationRecord } = this.state;
		if (! prevProps.isOpen && isOpen) {
			this.setState({
				editType: undefined,
				cieTradeCounterpartyID: undefined,
				selectedCieTradeLocation: undefined,
				selectedCieTradeCounterparty: undefined,
			}, async () => {
				await this.readRecords();
				this.resetValidity();
			});
		}
		if (! Boolean(prevState.editType) && Boolean(editType)) {
			this.validate();
		}
		if (prevState.cieTradeCounterpartyID !== cieTradeCounterpartyID) {
			this.readCieTradeRecords();
		}
		if ((prevState.companyRecord.name !== companyRecord.name)
		|| (prevState.locationRecord.name !== locationRecord.name)) {
			if (isOpen && Boolean(editType)) {
				this.setState({ isValidatingSelection: true }, () => this.debounceValidate());
			}
		}
  }

	componentWillUnmount() {
		if (this.validationTimer) window.clearTimeout(this.validationTimer);
	}

  async readRecords() {
    const { auth, companyID, locationID } = this.props;
    if (companyID && locationID) {
      this.setState({ isLoading: true });
      try {
        const token = await auth.getToken();
        const { data: companyData } = await Company.readRecord<CompanySchema>(token, companyID);
				const { data: locationData } = await Location.readRecord<LocationSchema>(token, locationID);
        this.setState({
          isLoading: false,
          companyRecord: { ...this.getDefaultCompanyRecord(), ...companyData },
					locationRecord: { ...this.getDefaultLocationRecord(), ...locationData },
					cieTradeCounterpartyID: companyData.counterpartyId,
					isCieTradeCounterpartyInputDisabled: Boolean(companyData.counterpartyId),
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isLoading: false,
          companyRecord: this.getDefaultCompanyRecord(),
					locationRecord: this.getDefaultLocationRecord(),
        });
      }
    }
  }

	debounceValidate() {
		if (this.validationTimer) window.clearTimeout(this.validationTimer);
		this.validationTimer = window.setTimeout(() => this.validate(), 500);
	}

	async validate() {
		const { auth } = this.props;
		const { companyRecord, locationRecord, cieTradeCounterpartyID, selectedCieTradeCounterparty, selectedCieTradeLocation } = this.state;
		this.setState({ isValidatingSelection: true });
		try {
			const token = await auth.getToken();
			// check the counterparty name
			let existingCieTradeCounterparty: CieTradeCounterpartySchema | undefined = undefined;
			if (companyRecord.name) {
				const { data: cieTradeCounterpartiesData } = await CieTradeCounterparty.readRecords<CieTradeCounterpartySchema>(token, {
					search: companyRecord.name,
				});
				existingCieTradeCounterparty = cieTradeCounterpartiesData
					.filter(c => {
						return c.name.toLowerCase() !== selectedCieTradeCounterparty?.name.toLowerCase();
					})
					.find(c => {
						return c.name.toLowerCase() === companyRecord.name.toLowerCase();
					});
			}
			// check the location name
			let existingCieTradeLocation: CieTradeLocationSchema | undefined = undefined;
			if (cieTradeCounterpartyID) {
				const { data: cieTradeLocationsData } = await CieTradeLocation.readRecords<CieTradeLocationSchema>(token, {
					cieTradeID: cieTradeCounterpartyID,
				});
				existingCieTradeLocation = cieTradeLocationsData
					.filter(l => {
						return l.label.toLowerCase() !== selectedCieTradeLocation?.label.toLowerCase();
					})
					.find(l => {
						return l.label.toLowerCase() === locationRecord.name.toLowerCase();
					});
			}
			this.setState({
				isValidatingSelection: false,
				isCompanyNameValid: ! Boolean(existingCieTradeCounterparty),
				isLocationNameValid: ! Boolean(existingCieTradeLocation),
			});
		} catch (error) {
			console.error(error);
			this.setState({
				isValidatingSelection: false,
				isCompanyNameValid: false,
				isLocationNameValid: false,
			});
		}
	}

	async readCieTradeRecords() {
    const { auth } = this.props;
		const { cieTradeCounterpartyID } = this.state;
    if (cieTradeCounterpartyID) {
      this.setState({ isLoadingCieTradeRecords: true });
      try {
        const token = await auth.getToken();
				const { data: cieTradeCounterpartyData } = await CieTradeCounterparty.readRecord<CieTradeCounterpartySchema>(token, cieTradeCounterpartyID.toString());
        const { data: cieTradeLocationsData } = await CieTradeLocation.readRecords<CieTradeLocationSchema>(token, {
					cieTradeID: cieTradeCounterpartyID,
				});
        this.setState({
          isLoadingCieTradeRecords: false,
          cieTradeLocationRecords: cieTradeLocationsData,
					selectedCieTradeCounterparty: cieTradeCounterpartyData,
					selectedCieTradeLocation: undefined,
					lookup: {
						cieTradeCounterparties: cieTradeCounterpartyData ? [cieTradeCounterpartyData] : [],
					},
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isLoadingCieTradeRecords: false,
          cieTradeLocationRecords: [],
					selectedCieTradeCounterparty: undefined,
					selectedCieTradeLocation: undefined,
					lookup: {},
        });
      }
    }
  }

  getDefaultCompanyRecord() {
    const company = Company.getDefaultRecord<CompanySchema>();
    const location = Location.getDefaultRecord<LocationSchema>();
    const defaultRecord: CompanySchema = {
      ...company,
      primaryLocation: location,
    };
    return defaultRecord;
  }

	getDefaultLocationRecord() {
    const location = Location.getDefaultRecord<LocationSchema>();
    const defaultRecord: LocationSchema = {
      ...location,
    };
    return defaultRecord;
  }

  setDefaultRecords() {
    this.setState({
      companyRecord: this.getDefaultCompanyRecord(),
			locationRecord: this.getDefaultLocationRecord(),
    }, () => this.resetValidity());

  }

  handleCompanyFieldChange(field: TableField<CompanySchema>, value: any) {
    const { companyRecord } = this.state;
		this.setState({
			companyRecord: {
				...companyRecord,
				[field.name]: value,
			}
      });
  }

  handleLocationFieldChange(field: TableField<LocationSchema>, value: any) {
    const { locationRecord } = this.state;
		this.setState({
			locationRecord: {
				...locationRecord,
				[field.name]: value,
			}
		});
  }

	handleCieTradeCounterpartyChange(value: string) {
		this.setState({ cieTradeCounterpartyID: Number(value) });
	}

	handleCieTradeLocationChange(value: string) {
		const { cieTradeLocationRecords } = this.state;
		const selectedCieTradeLocation = cieTradeLocationRecords.find(record => {
			return CieTradeLocation.getRecordValue<CieTradeLocationSchema>(record).toString() === value;
		});
		this.setState({ selectedCieTradeLocation: selectedCieTradeLocation });
	}

  resetValidity() {
    const formFields = [this.cieTradeCounterpartyInput, this.cieTradeLocationInput, ...this.formFields, ...this.locationFormFields];
    if (formFields.length > 0) {
      formFields.forEach(formField => formField.current?.resetValidity());
    }
  }

	getCieTradeLocationOptions(): UIOption[] {
		const { cieTradeLocationRecords } = this.state;
		const modelOptions = CieTradeLocation.getOptions();
		const options: UIOption[] = [
			{
				value: '',
				label: CieTradeLocation.getLabel('addSingular'),
				icon: { icon: 'add_circle' },
			},
		];
		const cieTradeOptions: UIOption[] = cieTradeLocationRecords.map(record => ({
			label: CieTradeLocation.getRecordLabel<CieTradeLocationSchema>(record),
			value: CieTradeLocation.getRecordValue<CieTradeLocationSchema>(record).toString(),
			disabled: Boolean(record.id),
			icon: { icon: modelOptions.icon, cover: CieTradeLocation.getRecordImage<CieTradeLocationSchema>(record) },
		}));
		options.push(...cieTradeOptions);
		return options;
	}

	isFieldDiff<T extends Record<string, any> = Record<string, any>>(field: TableField<T>, prevValue: any, nextValue: any): boolean {
		switch (field.type) {
			case 'address':
				const prevAddress: Address = prevValue;
				const nextAddress: Address = nextValue;
				if (prevAddress.address1 !== nextAddress.address1) return true;
				if (prevAddress.address2 !== nextAddress.address2) return true;
				if (prevAddress.city !== nextAddress.city) return true;
				if (prevAddress.state !== nextAddress.state) return true;
				if (prevAddress.zip !== nextAddress.zip) return true;
				if (prevAddress.country !== nextAddress.country) return true;
				return false;
			default:
				prevValue = Array.isArray(prevValue) ? prevValue.sort() : prevValue;
				nextValue = Array.isArray(nextValue) ? nextValue.sort() : nextValue;
				return (prevValue.toString() !== nextValue.toString());
		}
	}

  render() {
    const { disabled, isOpen, title, auth, companyID, locationID, className, submitLabel, cancelLabel, onFormCancel, onFormSubmit, ...restProps } = this.props;
    const { lookup, companyRecord, locationRecord, isLoading, isLoadingCieTradeRecords, editType, cieTradeCounterpartyID, selectedCieTradeLocation, selectedCieTradeCounterparty, isCompanyNameValid, isLocationNameValid, isValidatingSelection, isCieTradeCounterpartyInputDisabled } = this.state;
    const containerClass = classNames('fourg-cietrade-form-dialog', className);
    const companyFields = Company.getFieldsBy<CompanySchema>('isFormField', true).filter(field => ! this.ignoredCompanyFields.includes(field.name));
    const locationFields = Location.getFieldsBy<LocationSchema>('isFormField', true).filter(field => ! this.ignoredLocationFields.includes(field.name));
		const cieTradeLocationModelOptions = CieTradeLocation.getOptions();
		const cieTradeCounterpartyAsCompany: CompanySchema = selectedCieTradeCounterparty ? CieTradeCounterparty.toCompanySchema(selectedCieTradeCounterparty) : Company.getDefaultRecord<CompanySchema>();
		const cieTradeLocationAsLocation: LocationSchema = selectedCieTradeLocation ? CieTradeLocation.toLocationSchema(selectedCieTradeLocation) : Location.getDefaultRecord<LocationSchema>();
		return (
      <Dialog
      {...restProps}
      className={containerClass}
      title={title}
      isOpen={isOpen}
			size={(editType === 'existing') ? 'large' : undefined}>
				{! Boolean(editType) && (
					<React.Fragment>
						<div className="fourg-cietrade-form-dialog__info">
							<p>
								<i className="material-icons" style={{ fontSize: '14px', transform:	'translateY(0.1em)' }}>info</i>&nbsp;
								<span>{'Use this dialog to create a brand new cieTrade counterparty, add a location to an existing cieTrade counterparty, or overwrite an existing cieTrade counterparty/location.'}</span>
							</p>
							<p>
								<span>{'To create a brand new cieTrade counterparty, click the "Skip" button below to skip the search process.'}</span>&nbsp;
								<strong>{'Only do this if you have thoroughly searched for existing cieTrade counterparties and none were found.'}</strong>
							</p>
							<p>
								<span>{'To add a location to an existing cieTrade counterparty, search for and select it below. Then, leave the cieTrade location blank, and click the "Continue" button.'}</span>&nbsp;
								<strong>{'Only do this if you have thoroughly searched for existing cieTrade locations and none were found.'}</strong>
							</p>
							<p>
								<span>{'To update an existing cieTrade counterparty/location, search for and select them below. Then click the "Continue" button.'}</span>
							</p>
						</div>
						<Form
						className="fourg-cietrade-form-dialog__search-form"
						submitLabel={'Continue'}
						disabled={disabled || isLoading || ! isOpen}
						cancelLabel={cancelLabel}
						secondaryLabel={isCieTradeCounterpartyInputDisabled ? undefined : 'Skip'}
						onCancel={onFormCancel}
						ignoreSecondaryValidity={true}
						onSubmit={e => this.setState({ editType: 'existing' })}
						onSecondary={e => this.setState({ editType: 'new', selectedCieTradeCounterparty: undefined, selectedCieTradeLocation: undefined })}>
							<FormField>
								<LookupInputNew<CieTradeCounterpartySchema>
								ref={this.cieTradeCounterpartyInput}
								label={CieTradeCounterparty.getLabel('singular')}
								model={CieTradeCounterparty}
								auth={auth}
								required={true}
								lookup={lookup}
								isOptionDisabled={(cp) => Boolean(cp.id)}
								disabled={disabled || isLoading || ! isOpen || isCieTradeCounterpartyInputDisabled}
								value={cieTradeCounterpartyID?.toString() || ''}
								onChange={this.handleCieTradeCounterpartyChange} />
							</FormField>
							<FormField>
								<SelectInput
								ref={this.cieTradeLocationInput}
								icon={{ icon: cieTradeLocationModelOptions.icon }}
								label={CieTradeLocation.getLabel('singular')}
								// required={true}
								isLoading={isLoadingCieTradeRecords}
								disabled={disabled || isLoading || isLoadingCieTradeRecords || ! isOpen || ! cieTradeCounterpartyID}
								value={selectedCieTradeLocation ? CieTradeLocation.getRecordValue<CieTradeLocationSchema>(selectedCieTradeLocation).toString() : ''}
								onChange={this.handleCieTradeLocationChange}
								options={this.getCieTradeLocationOptions()} />
							</FormField>
						</Form>
					</React.Fragment>
				)}
				{(companyFields.length > 0 && Boolean(editType)) && (
					<React.Fragment>
						<div className="fourg-cietrade-form-dialog__info">
							{isValidatingSelection ? (
								<p>
									<i className="material-icons" style={{ fontSize: '14px', transform:	'translateY(0.1em)' }}>published_with_changes</i>&nbsp;
									<span>{`Validating...`}</span>
								</p>
							) : (! isCompanyNameValid || ! isLocationNameValid) ? (
								<React.Fragment>
									{! isCompanyNameValid && (
										<p className="fourg-cietrade-form-dialog__error-message">
											<i className="material-icons" style={{ fontSize: '14px', transform:	'translateY(0.1em)' }}>close</i>&nbsp;
											<span>{`There is already another cieTrade Counterparty with the name "${companyRecord.name}".`}</span>
										</p>
									)}
									{! isLocationNameValid && (
										<p  className="fourg-cietrade-form-dialog__error-message">
											<i className="material-icons" style={{ fontSize: '14px', transform:	'translateY(0.1em)' }}>close</i>&nbsp;
											<span>{`There is already another cieTrade location with the name "${locationRecord.name}".`}</span>
										</p>
									)}
								</React.Fragment>
							) : (
								<p  className="fourg-cietrade-form-dialog__success-message">
									<i className="material-icons" style={{ fontSize: '14px', transform:	'translateY(0.1em)' }}>done</i>&nbsp;
									<span>{`The company name and location name have both been validated with cieTrade.`}</span>
								</p>
							)}
						</div>
						<Form
						className="fourg-cietrade-form-dialog__edit-form"
						submitLabel={submitLabel}
						secondaryLabel={'Back'}
						disabled={disabled || isLoading || isLoadingCieTradeRecords || isValidatingSelection || ! isOpen}
						cancelLabel={cancelLabel}
						onCancel={onFormCancel}
						ignoreSecondaryValidity={true}
						forceInvalid={! isCompanyNameValid || ! isLocationNameValid}
						onSubmit={e => onFormSubmit(companyRecord, locationRecord, selectedCieTradeCounterparty, selectedCieTradeLocation, e)}
						onSecondary={e => this.setState({ editType: undefined })}>
							<div className="fourg-cietrade-form-dialog__forms">
								{(editType === 'existing') && (
									<div className="fourg-cietrade-form-dialog__cietrade-form">
										<FormFieldHeading>{'cieTrade Counterparty Info'}</FormFieldHeading>
										{companyFields.map((field, i) => (
											<FormFieldByField<CompanySchema>
											key={`cietrade-company-${field.name}`}
											record={cieTradeCounterpartyAsCompany}
											recordModel={Company}
											field={field}
											value={cieTradeCounterpartyAsCompany[field.name]}
											readOnly={true}
											onChange={() => {}}
											className={classNames('fourg-cietrade-form-dialog__form-field', {
												'fourg-cietrade-form-dialog__form-field--diff': selectedCieTradeCounterparty && this.isFieldDiff<CompanySchema>(field, companyRecord[field.name], cieTradeCounterpartyAsCompany[field.name]),
											})} />
										))}
										<FormFieldHeading>{'cieTrade Location Info'}</FormFieldHeading>
										{locationFields.map((field, i) => (
											<FormFieldByField<LocationSchema>
											key={`cietrade-location-${field.name}`}
											record={cieTradeLocationAsLocation}
											recordModel={Location}
											field={field}
											value={cieTradeLocationAsLocation[field.name]}
											readOnly={true}
											onChange={() => {}}
											className={classNames('fourg-cietrade-form-dialog__form-field', {
												'fourg-cietrade-form-dialog__form-field--diff': selectedCieTradeLocation && this.isFieldDiff<LocationSchema>(field, locationRecord[field.name], cieTradeLocationAsLocation[field.name]),
											})} />
										))}
									</div>
								)}
								<div className="fourg-cietrade-form-dialog__crm-form">
									<FormFieldHeading>{'CRM Company Info'}</FormFieldHeading>
									{companyFields.map((field, i) => (
										<FormFieldByField<CompanySchema>
										key={`crm-company-${field.name}`}
										ref={formField => this.formFields[i] = { current: formField }}
										record={companyRecord}
										recordModel={Company}
										field={field}
										value={companyRecord[field.name]}
										disabled={disabled || isLoading || isLoadingCieTradeRecords || ! isOpen}
										onChange={(value, e) => this.handleCompanyFieldChange(field, value)}
										className={classNames('fourg-cietrade-form-dialog__form-field', {
											'fourg-cietrade-form-dialog__form-field--diff': selectedCieTradeCounterparty && this.isFieldDiff<CompanySchema>(field, companyRecord[field.name], cieTradeCounterpartyAsCompany[field.name]),
										})} />
									))}
									<FormFieldHeading>{'CRM Location Info'}</FormFieldHeading>
									{locationFields.map((field, i) => (
										<FormFieldByField<LocationSchema>
										key={`crm-location-${field.name}`}
										ref={formField => this.locationFormFields[i] = { current: formField }}
										record={locationRecord}
										recordModel={Location}
										field={field}
										value={locationRecord[field.name]}
										disabled={disabled || isLoading || isLoadingCieTradeRecords || ! isOpen || ((field.name === 'primary') && (companyRecord.primaryLocationId === locationRecord.id))}
										onChange={(value, e) => this.handleLocationFieldChange(field, value)}
										className={classNames('fourg-cietrade-form-dialog__form-field', {
											'fourg-cietrade-form-dialog__form-field--diff': selectedCieTradeLocation && this.isFieldDiff<LocationSchema>(field, locationRecord[field.name], cieTradeLocationAsLocation[field.name]),
										})} />
									))}
								</div>
							</div>
						</Form>
					</React.Fragment>
				)}
        {isLoading && (
          <Loader
          position="absolute"
          className="fourg-cietrade-form-dialog__loader" />
        )}
      </Dialog>
    );
  }
}

export default CieTradeFormDialog;
