import React from 'react';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Form from '../Form/Form';
import FormField from '../FormField/FormField';
import Input from '../Input/Input';
import AutoParagraph from '../AutoParagraph/AutoParagraph';
import Button from '../Button/Button';
import { TaskSchema } from '../../types';
import './ShareTaskDialog.scss';

export interface Props extends DialogProps {
  recordID: TaskSchema['id'];
}

export interface State {

}

class ShareTaskDialog extends React.Component<Props, State> {

  static defaultProps = {
    ...Dialog.defaultProps,
  };

  constructor(props: Props) {
    super(props);
    this.handleCopyURLClick = this.handleCopyURLClick.bind(this);
    this.handleCopyEmailClick = this.handleCopyEmailClick.bind(this);
    this.state = {

    };
  }

  getUniqueEmail() {
    const { recordID } = this.props;
    return `attach+task#${recordID}@${process.env.REACT_APP_EMAIL_FORWARDING_DOMAIN}`;
  }

  getUniqueURL() {
    const { recordID } = this.props;
    return `${window.location.origin}/tasks/${recordID}`;
  }

  handleCopyURLClick() {
    copy(this.getUniqueURL(), {
      format: 'text/plain',
      onCopy: () => toast.success('Task URL Copied'),
    });
  }

  handleCopyEmailClick() {
    copy(this.getUniqueEmail(), {
      format: 'text/plain',
      onCopy: () => toast.success('Task Email Copied'),
    });
  }

  render() {
    const { isOpen, recordID, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-share-task-dialog', className);
    const urlDescription = 'Use the task\'s unique URL to share it. Note that a user must be signed in to access a task.';
    const emailDescription = 'Use the task\'s unique email address to forward an email to it. Note that the sender\'s email address must match the Site Manager user\'s email address or the email will be rejected.';
    return (
      <Dialog 
      className={containerClass}
      title={'Share'}
      isOpen={isOpen}
      {...restProps}>
        <Form className="fourg-share-task-dialog__form">
          <FormField label={'Share this task via URL'} className="fourg-share-task-dialog__form-field" size="large">
            <Input 
            type="url" 
            label={'Unique URL'}
            value={this.getUniqueURL()}
            disabled={! isOpen}
						onChange={() => {}} />
            <AutoParagraph className="fourg-share-task-dialog__description" text={urlDescription} />
            <Button 
            icon={{ icon: 'copy_all' }} 
            variant="outlined" 
            onClick={this.handleCopyURLClick}
            disabled={! isOpen}>{'Copy URL'}</Button>
          </FormField>
          <FormField label={'Forward an email to this task'} className="fourg-share-task-dialog__form-field" size="large">
            <Input 
            type="email" 
            label={'Unique Email Address'}
            value={this.getUniqueEmail()}
            disabled={! isOpen}
						onChange={() => {}} />
            <AutoParagraph className="fourg-share-task-dialog__description" text={emailDescription} />
            <Button 
            icon={{ icon: 'copy_all' }} 
            variant="outlined" 
            onClick={this.handleCopyEmailClick}
            disabled={! isOpen}>{'Copy Email Address'}</Button>
          </FormField>
        </Form>
      </Dialog>
    );
  }
}

export default ShareTaskDialog;
