import Table from '../Table';
import { TableOptions, TableLabels, TableField, TableEndpoints } from '../../types';

class Contact extends Table {

  static options: TableOptions = {
    name: 'Contact',
    slug: 'contacts',
    labelKey: 'email',
    valueKey: 'id',
    lookupKey: 'contacts',
    icon: 'perm_contact_calendar',
    defaultOrder: 'first',
  };

  static labels: TableLabels = {
    description: 'All contacts in the 4G Recycling Site Manager.',
    pageTitle: 'Contact Manager',
    singular: 'Contact',
    plural: 'Contacts',
    viewSingular: 'View Contact',
    viewPlural: 'View Contacts',
    selectSingular: 'Select Contact',
    selectPlural: 'Select Contacts',
    addSingular: 'Add Contact',
    addPlural: 'Add Contacts',
    editSingular: 'Edit Contact',
    editPlural: 'Edit Contacts',
    addedSingular: 'Contact added',
    addedPlural: 'Contacts added',
    updatedSingular: 'Contact updated',
    updatedPlural: 'Contacts updated',
    deletedSingular: 'Contact deleted',
    deletedPlural: 'Contacts deleted',
    archivedSingular: 'Contact archived',
    archivedPlural: 'Contacts archived',
    restoredSingular: 'Contact restored',
    restoredPlural: 'Contacts restored',
    errorFetchingSingular: 'Error fetching Contact',
    errorFetchingPlural: 'Error fetching Contacts',
    errorAddingSingular: 'Error adding Contact',
    errorAddingPlural: 'Error adding Contacts',
    errorUpdatingSingular: 'Error updating Contact',
    errorUpdatingPlural: 'Error updating Contacts',
    errorDeletingSingular: 'Error deleting Contact',
    errorDeletingPlural: 'Error deleting Contacts',
    errorArchivingSingular: 'Error archiving Contact',
    errorArchivingPlural: 'Error archiving Contacts',
    errorRestoringSingular: 'Error restoring Contact',
    errorRestoringPlural: 'Error restoring Contacts',
    notFoundSingular: 'Contact not found',
    notFoundPlural: 'No Contacts found',
    loadingSingular: 'Loading Contact',
    loadingSingularEllipsis: 'Loading Contact...',
    loadingPlural: 'Loading Contacts',
    loadingPluralEllipsis: 'Loading Contacts...',
    search: 'Search Contacts',
    searchEllipsis: 'Search Contacts...',
    filter: 'Filter Contacts',
    settings: 'Contacts Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'contacts',
    readRecord: 'contact/:id',
    createRecord: 'contact',
    updateRecord: 'contact/:id',
    updateRecords: 'contacts',
    patchRecord: 'contact/:id',
    deleteRecord: 'contact/:id',
    archiveRecord: 'contact/:id/archive',
    restoreRecord: 'contact/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
    },
    {
      name: 'first',
      label: 'First Name',
      type: 'text',
      default: '',
      required: true,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
    },
    {
      name: 'last',
      label: 'Last Name',
      type: 'text',
      default: '',
      required: true,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      default: '',
      isSortable: true,
      isTableColumn: true,
      isFormField: true,
      columnWidth: 120,
      formFieldSize: 'medium',
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      default: '',
      isSortable: true,
			isTableColumn: true,
			isFormField: true,
      columnWidth: 180,
      formFieldSize: 'medium',
    },
    {
      name: 'phone',
      label: 'Direct Phone',
      type: 'tel',
      default: '',
			isTableColumn: true,
			isFormField: true,
      columnWidth: 140,
      formFieldSize: 'medium',
    },
    {
      name: 'mobile',
      label: 'Mobile Phone',
      type: 'tel',
      default: '',
      isTableColumn: true,
      isFormField: true,
      columnWidth: 140,
      formFieldSize: 'medium',
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      default: '',
      isFormField: true,
      formFieldSize: 'large',
    },
		{
      name: 'primary',
      label: 'Company Primary Contact',
      type: 'checkbox',
      default: false,
      isTableColumn: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'large',
    },
		{
			name: 'created',
			label: 'Created',
			type: 'date',
			default: '',
			readOnly: true,
			isSortable: true,
		},
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
			default: '',
			readOnly: true,
      isSortable: true,
		},
  ];

  static getRecordLabel(record: any) {
    return `${record.first} ${record.last}`;
  }
}

export default Contact;
