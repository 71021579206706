import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Loader from '../Loader/Loader';
import Form from '../Form/Form';
import FormSection from '../FormSection/FormSection';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import SelectInput from '../SelectInput/SelectInput';
import FileInput from '../FileInput/FileInput';
import FormField from '../FormField/FormField';
import Checkbox from '../Checkbox/Checkbox';
import User from '../../models/tables/User';
import Attachment from '../../models/tables/Attachment';
import Task from '../../models/tables/Task';
import { TableField, Auth, UserSchema, AttachmentSchema, UserSettings } from '../../types';
import './UserFormDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  recordID?: string;
  enforcedValues?: Partial<UserSchema>;
  submitLabel: string;
  secondaryLabel?: string;
  deleteLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSecondary: (record: UserSchema, settings: UserSettings, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: UserSchema, settings: UserSettings, e: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete: (record: UserSchema, settings: UserSettings, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isLoading: boolean;
  isUpdating: boolean;
  record: UserSchema;
  settings: UserSettings;
}

class UserFormDialog extends React.Component<Props, State> {
  
  private formFields: React.RefObject<FormFieldByField<UserSchema>>[] = [];

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSecondary: console.info,
    onFormSubmit: console.info,
    onDelete: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
    this.state = {
      isLoading: false,
      isUpdating: false,
      record: User.getDefaultRecord<UserSchema>(),
      settings: User.getDefaultSettings(),
    };
  }

  componentDidMount() {
    const { recordID, isOpen } = this.props;
    if (isOpen && recordID) {
      this.readRecord();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { recordID, isOpen } = this.props;
    if (prevProps.isOpen && ! isOpen) {
      this.setDefaultRecord();
    }
    if (! prevProps.isOpen && isOpen) {
      if (recordID) {
        this.readRecord();
      } else {
        this.setDefaultRecord();
      }
    }
  }

  async readRecord() {
    const { auth, recordID } = this.props;
    if (recordID) {
      this.setState({ isLoading: true });
      try {
        const token = await auth.getToken();
        const { data } = await User.readRecord<UserSchema>(token, recordID);
        const settings = await User.readSettings(token, recordID);
        this.setState({
          isLoading: false,
          record: data,
          settings: settings,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ 
          isLoading: false,
          record: User.getDefaultRecord<UserSchema>(), 
          settings: User.getDefaultSettings(),
        });
      }
    }
  }

  setDefaultRecord() {
    const { enforcedValues } = this.props;
    this.setState({
      record: {
        ...User.getDefaultRecord<UserSchema>(),
        ...enforcedValues,
      },
      settings: User.getDefaultSettings(),
    });
    this.resetValidity();
  }

  handleFieldChange(field: TableField<UserSchema>, value: any) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          [field.name]: value,
        }
      });
    }
  }

  handleAvatarChange(value: FileList | null, e: React.ChangeEvent<HTMLInputElement>) {
    const file = value?.item(0);
    if (file) {
      this.createAvatar(file);
    }
  }

  async createAvatar(file: File) {
    const { auth, recordID } = this.props;
    if (recordID) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const attachment: AttachmentSchema = {
          ...Attachment.getDefaultRecord<AttachmentSchema>(),
          file: file,
        };
        await User.createAvatar(token, recordID, attachment);
        this.readRecord();
        toast.success(Attachment.getLabel('addedSingular'));
        this.setState({ isUpdating: false });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  isFieldEnforced(field: TableField<UserSchema>) {
    const { enforcedValues } = this.props;
    return enforcedValues && Object.keys(enforcedValues).includes(field.name.toString());
  }

  resetValidity() {
    if (this.formFields.length > 0) {
      this.formFields.forEach(formField => formField.current?.resetValidity());
    }
  }

  handleSettingsChange(sectionName: keyof UserSettings, fieldName: string, value: any) {
    const { settings } = this.state;
    if (settings) {
      this.setState({
        settings: {
          ...settings,
          [sectionName]: {
            ...settings[sectionName],
            [fieldName]: value,
          }, 
        },
      });
    }
  }

  render() {
    const { deleteLabel, onDelete, enforcedValues, disabled, isOpen, title, auth, recordID, className, submitLabel, secondaryLabel, cancelLabel, onFormCancel, onFormSubmit, onFormSecondary, ...restProps } = this.props;
    const { record, settings, isLoading, isUpdating } = this.state;
    const containerClass = classNames('fourg-user-form-dialog', className);
    const fields = User.getFieldsBy<UserSchema>('isFormField', true);
    const modelOptions = User.getOptions();
    return (
      <Dialog 
      className={containerClass}
      title={title}
      isOpen={isOpen}
      {...restProps}>
        {(fields.length > 0) && (
          <Form
          className="fourg-user-form-dialog__form"
          submitLabel={submitLabel}
          secondaryLabel={secondaryLabel}
          deleteLabel={deleteLabel}
          disabled={(disabled || isLoading || isUpdating || ! isOpen)}
          cancelLabel={cancelLabel}
          pages={User.getFormPages()}
          onCancel={onFormCancel}
          onSubmit={e => onFormSubmit(record, settings, e)}
          onSecondary={e => onFormSecondary(record, settings, e)}
          onDelete={e => onDelete(record, settings, e)}
          renderPage={pageName => {
            const sections = User.getFormSections();
            return sections.map(section => {
              const sectionFields = fields.filter(field => (field.formSection === section.name));
              return (
                <FormSection 
                isHidden={(section.page !== pageName)}
                key={`form-section-${section.name}`}
                className={`fourg-user-form-dialog__section--${section.name}`}
                heading={section.label}>
                  {(section.name === 'profile-image') && (
                    <FormField size="large">
                      <FileInput 
                      title={'Upload an image'}
                      accept="image/jpeg,image/png"
                      variant="mini"
                      disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                      label={'Avatar'} 
                      icon={{ icon: modelOptions.icon, cover: User.getRecordImage(record) }}
                      onChange={this.handleAvatarChange} />
                    </FormField>
                  )}
                  {(section.name === 'notifications') && (
                    <React.Fragment>
                      <FormField size="large">
                        <Checkbox 
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Receive Email Notifications'} 
                        checked={Boolean(settings.notifications.email)}
                        onChange={(checked) => this.handleSettingsChange('notifications', 'email', checked)} />
                      </FormField>
                      <FormField size="large">
                        <Checkbox 
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Receive Notifications from Tasks I\'ve Created'} 
                        checked={Boolean(settings.notifications.createdTasks)}
                        onChange={(checked) => this.handleSettingsChange('notifications', 'createdTasks', checked)} />
                      </FormField>
                      <FormField size="large">
                        <Checkbox 
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Receive Notifications from To-Dos I\'ve Created'} 
                        checked={Boolean(settings.notifications.createdReminders)}
                        onChange={(checked) => this.handleSettingsChange('notifications', 'createdReminders', checked)} />
                      </FormField>
                    </React.Fragment>
                  )}
                  {(section.name === 'application') && (
                    <React.Fragment>
                      <FormField size="medium">
                        <SelectInput 
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Default Dashboard Section'} 
                        value={settings.application?.defaultDashboardSection || ''}
                        options={[
                          { value: '', label: 'Last Used' },
                          { value: 'reminders', label: 'To-Dos' },
                          { value: 'created-tasks', label: 'My Tasks' },
                          { value: 'assigned-tasks', label: 'Assigned to Me' },
                          { value: 'following-tasks', label: 'Following' },
                          { value: 'member-tasks', label: 'Member Of' },
                        ]}
                        onChange={(value) => this.handleSettingsChange('application', 'defaultDashboardSection', value)} />
                      </FormField>
                      <FormField size="medium">
                        <SelectInput 
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Default Quick View Section'} 
                        value={settings.application?.defaultQuickViewSection || ''}
                        options={[
                          { value: '', label: 'Last Used' },
                          { value: 'details', label: 'Details' },
                          { value: 'activity', label: 'Activity' },
                        ]}
                        onChange={(value) => this.handleSettingsChange('application', 'defaultQuickViewSection', value)} />
                      </FormField>
                      <FormField size="medium">
                        <SelectInput 
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Default Board'} 
                        value={settings.application?.defaultBoard || ''}
                        options={[
                          { value: '', label: 'Last Used' },
                          ...Task.getField('board')?.options || [],
                        ]}
                        onChange={(value) => this.handleSettingsChange('application', 'defaultBoard', value)} />
                      </FormField>
                      <FormField size="medium">
                        <SelectInput 
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Default Board View'} 
                        value={settings.application?.defaultBoardView || ''}
                        options={[
                          { value: '', label: 'Last Used' },
                          { value: 'board', label: 'Board' },
                          { value: 'table', label: 'Table' },
                        ]}
                        onChange={(value) => this.handleSettingsChange('application', 'defaultBoardView', value)} />
                      </FormField>
                      <FormField size="large">
                        <Checkbox
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Hide Completed on Dashboard'}
                        checked={Boolean(settings.application?.hideCompletedOnDashboard)}
                        onChange={(value) => this.handleSettingsChange('application', 'hideCompletedOnDashboard', value)} />
                      </FormField>
                      <FormField size="large">
                        <Checkbox
                        disabled={(disabled || isLoading || isUpdating || ! isOpen)}
                        label={'Remember Board Group'}
                        checked={Boolean(settings.application?.rememberBoardGroup)}
                        onChange={(value) => this.handleSettingsChange('application', 'rememberBoardGroup', value)} />
                      </FormField>
                    </React.Fragment>
                  )}
                  {sectionFields.map((field, i) => {
                    return (
                    <FormFieldByField<UserSchema>
                    ref={formField => this.formFields[i] = { current: formField }}
                    record={record}
										recordModel={User}
                    key={`form-field-${field.name}`} 
                    field={field}
                    value={record[field.name]}
                    disabled={(disabled || isLoading || isUpdating || ! isOpen || this.isFieldEnforced(field))}
                    onChange={(value, e) => this.handleFieldChange(field, value)} />
                  )})}
                </FormSection>
              );
            });
          }} />
        )}
        {isLoading && (
          <Loader 
          position="absolute" 
          className="fourg-user-form-dialog__loader" />
        )}
      </Dialog>
    );
  }
}

export default UserFormDialog;
