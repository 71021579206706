import React from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import './AutoParagraph.scss';

interface Props {
  id?: string;
  className?: string;
  text: string;
  splitOn: string;
}

class AutoParagraph extends React.Component<Props> {

  static defaultProps = {
    splitOn: '\n\n',
  };

  render() {
    const { text, splitOn, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-auto-paragraph', className);
    return (
      <div className={containerClass} {...restProps}>
        {text.split(splitOn).map((paragraph, i) => ( 
          <p key={i} dangerouslySetInnerHTML={{ 
            __html: DOMPurify.sanitize(paragraph, { 
              ALLOWED_TAGS: ['strong', 'em', 'a', 'sub', 'sup', 'small'], 
              ALLOWED_ATTR: ['id', 'class', 'style', 'target', 'rel', 'href'], 
            }) 
          }} /> 
        ))}
      </div>
    );
  }
}

export default AutoParagraph;