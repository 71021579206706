import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField, CompanySchema, LocationSchema, ReadRecordsResponse, ReadRecordsQuery, ContactSchema, TaskSchema, AttachmentSchema, CieTradeLogSchema } from '../../types';
import { APIError } from '../../errors';
import Contact from './Contact';

class Company extends Table {

  static options: TableOptions = {
    name: 'Company',
    slug: 'companies',
    labelKey: 'name',
    valueKey: 'id',
    lookupKey: 'companies',
    icon: 'business',
    defaultOrder: 'name',
    hasQuickAdd: true,
  };

  static labels: TableLabels = {
    description: 'All companies in the 4G Recycling Site Manager.',
    pageTitle: 'Company Manager',
    singular: 'Company',
    plural: 'Companies',
    viewSingular: 'View Company',
    viewPlural: 'View Companies',
    selectSingular: 'Select Company',
    selectPlural: 'Select Companies',
    addSingular: 'Add Company',
    addPlural: 'Add Companies',
    editSingular: 'Edit Company',
    editPlural: 'Edit Companies',
    addedSingular: 'Company added',
    addedPlural: 'Companies added',
    updatedSingular: 'Company updated',
    updatedPlural: 'Companies updated',
    deletedSingular: 'Company deleted',
    deletedPlural: 'Companies deleted',
    archivedSingular: 'Company archived',
    archivedPlural: 'Companies archived',
    restoredSingular: 'Company restored',
    restoredPlural: 'Companies restored',
    errorFetchingSingular: 'Error fetching Company',
    errorFetchingPlural: 'Error fetching Companies',
    errorAddingSingular: 'Error adding Company',
    errorAddingPlural: 'Error adding Companies',
    errorUpdatingSingular: 'Error updating Company',
    errorUpdatingPlural: 'Error updating Companies',
    errorDeletingSingular: 'Error deleting Company',
    errorDeletingPlural: 'Error deleting Companies',
    errorArchivingSingular: 'Error archiving Company',
    errorArchivingPlural: 'Error archiving Companies',
    errorRestoringSingular: 'Error restoring Company',
    errorRestoringPlural: 'Error restoring Companies',
    notFoundSingular: 'Company not found',
    notFoundPlural: 'No Companies found',
    loadingSingular: 'Loading Company',
    loadingSingularEllipsis: 'Loading Company...',
    loadingPlural: 'Loading Companies',
    loadingPluralEllipsis: 'Loading Companies...',
    search: 'Search Companies',
    searchEllipsis: 'Search Companies...',
    filter: 'Filter Companies',
    settings: 'Companies Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'companies',
    readRecord: 'company/:id',
    createRecord: 'company',
    updateRecord: 'company/:id',
    updateRecords: 'companies',
    patchRecord: 'company/:id',
    deleteRecord: 'company/:id',
    archiveRecord: 'company/:id/archive',
    restoreRecord: 'company/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
    },
    {
      name: 'name',
      label: 'Company Name',
      type: 'unique',
      model: Company,
			default: '',
			formFieldSize: 'large',
      required: true,
      isSortable: true,
      isFormField: true,
      isTableColumn: true,
      isQuickAddField: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
				{ label: 'Active', value: 'active' },
				{ label: 'Inactive', value: 'inactive' },
			],
      default: 'active',
      readOnly: true,
      isSortable: true,
      isTableColumn: true,
      isInfo: true,
      infoSize: 'small',
    },
		{
      name: 'payTo',
      label: 'Pay To Name',
      type: 'text',
      default: '',
      isFormField: true,
      isInfo: true,
    },
		{
      name: 'paymentMethod',
      label: 'Payment Method',
      type: 'text',
      default: '',
      isFormField: true,
      isInfo: true,
			formFieldSize: 'medium',
    },
		{
      name: 'paymentTerms',
      label: 'Payment Terms',
      type: 'text',
      default: '',
      isFormField: true,
      isInfo: true,
			formFieldSize: 'medium',
    },
    {
      name: 'website',
      label: 'Website',
      type: 'url',
      default: '',
      isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
      isInfo: true,
      infoSize: 'large',
    },
		{
			name: 'primaryContactId',
			label: 'Primary Contact',
			type: 'lookup-company-section',
      model: Contact,
      // required: true,
			default: '',
      isFormField: true,
      isInfo: true,
      infoSize: 'large',
      formFieldSize: 'medium',
			// isTableColumn: true,
			options: [
				{ label: 'Unassigned', value: '' },
			],
    },
    // {
    //   name: 'role',
    //   label: 'Role',
    //   type: 'chip-list',
    //   default: [],
    //   options: [
    //     { value: 'supplier', label: 'Supplier' },
    //     { value: 'customer', label: 'Customer' },
    //     { value: 'expense', label: 'Expense Vendor' },
    //   ],
    //   isTableColumn: true,
    //   isFormField: true,
    //   formFieldSize: 'large',
    //   infoSize: 'large',
    //   isInfo: true,
		// 	required: true,
    // },
    {
      name: 'locationsCount',
      label: 'Locations',
      type: 'number',
      default: 0,
      readOnly: true,
      isSortable: true,
      isTableColumn: true,
    },
    {
      name: 'contactsCount',
      label: 'Contacts',
      type: 'number',
      default: 0,
      readOnly: true,
      isSortable: true,
    },
    {
      name: 'attachmentsCount',
      label: 'Attachments',
      type: 'number',
      default: 0,
      readOnly: true,
      isSortable: true,
    },
		{
      name: 'isPushed',
      label: 'cieTrade',
      type: 'checkbox',
      default: false,
      // isSortable: true,
      isTableColumn: true,
      isInfo: true,
      infoSize: 'small',
    },
		{
			name: 'created',
			label: 'Created',
			type: 'date',
			default: '',
			readOnly: true,
			isSortable: true,
			isTableColumn: true,
      isInfo: true,
    },
		{
			name: 'updated',
			label: 'Updated',
			type: 'date',
			default: '',
			readOnly: true,
			isSortable: true,
			isTableColumn: true,
      isInfo: true,
    },
  ];

  static async readLocations(token: string, id: CompanySchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<LocationSchema>>{
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/locations`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get company locations');
    }
  }

  static async readContacts(token: string, id: CompanySchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<ContactSchema>>{
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/contacts`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    switch (response.status) {
      case 200: return await response.json();
			default: throw new APIError(response.status, 'Could not get company contacts');
    }
  }

  static async readTasks(token: string, id: CompanySchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<TaskSchema>> {
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/tasks`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get company tasks');
    }
  }

  static async readAttachments(token: string, id: CompanySchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<AttachmentSchema>> {
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/attachments`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get company attachments');
    }
  }

	static async readCieTradeLogs(token: string, id: CompanySchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<CieTradeLogSchema>> {
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/log`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get company cieTrade logs');
    }
  }
}

export default Company;
