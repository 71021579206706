import React from 'react';
import classNames from 'classnames';
import Badge from '../Badge/Badge';
import Radio from '../Radio/Radio';
import Icon from '../Icon/Icon';
import DateSpan from '../DateSpan/DateSpan';
import ActionToggle from '../ActionToggle/ActionToggle';
import User from '../../models/tables/User';
import Task from '../../models/tables/Task';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import { LookupContext } from '../../contexts';
import { TaskSchema, UIOption, UserSchema } from '../../types';
import './TaskCard.scss';

export interface Props {
  id?: string;
  className?: string;
  record: TaskSchema;
  disabled?: boolean;
  variant: 'default' | 'mini';
  userKey: 'createdBy' | 'assignedTo',
  dateKey: 'created' | 'dueDate',
  isActive?: boolean;
  radioName?: string;
  getActions?: (record: TaskSchema) => UIOption[];
  onActionsChange: (value: UIOption['value'], record: TaskSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFollowChange?: (record: TaskSchema, following: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  onClick: (record: TaskSchema, e: React.MouseEvent<HTMLDivElement>) => void;
  onDoubleClick?: (record: TaskSchema, e: React.MouseEvent<HTMLDivElement>) => void;
}

export interface State {

}

class TaskCard extends React.Component<Props, State> {

  private radio = React.createRef<Radio>();

  static defaultProps = {
    variant: 'default', 
    userKey: 'assignedTo',
    dateKey: 'dueDate',
    onClick: console.info,
    onActionsChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  
  getModelByBoard(board: string): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  handleClick(record: TaskSchema, e: React.MouseEvent<HTMLDivElement>) {
    const { onClick } = this.props;
    const radio = this.radio.current;
    radio?.focus();
    onClick(record, e);
  }

  render() {
    const { getActions, onActionsChange, radioName, isActive, userKey, dateKey, variant, record, onClick, onDoubleClick, /* onRadioChange,  */disabled, /* onFocusActionClick,  */onFollowChange, className, children, ...restProps } = this.props;
    const model = this.getModelByBoard(record.board);
		const warnings = model.getWarnings(record);
    const containerClass = classNames('fourg-task-card', `fourg-task-card--variant-${variant}`, {
      'fourg-task-card--disabled': disabled,
      'fourg-task-card--following': record.following,
      'fourg-task-card--active': isActive,
      // 'fourg-task-card--error': model.isPastDue(record),
			'fourg-task-card--error': (warnings.length > 0),
    }, className);
    const priorityOption = model.getFieldOption<TaskSchema>('priority', record.priority || '');
    const statusOption = model.getFieldOption<TaskSchema>('status', record.status || '');
    const priorityField = model.getField<TaskSchema>('priority');
    const categoryField = model.getField<TaskSchema>('category');
    const statusField = model.getField<TaskSchema>('status');
    const boardField = model.getField<TaskSchema>('board');
    const actions = getActions ? getActions(record) : [];
    const date = record[dateKey];
    return (
      <LookupContext.Consumer>
        {({ users }) => { 
          const user = users?.find(user => (user.id === record[userKey]));
          return (
            <div 
            onClick={e => this.handleClick(record, e)} 
            onDoubleClick={onDoubleClick ? e => onDoubleClick(record, e) : undefined} 
            className={containerClass} 
            {...restProps}>
              <div className="fourg-task-card__header">
                <div className="fourg-task-card__header-content">
                  <h3 className="fourg-task-card__title">
                    {record.parentId && (
                      <Icon 
                      className="fourg-task-card__subtask-icon" 
                      src={{ icon: 'subdirectory_arrow_right' }} 
                      label={'Subtask'} />
                    )}
                    <span className="fourg-task-card__title-text">
                      {record.title}
                    </span> 
                    {record.archived && ( 
                      <Icon 
                      className="fourg-task-card__archived-icon" 
                      src={{ icon: 'archive' }} 
                      label={'Archived'} /> 
                    )}
									</h3>
                  <h4 className="fourg-task-card__subtitle">
                    {(variant === 'mini') && (
                      <React.Fragment>
                        <span title={boardField?.label}>{model.getFieldOptionLabel<TaskSchema>('board', record.board || '')}</span>
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                      </React.Fragment>
                    )}
                    {record.board !== 'personal' && (
                      <React.Fragment>
                        <span title={categoryField?.label}>{model.getFieldOptionLabel<TaskSchema>('category', record.category || '')}</span>
                        {variant !== 'mini' && (
                          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        )}
                      </React.Fragment>
                    )}
                    {(variant !== 'mini') && (
                      <span title={statusField?.label}>{model.getFieldOptionLabel<TaskSchema>('status', record.status || '')}</span>
                    )}
                  </h4>
                </div>
                {(variant === 'mini') && (
                  <div className="fourg-task-card__end">
                    <Badge
                    title={statusOption?.label}
                    color={statusOption?.color}
                    className="fourg-task-card__badge fourg-task-card__badge--status">
                      {model.getFieldOptionLabel<TaskSchema>('status', record.status)}
                    </Badge>
                    {date && (
                      <DateSpan 
                      className="fourg-task-card__date" 
                      date={new Date(date)} />
                    )}
                  </div>
                )}
                {(actions.length > 0) && (
                  <ActionToggle
                  disabled={disabled}
                  className="fourg-task-card__actions"
                  variant="mini"
                  icon={{ icon: 'more_vert' }}
                  label={'Actions'}
                  isIconOnly={true}
                  anchor="top-right"
                  options={actions}
                  value=""
                  onChange={(value, e) => onActionsChange(value, record, e)} />
                )}
              </div>
              {variant !== 'mini' && (
                <div className="fourg-task-card__footer">
                  {user && (
                    <Icon 
                    className="fourg-task-card__avatar"
                    label={User.getRecordLabel(user)}
                    src={{
                      icon: 'account_circle',
                      cover: User.getRecordImage<UserSchema>(user),
                    }} />
                  )}
                  {date && (
                    <DateSpan 
                    className="fourg-task-card__date" 
                    date={new Date(date)} />
                  )}
                  {record.priority && (
                    <Badge
                    title={priorityField?.label}
                    color={priorityOption?.color}
                    className="fourg-task-card__badge fourg-task-card__badge--priority">
                      {model.getFieldOptionLabel<TaskSchema>('priority', record.priority)}
                    </Badge>
                  )}
                </div>
              )}
							{warnings.length > 0 && (
								<div className="fourg-task-card__warnings" title={warnings.join('\n')}>
									<Icon src={{ icon: 'warning_amber' }} label={'Warnings'} />
									<p>{`${warnings.length.toLocaleString()} ${warnings.length === 1 ? 'Warning' : 'Warnings'}`}</p>
								</div>
							)}
              {radioName && (
                <Radio 
                ref={this.radio}
                name={radioName}
                className="fourg-task-card__radio" 
                checked={isActive} 
                readOnly={disabled}
                value={model.getRecordValue<TaskSchema>(record).toString()}
                onChange={() => {}} />
              )}
            </div>
          );
        }}
      </LookupContext.Consumer>
    );
  }
}

export default TaskCard;
