import Task from '../Task';
import User from '../User';
import Company from '../Company';
import Location from '../Location';
import { TableOptions, TableField, TableFilter } from '../../../types';

class TargetTask extends Task {

  static options: TableOptions = {
    name: 'TargetTask',
    slug: 'tasks',
    labelKey: 'title',
    valueKey: 'id',
    lookupKey: 'tasks',
    icon: 'assignment',
    defaultOrder: '-created',
  };

  static filters: TableFilter[] = [
    {
      name: 'updated',
      label: 'Task Activity',
      type: 'radio',
      options: [
        { value: '', label: 'Any time'},
        { value: 'today', label: 'Active today' },
        { value: 'week', label: 'One week or less' },
        { value: 'month', label: 'One month or less' },
        { value: 'older', label: 'More than one month' },
      ],
    },
    {
      name: 'age',
      type: 'radio',
      label: 'Task Age',
      options: [
        { value: '', label: 'Any Age'},
        { value: 'today', label: 'Added today' },
        { value: 'week', label: 'One week or less' },
        { value: 'month', label: 'One month or less' },
        { value: 'older', label: 'Older than one month' },
      ],
    },
    {
      name: 'due',
      type: 'radio',
      label: 'Due Date',
      options: [
        { value: '', label: 'Any due date' },
        { value: 'overdue', label: 'Overdue' },
        { value: 'today', label: 'Due today' },
        { value: 'week', label: 'Due this week' },
        { value: 'month', label: 'Due this month' },
      ],
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'radio',
      options: [
        { value: '', label: 'Any priority'},
        { value: 'unprioritized', label: 'Unprioritized' },
        { value: 'low', label: 'Low priority' },
        { value: 'medium', label: 'Medium priority' },
        { value: 'high', label: 'High priority' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'radio',
      options: [
        { label: 'Any Status', value: '' },
        { label: 'Research', value: 'research' },
        { label: 'Prospect', value: 'prospect' },
        { label: 'Connect', value: 'connect' },
        { label: 'Not Interested', value: 'not-interested' },
        { label: 'Do Not Call', value: 'do-not-call' },
      ],
    },
    {
      name: 'category',
      label: 'Category',
      type: 'radio',
      options: [
        { label: 'Any Category', value: '' },
        { label: 'Uncategorized', value: 'uncategorized' },
        { label: 'Mill Direct', value: 'mill-direct' },
        { label: 'Recycling', value: 'recycling' },
        { label: 'Trash', value: 'trash' },
        { label: 'Grocery Retail', value: 'grocery-retail' },
				{ label: 'Non-grocery Retail', value: 'non-grocery-retail' },
				{ label: 'Printing', value: 'printing' },
        { label: 'Manufacturing', value: 'manufacturing' },
        { label: 'Converting', value: 'converting' },
      ],
    },
    {
      name: 'assignee',
      label: 'Assignee',
      type: 'lookup-input',
      model: User,
      options: [
        { value: '', label: 'Any Assignee'},
        { value: 'unassigned', label: 'Unassigned', icon: { icon: 'person_outline' } },
        { value: 'assigned-to-me', label: 'Assigned to Me', icon: { icon: 'person' } },
      ],
    },
    {
      name: 'creator',
      label: 'Creator',
      type: 'lookup-input',
      model: User,
      options: [
        { value: '', label: 'Any Creator'},
        { value: 'created-by-me', label: 'Created by Me', icon: { icon: 'person' } },
      ],
    },
    {
      name: 'following',
      label: 'Following',
      type: 'radio',
      options: [
        { value: '', label: 'Any'},
        { value: 'true', label: 'Following' },
        { value: 'false', label: 'Not Following' },
      ],
    },
    {
      name: 'archived',
      label: 'Archived',
      type: 'radio',
      options: [
        { value: '', label: 'Unarchived' },
        { value: 'true', label: 'Archived' },
      ],
    },
  ];

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
      default: 0,
      readOnly: true,
      isInfo: true,
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      default: '',
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
			isTableColumn: true,
      // formFieldSize: 'large',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'address',
      default: {
        address1: '',
        address2: '',
        country: 'US',
        city: '',
        state: '',
        zip: '',
      },
      isFormField: true,
      // formFieldSize: 'large',
      // required: true,
      isInfo: true,
      excludedFields: [
        'address1',
        'address2',
        'zip',
      ],
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
			default: '',
      isFormField: true,
      isInfo: true,
			// formFieldSize: 'large',
    },
    {
      name: 'board',
      label: 'Board',
      type: 'select',
      default: 'customer-service',
      options: [
        { label: 'Personal', value: 'personal' },
        { label: 'Targets', value: 'targets' },
        { label: 'Sales Pipeline', value: 'new-business' },
        { label: 'Finance', value: 'finance' },
        { label: 'Customer Service', value: 'customer-service' },
      ],
      required: true,
      // isFormField: true,
      isInfo: true,
      infoSize: 'small',
      columnWidth: 180,
    },
    {
      name: 'status',
      label: 'Status',
			type: 'select',
			default: 'research',
			options: [
        { label: 'Research', value: 'research', color: '#607D8B' },
        { label: 'Prospect', value: 'prospect', color: '#03A9F4' },
        { label: 'Connect', value: 'connect', color: '#4CAF50' },
        { label: 'Not Interested', value: 'not-interested', color: '#DB584F' },
        { label: 'Do Not Call', value: 'do-not-call', color: '#B00020' },
			],
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'small',
      isTableColumn: true,
      columnWidth: 160,
    },
    {
      name: 'category',
      label: 'Category',
      type: 'select',
      default: '',
      options: [
        { label: 'Uncategorized', value: '' },
        { label: 'Mill Direct', value: 'mill-direct' },
        { label: 'Recycling', value: 'recycling' },
        { label: 'Trash', value: 'trash' },
        { label: 'Grocery Retail', value: 'grocery-retail' },
				{ label: 'Non-grocery Retail', value: 'non-grocery-retail' },
				{ label: 'Printing', value: 'printing' },
        { label: 'Manufacturing', value: 'manufacturing' },
        { label: 'Converting', value: 'converting' },
      ],
      // isSortable: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'small',
      isTableColumn: true,
      columnWidth: 180,
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'select',
      default: 'low',
      options: [
        // { label: 'Unprioritized', value: '' },
        { label: 'Low', value: 'low', color: '#2FA0B5' },
        { label: 'Medium', value: 'medium', color: '#E8AD55' },
        { label: 'High', value: 'high', color: '#D04B52' },
      ],
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'small',
      isTableColumn: true,
      columnWidth: 130,
    },
		{
			name: 'dueDate',
			label: 'Due Date',
			type: 'date',
			default: '',
			isSortable: true,
      isFormField: true,
      isInfo: true,
			isTableColumn: true,
      columnWidth: 140,
      formFieldSize: 'medium',
		},
		{
			name: 'assignedTo',
			label: 'Assigned To',
			type: 'lookup-input',
      model: User,
      // options: [
      //   { label: 'Unassigned', value: '' },
      // ],
      required: true,
			default: '',
      isFormField: true,
      isInfo: true,
      formFieldSize: 'medium',
      infoSize: 'large',
			isTableColumn: true,
      columnWidth: 180,
    },
    {
      name: 'companyId',
      label: 'Company',
      type: 'lookup',
      model: Company,
      options: [
        { label: 'Unassigned', value: '' },
      ],
      default: '',
      isFormField: true,
      isInfo: true,
      // formFieldSize: 'medium',
    },
    {
      name: 'locationId',
      label: 'Location',
      type: 'lookup-company-section',
      model: Location,
      options: [
        { label: 'Unassigned', value: '' },
      ],
      default: '',
      isFormField: true,
      isInfo: true,
      // formFieldSize: 'medium',
    },
    {
      name: 'members',
      label: 'Members',
      type: 'lookup-multiple',
      model: User,
      default: [],
    },
    {
      name: 'commentCount',
      label: 'Comments',
      type: 'number',
      default: 0,
      isSortable: true,
      isTableColumn: true,
      icon: { icon: 'chat_bubble_outline' },
      columnWidth: 80,
    },
		{
			name: 'createdBy',
			label: 'Created By',
			type: 'lookup-input',
      model: User,
			default: '',
      readOnly: true,
      isInfo: true,
		},
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      // isTableColumn: true,
      isInfo: true,
    },
    {
      name: 'updated',
      label: 'Updated', 
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
    }
  ];

	static completedStatuses: string[] =  ['not-interested', 'do-not-call'];
}

export default TargetTask;
