import React from 'react';
import classNames from 'classnames';
import './CardListItem.scss';

export interface Props {
  id?: string;
  className?: string;
  children?: React.ReactElement;
}

export interface State {

}

class CardListItem extends React.Component<Props, State> {

  render() {
    const { className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-card-list-item', className);
    return (
      <li className={containerClass} {...restProps}>
        {children}
      </li>
    );
  }
}

export default CardListItem;
