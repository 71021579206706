import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import Icon, { Props as IconProps } from '../Icon/Icon';
import './Checkbox.scss';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  uncheckedLabel?: string;
  checkedLabel?: string;
  uncheckedIcon?: IconProps['src'];
  checkedIcon?: IconProps['src'];
  title?: string;
  checked: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface State {
  isFocused: boolean;
  isValid: boolean;
  internalID: string;
}

class Checkbox extends React.Component<Props, State> {

  private input = React.createRef<HTMLInputElement>();

  static defaultProps = {
    checked: true,
    checkedIcon: { icon: 'check_box' },
    uncheckedIcon: { icon: 'check_box_outline_blank' },
    onChange: console.info,
  }

  constructor(props: Props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      isFocused: false,
      isValid: true,
      internalID: props.id || uniqid('Checkbox-'),
    };
  }

  componentDidMount() {
    const input = this.input.current;
    if (input) {
      this.setState({ isValid: input.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { id, required, checked, disabled } = this.props;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('Checkbox-') });
    }
    if ((prevProps.checked !== checked)
    || (prevProps.required !== required)) {
      const input = this.input.current;
      if (input) {
        this.setState({ isValid: input.checkValidity() });
      }
    }
    if (! prevProps.disabled && disabled) {
      this.setState({ isFocused: false });
    }
  }

  handleFocus() {
    const { onFocus } = this.props;
    if (onFocus) onFocus();
    this.setState({ isFocused: true });
  }

  handleBlur() {
    const { onBlur } = this.props;
    if (onBlur) onBlur();
    this.setState({ isFocused: false });
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  isValid() {
    const input = this.input.current;
    return input?.checkValidity() || false;
  }

  render() {
    const { required, disabled, readOnly, label, title, checkedLabel, uncheckedLabel, checkedIcon, uncheckedIcon, checked, onChange, onFocus, onBlur, className, ...restProps } = this.props;
    const { isFocused } = this.state;
    const containerClass = classNames('fourg-checkbox', {
      'fourg-checkbox--focused': isFocused,
      'fourg-checkbox--disabled': disabled || readOnly,
      'fourg-checkbox--checked': checked,
    }, className);
    return (
      <label className={containerClass} title={title}>
        <input
        ref={this.input}
        type="checkbox"
        className="fourg-checkbox__input"
        checked={checked}
        disabled={disabled}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={e => onChange(! checked, e)}
        required={required}
				readOnly={readOnly}
        {...restProps} />
        <Icon 
        src={checkedIcon} 
        className="fourg-checkbox__icon fourg-checkbox__icon--checked"
        label={checkedLabel || label || ''} />
        <Icon 
        src={uncheckedIcon} 
        className="fourg-checkbox__icon fourg-checkbox__icon--unchecked"
        label={uncheckedLabel || label || ''} />
        {label && (
          <span className="fourg-checkbox__label">
            {`${label}${required ? '*' : ''}`}
          </span>
        )}
      </label>
    );
  }
}

export default Checkbox;
