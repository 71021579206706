
import React from 'react';
import classNames from 'classnames';
import Chip, { Props as ChipProps } from '../Chip/Chip';
import { UIOption } from '../../types';
import './ChipList.scss';

export interface Props {
  id?: string;
  className?: string;
	name?: string;
  options: UIOption[];
  value: UIOption['value'][];
  label?: string;
  disabled?: boolean;
  variant?: ChipProps['variant'],
  required?: boolean;
  multiple?: boolean;
	readOnly?: boolean;
  onChange: (value: UIOption['value'][], e: React.MouseEvent<HTMLButtonElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface State {
  isFocused: boolean;
  isValid: boolean;
}

class ChipList extends React.Component<Props, State> {

  private input = React.createRef<HTMLInputElement>();

  static defaultProps = {
    multiple: true,
    value: [],
    options: [],
    onChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleChipClick = this.handleChipClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      isFocused: false,
      isValid: true,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const input = this.input.current;
    if (value && input) {
      this.setState({ isValid: input.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { value, required, onBlur, onFocus } = this.props;
    const { isFocused } = this.state;
    if (prevState.isFocused && ! isFocused) {
      if (onBlur) onBlur();
    }
    if (! prevState.isFocused && isFocused) {
      if (onFocus) onFocus();
    }
    if ((prevProps.value !== value)
    || (prevProps.required !== required)) {
      const input = this.input.current;
      if (input) {
        this.setState({ isValid: input.checkValidity() });
      }
    }
  }

  handleChipClick(chipValue: UIOption['value'], isActive: boolean, e: React.MouseEvent<HTMLButtonElement>) {
    const { multiple, value, onChange } = this.props;
    let newValue = [...value];
    if (isActive) {
      if (! multiple) {
        newValue = [chipValue];
      } else if (! newValue.includes(chipValue)) {
        newValue.push(chipValue);
      }
    } else {
      newValue = newValue.filter(v => v !== chipValue);
    }
    onChange(newValue, e);
  }

  handleFocus() {
    const { isFocused } = this.state;
    if (! isFocused) {
      this.setState({ isFocused: true });
    }
  }

  handleBlur() {
    const { isFocused } = this.state;
    if (isFocused) {
      this.setState({ isFocused: false });
    }
  }

  render() {
    const { name, onFocus, onBlur, readOnly, required, multiple, variant, disabled, label, value, onChange, options, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-chip-list', className);
    return (
      <div className={containerClass} {...restProps}>
        {label && (
          <label
          className="fourg-chip-list__label"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}>{required ? `${label}*` : label}</label>
        )}
        {(options.length > 0) && (
          <ul className="fourg-chip-list__list">
            {options.map(option => (
              <li
              key={`chip-list-item-${option.value}`}
              className="fourg-chip-list__item">
                <Chip
                variant={variant}
                disabled={(disabled || option.disabled || readOnly)}
                isActive={value.includes(option.value)}
                onClick={this.handleChipClick}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                {...option} />
              </li>
            ))}
          </ul>
        )}
        <input
				name={name}
				tabIndex={-1}
        ref={this.input}
        className="fourg-chip-list__validator"
        type="checkbox"
        required={required}
        disabled={disabled}
				readOnly={readOnly}
        checked={(value.length > 0)}
        onChange={() => {}} />
      </div>
    );
  }
}

export default ChipList;
