import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import Icon, { Props as IconProps } from '../Icon/Icon';
import ActionList, { Props as ActionListProps } from '../ActionList/ActionList';
import ActionListItem from '../ActionListItem/ActionListItem';
import { UIOption } from '../../types';
import './Select.scss';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  variant?: 'default' | 'quiet' | 'mini' | 'link',
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  label: string;
  title?: string;
  options: UIOption[];
  value: string;
  icon?: IconProps['src'];
  anchor?: ActionListProps['anchor'];
  isListOverlaid?: boolean;
  tabIndex?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (value: string, e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export interface State {
  isFocused: boolean;
  isValid: boolean;
  isExpanded: boolean;
  internalID: string;
}

class Select extends React.Component<Props, State> {

  private select = React.createRef<HTMLSelectElement>();
  private ui = React.createRef<HTMLDivElement>();
  private expand = React.createRef<HTMLButtonElement>();

  static defaultProps = {
    options: [],
    variant: 'default',
    isListOverlaid: true,
    onChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleWindowKeyPress = this.handleWindowKeyPress.bind(this);
    this.handleWindowKeyDown = this.handleWindowKeyDown.bind(this);
    this.handleWindowClick = this.handleWindowClick.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleUILabelClick = this.handleUILabelClick.bind(this);
    this.state = {
      isFocused: false,
      isValid: true,
      isExpanded: false,
      internalID: props.id || uniqid('Select-'),
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const { isExpanded } = this.state;
    const select = this.select.current;
    if (value && select) {
      this.setState({ isValid: select.checkValidity() });
    }
    if (isExpanded) {
      window.addEventListener('click', this.handleWindowClick);
      window.addEventListener('keypress', this.handleWindowKeyPress);
      window.addEventListener('keydown', this.handleWindowKeyDown);
    } 
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { id, value, required, disabled, options } = this.props;
    const { isExpanded } = this.state;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('Select-') });
    }
    if ((prevProps.value !== value)
    || (prevProps.required !== required)
    || (prevProps.options.length !== options.length)) {
      const select = this.select.current;
      if (select) {
        this.setState({ isValid: select.checkValidity() });
      }
    }
    if (! prevProps.disabled && disabled) {
      this.setState({ isFocused: false });
    }
    if (! prevState.isExpanded && isExpanded) {
      window.addEventListener('click', this.handleWindowClick);
      window.addEventListener('keypress', this.handleWindowKeyPress);
      window.addEventListener('keydown', this.handleWindowKeyDown);
      window.addEventListener('resize', this.handleWindowResize);
    }
    if (prevState.isExpanded && ! isExpanded) {
      window.removeEventListener('click', this.handleWindowClick);
      window.removeEventListener('keypress', this.handleWindowKeyPress);
      window.removeEventListener('keydown', this.handleWindowKeyDown);
      window.removeEventListener('resize', this.handleWindowResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
    window.removeEventListener('keypress', this.handleWindowKeyPress);
    window.removeEventListener('keydown', this.handleWindowKeyDown);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.setState({ isExpanded: false });
  }

  handleWindowClick(e: MouseEvent) {
    const ui = this.ui.current;
    if (ui && (e.target instanceof Node) && ! ui.contains(e.target)) {
      this.setState({ isExpanded: false });
    }
  }

  handleWindowKeyPress(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      const expand = this.expand.current;
      expand?.focus();
      this.setState({ isExpanded: false });
    }
  }
  
  handleWindowKeyDown(e: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
      e.preventDefault();
    }
  }

  handleFocus() {
    const { onFocus } = this.props;
    const expand = this.expand.current;
    expand?.focus();
    this.setState({ isFocused: true });
    if (onFocus) onFocus();
  }

  handleBlur() {
    const { onBlur } = this.props;
    const expand = this.expand.current;
    expand?.blur();
    this.setState({ isFocused: false });
    if (onBlur) onBlur();
  }

  handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { onChange } = this.props;
    onChange(e.target.value, e);
  }

  handleExpandClick(e: React.MouseEvent<HTMLButtonElement>) {
    const { onClick } = this.props;
    this.setState(({ isExpanded }) => {
      return {
        isExpanded: ! isExpanded,
      };
    });
    if (onClick) onClick(e);
  }

  handleActionClick(newValue: string, e: React.MouseEvent<HTMLButtonElement>) {
    this.triggerChange(newValue);
    const expand = this.expand.current;
    expand?.focus();
    this.setState({ isExpanded: false });
  }

  triggerChange(newValue: UIOption['value']) {
    const { value } = this.props;
    const select = this.select.current;
    if (select && (newValue !== value)) {
      select.value = newValue;
      const changeEvent = new Event('change', { bubbles: true });
      select.dispatchEvent(changeEvent);
    }
  }

  handleUILabelClick(e: React.MouseEvent<HTMLLabelElement>) {
    this.focus();
  }

  getOption(value: string) {
    const { options } = this.props;
    return options.find(option => (option.value === value));
  }

  open() {
    this.setState({ isExpanded: true });
  }

  close() {
    this.setState({ isExpanded: false });
  }

  focus() {
    const expand = this.expand.current;
    expand?.focus();
  }

  blur() {
    const expand = this.expand.current;
    expand?.blur();
  }

  hasFocus() {
    const { isFocused } = this.state;
    return isFocused;
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  isValid() {
    const select = this.select.current;
    return select?.checkValidity() || false;
  }

  render() {
    const { required, readOnly, title, isListOverlaid, anchor, id, label, icon, value, disabled, options, onChange, variant, tabIndex, className, onClick, children, ...restProps } = this.props;
    const { isFocused, isValid, isExpanded, internalID } = this.state;
    const selectedOption = this.getOption(value);
    const containerClass = classNames('fourg-select', `fourg-select--variant-${variant}`, {
      'fourg-select--dirty': Boolean(value || isExpanded),
      'fourg-select--focused': (isFocused || isExpanded),
      'fourg-select--disabled': disabled,
      'fourg-select--invalid': ! isValid,
      'fourg-select--expanded': isExpanded,
      'fourg-select--has-icon': Boolean(selectedOption?.icon || icon),
      'fourg-select--list-overlaid': isListOverlaid,
    }, className);
    return (
      <div className={containerClass}>
        <div className="fourg-select__ui" ref={this.ui}>
          <div className="fourg-select__ui-preview">
            <label 
            className="fourg-select__ui-label" 
            onClick={this.handleUILabelClick}>
              {`${label}${required ? '*' : ''}`}
            </label>
            <div className="fourg-select__ui-expand-container">
              <button 
              title={title}
              tabIndex={tabIndex}
              ref={this.expand}
              type="button" 
              className="fourg-select__ui-expand"
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onClick={this.handleExpandClick}
              disabled={(disabled || readOnly)}>
                {(selectedOption?.icon || icon) && (
                  <Icon 
                  className="fourg-select__ui-icon" 
                  src={selectedOption?.icon || icon} 
                  label={label} />
                )}
                <span className="fourg-select__ui-value">
                  {selectedOption?.label || value}&nbsp;
                </span>
                <Icon 
                className="fourg-select__ui-caret" 
                src={{ icon: 'arrow_drop_down' }} 
                label="Expand" />
              </button>
            </div>
          </div>
          {(options.length > 0) && (
            <ActionList 
            isOverlaid={isListOverlaid}
            triggerRef={this.ui}
            anchor={anchor}
            className="fourg-select__ui-actions" 
            isExpanded={isExpanded}>
              {options.map(option => (
                <ActionListItem
                key={`action-${option.value}`}
                disabled={(disabled || readOnly || option.disabled)}
                onClick={this.handleActionClick}
                isActive={(option.value === value)}
                {...option} />
              ))}
            </ActionList>
          )}
        </div>
        <div className="fourg-select__native">
          <label className="fourg-select__label" htmlFor={internalID}>{label}</label>
          <select 
          ref={this.select}
          id={internalID}
          value={value}
          disabled={(disabled || readOnly)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          title={title}
          tabIndex={-1}
          className="fourg-select__select" 
          required={required}
          {...restProps}>
            {(options.length > 0) && options.map(option => (
              <option 
              disabled={(disabled || readOnly || option.disabled)}
              key={`option-${option.value}`} 
              value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default Select;
