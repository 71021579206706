import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import FormActions from '../FormActions/FormActions';
import Table from '../../models/Table';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import { TableField, Auth, CompanySchema, LocationSchema, ContactSchema } from '../../types';
import './ResourceSectionQuickAddFormDialog.scss';

export type sectionRecordSchema = LocationSchema | ContactSchema;

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  submitLabel: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  resourceID: CompanySchema['id'] | LocationSchema['id'];
	resourceModel: typeof Company | typeof Location;
	model: typeof Table;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSecondary: (record: sectionRecordSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: sectionRecordSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  record: sectionRecordSchema;
  isValid: boolean;
}

class ResourceSectionQuickAddFormDialog extends React.Component<Props, State> {

  private formFields: React.RefObject<FormFieldByField<sectionRecordSchema>>[] = [];

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSecondary: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.state = {
      record: this.getDefaultRecord(),
      isValid: this.isValid(),
    };
  }

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isOpen } = this.props;
    const isValid = this.isValid();
    if (prevProps.isOpen && ! isOpen) {
      this.setDefaultRecord();
    }
    if (prevState.isValid !== isValid) {
      this.setState({ isValid: isValid });
    }
  }

  getDefaultRecord() {
    const { resourceID, resourceModel, model } = this.props;
		const resourceModelOptions = resourceModel.getOptions();
    const defaultRecord: sectionRecordSchema = {
      ...model.getDefaultRecord<sectionRecordSchema>(),
    };
		if (resourceModelOptions.name === 'Company') {
			(defaultRecord as LocationSchema).companyId = resourceID;
		} else if (resourceModelOptions.name === 'Location') {
			(defaultRecord as ContactSchema).locationId = resourceID;
		}
    return defaultRecord;
  }

  setDefaultRecord() {
    this.setState({
      record: this.getDefaultRecord(),
    }, () => this.resetValidity());
  }

  handleFieldChange(field: TableField<sectionRecordSchema>, value: any, e: any) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          [field.name]: value,
        },
      });
    }
  }

  resetValidity() {
    if (this.formFields.length > 0) {
      this.formFields.forEach(formField => formField.current?.resetValidity());
    }
  }

  isValid() {
    let isValid = true;
    const formFields = [...this.formFields];
    if (formFields.find(formField => (formField.current && ! formField.current.isValid()))) {
      isValid = false;
    }
    return isValid;
  }

  render() {
    const { model, resourceID, size, disabled, isOpen, title, auth, className, submitLabel, secondaryLabel, cancelLabel, onFormCancel, onFormSubmit, onFormSecondary, resourceModel, ...restProps } = this.props;
    const { record, isValid } = this.state;
    const containerClass = classNames('fourg-resource-section-quick-add-form-dialog', className);
    const fields = model.getFieldsBy<sectionRecordSchema>('isQuickAddField', true);
    return ReactDOM.createPortal(
      <Dialog 
      className={containerClass}
      title={title}
      isOpen={isOpen}
      size="small"
      {...restProps}>
        {(fields.length > 0) && (
          <div className="fourg-resource-section-quick-add-form-dialog__form">
            <div className="fourg-resource-section-quick-add-form-dialog__form-fields">
              {fields.map((field, i) => (
                <FormFieldByField<sectionRecordSchema>
                className={"fourg-resource-section-quick-add-form-dialog__form-field"}
                formContext="quick-add"
                ref={formField => this.formFields[i] = { current: formField }}
                record={record}
								recordModel={model}
                key={`form-field-${field.name}`} 
                field={field}
                value={record[field.name]}
                disabled={(disabled || ! isOpen)}
                onChange={(value, e) => this.handleFieldChange(field, value, e)} />
              ))}
            </div>
            <FormActions
            submitLabel={submitLabel}
            secondaryLabel={secondaryLabel}
            cancelLabel={cancelLabel}
            onCancel={onFormCancel}
            onSecondary={e => onFormSecondary(record, e)}
            onSubmit={e => onFormSubmit(record, e)}
            disabled={(disabled || ! isOpen)}
            isValid={isValid} />
          </div>
        )}
      </Dialog>,
			document.body
    );
  }
}

export default ResourceSectionQuickAddFormDialog;
