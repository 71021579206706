import React from 'react';
import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import './BoardColumn.scss';
import Loader from '../Loader/Loader';

export interface Props<T extends Record<string, any> = Record<string, any>> {
  id?: string;
  className?: string;
  label: string;
  value: keyof T;
  disabled?: boolean;
  isHighlighted?: boolean;
  isLoading?: boolean;
  total?: number,
}

export interface State {

}

class BoardColumn<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State> {

  render() {
    const { isLoading, total, isHighlighted, disabled, value, label, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-board-column', {
      'fourg-board-column--disabled': disabled,
      'fourg-board-column--highlighted': isHighlighted,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-board-column__header">
          <div className="fourg-board-column__header-content">
            <h3 className="fourg-board-column__label">{label}</h3>
            {(total !== undefined) && (
              <span className="fourg-board-column__total">{total}</span>
            )}
          </div>
        </div>
        {isLoading ? (
          <Loader
          className="fourg-board-column__loader"
          size={32} />
        ) : (
          <Droppable
          droppableId={value.toString()}
          isDropDisabled={disabled}>
            {(provided) => (
              <div
              className="fourg-board-column__handles"
              ref={provided.innerRef}
              {...provided.droppableProps}>
                {children}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
    );
  }
}

export default BoardColumn;
