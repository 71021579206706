import React from 'react';
import classNames from 'classnames';
import LoaderPill from '../LoaderPill/LoaderPill';
import CardList from '../CardList/CardList';
import CardListItem from '../CardListItem/CardListItem';
import CommentCard from '../CommentCard/CommentCard';
import Editor from '../Editor/Editor';
import { LookupContext, AuthContext } from '../../contexts';
import { ActionSchema, TaskSchema } from '../../types';
import './TaskComments.scss';

export interface Props {
  id?: string;
  className?: string;
  records: ActionSchema[];
  task: TaskSchema;
  total: number;
  disabled?: boolean;
  isLoading?: boolean;
  onCommentSubmit: (value: string) => void;
  onCommentDelete: (record: ActionSchema) => void;
  onCommentEditSubmit: (record: ActionSchema) => void;
  onLoadMore: () => void;
  onSubtaskCardClick?: (record: TaskSchema) => void;
}

export interface State {
  // commentValue: string;
  loadMoreTimer?: number;
  editCommentID?: ActionSchema['id'];
}

class TaskComments extends React.Component<Props, State> {

  private scroller = React.createRef<HTMLDivElement>();
  private editor = React.createRef<Editor>();
  private commentCards: React.RefObject<CommentCard>[] = [];

  static defaultProps = {
    records: [],
    total: 0,
    onCommentSubmit: console.info,
    onCommentEditSubmit: console.info,
    onCommentDelete: console.info,
  };

  constructor(props: Props) {
    super(props);
    // this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleCommentEditAction = this.handleCommentEditAction.bind(this);
    this.handleCommentEditCancel = this.handleCommentEditCancel.bind(this);
    this.state = {
      // commentValue: '',
    };
  }
  
  componentDidMount() {
    this.scrollToStart();
  }

  scrollToStart() {
    const scroller = this.scroller.current;
    if (scroller) {
      scroller.scrollTop = scroller.scrollHeight;
    }
  }

  setEmptyComment() {
    const editor = this.editor.current;
    editor?.setValue('');
  }

  handleScroll(e: React.UIEvent<HTMLDivElement>) {
    const { isLoading, onLoadMore, records, total } = this.props;
    const scroller = this.scroller.current;
    if (scroller && (scroller.scrollTop < 1)) {
      scroller.scrollTop = 1;
      if (! isLoading && (records.length < total)) {
        onLoadMore();
      }
    }
  }

  handleCommentEditAction(record: ActionSchema) {
    this.setState({ editCommentID: record.id });
  }

  handleCommentDeleteAction(record: ActionSchema) {
    this.setState({ editCommentID: record.id });
  }

  getCommentCardRef(id: ActionSchema['id']) {
    return this.commentCards[id].current;
  }

  handleCommentEditCancel() {
    this.setState({ editCommentID: undefined });
  }

  finishEdit() {
    const { editCommentID } = this.state;
    if (editCommentID) {
      const commentCard = this.getCommentCardRef(editCommentID);
      commentCard?.finishEdit();
    }
    this.setState({ editCommentID: undefined });
  }

  setCommentEditorValue(record: ActionSchema, isForced: boolean = true) {
    const commentCard = this.getCommentCardRef(record.id);
    commentCard?.setEditorValue(record.content, isForced);
  }

  render() {
    const { task, onSubtaskCardClick, onCommentSubmit, onCommentEditSubmit, onCommentDelete, onLoadMore, disabled, isLoading, records, total, className, children, ...restProps } = this.props;
    const { /* commentValue,  */editCommentID } = this.state;
    const containerClass = classNames('fourg-task-comments', className);
    const recordsReversed = [...records].reverse();
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-task-comments__start">
          {isLoading && (
            <LoaderPill>
              {'Loading More...'}
            </LoaderPill>
          )}
        </div>
        <div className="fourg-task-comments__surface">
          {(recordsReversed && (recordsReversed.length > 0)) && (
            <div 
            ref={this.scroller} 
            className="fourg-task-comments__scroller" 
            onScroll={this.handleScroll}>
              <CardList className="fourg-task-comments__cards" gutterSize="stretch">
                {recordsReversed.map(record => (
                  <CardListItem key={`comment-${record.id.toString()}`}>
                    <CommentCard 
                    ref={commentCard => this.commentCards[record.id] = { current: commentCard }}
                    task={task}
                    record={record} 
                    onFormSubmit={onCommentEditSubmit}
                    onEditAction={this.handleCommentEditAction}
                    onDeleteAction={onCommentDelete}
                    onFormCancel={this.handleCommentEditCancel}
                    onSubtaskCardClick={onSubtaskCardClick}
                    disabled={Boolean(task.archived || disabled || (editCommentID && (editCommentID !== record.id)))} />
                  </CardListItem>
                ))}
              </CardList>
            </div>
          )}
        </div>
        <div className="fourg-task-comments__end">
          <AuthContext.Consumer>
            {auth => (
              <LookupContext.Consumer>
                {lookup => (
                  <Editor
                  auth={auth}
                  className="fourg-task-comments__editor"
                  ref={this.editor}
                  label={'Comment'}
                  disabled={disabled || task.archived}
                  required={true}
                  lookup={lookup}
                  isScrollable={true}
                  placeholder={'Type to post a comment...'}
                  onEnter={onCommentSubmit}
                  />
                )}
              </LookupContext.Consumer>
            )}
          </AuthContext.Consumer>
        </div>
      </div>
    );
  }
}

export default TaskComments;
