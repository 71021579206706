import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import ErrorPage from '../ErrorPage/ErrorPage';
import Task from '../../models/tables/Task';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import Contact from '../../models/tables/Contact';
import User from '../../models/tables/User';
import { LayoutContext, LookupContext } from '../../contexts';
import { APIError } from '../../errors';
import { TaskSchema, UserSchema, Lookup, Socket, Auth, CompanySchema, LocationSchema, GradeSchema, ExpenseSchema, Address, AttachmentSchema, ReminderSchema, ContactSchema } from '../../types';
import { mergeRecords } from '../../utils';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { State as LayoutState } from '../../components/Layout/Layout';
import Page from '../../components/Page/Page';
import './TaskPrintPage.scss';

export interface RouteParams {
  id: string;
}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
  socket: Socket;
	layout: LayoutState;
}

export interface State {
  record?: TaskSchema;
  companyRecord?: CompanySchema;
  locationRecord?: LocationSchema;
  lookup: Lookup;
  isLoading: boolean;
  error?: APIError;
}

class TaskPrintPageBase extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
			record: undefined,
			companyRecord: undefined,
			locationRecord: undefined,
      isLoading: false,
      lookup: {},
			error: undefined,
    };
  }

  componentDidMount() {
		const { layout } =this.props;
		layout.makeBodyScrollable();
		layout.hideHeader();
    this.readRecord();
  }

  componentDidUpdate(prevProps: Props) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.readRecord();
    }
  }

	componentWillUnmount() {
		const { layout } =this.props;
		layout.makeBodyUnscrollable();
		layout.showHeader();
	}

  getModelByBoard(board: string = 'peronal'): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  async readRecord() {
    const { auth, match } = this.props;
    this.setState({
      isLoading: true,
      error: undefined,
    });
    try {
      const token = await auth.getToken();
      const readDate = new Date();
      const { meta: taskMeta, data: taskData } = await Task.readRecord<TaskSchema>(token, match.params.id, {
        created: `-${readDate.toISOString()}`,
      });
			const { meta: companyMeta, data: companyData } = await Company.readRecord<CompanySchema>(token, taskData.companyId?.toString() || '');
			const { meta: locationMeta, data: locationData } = await Location.readRecord<LocationSchema>(token, taskData.locationId?.toString() || '');
			const newUsers = mergeRecords<UserSchema>(taskMeta?.users || [], auth.user ? [auth.user] : []);
      this.setState({
        record: taskData,
				companyRecord: companyData,
				locationRecord: locationData,
        lookup: {
          users: mergeRecords<UserSchema>(newUsers, [...companyMeta.users || [], ...locationMeta.users || []]),
          companies: mergeRecords<CompanySchema>(taskMeta.companies, [...companyMeta.companies || [], ...locationMeta.companies || []]),
          locations: mergeRecords<LocationSchema>(taskMeta.locations, [...companyMeta.locations || [], ...locationMeta.locations || []]),
          tasks: mergeRecords<TaskSchema>(taskMeta.tasks, [...companyMeta.tasks || [], ...locationMeta.tasks || []]),
          attachments: mergeRecords<AttachmentSchema>(taskMeta.attachments, [...companyMeta.attachments || [], ...locationMeta.attachments || []]),
          reminders: mergeRecords<ReminderSchema>(taskMeta.reminders, [...companyMeta.reminders || [], ...locationMeta.reminders || []]),
					grades: mergeRecords<GradeSchema>(taskMeta.grades, [...companyMeta.grades || [], ...locationMeta.grades || []]),
					expenses: mergeRecords<ExpenseSchema>(taskMeta.expenses, [...companyMeta.expenses || [], ...locationMeta.expenses || []]),
					contacts: mergeRecords<ContactSchema>(taskMeta.contacts, [...companyMeta.contacts || [], ...locationMeta.contacts || []]),
        },
        isLoading: false,
      });
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        record: undefined,
        companyRecord: undefined,
        locationRecord: undefined,
        lookup: {},
        isLoading: false,
        error: error as Error,
      });
    }
  }

	getGrades(): GradeSchema[] {
		const { record, lookup } = this.state;
		return lookup.grades?.filter(g => record?.grades?.includes(g.id)) || [];
	}

	getExpenses(): ExpenseSchema[] {
		const { record, lookup } = this.state;
		return lookup.expenses?.filter(e => record?.expenses?.includes(e.id)) || [];
	}

	formatAddress(value: Address): string {
    let formatted = '';
    if (value && (typeof value === 'object')) {
      formatted = Object.values(value).filter(piece => Boolean(piece)).join(', ');
    }
    return formatted;
  }

	getCompanyPrimaryContact(): ContactSchema | undefined {
		const { companyRecord, lookup } = this.state;
		const { lookupKey } = Contact.getOptions();
		let found: ContactSchema | undefined = undefined;
		if (lookupKey) {
			const lookupRecords: ContactSchema[] = lookup[lookupKey] as ContactSchema[] || [];
			found = lookupRecords.find(r => r.id === companyRecord?.primaryContactId);
		}
		return found;
	}

	getLocationSchedulingContact(): ContactSchema | undefined {
		const { locationRecord, lookup } = this.state;
		const { lookupKey } = Contact.getOptions();
		let found: ContactSchema | undefined = undefined;
		if (lookupKey) {
			const lookupRecords: ContactSchema[] = lookup[lookupKey] as ContactSchema[] || [];
			found = lookupRecords.find(r => r.id === locationRecord?.schedulingContactId);
		}
		return found;
	}

	getLocationAlternateContact(): ContactSchema | undefined {
		const { locationRecord, lookup } = this.state;
		const { lookupKey } = Contact.getOptions();
		let found: ContactSchema | undefined = undefined;
		if (lookupKey) {
			const lookupRecords: ContactSchema[] = lookup[lookupKey] as ContactSchema[] || [];
			found = lookupRecords.find(r => r.id === locationRecord?.alternateContactId);
		}
		return found;
	}

	getAssignee(): UserSchema | undefined {
		const { record, lookup } = this.state;
		const { lookupKey } = User.getOptions();
		let found: UserSchema | undefined = undefined;
		if (lookupKey) {
			const lookupRecords: UserSchema[] = lookup[lookupKey] as UserSchema[] || [];
			found = lookupRecords.find(r => r.id === record?.assignedTo);
		}
		return found;
	}

  render() {
    const { className, match } = this.props;
    const { record, companyRecord, locationRecord, isLoading, lookup, error } = this.state;
    const containerClass = classNames('fourg-task-print-page', className);
    const model = this.getModelByBoard(record?.board);
    const taskLabel = record ? model.getRecordLabel<TaskSchema>(record) : `${model.getLabel('singular')} ${match.params.id}`;
		const primaryContactRecord = this.getCompanyPrimaryContact();
		const schedulingContactRecord = this.getLocationSchedulingContact();
		const alternateContactRecord = this.getLocationAlternateContact();
		const assigneeRecord = this.getAssignee();
		const generatedDate = new Date();
    return isLoading ? (
      <Loader position="fixed" />
    ) : ! record ? (
      <Route render={(routeProps) => (
        <ErrorPage status={error?.status} message={error?.message} {...routeProps} />
      )} />
    ) : (
      <LookupContext.Provider value={lookup}>
				<Page title={taskLabel} className={containerClass}>
					<div className="fourg-task-print-page__top">
						<Link to={`/tasks/${record.id}`} className="fourg-task-print-page__back-link">{`← Back to task`}</Link>
					</div>
					<div className="fourg-task-print-page__header">
						<div className="fourg-task-print-page__logo">
							<Logo />
						</div>
						<div className="fourg-task-print-page__doc-info">
							<h1 className="fourg-task-print-page__title">{taskLabel}</h1>
							<span className="fourg-task-print-page__meta">
								<span>{`Sales Rep: ${User.getRecordLabel(assigneeRecord)}`}</span>
								<span> • </span>
								<span>{`Generated on ${generatedDate.toLocaleDateString()} at ${generatedDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`}</span>
							</span>
						</div>
					</div>
					<div className="fourg-task-print-page__sections">
						{companyRecord && (
							<div className="fourg-task-print-page__section">
								<div className="fourg-task-print-page__section-header">
									<h2 className="fourg-task-print-page__section-title">
										{'Corporate Information'}
									</h2>
								</div>
								<div className="fourg-task-print-page__table">
									<InfoNode label={'Company Name'} width={50}>
										{Company.getRecordLabel(companyRecord)}
									</InfoNode>
									<InfoNode label={'Pay To Name'} width={50}>
										{companyRecord.payTo}
									</InfoNode>
									<InfoNode label={'Payment Method'} width={50}>
										{companyRecord.paymentMethod}
									</InfoNode>
									<InfoNode label={'Payment Terms'} width={50}>
										{companyRecord.paymentTerms}
									</InfoNode>
									<InfoNode label={'Phone'} width={50}>
										{companyRecord.primaryLocation.phone}
									</InfoNode>
									<InfoNode label={'Fax'} width={50}>
										{companyRecord.primaryLocation.fax}
									</InfoNode>
									<InfoNode label={'Address'}>
										{this.formatAddress(companyRecord.primaryLocation.address)}
									</InfoNode>
									<InfoNode label={'Primary Contact Name'}>
										{primaryContactRecord ? Contact.getRecordLabel(primaryContactRecord) : ''}
									</InfoNode>
									<InfoNode label={'Primary Contact Title'} width={33.33333}>
										{primaryContactRecord?.title}
									</InfoNode>
									<InfoNode label={'Primary Contact Phone'} width={33.33333}>
										{primaryContactRecord?.phone}
									</InfoNode>
									<InfoNode label={'Primary Contact Email'} width={33.33333}>
										{primaryContactRecord?.email}
									</InfoNode>
								</div>
							</div>
						)}
						{locationRecord && (
							<div className="fourg-task-print-page__section">
								<div className="fourg-task-print-page__section-header">
									<h2 className="fourg-task-print-page__section-title">
										{'Service Location Information'}
									</h2>
								</div>
								<div className="fourg-task-print-page__table">
									<InfoNode label={'Location Name'}>
										{Location.getRecordLabel(locationRecord)}
									</InfoNode>
									<InfoNode label={'Type'} width={33.33333}>
										{Location.getFieldOptionLabel('type', locationRecord.type)}
									</InfoNode>
									<InfoNode label={'Phone'} width={33.33333}>
										{locationRecord.phone}
									</InfoNode>
									<InfoNode label={'Fax'} width={33.33333}>
										{locationRecord.fax}
									</InfoNode>
									<InfoNode label={'Address'}>
										{this.formatAddress(locationRecord.address)}
									</InfoNode>
									<InfoNode label={'Scheduling Contact Name'}>
										{schedulingContactRecord ? Contact.getRecordLabel(schedulingContactRecord) : ''}
									</InfoNode>
									<InfoNode label={'Scheduling Contact Title'} width={33.33333}>
										{schedulingContactRecord?.title}
									</InfoNode>
									<InfoNode label={'Scheduling Contact Phone'} width={33.33333}>
										{schedulingContactRecord?.phone}
									</InfoNode>
									<InfoNode label={'Scheduling Contact Email'} width={33.33333}>
										{schedulingContactRecord?.email}
									</InfoNode>
									<InfoNode label={'Alternate Contact Name'}>
										{alternateContactRecord ? Contact.getRecordLabel(alternateContactRecord) : ''}
									</InfoNode>
									<InfoNode label={'Alternate Contact Title'} width={33.33333}>
										{alternateContactRecord?.title}
									</InfoNode>
									<InfoNode label={'Alternate Contact Phone'} width={33.33333}>
										{alternateContactRecord?.phone}
									</InfoNode>
									<InfoNode label={'Alternate Contact Email'} width={33.33333}>
										{alternateContactRecord?.email}
									</InfoNode>
									<InfoNode label={'Loading Hours'} width={50}>
										{locationRecord.loadingHours}
									</InfoNode>
									<InfoNode label={'Type of Service'} width={50}>
										{record.serviceType ? NewBusinessTask.getFieldOptionLabel('serviceType', record.serviceType) : ''}
									</InfoNode>
									<InfoNode label={'Directions'} width={50}>
										{locationRecord?.directions}
									</InfoNode>
									<InfoNode label={'CRM Notes'} width={50}>
										{locationRecord?.notes}
									</InfoNode>
								</div>
							</div>
						)}
					</div>
				</Page>
      </LookupContext.Provider>
    );
  }
}

export const InfoNode: React.FC<{ label: string, width?: number, children?: React.ReactNode }> = (props) => {
	const { label, children, width } = props;
	return (
		<div className="fourg-task-print-page-info-node" style={{ width: `${width || 100}%` }}>
			<h3 className="fourg-task-print-page-info-node__label">{label}</h3>
			<div className="fourg-task-print-page-info-node__value">
				{children || '―'}
			</div>
		</div>
	);
}

const TaskPrintPage: React.FC<Omit<Props, 'layout'>> = (props) => {
	return (
		<LayoutContext.Consumer>
			{(layout) => (
				<TaskPrintPageBase layout={layout} {...props} />
			)}
		</LayoutContext.Consumer>
	);
};

export default TaskPrintPage;
