import React from 'react';
import classNames from 'classnames';
import './Badge.scss';

export interface Props {
  id?: string;
  className?: string;
  disabled?: boolean;
  color?: string;
  title?: string;
  variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'highlight' | 'registration';
}

export interface State {

}

class Badge extends React.Component<Props, State> {

  static defaultProps = {
    variant: 'primary',
  };

  render() {
    const { color, variant, disabled, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-badge', `fourg-badge--variant-${variant}`, {
      'fourg-badge--disabled': disabled,
    }, className);
    return (
      <div className={containerClass} style={color ? { backgroundColor: color } : undefined} {...restProps}>
        <span className="fourg-badge__label">
          {children}
        </span>
      </div>
    );
  }
}

export default Badge;
