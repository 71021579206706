import React from 'react';
import classNames from 'classnames';
import './FormField.scss';

export interface Props {
  id?: string;
  className?: string;
  label?: string;
  labelFor?: string;
  size?: 'large' | 'medium' | 'small' | 'xsmall';
  isHidden?: boolean;
}

export interface State {

}

class FormField extends React.Component<Props, State> {

  static defaultProps = {
    size: 'large',
    isHidden: false,
  };

  render() {
    const { isHidden, size, label, labelFor, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-form-field', `fourg-form-field--size-${size}`, {
      'fourg-form-field--hidden': isHidden,
    }, className);
    return (
      <div className={containerClass} {...restProps}>
        {label && (
          <div className="fourg-form-field__label">
            <label htmlFor={labelFor}>{label}</label>
          </div>
        )}
        <div className="fourg-form-field__input">
          {children}
        </div>
      </div>
    );
  }
}

export default FormField;
