import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Loader from '../Loader/Loader';
import Form from '../Form/Form';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import FormField from '../FormField/FormField';
import LocationInfoInput from '../LocationInfoInput/LocationInfoInput';
import Contact from '../../models/tables/Contact';
import { TableField, Auth, ContactSchema, LocationInfo, CompanySchema, LocationSchema } from '../../types';
import './ContactFormDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  recordID?: ContactSchema['id'];
  companyID: CompanySchema['id'];
  locationID?: LocationSchema['id'];
  submitLabel: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  deleteLabel?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSecondary: (record: ContactSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: ContactSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormDelete: (record: ContactSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isLoading: boolean;
  record: ContactSchema;
}

class ContactFormDialog extends React.Component<Props, State> {

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSecondary: console.info,
    onFormSubmit: console.info,
    onFormDelete: console.info,
    size: 'large',
  };

  constructor(props: Props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleLocationInfoChange = this.handleLocationInfoChange.bind(this);
    this.state = {
      isLoading: false,
      record: this.getDefaultRecord(),
    };
  }

  componentDidMount() {
    const { recordID, isOpen } = this.props;
    if (isOpen && recordID) {
      this.readRecord();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { recordID, isOpen } = this.props;
    if (prevProps.isOpen && ! isOpen) {
      this.setDefaultRecord();
    }
    if (! prevProps.isOpen && isOpen) {
      if (recordID) {
        this.readRecord();
      } else {
        this.setDefaultRecord();
      }
    }
  }

  async readRecord() {
    const { auth, recordID } = this.props;
    if (recordID) {
      this.setState({ isLoading: true });
      try {
        const token = await auth.getToken();
        const { data } = await Contact.readRecord<ContactSchema>(token, recordID.toString());
        this.setState({
          isLoading: false,
          record: data,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isLoading: false,
          record: this.getDefaultRecord(),
        });
      }
    }
  }

  getDefaultRecord() {
    const { companyID, locationID } = this.props;
    const locationInfo: LocationInfo[] | undefined = locationID ? [{ id: locationID, types: [] }] : undefined;
    const defaultRecord: ContactSchema = {
      ...Contact.getDefaultRecord<ContactSchema>(),
      companyId: companyID,
      locationInfo: locationInfo,
    };
    return defaultRecord;
  }

  setDefaultRecord() {
    this.setState({
      record: this.getDefaultRecord(),
    });
  }

  handleFieldChange(field: TableField<ContactSchema>, value: any) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          [field.name]: value,
        }
      });
    }
  }

  handleLocationInfoChange(value: LocationInfo[]) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          locationInfo: value,
        }
      });
    }
  }

  render() {
    const { companyID, locationID, disabled, isOpen, title, auth, recordID, className, deleteLabel, submitLabel, secondaryLabel, cancelLabel, onFormDelete, onFormCancel, onFormSubmit, onFormSecondary, ...restProps } = this.props;
    const { record, isLoading } = this.state;
    const containerClass = classNames('fourg-contact-form-dialog', className);
    const fields = Contact.getFieldsBy<ContactSchema>('isFormField', true);
    return (
      <Dialog
      className={containerClass}
      title={title}
      isOpen={isOpen}
      {...restProps}>
        {(fields.length > 0) && (
          <Form
          className="fourg-contact-form-dialog__form"
          submitLabel={submitLabel}
          secondaryLabel={secondaryLabel}
          disabled={disabled || isLoading || ! isOpen}
          cancelLabel={cancelLabel}
          deleteLabel={deleteLabel}
          onCancel={onFormCancel}
          onSubmit={e => onFormSubmit(record, e)}
          onSecondary={e => onFormSecondary(record, e)}
          onDelete={e => onFormDelete(record, e)}>
            <div className="fourg-contact-form-dialog__fields">
              {fields.map(field => (
                <FormFieldByField<ContactSchema>
								key={`form-field-${field.name}`}
                record={record}
								recordModel={Contact}
                field={field}
                value={record[field.name]}
                disabled={disabled || isLoading || ! isOpen}
                onChange={(value, e) => this.handleFieldChange(field, value)} />
              ))}
            </div>
            <div className="fourg-contact-form-dialog__locations">
              <FormField size="large" className="fourg-contact-form-dialog__locations-field">
                <LocationInfoInput
                disabled={disabled || isLoading || !isOpen}
                auth={auth}
                companyID={companyID}
                value={record.locationInfo}
                onChange={this.handleLocationInfoChange}
                loadOnMount={true}
                required={true}
                label={'Locations'}
                options={[
                  { value: 'scheduling', label: 'Scheduling' },
                  { value: 'alternate', label: 'Alternate' },
                ]} />
              </FormField>
            </div>
          </Form>
        )}
        {isLoading && (
          <Loader
          position="absolute"
          className="fourg-contact-form-dialog__loader" />
        )}
      </Dialog>
    );
  }
}

export default ContactFormDialog;
