import React from 'react';
import classNames from 'classnames';
import countries from '../../assets/json/countries.json';
import usStates from '../../assets/json/us-states.json';
import caProvinces from '../../assets/json/ca-provinces.json';
import mxStates from '../../assets/json/mx-states.json';
import Input from '../Input/Input';
// import Select from '../Select/Select';
import FormFieldHeading from '../FormFieldHeading/FormFieldHeading';
import { Address } from '../../types';
import './AddressInput.scss';
import SelectInput from '../SelectInput/SelectInput';

export interface Props {
  id?: string;
  className?: string;
  value: Address;
  variant?: 'default' | 'quiet',
  label: string;
  name?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  excludedFields: (keyof Address)[];
  isExpandedOnMount: boolean;
  isExpandable?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (value: Address, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

export interface State {
  isExpanded: boolean;
}

class AddressInput extends React.Component<Props, State> {

  private countrySelect = React.createRef<SelectInput>();
  private address1Input = React.createRef<Input>();
  private address2Input = React.createRef<Input>();
  private cityInput = React.createRef<Input>();
  private stateSelect = React.createRef<SelectInput>();
  private stateInput = React.createRef<Input>();
  private zipInput = React.createRef<Input>();

  static defaultProps = {
    excludedFields: [],
    isExpandedOnMount: false,
    value: {
      address1: '',
      address2: '',
      country: 'US',
      state: '',
      city: 'AL',
      zip: '',
    },
  };

  constructor(props: Props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.state = {
      isExpanded: props.isExpandedOnMount,
    }
  }

  handleInputChange(key: string, newKeyValue: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
    const { value, onChange } = this.props;
    const newValue: Address = {
      ...value,
      [key]: newKeyValue,
    };
    if (key === 'country') {
      const stateOptions = this.getStateOptions(newKeyValue);
      newValue.state = (stateOptions.length > 0) ? stateOptions[0].value : '';
    }
    onChange(newValue, e);
  }

  getStateOptions(country: string) {
    switch (country) {
      case 'US': return usStates;
      case 'CA': return caProvinces;
      case 'MX': return mxStates;
      default: return [];
    }
  }

  getStateLabel(country: string) {
    switch (country) {
      case 'US': return 'State';
      case 'CA': return 'Province';
      case 'MX': return 'State';
      default: return 'State / Province / Region';
    }
  }

  resetValidity() {
    const countrySelect = this.countrySelect.current;
    const address1Input = this.address1Input.current;
    const address2Input = this.address2Input.current;
    const cityInput = this.cityInput.current;
    const stateSelect = this.stateSelect.current;
    const stateInput = this.stateInput.current;
    const zipInput = this.zipInput.current;
    countrySelect?.resetValidity();
    address1Input?.resetValidity();
    address2Input?.resetValidity();
    cityInput?.resetValidity();
    stateSelect?.resetValidity();
    stateInput?.resetValidity();
    zipInput?.resetValidity();
  }

  isValid() {
    const countrySelect = this.countrySelect.current;
    const address1Input = this.address1Input.current;
    const address2Input = this.address2Input.current;
    const cityInput = this.cityInput.current;
    const stateSelect = this.stateSelect.current;
    const stateInput = this.stateInput.current;
    const zipInput = this.zipInput.current;
    let isValid = true;
    if ((countrySelect && ! countrySelect.isValid())
    || (address1Input && ! address1Input.isValid())
    || (address2Input && ! address2Input.isValid())
    || (cityInput && ! cityInput.isValid())
    || (stateSelect && ! stateSelect.isValid())
    || (stateInput && ! stateInput.isValid())
    || (zipInput && ! zipInput.isValid())) {
      isValid = false;
    }
    return isValid;
  }

  handleExpandChange(checked: boolean) {
    this.setState({ isExpanded: checked });
  }

  render() {
    const { isExpandable, isExpandedOnMount, excludedFields, disabled, readOnly, required, name, label, variant, value, onFocus, onBlur, onChange, className, children, ...restProps } = this.props;
    const { isExpanded } = this.state;
    const containerClass = classNames('fourg-address-input', className);
    const stateOptions = this.getStateOptions(value['country']);
    const stateLabel = this.getStateLabel(value['country']);
    return (
      <div className={containerClass} {...restProps}>
        <FormFieldHeading
        isExpanded={isExpanded}
        onExpandChange={isExpandable ? this.handleExpandChange : undefined}
        className="fourg-address-input__label">{label}</FormFieldHeading>
        {((isExpandable && isExpanded) || ! isExpandable) && (
          <div className="fourg-address-input__content">
            {! excludedFields.includes('country') && (
              <SelectInput
              ref={this.countrySelect}
              className="fourg-address-input__country"
              options={countries}
              required={required}
              disabled={disabled}
							readOnly={readOnly}
              name={name ? `${name}[country]` : undefined}
              // variant={variant}
              label={'Country'}
              value={value['country']}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(value, e) => this.handleInputChange('country', value, e)} />
            )}
          {! excludedFields.includes('address1') && (
            <Input
            ref={this.address1Input}
            className="fourg-address-input__address1"
            // required={required}
            readOnly={readOnly}
            disabled={disabled}
            name={name ? `${name}[address1]` : undefined}
            variant={variant}
            label={'Address'}
            value={value['address1']}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(value, e) => this.handleInputChange('address1', value.toString(), e)} />
          )}
          {! excludedFields.includes('address2') && (
            <Input
            ref={this.address2Input}
            className="fourg-address-input__address2"
            readOnly={readOnly}
            disabled={disabled}
            name={name ? `${name}[address2]` : undefined}
            variant={variant}
            label={'Address Line 2'}
            value={value['address2'] || ''}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(value, e) => this.handleInputChange('address2', value.toString(), e)} />
          )}
          {! excludedFields.includes('city') && (
            <Input
            ref={this.cityInput}
            className="fourg-address-input__city"
            required={required}
            readOnly={readOnly}
            disabled={disabled}
            name={name ? `${name}[city]` : undefined}
            variant={variant}
            label={'City'}
            value={value['city']}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(value, e) => this.handleInputChange('city', value.toString(), e)} />
            )}
            {! excludedFields.includes('state') && (
              <React.Fragment>
                {(stateOptions.length > 0) ? (
                  <SelectInput
                  ref={this.stateSelect}
                  className="fourg-address-input__state-select"
                  options={stateOptions}
                  required={required}
                  disabled={disabled}
									readOnly={readOnly}
                  name={name ? `${name}[state]` : undefined}
                  // variant={variant}
                  label={stateLabel}
                  value={value['state']}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={(value, e) => this.handleInputChange('state', value, e)} />
                ) : (
                  <Input
                  ref={this.stateInput}
                  className="fourg-address-input__state-input"
                  required={required}
                  readOnly={readOnly}
                  disabled={disabled}
                  name={name ? `${name}[state]` : undefined}
                  variant={variant}
                  label={stateLabel}
                  value={value['state']}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={(value, e) => this.handleInputChange('state', value.toString(), e)} />
                )}
              </React.Fragment>
            )}
            {! excludedFields.includes('zip') && (
              <Input
              ref={this.zipInput}
              className="fourg-address-input__zip"
              // required={required}
              readOnly={readOnly}
              disabled={disabled}
              name={name ? `${name}[zip]` : undefined}
              variant={variant}
              label={'Zip'}
              value={value['zip']}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(value, e) => this.handleInputChange('zip', value.toString(), e)} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AddressInput;
