import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Page from '../../components/Page/Page';
import ActionToggle from '../../components/ActionToggle/ActionToggle';
import TabBar from '../../components/TabBar/TabBar';
import FilterBar from '../../components/FilterBar/FilterBar';
import Table from '../../components/Table/Table';
import Pagination from '../../components/Pagination/Pagination';
import TableCellByField from '../../components/TableCellByField/TableCellByField';
import TaskQuickView from '../../components/TaskQuickView/TaskQuickView';
import TaskFormDialog from '../../components/TaskFormDialog/TaskFormDialog';
import ShareTaskDialog from '../../components/ShareTaskDialog/ShareTaskDialog';
import ReminderFormDialog from '../../components/ReminderFormDialog/ReminderFormDialog';
import TaskRemoveSelfDialog from '../../components/TaskRemoveSelfDialog/TaskRemoveSelfDialog';
import CloseToast from '../../components/CloseToast/CloseToast';
import Task from '../../models/tables/Task';
import Reminder from '../../models/tables/Reminder';
import TableModel from '../../models/Table';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import { LookupContext } from '../../contexts';
import { Auth, Socket, ReminderSchema, TaskSchema, UIOption, ReadRecordsQuery, Lookup, SocketMessage, UserSchema, CompanySchema, LocationSchema, AttachmentSchema, GradeSchema, ExpenseSchema, ContactSchema } from '../../types';
import { mergeRecords } from '../../utils';
import './DashboardPage.scss';

export type sectionRecordSchema = TaskSchema | ReminderSchema;

export interface RouteParams {
  section?: string;
}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
  socket: Socket;
}

export interface State {
  isUpdating: boolean;
  isLoadingSection: boolean;
  records: sectionRecordSchema[];
  sectionTotal: number;
  isInfoOpen: boolean;
  selectedRecordID?: sectionRecordSchema['id'];
  shareRecordID?: sectionRecordSchema['id'];
  removeSelfRecord?: TaskSchema,
  lookup: Lookup;
}

class DashboardPage extends React.Component<Props, State> {

  private createPersonalTaskDialog = React.createRef<TaskFormDialog>();
  private createTargetTaskDialog = React.createRef<TaskFormDialog>();
  private createNewBusinessTaskDialog = React.createRef<TaskFormDialog>();
  private createCustomerServiceTaskDialog = React.createRef<TaskFormDialog>();
  private createFinanceTaskDialog = React.createRef<TaskFormDialog>();

  constructor(props: Props) {
    super(props);
    this.handleTabBarChange = this.handleTabBarChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleCreateTaskActionChange = this.handleCreateTaskActionChange.bind(this);
    this.handleInfoButtonClick = this.handleInfoButtonClick.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleTableRowDoubleClick = this.handleTableRowDoubleClick.bind(this);
    this.handleTableRowClick = this.handleTableRowClick.bind(this);
    this.handleSubtaskCardClick = this.handleSubtaskCardClick.bind(this);
    this.getTableRowActions = this.getTableRowActions.bind(this);
    this.handleCreateTaskDialogClose = this.handleCreateTaskDialogClose.bind(this);
    this.handleCreateTaskDialogSubmit = this.handleCreateTaskDialogSubmit.bind(this);
    this.handleCreateTaskDialogSecondary = this.handleCreateTaskDialogSecondary.bind(this);
    this.handleTableRowActionsChange = this.handleTableRowActionsChange.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this);
    this.handleShareDialogClose = this.handleShareDialogClose.bind(this);
    this.handleUpdateReminderDialogClose = this.handleUpdateReminderDialogClose.bind(this);
    this.handleUpdateReminderDialogSubmit = this.handleUpdateReminderDialogSubmit.bind(this);
    this.handleRemoveSelfDialogSubmit = this.handleRemoveSelfDialogSubmit.bind(this);
    this.handleRemoveSelfDialogClose = this.handleRemoveSelfDialogClose.bind(this);
    this.handleGetTableRowWarnings = this.handleGetTableRowWarnings.bind(this);
    this.state = {
      isUpdating: false,
      isLoadingSection: false,
      records: [],
      sectionTotal: 0,
      isInfoOpen: false,
      lookup: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    Cookies.set('dashboard_section', this.getSection(match));
    this.readSectionRecords();
  }

  componentDidUpdate(prevProps: Props) {
    const { location, match } = this.props;
    const prevSection = this.getSection(prevProps.match);
    const section = this.getSection(match);
    if (prevSection !== section) {
      Cookies.set('dashboard_section', section);
    }
    if ((this.getPage(prevProps.location) !== this.getPage(location))
    || (this.getLimit(prevProps.location, prevProps.match) !== this.getLimit(location, match))
    || (this.getOrder(prevProps.location) !== this.getOrder(location))
    || (this.getSearch(prevProps.location) !== this.getSearch(location))
    || (prevSection !== section)) {
      this.readSectionRecords(false);
    }
  }

  componentWillUnmount() {
    this.socketDisconnect();
		toast.dismiss('update-list');
  }

  socketConnect() {
    const { socket, auth } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: 'tasks:connect' }));
			if (auth.user) {
				socket.current?.send(JSON.stringify({ action: `personal-tasks/${auth.user.id}:connect` }));
				socket.current?.send(JSON.stringify({ action: `reminders/${auth.user.id}:connect` }));
			}
		}
    window.addEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  socketDisconnect() {
    const { socket, auth } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: 'tasks:disconnect' }));
			if (auth.user) {
				socket.current?.send(JSON.stringify({ action: `personal-tasks/${auth.user.id}:disconnect` }));
				socket.current?.send(JSON.stringify({ action: `reminders/${auth.user.id}:disconnect` }));
			}
		}
    window.removeEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  isSocketMessageValid(socketMessage?: SocketMessage) {
    const { auth } = this.props;
    let isValid = false;
    if (socketMessage) {
      const isStreamValid = ['tasks', `personal-tasks/${auth.user?.id}`, `reminders/${auth.user?.id}`].includes(socketMessage.stream);
      const isCreatedByUser = (socketMessage.data.createdBy === auth.user?.id);
      let isRelevantSection = false;
      if ((['tasks', `personal-tasks/${auth.user?.id}`].includes(socketMessage.stream) && this.isTaskSection())
      || ([`reminders/${auth.user?.id}`].includes(socketMessage.stream) && this.isReminderSection())) {
        isRelevantSection = true;
      }
      isValid = (isStreamValid && isRelevantSection && ! isCreatedByUser);
    }
    return isValid;
  }

  handleSocketMessage(e: CustomEventInit<SocketMessage>) {
    if (this.isSocketMessageValid(e.detail)) {
      toast.info('There have been updates to the list', {
        toastId: 'update-list',
        autoClose: false,
        hideProgressBar: true,
        draggable: false,
        onClose: () => this.readSectionRecords(false),
        closeButton: ( <CloseToast icon={{ icon: 'refresh' }} label={'Reload'} /> ),
      });
    }
  }

  getSection(match: RouteComponentProps<RouteParams>['match']) {
    const { auth } = this.props;
    return match.params.section || auth.settings?.application?.defaultDashboardSection || Cookies.get('dashboard_section') || 'created-tasks';
  }

  getSearch(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('search') || undefined;
  }

  getCreate(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('create-task') || undefined;
  }

  getReminder(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('reminder') || undefined;
  }

  getOrder(location: RouteComponentProps<RouteParams>['location']) {
    const sectionModel = this.getSectionModel();
    const { defaultOrder } = sectionModel.getOptions();
    const params = new URLSearchParams(location.search);
    return params.get('order') || defaultOrder;
  }

  getPage(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page, 10) : 1;
  }

  getLimit(location: RouteComponentProps<RouteParams>['location'], match: RouteComponentProps<RouteParams>['match']) {
    const defaultLimit = (this.getSection(match) === 'attachments') ? 12 : 20;
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit');
    return limit ? parseInt(limit, 10) : defaultLimit;
  }

  getSectionModel(): (typeof TableModel) {
    const { match } = this.props;
    switch (this.getSection(match)) {
      case 'reminders': return Reminder;
      case 'recurring-reminders': return Reminder;
      default: return Task;
    }
  }

  getTaskModelByBoard(board: string): (typeof Task) {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  getSectionQuery() {
    const { match, auth } = this.props;
    const completedStatusesSet = new Set([
			...Task.completedStatuses,
			...PersonalTask.completedStatuses,
			...TargetTask.completedStatuses,
			...NewBusinessTask.completedStatuses,
			...FinanceTask.completedStatuses,
			...CustomerServiceTask.completedStatuses,
		]);
		const completedStatuses = Array.from(completedStatusesSet);
    let query: Partial<ReadRecordsQuery> = {};
    switch (this.getSection(match)) {
      case 'created-tasks':
        query = {
          createdBy: auth.user?.id,
          hideStatus: auth.settings?.application?.hideCompletedOnDashboard ? completedStatuses.join(',') : undefined,
        };
        break;
      case 'assigned-tasks':
        query = {
          assignedTo: auth.user?.id,
          hideStatus: auth.settings?.application?.hideCompletedOnDashboard ? completedStatuses.join(',') : undefined,
        };
        break;
      case 'following-tasks':
        query = {
          following: true,
          hideStatus: auth.settings?.application?.hideCompletedOnDashboard ? completedStatuses.join(',') : undefined,
        };
        break;
      case 'member-tasks':
        query = {
          members: auth.user?.id,
          hideStatus: auth.settings?.application?.hideCompletedOnDashboard ? completedStatuses.join(',') : undefined,
        };
        break;
      case 'reminders':
        query = {
          recurring: 'false',
          incomplete: auth.settings?.application?.hideCompletedOnDashboard,
        };
        break;
      case 'recurring-reminders':
        query = {
          recurring: 'true',
          incomplete: auth.settings?.application?.hideCompletedOnDashboard,
        };
        break;
    }
    return query;
  }

  getCreateTaskOptions() {
    const boardField = Task.getField<TaskSchema>('board');
    return boardField?.options || [];
  }

  async readRecordsBySection(token: string, query: ReadRecordsQuery) {
    const { match } = this.props;
    switch (this.getSection(match)) {
      case 'reminders': return await Reminder.readRecords<ReminderSchema>(token, query);
      case 'recurring-reminders': return await Reminder.readRecords<ReminderSchema>(token, query);
      default: return await Task.readRecords<TaskSchema>(token, query);
    }
  }

  async readSectionRecords(isInitialLoad: boolean = true) {
    const { socket, location, match, auth } = this.props;
		const { lookup } = this.state;
    this.setState({ isLoadingSection: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await this.readRecordsBySection(token, {
        ...this.getSectionQuery(),
        page: this.getPage(location),
        limit: this.getLimit(location, match),
        order: this.getOrder(location),
        search: this.getSearch(location),
        archived: false,
      });
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      this.setState({
        isLoadingSection: false,
        records: data,
        sectionTotal: meta.total,
        lookup: {
					...lookup,
					users: mergeRecords<UserSchema>(newUsers, lookup.users),
					companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
					locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
					tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
					attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
					reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
					grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
				},
      });
      if (isInitialLoad && (socket.current?.readyState === WebSocket.OPEN)) {
        this.socketConnect();
      }
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoadingSection: false,
        records: [],
        sectionTotal: 0,
        lookup: {},
      });
    }
  }

  async createTask(record: TaskSchema, isCreateDialogOpen: boolean = false) {
    const { auth, location, history } = this.props;
    const model = this.getTaskModelByBoard(record.board);
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      await model.createRecord<TaskSchema>(token, record);
      toast.success(model.getLabel('addedSingular'));
      this.setState({ isUpdating: false });
      if (! isCreateDialogOpen) {
        const params = new URLSearchParams(location.search);
        params.delete('create-task');
        history.push(`${location.pathname}?${params.toString()}`);
      }
      const createPersonalTaskDialog = this.createPersonalTaskDialog.current;
      const createTargetTaskDialog = this.createTargetTaskDialog.current;
      const createNewBusinessTaskDialog = this.createNewBusinessTaskDialog.current;
      const createCustomerServiceTaskDialog = this.createCustomerServiceTaskDialog.current;
      const createFinanceTaskDialog = this.createFinanceTaskDialog.current;
      createPersonalTaskDialog?.setDefaultRecord();
      createTargetTaskDialog?.setDefaultRecord();
      createNewBusinessTaskDialog?.setDefaultRecord();
      createCustomerServiceTaskDialog?.setDefaultRecord();
      createFinanceTaskDialog?.setDefaultRecord();
      this.readSectionRecords(false);
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async followTask(record: TaskSchema) {
    const { auth, match } = this.props;
    const model = this.getTaskModelByBoard(record.board);
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      await model.followRecord(token, record.id);
      toast.success(model.getLabel('updatedSingular'));
      this.setState({ isUpdating: false });
      if (this.getSection(match) === 'following-tasks') {
        this.readSectionRecords(false);
      } else {
        this.syncRecord({ ...record, following: true });
      }
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async unfollowTask(record: TaskSchema) {
    const { auth, match } = this.props;
    const model = this.getTaskModelByBoard(record.board);
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      await model.unfollowRecord(token, record.id);
      toast.success(model.getLabel('updatedSingular'));
      this.setState({ isUpdating: false });
      if (this.getSection(match) === 'following-tasks') {
        this.readSectionRecords(false);
      } else {
        this.syncRecord({ ...record, following: false });
      }
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async archiveTask(record: TaskSchema) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getTaskModelByBoard(record.board);
      await model.archiveRecord(token, record.id.toString());
      toast.success(model.getLabel('archivedSingular'));
      // this.syncRecord({ ...record, archived: true });
      this.setState({ isUpdating: false });
      this.readSectionRecords(false);
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async restoreTask(record: TaskSchema) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getTaskModelByBoard(record.board);
      await model.restoreRecord(token, record.id.toString());
      toast.success(model.getLabel('restoredSingular'));
      // this.syncRecord({ ...record, archived: false });
      this.readSectionRecords(false);
      this.setState({ isUpdating: false });
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async markReminderAsComplete(reminder: ReminderSchema) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getReminderResourceModel(reminder.resourceType);
      await model.markReminderAsComplete(token, reminder.resourceId, reminder);
      toast.success(Reminder.getLabel('updatedSingular'));
      this.setState({ isUpdating: false });
      this.readSectionRecords();
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async markReminderAsIncomplete(reminder: ReminderSchema) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getReminderResourceModel(reminder.resourceType);
      await model.markReminderAsIncomplete(token, reminder.resourceId, reminder);
      toast.success(Reminder.getLabel('updatedSingular'));
      this.setState({ isUpdating: false });
      this.readSectionRecords();
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async updateReminder(reminder: ReminderSchema) {
    const { auth, location, history } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getReminderResourceModel(reminder.resourceType);
      await model.updateReminder(token, reminder.resourceId, reminder);
      toast.success(Reminder.getLabel('updatedSingular'));
      this.setState({ isUpdating: false });
      this.readSectionRecords();
      const params = new URLSearchParams(location.search);
      params.delete('reminder');
      history.push(`${location.pathname}?${params.toString()}`);

    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async removeSelfFromTask(assignedTo: TaskSchema['assignedTo']) {
    const { auth } = this.props;
    const { removeSelfRecord } = this.state;
    if (removeSelfRecord) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        await Task.removeSelf(token, removeSelfRecord.id, assignedTo);
        toast.success(Task.getLabel('updatedSingular'));
        this.readSectionRecords(false);
        this.setState({
          isUpdating: false,
          removeSelfRecord: undefined,
        });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  getReminderResourceModel(resourceType?: string): typeof Task {
    switch (resourceType) {
      default: return Task;
    }
  }

  syncRecord(newRecord: sectionRecordSchema) {
    const { records } = this.state;
    let newRecords = records.map(record => {
      return (record.id === newRecord.id) ? newRecord : record;
    });
    this.setState({
      records: newRecords,
    });
  }

  handleTabBarChange(value: string) {
    const { history } = this.props;
    this.setState({
      records: [],
      sectionTotal: 0,
      isInfoOpen: false,
      selectedRecordID: undefined,
      shareRecordID: undefined,
      removeSelfRecord: undefined,
    });
    history.push(`/dashboard/${value}`);
  }

  handleSearchChange(search: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('search', search);
    } else {
      params.delete('search');
    }
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleOrderChange(order: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('order', order);
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handlePageChange(page: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleLimitChange(limit: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('limit', limit.toString());
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleCreateTaskActionChange(value: UIOption['value']) {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('create-task', value);
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleTableRowDoubleClick(record: sectionRecordSchema) {
    const { history, match } = this.props;
    const section = this.getSection(match);
    switch (section) {
      case 'reminders':
        this.routeToReminderResource(record as ReminderSchema);
        break;
      case 'recurring-reminders':
        this.routeToReminderResource(record as ReminderSchema);
        break;
      default:
        history.push(`/tasks/${record.id}`);
        break;
    }
  }

  routeToReminderResource(reminder: ReminderSchema) {
    const { history } = this.props;
    switch (reminder.resourceType) {
      default: return history.push(`/tasks/${reminder.resourceId}?reminder=${reminder.id}`);
    }
  }

  routeToReminderForm(reminder: ReminderSchema) {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('reminder', `${reminder.resourceType},${reminder.resourceId.toString()},${reminder.id.toString()}`);
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleTableRowClick(record: sectionRecordSchema) {
    this.setState({
      selectedRecordID: record.id,
      isInfoOpen: true,
    });
  }

  getTableRowActions(record: sectionRecordSchema) {
    const { match, auth } = this.props;
    let actions: UIOption[] = [];
    switch (this.getSection(match)) {
      case 'reminders':
        const reminder = { ...record } as ReminderSchema;
        actions = [
          {
            value: reminder.completed ? 'incomplete-reminder' : 'complete-reminder',
            label: reminder.completed ? 'Mark as Incomplete' : 'Mark as Complete',
          },
          {
            value: 'edit-reminder',
            label: 'Edit',
          }
        ];
        break;
      case 'recurring-reminders':
        const recurringReminder = { ...record } as ReminderSchema;
        actions = [
          {
            value: recurringReminder.completed ? 'incomplete-reminder' : 'complete-reminder',
            label: recurringReminder.completed ? 'Mark as Incomplete' : 'Mark as Complete',
          },
          {
            value: 'edit-reminder',
            label: 'Edit',
          }
        ];
        break;
      default:
        const task = { ...record } as TaskSchema;
        actions = [
          {
            value: task.following ? 'unfollow-task' : 'follow-task',
            label: task.following ? 'Unfollow' : 'Follow',
            // icon: { icon: task.following ? 'bookmark' : 'bookmark_border' },
          },
          {
            value: task.archived ? 'restore-task' : 'archive-task',
            label: task.archived ? 'Restore' : 'Archive',
          },
          {
            value: 'share',
            label: 'Share',
          },
        ]
        if (auth.user && ((auth.user.id === task.assignedTo) || task.members?.includes(auth.user.id))) {
          actions.push({
            value: 'remove-self',
            label: 'Remove Me',
          });
        }
        break;
    }
    return actions;
  }

  handleInfoButtonClick(isInfoOpen: boolean) {
    this.setState({
      isInfoOpen: isInfoOpen,
    });
  }

  handleSubtaskCardClick(subtask: TaskSchema) {
    const { history } = this.props;
    history.push(`/tasks/${subtask.id}`);
  }

  handleCreateTaskDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('create-task');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleCreateTaskDialogSubmit(record: TaskSchema) {
    this.createTask(record);
  }

  handleCreateTaskDialogSecondary(record: TaskSchema) {
    this.createTask(record, true);
  }

  handleTableRowActionsChange(value: UIOption['value'], record: sectionRecordSchema) {
    switch (value) {
      case 'follow-task': return this.followTask(record as TaskSchema);
      case 'unfollow-task': return this.unfollowTask(record as TaskSchema);
      case 'archive-task': return this.archiveTask(record as TaskSchema);
      case 'restore-task': return this.restoreTask(record as TaskSchema);
      case 'complete-reminder': return this.markReminderAsComplete(record as ReminderSchema);
      case 'incomplete-reminder': return this.markReminderAsIncomplete(record as ReminderSchema);
      case 'edit-reminder': return this.routeToReminderForm(record as ReminderSchema);
      case 'share': return this.setState({ shareRecordID: record.id });
      case 'remove-self': return this.setState({ removeSelfRecord: record as TaskSchema });
    }
  }

  handleUpdateReminderDialogSubmit(reminder: ReminderSchema) {
    this.updateReminder(reminder);
  }

  handleUpdateReminderDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('reminder');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  isTaskSection() {
    const { match } = this.props;
    const section = this.getSection(match);
    return ['created-tasks', 'assigned-tasks', 'following-tasks', 'member-tasks'].includes(section);
  }

  isReminderSection() {
    const { match } = this.props;
    const section = this.getSection(match);
    return ['reminders'].includes(section);
  }

  handleRefreshButtonClick() {
    this.readSectionRecords();
  }

  handleShareDialogClose() {
    this.setState({ shareRecordID: undefined });
  }

  handleRemoveSelfDialogSubmit(assignedTo: TaskSchema['assignedTo']) {
    this.removeSelfFromTask(assignedTo);
  }

  handleRemoveSelfDialogClose() {
    this.setState({
      removeSelfRecord: undefined,
    });
  }

  handleGetTableRowWarnings(record: sectionRecordSchema): string[] {
    const { match } = this.props;
    switch (this.getSection(match)) {
      case 'reminders': return Reminder.isPastDue(record as ReminderSchema) ? ['The due date is past due.'] : [];
      default:
				if (this.isTaskSection()) {
					const taskModel = this.getTaskModelByBoard((record as TaskSchema).board);
					return taskModel.getWarnings(record as TaskSchema);
				} else {
					const model = this.getSectionModel();
					return model.getWarnings(record);
				}
    }
  }

  render() {
    const { auth, socket, className, match, location, history, staticContext, ...restProps } = this.props;
    const { lookup, records, sectionTotal, isUpdating, isLoadingSection, isInfoOpen, selectedRecordID, shareRecordID, removeSelfRecord } = this.state;
    const containerClass = classNames('fourg-dashboard-page', className);
    const section = this.getSection(match);
    const sectionModel = this.getSectionModel();
    const sectionModelOptions = sectionModel.getOptions();
    const isTaskSection = this.isTaskSection();
    const isReminderSection = this.isReminderSection();
    const create = this.getCreate(location);
    const reminder = this.getReminder(location);
    const page = this.getPage(location);
    const limit = this.getLimit(location, match);
    const startNumber = (((page * limit) - limit) + 1);
    let endNumber = ((startNumber + limit) - 1);
    endNumber = (endNumber <= sectionTotal) ? endNumber : sectionTotal;
    return (
      <LookupContext.Provider value={lookup}>
        <Page
        headerTitle={`Welcome, ${auth.user?.first}`}
        title={'Dashboard'}
        className={containerClass}
        headerDescription={isLoadingSection ? sectionModel.getLabel('loadingPluralEllipsis') : `Viewing ${(sectionTotal > 0) ? `${startNumber}-${endNumber} of ` : ''}${sectionTotal} ${sectionModel.getLabel('plural')}.`}
        {...restProps}>
          <TabBar
          disabled={(isUpdating || isLoadingSection)}
          value={section}
          onChange={this.handleTabBarChange}
          options={[
            { label: Reminder.getLabel('plural'), value: 'reminders' },
            { label: `Recurring ${Reminder.getLabel('plural')}`, value: 'recurring-reminders' },
            { label: 'Created by Me', value: 'created-tasks' },
            { label: 'Assigned to Me', value: 'assigned-tasks' },
            { label: 'Following', value: 'following-tasks' },
            { label: 'Member of', value: 'member-tasks' },
          ]} />
          <FilterBar
          disabled={(isUpdating || isLoadingSection)}
          isInfoOpen={isInfoOpen}
          onInfoButtonClick={isTaskSection ? this.handleInfoButtonClick : undefined}
          searchLabel={sectionModel.getLabel('searchEllipsis')}
          searchValue={this.getSearch(location)}
          onRefreshButtonClick={this.handleRefreshButtonClick}
          onSearchChange={this.handleSearchChange}>
            {isTaskSection && (
              <ActionToggle
              label={sectionModel.getLabel('addSingular')}
              variant="raised"
              icon={{ icon: 'add' }}
              options={this.getCreateTaskOptions()}
              value=""
              anchor="top-right"
              onChange={this.handleCreateTaskActionChange} />
            )}
          </FilterBar>
          <div className="fourg-tasks-page__content">
            <div className="fourg-tasks-page__data">
              <Table<sectionRecordSchema>
              disabled={(isUpdating || isLoadingSection)}
              rowRadioName="selected-record"
              isScrollable={true}
              columns={sectionModel.getFieldColumns<sectionRecordSchema>()}
              records={records}
              order={this.getOrder(location)}
              isLoading={isLoadingSection}
              onOrderChange={this.handleOrderChange}
              onRowDoubleClick={this.handleTableRowDoubleClick}
              onRowClick={isTaskSection ? this.handleTableRowClick : undefined}
              notFoundIcon={{ icon: sectionModelOptions.icon }}
              notFoundHeading={sectionModel.getLabel('notFoundPlural')}
              isRowActive={record => Boolean(selectedRecordID && (record.id === selectedRecordID))}
              getRowWarnings={this.handleGetTableRowWarnings}
              getRowActions={this.getTableRowActions}
              onRowActionsChange={this.handleTableRowActionsChange}
              renderCell={(value, column, record) => {
                let field = sectionModel.getField<sectionRecordSchema>(column.key);
                if (isTaskSection) {
                  const taskModel = this.getTaskModelByBoard((record as TaskSchema).board);
                  field = taskModel.getField<sectionRecordSchema>(column.key);
                }
                return (! field) ? value.toString() : (
                  <TableCellByField<sectionRecordSchema>
                  field={field}
                  value={value}
                  record={record} />
                );
              }} />
              <Pagination
              page={page}
              limit={limit}
              total={sectionTotal}
              onPageChange={this.handlePageChange}
              onLimitChange={this.handleLimitChange} />
            </div>
            {(isInfoOpen && isTaskSection) && (
              <div className="fourg-tasks-page__sidebars">
                <TaskQuickView
                auth={auth}
                socket={socket}
                recordID={selectedRecordID}
                onSubtaskCardClick={this.handleSubtaskCardClick} />
              </div>
            )}
          </div>
          {isTaskSection && (
            <React.Fragment>
              <TaskFormDialog
              model={PersonalTask}
              disabled={(isLoadingSection || isUpdating)}
              auth={auth}
              ref={this.createPersonalTaskDialog}
              enforcedValues={{ board: 'personal' }}
              title={PersonalTask.getLabel('addSingular')}
              isOpen={(create === 'personal')}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateTaskDialogSubmit}
              onFormSecondary={this.handleCreateTaskDialogSecondary}
              onCloseClick={this.handleCreateTaskDialogClose}
              // onBackdropClick={this.handleCreateTaskDialogClose}
              onFormCancel={this.handleCreateTaskDialogClose}
              onEscape={this.handleCreateTaskDialogClose} />
              <TaskFormDialog
              model={NewBusinessTask}
              disabled={(isLoadingSection || isUpdating)}
              auth={auth}
              ref={this.createNewBusinessTaskDialog}
              enforcedValues={{ board: 'new-business' }}
              title={NewBusinessTask.getLabel('addSingular')}
              isOpen={(create === 'new-business')}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateTaskDialogSubmit}
              onFormSecondary={this.handleCreateTaskDialogSecondary}
              onCloseClick={this.handleCreateTaskDialogClose}
              // onBackdropClick={this.handleCreateTaskDialogClose}
              onFormCancel={this.handleCreateTaskDialogClose}
              onEscape={this.handleCreateTaskDialogClose}
              initialValues={{
                assignedTo: auth.user?.id,
              }} />
              <TaskFormDialog
              model={CustomerServiceTask}
              disabled={(isLoadingSection || isUpdating)}
              auth={auth}
              ref={this.createCustomerServiceTaskDialog}
              enforcedValues={{ board: 'customer-service' }}
              title={CustomerServiceTask.getLabel('addSingular')}
              isOpen={(create === 'customer-service')}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateTaskDialogSubmit}
              onFormSecondary={this.handleCreateTaskDialogSecondary}
              onCloseClick={this.handleCreateTaskDialogClose}
              // onBackdropClick={this.handleCreateTaskDialogClose}
              onFormCancel={this.handleCreateTaskDialogClose}
              onEscape={this.handleCreateTaskDialogClose}
              initialValues={{
                assignedTo: auth.user?.id,
              }} />
              <TaskFormDialog
              model={FinanceTask}
              disabled={(isLoadingSection || isUpdating)}
              auth={auth}
              ref={this.createFinanceTaskDialog}
              enforcedValues={{ board: 'finance' }}
              title={FinanceTask.getLabel('addSingular')}
              isOpen={(create === 'finance')}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateTaskDialogSubmit}
              onFormSecondary={this.handleCreateTaskDialogSecondary}
              onCloseClick={this.handleCreateTaskDialogClose}
              // onBackdropClick={this.handleCreateTaskDialogClose}
              onFormCancel={this.handleCreateTaskDialogClose}
              onEscape={this.handleCreateTaskDialogClose}
              initialValues={{
                assignedTo: auth.user?.id,
              }} />
              <TaskFormDialog
              model={TargetTask}
              disabled={(isLoadingSection || isUpdating)}
              auth={auth}
              ref={this.createTargetTaskDialog}
              enforcedValues={{ board: 'targets' }}
              title={TargetTask.getLabel('addSingular')}
              isOpen={(create === 'targets')}
              submitLabel={'Save'}
              secondaryLabel={'Save and Add Another'}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleCreateTaskDialogSubmit}
              onFormSecondary={this.handleCreateTaskDialogSecondary}
              onCloseClick={this.handleCreateTaskDialogClose}
              // onBackdropClick={this.handleCreateTaskDialogClose}
              onFormCancel={this.handleCreateTaskDialogClose}
              onEscape={this.handleCreateTaskDialogClose}
              initialValues={{
                assignedTo: auth.user?.id,
              }} />
              <TaskRemoveSelfDialog
              disabled={(isLoadingSection || isUpdating)}
              auth={auth}
              lookup={lookup}
              recordID={removeSelfRecord?.id || 0}
              isOpen={Boolean(removeSelfRecord)}
              isAssignedToSelf={(removeSelfRecord && (removeSelfRecord.assignedTo === auth.user?.id))}
              onFormSubmit={this.handleRemoveSelfDialogSubmit}
              onCloseClick={this.handleRemoveSelfDialogClose}
              onBackdropClick={this.handleRemoveSelfDialogClose}
              onFormCancel={this.handleRemoveSelfDialogClose}
              onEscape={this.handleRemoveSelfDialogClose} />
              <ShareTaskDialog
              isOpen={Boolean(shareRecordID)}
              recordID={shareRecordID || 0}
              onCloseClick={this.handleShareDialogClose}
              onEscape={this.handleShareDialogClose}
              onBackdropClick={this.handleShareDialogClose} />
            </React.Fragment>
          )}
          {isReminderSection && (
            <React.Fragment>
              <ReminderFormDialog
              resourceModel={this.getReminderResourceModel(reminder?.split(',')[0])}
              resourceID={reminder?.split(',')[1] ? parseInt(reminder?.split(',')[1], 10) : 0}
              recordID={reminder?.split(',')[2] ? parseInt(reminder?.split(',')[2], 10) : 0}
              auth={auth}
              title={Reminder.getLabel('editSingular')}
              isOpen={Boolean(reminder)}
              submitLabel={'Save'}
              disabled={(isLoadingSection || isUpdating)}
              cancelLabel={'Cancel'}
              onFormSubmit={this.handleUpdateReminderDialogSubmit}
              onCloseClick={this.handleUpdateReminderDialogClose}
              // onBackdropClick={this.handleUpdateReminderDialogClose}
              onFormCancel={this.handleUpdateReminderDialogClose}
              onEscape={this.handleUpdateReminderDialogClose} />
            </React.Fragment>
          )}
        </Page>
      </LookupContext.Provider>
    );
  }
}

export default DashboardPage;
