import React from 'react';
import classNames from 'classnames';
import Icon, { Props as IconProps } from '../Icon/Icon';
import './CloseToast.scss';

export interface Props {
  id?: string;
  className?: string;
  icon: IconProps['src'];
  label: string;
  closeToast: () => void;
}

export interface State {

}

class CloseToast extends React.Component<Props, State> {

  static defaultProps = {
    closeToast: console.info,
  }

  render() {
    const { icon, label, closeToast, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-close-toast', className);
    return (
      <button 
      type="button" 
      onClick={closeToast}
      className={containerClass} 
      title={label}
      {...restProps}>
        <Icon src={icon} label={label} /> 
      </button>
    );
  }
}

export default CloseToast;
