import React from 'react';
import classNames from 'classnames';
import Sidebar, { Props as SidebarProps } from '../Sidebar/Sidebar';
import SidebarSection from '../SidebarSection/SidebarSection';
import Location from '../../models/tables/Location';
import { LocationSchema } from '../../types';
import './LocationSidebar.scss';
import InfoByField from '../InfoByField/InfoByField';
import Info from '../Info/Info';

export interface Props extends SidebarProps {
  record: LocationSchema;
  disabled?: boolean;
}

export interface State {

}

class LocationSidebar extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    ...Sidebar.defaultProps,
  };

  render() {
    const { disabled, record, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-location-sidebar', className);
    const fields = Location.getFieldsBy<LocationSchema>('isInfo', true);
    return (
      <Sidebar className={containerClass} {...restProps}>
        <SidebarSection 
        className="fourg-location-sidebar__details"
        label="Location Details"
        disabled={disabled}>
          <Info>
            {fields.map((field) => (
              <InfoByField<LocationSchema>
              size={field.infoSize}
              variant="quiet"
              key={`form-field-${field.name}`}
              field={field}
              value={record[field.name]} />
            ))}
          </Info>
        </SidebarSection>
      </Sidebar>
    );
  }
}

export default LocationSidebar;
