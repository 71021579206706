import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Button from '../../components/Button/Button';
import FilterBar from '../../components/FilterBar/FilterBar';
import Table from '../../components/Table/Table';
import TableCellByField from '../../components/TableCellByField/TableCellByField';
import Pagination from '../../components/Pagination/Pagination';
import FormDialog from '../../components/FormDialog/FormDialog';
import UserFormDialog from '../../components/UserFormDialog/UserFormDialog';
import ResetPasswordDialog from '../../components/ResetPasswordDialog/ResetPasswordDialog';
import CloseToast from '../../components/CloseToast/CloseToast';
import User from '../../models/tables/User';
import { UserSchema, Auth, Socket, UserSettings, UIOption, SocketMessage } from '../../types';
import './UsersPage.scss';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
  socket: Socket;
}

export interface State {
  isLoading: boolean;
  isUpdating: boolean;
  isCreateDialogOpen: boolean;
  records?: UserSchema[];
  total: number;
}

class UsersPage extends React.Component<Props, State> {

  private createDialog = React.createRef<FormDialog<UserSchema>>();

  constructor(props: Props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleTableRowDoubleClick = this.handleTableRowDoubleClick.bind(this);
    this.handleFormDialogClose = this.handleFormDialogClose.bind(this);
    this.handleFormDialogSubmit = this.handleFormDialogSubmit.bind(this);
    this.handleCreateDialogSubmit = this.handleCreateDialogSubmit.bind(this);
    this.handleCreateDialogSecondary = this.handleCreateDialogSecondary.bind(this);
    this.handleCreateDialogClose = this.handleCreateDialogClose.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleTableRowActionsChange = this.handleTableRowActionsChange.bind(this);
    this.handleResetPasswordDialogClose = this.handleResetPasswordDialogClose.bind(this);
    this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.state = {
      records: [],
      total: 0,
      isLoading: false,
      isUpdating: false,
      isCreateDialogOpen: false,
    };
  }

  componentDidMount() {
    this.readRecords();
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    if ((this.getPage(prevProps.location) !== this.getPage(location))
    || (this.getLimit(prevProps.location) !== this.getLimit(location))
    || (this.getOrder(prevProps.location) !== this.getOrder(location))
    || (this.getSearch(prevProps.location) !== this.getSearch(location))) {
      this.readRecords();
    }
  }

  componentWillUnmount() {
    this.socketDisconnect();
		toast.dismiss('update-list');
  }

  socketConnect() {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
    	socket.current?.send(JSON.stringify({ action: `users:connect` }));
		}
    window.addEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  socketDisconnect() {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
    	socket.current?.send(JSON.stringify({ action: `users:disconnect` }));
		}
    window.removeEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  isSocketMessageValid(socketMessage?: SocketMessage) {
    const { auth } = this.props;
    let isValid = false;
    if (socketMessage) {
      const isStreamValid = ['users'].includes(socketMessage.stream);
      const isCreatedByUser = (socketMessage.data.createdBy === auth.user?.id);
      isValid = (isStreamValid && ! isCreatedByUser);
    }
    return isValid;
  }

  handleSocketMessage(e: CustomEventInit<SocketMessage>) {
    if (this.isSocketMessageValid(e.detail)) {
      toast.info('There have been updates to the list', {
        toastId: 'update-list',
        autoClose: false,
        hideProgressBar: true,
        draggable: false,
        onClose: () => this.readRecords(),
        closeButton: ( <CloseToast icon={{ icon: 'refresh' }} label={'Reload'} /> ),
      });
    }
  }

  async readRecords(isInitialLoad: boolean = true) {
    const { auth, location, socket } = this.props;
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await User.readRecords<UserSchema>(token, {
        page: this.getPage(location),
        limit: this.getLimit(location),
        order: this.getOrder(location),
        search: this.getSearch(location),
      });
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
      });
      if (isInitialLoad && (socket.current?.readyState === WebSocket.OPEN)) {
        this.socketConnect();
      }
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoading: false,
        records: [],
        total: 0,
      });
    }
  }

  async createRecord(record: UserSchema, isCreateDialogOpen: boolean = false) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      await User.createRecord<UserSchema>(token, record);
      toast.success(User.getLabel('addedSingular'));
      this.setState({
        isUpdating: false,
        isCreateDialogOpen: isCreateDialogOpen,
      });
      this.readRecords();
      const createDialog = this.createDialog.current;
      createDialog?.setDefaultRecord();
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async updateRecord(id: string, record: UserSchema, settings: UserSettings) {
    const { auth, location, history } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const { data } = await User.updateRecord<UserSchema>(token, id, record);
      await User.updateSettings(token, id, settings);
      toast.success(User.getLabel('updatedSingular'));
      this.syncRecord(data);
      this.setState({ isUpdating: false });
      const params = new URLSearchParams(location.search);
      params.delete('record');
      history.push(`${location.pathname}?${params.toString()}`);
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  syncRecord(newRecord: UserSchema) {
    const { records } = this.state;
    if (records) {
      this.setState({
        records: records.map(record => {
          return (record.id === newRecord.id) ? newRecord : record;
        }),
      });
    }
  }

  getPage(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page, 10) : 1;
  }

  getLimit(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit');
    return limit ? parseInt(limit, 10) : 20;
  }

  getOrder(location: RouteComponentProps['location']) {
    const { defaultOrder } = User.getOptions<UserSchema>();
    const params = new URLSearchParams(location.search);
    return params.get('order') || defaultOrder || 'first';
  }

  getRecord(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('record') || undefined;
  }

  getResetPasswordRecord(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('reset-password') || undefined;
  }

  getSearch(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('search') || undefined;
  }

  handlePageChange(page: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleLimitChange(limit: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('limit', limit.toString());
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleOrderChange(order: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('order', order);
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleTableRowDoubleClick(record: UserSchema) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('record', record.id);
    params.delete('reset-password');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleFormDialogClose() {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('record');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleSearchChange(search: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('search', search);
    } else {
      params.delete('search');
    }
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleCreateDialogClose() {
    this.setState({ isCreateDialogOpen: false });
  }

  handleCreateDialogSubmit(record: UserSchema) {
    this.createRecord(record);
  }

  handleCreateDialogSecondary(record: UserSchema) {
    this.createRecord(record, true);
  }

  handleCreateClick() {
    this.setState({ isCreateDialogOpen: true });
  }

  handleFormDialogSubmit(record: UserSchema, settings: UserSettings) {
    this.updateRecord(record.id, record, settings);
  }

  getTableRowActions(record: UserSchema) {
    const actions: UIOption[] = [
      { label: 'Reset Password', value: 'reset-password' },
    ];
    return actions;
  }

  handleTableRowActionsChange(action: UIOption['value'], record: UserSchema) {
    const { location, history } = this.props;
    switch (action) {
      case 'reset-password':
        const params = new URLSearchParams(location.search);
        params.set('reset-password', record.id);
        params.delete('record');
        history.push(`${location.pathname}?${params.toString()}`);
        break;

      default:
        break;
    }
  }

  handleResetPasswordDialogClose() {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('reset-password');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleRefreshButtonClick() {
    this.readRecords();
  }

  render() {
    const { auth, className, match, location, history, staticContext, ...restProps } = this.props;
    const { records, total, isLoading, isUpdating, isCreateDialogOpen } = this.state;
    const containerClass = classNames('fourg-users-page', className);
    const recordID = this.getRecord(location);
    const passwordResetRecordID = this.getResetPasswordRecord(location);
    const userOptions = User.getOptions<UserSchema>();
    const page = this.getPage(location);
    const limit = this.getLimit(location);
    const startNumber = (((page * limit) - limit) + 1);
    let endNumber = ((startNumber + limit) - 1);
    endNumber = (endNumber <= total) ? endNumber : total;
    return (
      <Page
      title={User.getLabel('plural')}
      description={User.getLabel('description')}
      headerDescription={isLoading ? User.getLabel('loadingPluralEllipsis') : `Viewing ${(total > 0) ? `${startNumber}-${endNumber} of ` : ''}${total} ${User.getLabel('plural')}.`}
      className={containerClass}
      {...restProps}>
        <FilterBar
        disabled={(isLoading || isUpdating)}
        onRefreshButtonClick={this.handleRefreshButtonClick}
        searchLabel={User.getLabel('searchEllipsis')}
        searchValue={this.getSearch(location)}
        onSearchChange={this.handleSearchChange}>
          {auth.user?.admin && (
            <Button
            icon={{ icon: 'add' }}
            variant="raised"
            onClick={this.handleCreateClick}>
              {User.getLabel('addSingular')}
            </Button>
          )}
        </FilterBar>
        <Table<UserSchema>
        isScrollable={true}
        columns={User.getFieldColumns<UserSchema>()}
        records={records}
        order={this.getOrder(location)}
        isLoading={isLoading}
        onOrderChange={this.handleOrderChange}
        onRowDoubleClick={this.handleTableRowDoubleClick}
        notFoundIcon={{ icon: userOptions.icon }}
        notFoundHeading={User.getLabel('notFoundPlural')}
        getRowActions={this.getTableRowActions}
        onRowActionsChange={this.handleTableRowActionsChange}
        renderCell={(value, column, record) => {
          const field = User.getField<UserSchema>(column.key);
          return (! field) ? value.toString() : (
            <TableCellByField<UserSchema>
            field={field}
            value={value}
            record={record} />
          );
        }} />
        <Pagination
        disabled={(isLoading || isUpdating)}
        page={page}
        limit={limit}
        total={total}
        onPageChange={this.handlePageChange}
        onLimitChange={this.handleLimitChange} />
        <UserFormDialog
        disabled={(isLoading || isUpdating || ! auth.user?.admin)}
        auth={auth}
        title={User.getLabel('editSingular')}
        recordID={recordID}
        isOpen={Boolean(recordID)}
        submitLabel={'Save'}
        cancelLabel={'Cancel'}
        onFormSubmit={this.handleFormDialogSubmit}
        onCloseClick={this.handleFormDialogClose}
        // onBackdropClick={this.handleFormDialogClose}
        onFormCancel={this.handleFormDialogClose}
        onEscape={this.handleFormDialogClose} />
        <ResetPasswordDialog
        disabled={(isLoading || isUpdating || ! auth.user?.admin)}
        auth={auth}
        recordID={passwordResetRecordID}
        isOpen={Boolean(passwordResetRecordID)}
        onCloseClick={this.handleResetPasswordDialogClose}
        onBackdropClick={this.handleResetPasswordDialogClose}
        onFormCancel={this.handleResetPasswordDialogClose}
        onEscape={this.handleResetPasswordDialogClose} />
        {auth.user?.admin && (
          <FormDialog<UserSchema>
          disabled={(isLoading || isUpdating)}
          auth={auth}
          ref={this.createDialog}
          title={User.getLabel('addSingular')}
          model={User}
          isOpen={isCreateDialogOpen}
          submitLabel={'Save'}
          secondaryLabel={'Save and Add Another'}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCreateDialogSubmit}
          onFormSecondary={this.handleCreateDialogSecondary}
          onCloseClick={this.handleCreateDialogClose}
          // onBackdropClick={this.handleCreateDialogClose}
          onFormCancel={this.handleCreateDialogClose}
          onEscape={this.handleCreateDialogClose} />
        )}
      </Page>
    );
  }
}

export default UsersPage;
