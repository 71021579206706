import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import DatePicker from 'react-datepicker';
import Icon, { Props as IconProps } from '../Icon/Icon';
import './DateInput.scss';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  variant?: 'default' | 'quiet';
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
  title?: string;
  value: string;
  placeholder?: string;
  icon?: IconProps['src'];
  type: 'datetime' | 'date' | 'time' | 'monthdaytime';
  isPastAllowed?: boolean;
  onChange: (value: string, e: React.SyntheticEvent<DatePicker>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface State {
  isFocused: boolean;
  isValid: boolean;
  internalID: string;
}

class DateInput extends React.Component<Props, State> {

  private validator = React.createRef<HTMLInputElement>();
  private datePicker = React.createRef<DatePicker>();

  static defaultProps = {
    icon: { icon: 'today' },
    value: '',
    variant: 'default',
    type: 'datetime',
    onChange: console.info,
  }

  constructor(props: Props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRaw = this.handleChangeRaw.bind(this);
    this.state = {
      isFocused: false,
      isValid: true,
      internalID: props.id || uniqid('DateInput-'),
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const validator = this.validator.current;
    if (value && validator) {
      this.setState({ isValid: validator.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { id, value, required, disabled } = this.props;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('DateInput-') });
    }
    if (! prevProps.disabled && disabled) {
      this.setState({ isFocused: false });
    }
    if ((prevProps.value !== value)
    || (prevProps.required !== required)) {
      const validator = this.validator.current;
      if (validator) {
        this.setState({ isValid: validator.checkValidity() });
      }
    }
  }

  handleFocus() {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    if (onFocus) onFocus();
  }

  handleBlur() {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    if (onBlur) onBlur();
	}

	handleChange(date: Date | null, e: React.SyntheticEvent<DatePicker>) {
    const { onChange, isPastAllowed } = this.props;
    const now = new Date();
    if (date && ! isPastAllowed) {
      if (this.datesAreOnSameDay(now, date) && (date.getTime() < now.getTime())) {
        date.setHours(now.getHours());
        date.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
      }
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
		onChange(date?.toISOString() || '', e);
  }

  handleChangeRaw(e: React.FocusEvent<HTMLInputElement>) {
    this.setState({ isValid: this.isValid() });
  }

  focus() {
    const datePicker = this.datePicker.current;
    datePicker?.setFocus();
  }

  blur() {
    const datePicker = this.datePicker.current;
    datePicker?.setBlur();
  }

  hasFocus() {
    const { isFocused } = this.state;
    return isFocused;
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  isValid() {
    const validator = this.validator.current;
    return validator?.checkValidity() || false;
  }

  datesAreOnSameDay(first: Date, second: Date) {
    const sameYear = (first.getFullYear() === second.getFullYear());
    const sameMonth = (first.getMonth() === second.getMonth());
    const sameDate = (first.getDate() === second.getDate());
    return (sameYear && sameMonth && sameDate);
  }

  getMinDate() {
    const minDate = new Date();
    return minDate;
  }

  getMinTime() {
    const { value } = this.props;
    const valueDate = value ? new Date(value) : undefined;
    const minTime = new Date();
    if (Boolean(valueDate && this.datesAreOnSameDay(valueDate, minTime))) {
      minTime.setMinutes(Math.ceil(minTime.getMinutes() / 15) * 15);
    } else {
      minTime.setHours(0);
      minTime.setMinutes(0);
    }
    minTime.setSeconds(0);
    minTime.setMilliseconds(0);
    return minTime;
  }

  getMaxTime() {
    const maxTime = new Date();
    maxTime.setHours(23);
    maxTime.setMinutes(59);
    maxTime.setSeconds(59);
    maxTime.setMilliseconds(999);
    return maxTime;
  }

  getFormatByType() {
    const { type } = this.props;
    switch (type) {
      case 'date': return 'MM/dd/yyyy';
      case 'time': return 'h:mm aa';
      case 'monthdaytime': return 'MM/dd h:mm aa';
      default: return 'MM/dd/yyyy h:mm aa';
    }
  }

  render() {
    const { isPastAllowed, type, icon, variant, value, id, required, readOnly, disabled, label, onChange, onFocus, onBlur, className, ...restProps } = this.props;
    const { isFocused, isValid, internalID } = this.state;
    const containerClass = classNames('fourg-date-input', `fourg-date-input--variant-${variant}`, {
      'fourg-date-input--dirty': value,
      'fourg-date-input--focused': isFocused,
      'fourg-date-input--disabled': disabled,
      'fourg-date-input--invalid': ! isValid,
      'fourg-date-input--has-icon': Boolean(icon),
    }, className);
    return (
      <div className={containerClass}>
        <label className="fourg-date-input__label" htmlFor={internalID}>
        {`${label}${required ? '*' : ''}`}
        </label>
        <div className="fourg-date-input__container">
          <DatePicker
          ref={this.datePicker}
          id={internalID}
          className="fourg-date-input__input"
          calendarClassName="fourg-date-input__calendar"
          disabled={disabled}
          selected={value ? new Date(value) : null}
          onChange={this.handleChange}
          onChangeRaw={this.handleChangeRaw}
          required={required}
          onCalendarOpen={this.handleFocus}
          onCalendarClose={this.handleBlur}
          autoComplete="off"
          dateFormat={this.getFormatByType()}
          showPopperArrow={false}
          showTimeSelect={['datetime', 'time', 'monthdaytime'].includes(type)}
          showTimeSelectOnly={['time'].includes(type)}
          shouldCloseOnSelect={false}
          fixedHeight={true}
          minDate={isPastAllowed ? undefined : this.getMinDate()}
          minTime={isPastAllowed ? undefined : this.getMinTime()}
          maxTime={isPastAllowed ? undefined : this.getMaxTime()}
          timeIntervals={15}
          popperProps={{ strategy: 'fixed' }}
          {...restProps} />
          {icon && (
            <Icon
            src={icon}
            className="fourg-date-input__icon"
            label={label} />
          )}
          <Icon
          src={{ icon: 'warning' }}
          className="fourg-date-input__warning"
          label="Warning" />
        </div>
        <input
				tabIndex={-1}
        className="fourg-date-input__validator"
        type="text"
        ref={this.validator}
        required={required}
        readOnly={readOnly}
        value={value}
        onChange={() => {}} />
      </div>
    );
  }
}

export default DateInput;
