import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import Icon, { Props as IconProps } from '../Icon/Icon';
import Attachment from '../../models/tables/Attachment';
import './FileInput.scss';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  variant?: 'default' | 'quiet' | 'mini',
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
  title?: string;
  tabIndex?: number;
  autoComplete?: string;
  icon?: IconProps['src'];
  accept?: string;
  multiple?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (value: FileList | null, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface State {
  isFocused: boolean;
  isValid: boolean;
  internalID: string;
  files: FileList | null;
}

class FileInput extends React.Component<Props, State> {

  private input = React.createRef<HTMLInputElement>();

  static defaultProps = {
    variant: 'default',
    icon: { icon: Attachment.getOptions().icon },
    onChange: console.info,
  }

  constructor(props: Props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isFocused: false,
      isValid: true,
      internalID: props.id || uniqid('FileInput-'),
      files: null,
    };
  }

  componentDidMount() {
    const input = this.input.current;
    if (input && input.files) {
      this.setState({ isValid: input.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { id, required, disabled } = this.props;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('Input-') });
    }
    if (prevProps.required !== required) {
      const input = this.input.current;
      if (input) {
        this.setState({ isValid: input.checkValidity() });
      }
    }
    if (! prevProps.disabled && disabled) {
      this.setState({ isFocused: false });
    }
  }

  handleFocus() {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    if (onFocus) onFocus();
  }

  handleBlur() {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    if (onBlur) onBlur();
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    this.setState({ files: e.target.files });
    onChange(e.target.files || null, e);
  }

  focus() {
    const input = this.input.current;
    input?.focus();
  }

  blur() {
    const input = this.input.current;
    input?.blur();
  }

  hasFocus() {
    const { isFocused } = this.state;
    return isFocused;
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  isValid() {
    const input = this.input.current;
    return input?.checkValidity() || false;
  }

  getHeading() {
    const input = this.input.current;
    let heading = 'Upload file(s)';
    if (input && input.files && (input.files.length > 0)) {
      heading = Array.from(input.files).map(file => file.name).join(', ');
    }
    return heading;
  }
  
  getDescription() {
    const input = this.input.current;
    let description = 'Drop files here or click to browse';
    if (input && input.files && (input.files.length > 0)) {
      description = (input.files.length === 1) ? '1 file selected' : `${input.files.length} files selected`;
    }
    return description;
  }

  reset() {
    const input = this.input.current;
    if (input) input.value = '';
    if (input) input.files = null;
    this.setState({ files: null });
  }

  render() {
    const { icon, variant, id, disabled, label, className, onChange, onFocus, onBlur, ...restProps } = this.props;
    const { isFocused, isValid, internalID } = this.state;
    const input = this.input.current;
    const containerClass = classNames('fourg-file-input', `fourg-file-input--variant-${variant}`, {
      'fourg-file-input--dirty': Boolean(input?.files),
      'fourg-file-input--focused': isFocused,
      'fourg-file-input--disabled': disabled,
      'fourg-file-input--invalid': ! isValid,
      'fourg-file-input--has-icon': Boolean(icon),
    }, className);
    return (
      <div className={containerClass}>
        <label className="fourg-file-input__label" htmlFor={internalID}>{label}</label>
        <input
        ref={this.input}
        type="file"
        id={internalID}
        className="fourg-file-input__input"
        disabled={disabled}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        {...restProps} />
        <div className="fourg-file-input__ui">
          <Icon 
          className="fourg-file-input__icon" 
          src={icon} 
          label={label} />
          <h3 className="fourg-file-input__heading">{this.getHeading()}</h3>
          <p className="fourg-file-input__description">{this.getDescription()}</p>
        </div>
      </div>
    );
  }
}

export default FileInput;
