export class APIError extends Error {

    public status?: number = undefined;

    constructor(status: number, ...params: any) {
        super(...params);
        if (Error.captureStackTrace) Error.captureStackTrace(this, APIError);
        this.name = 'APIError';
        this.status = status;
    }
}
