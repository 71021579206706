import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Loader from '../Loader/Loader';
import Form from '../Form/Form';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import Task from '../../models/tables/Task';
import { TableField, Auth, TaskSchema } from '../../types';
import './TaskFormDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  model: typeof Task;
  recordID?: string;
  initialValues?: Partial<TaskSchema>;
  enforcedValues?: Partial<TaskSchema>;
  submitLabel: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSecondary: (record: TaskSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: TaskSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isLoading: boolean;
  record: TaskSchema;
}

class TaskFormDialog extends React.Component<Props, State> {

  private formFields: React.RefObject<FormFieldByField<TaskSchema>>[] = [];

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSecondary: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.state = {
      isLoading: false,
      record: this.getDefaultRecord(),
    };
  }

  componentDidMount() {
    const { recordID, isOpen } = this.props;
    if (isOpen && recordID) {
      this.readRecord();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { recordID, isOpen, model } = this.props;
    if (prevProps.isOpen && ! isOpen) {
      this.setDefaultRecord();
    }
    if ((! prevProps.isOpen && isOpen)
    || (prevProps.model !== model)) {
      if (recordID) {
        this.readRecord();
      } else {
        this.setDefaultRecord();
      }
    }
  }

  async readRecord() {
    const { model, auth, recordID } = this.props;
    if (recordID) {
      this.setState({ isLoading: true });
      try {
        const token = await auth.getToken();
        const { data } = await model.readRecord<TaskSchema>(token, recordID);
        this.setState({
          isLoading: false,
          record: data,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ 
          isLoading: false,
          record: this.getDefaultRecord(), 
        });
      }
    }
  }

  getDefaultRecord() {
    const { model, enforcedValues, initialValues } = this.props;
    const record: TaskSchema = {
      ...model.getDefaultRecord<TaskSchema>(),
      ...initialValues,
      ...enforcedValues,
    };
    return record;
  }

  setDefaultRecord() {
    this.setState({ record: this.getDefaultRecord() }, () => this.resetValidity());
  }

  handleFieldChange(field: TableField<TaskSchema>, value: any) {
    const { record } = this.state;
    if (record) {
      let newRecord: TaskSchema = {
        ...record,
        [field.name]: value,
      };
      if (field.name === 'board') {
        newRecord = {
          ...this.getDefaultRecord(),
          board: value as string,
        };
      }
      if (field.name === 'companyId') {
        newRecord.locationId = undefined;
      }
      this.setState({ record: newRecord });
    }
  }

  isFieldEnforced(field: TableField<TaskSchema>) {
    const { enforcedValues } = this.props;
    return enforcedValues && Object.keys(enforcedValues).includes(field.name.toString());
  }

  getFields() {
    const { model } = this.props;
    let fields = model.getFieldsBy<TaskSchema>('isFormField', true);
    return fields;
  }

  resetValidity() {
    if (this.formFields.length > 0) {
      this.formFields.forEach(formField => formField.current?.resetValidity());
    }
  }

  render() {
    const { model, enforcedValues, initialValues, disabled, isOpen, title, auth, recordID, className, submitLabel, secondaryLabel, cancelLabel, onFormCancel, onFormSubmit, onFormSecondary, ...restProps } = this.props;
    const { record, isLoading } = this.state;
    const containerClass = classNames('fourg-task-form-dialog', `fourg-task-form-dialog--board-${record.board}`, {
      'fourg-task-form-dialog--add': ! Boolean(recordID),
      'fourg-task-form-dialog--edit': Boolean(recordID),
    }, className);
    const fields = this.getFields();
    return (
      <Dialog 
      className={containerClass}
      title={title}
      isOpen={isOpen}
      {...restProps}>
        {(fields.length > 0) && (
          <Form
          className="fourg-task-form-dialog__form"
          submitLabel={submitLabel}
          secondaryLabel={secondaryLabel}
          disabled={disabled || isLoading || ! isOpen}
          cancelLabel={cancelLabel}
          onCancel={onFormCancel}
          onSubmit={e => onFormSubmit(record, e)}
          onSecondary={e => onFormSecondary(record, e)}>
            {fields.map((field, i) => (
              <FormFieldByField<TaskSchema>
              className="fourg-task-form-dialog__form-field"
              ref={formField => this.formFields[i] = { current: formField }}
              record={record}
							recordModel={model}
              key={`form-field-${field.name}`} 
              field={field}
              value={record[field.name]}
              disabled={disabled || isLoading || ! isOpen || this.isFieldEnforced(field)}
              onChange={(value, e) => this.handleFieldChange(field, value)} />
            ))}
          </Form>
        )}
        {isLoading && (
          <Loader 
          position="absolute" 
          className="fourg-task-form-dialog__loader" />
        )}
      </Dialog>
    );
  }
}

export default TaskFormDialog;
