import React from 'react';
import classNames from 'classnames';
import RadioGroup from '../RadioGroup/RadioGroup';
import Sidebar, { Props as SidebarProps } from '../Sidebar/Sidebar';
import SidebarSection from '../SidebarSection/SidebarSection';
import LookupInput from '../LookupInput/LookupInput';
import LookupInputNew from '../LookupInputNew/LookupInputNew';
import Task from '../../models/tables/Task';
import { Auth, Lookup, TableFilter, UIOption } from '../../types';
import './TaskFilters.scss';

export interface FilterValues {
  [key: string]: UIOption['value'];
}

export interface Props extends SidebarProps {
  id?: string;
  className?: string;
  auth: Auth;
  lookup: Lookup;
  disabled?: boolean;
  value: FilterValues;
  board: string;
  model: typeof Task;
  onChange: (value: FilterValues, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export interface State {

}

class TaskFilters extends React.Component<Props, State> {

  static defaultProps = {
    ...Sidebar.defaultProps,
    value: {},
    onChange: console.info,
  };

  renderLookup(filter: TableFilter) {
    const { auth, lookup, disabled, value, onChange } = this.props;
    return filter.model && (
      <LookupInput
      label={filter.label}
      model={filter.model}
      auth={auth}
      lookup={lookup}
      readOnly={disabled}
      name={filter.name}
      value={value[filter.name]}
      options={filter.options}
      onChange={(selectedValue, e) => onChange({ ...value, [filter.name]: selectedValue }, e)} />
    );
  }

	renderLookupInput(filter: TableFilter) {
    const { auth, lookup, disabled, value, onChange } = this.props;
    return filter.model && (
      <LookupInputNew
      label={filter.label}
      model={filter.model}
      auth={auth}
      lookup={lookup}
      readOnly={disabled}
      name={filter.name}
      value={value[filter.name]}
      options={filter.options}
      onChange={(selectedValue, e) => onChange({ ...value, [filter.name]: selectedValue }, e)} />
    );
  }

  renderRadio(filter: TableFilter) {
    const { disabled, value, onChange } = this.props;
    return (
      <RadioGroup
      readOnly={disabled}
      name={filter.name}
      value={value[filter.name]}
      options={filter.options}
      onChange={(selectedValue, e) => onChange({ ...value, [filter.name]: selectedValue }, e)} />
    );
  }

  renderByType(filter: TableFilter) {
    switch (filter.type) {
      case 'lookup-input': return this.renderLookupInput(filter);
      case 'lookup': return this.renderLookup(filter);
      default: return this.renderRadio(filter);
    }
  }

  render() {
    const { model, board, disabled, value, onChange, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-task-filters', className);
    const filters = model.getFilters();
    return (
      <Sidebar className={containerClass} {...restProps}>
        {(filters.length > 0) && filters.map((filter, i) => (
          <SidebarSection
          disabled={disabled}
          key={filter.name}
          label={filter.label}
          isExpandedOnMount={(i === 0)}>
            {this.renderByType(filter)}
          </SidebarSection>
        ))}
      </Sidebar>
    );
  }
}

export default TaskFilters;
