import React from 'react';
import classNames from 'classnames';
import TimeAgo from 'react-timeago';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Breadcrumb } from '../../types';
import './PageHeader.scss';

export interface Props {
  id?: string;
  className?: string;
  topline: string;
  lastActive?: Date;
  title: React.ReactNode;
  description: React.ReactNode;
  breadcrumbs?: Breadcrumb[],
}

export interface State {

}

class PageHeader extends React.Component<Props, State> {

  static defaultProps = {
    topline: process.env.REACT_APP_TITLE || '',
  };

  render() {
    const { lastActive, breadcrumbs, topline, title, description, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-page-header', className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-page-header__content">
          <span className="fourg-page-header__topline">
            {(! breadcrumbs || (breadcrumbs.length <= 0)) ? topline : (
              <Breadcrumbs items={breadcrumbs} />
            )}
          </span>
          <h1 className="fourg-page-header__title">{title}</h1>
          {(description || lastActive) && (
            <div className="fourg-page-header__description-container">
              {description && (
                <span className="fourg-page-header__description">{description}</span>
              )}
              {(description && lastActive) && (
                <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
              )}
              {lastActive && (
                <span className="fourg-page-header__last-active">
                  <span>{'Last active'}&nbsp;</span>
                  <TimeAgo
                  date={lastActive}
                  title={lastActive.toLocaleString()} />
                </span>
              )}
            </div>
          )}
        </div>
        {children && (
          <div className="fourg-page-header__actions">
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default PageHeader;
