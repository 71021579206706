import React from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Form from '../../components/Form/Form';
import Input from '../../components/Input/Input';
import FormField from '../../components/FormField/FormField';
import SusiFormContainer from '../../components/SusiFormContainer/SusiFormContainer';
import { AuthContext, LayoutContext } from '../../contexts';
import Button from '../../components/Button/Button';
import { State as LayoutState } from '../../components/Layout/Layout';
import { Auth } from '../../types';
import './PasswordRecoverPage.scss';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
	layout: LayoutState;
}

export interface State {
  code: string;
  password: string;
}

class PasswordRecoverPageBase extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.state = {
      code: '',
      password: '',
    };
  }

	componentDidMount() {
		const { layout } = this.props;
		layout.hideHeader();
		layout.makeBodyScrollable();
	}

	componentWillUnmount() {
		const { layout } = this.props;
		layout.showHeader();
		layout.makeBodyUnscrollable();
	}

  handleCodeChange(value: string | number) {
    this.setState({ code: value.toString() });
  }

  handlePasswordChange(value: string | number) {
    this.setState({ password: value.toString() });
  }

  render() {
    const { auth, className, match, location, history, staticContext, layout, ...restProps } = this.props;
    const { code, password } = this.state;
    const containerClass = classNames('fourg-password-recover-page', className);
    return (
      <Page title="Recover Password" className={containerClass} {...restProps}>
        {auth.isLostPasswordReset ? (
          <p>Password reset. <Button to="/">Sign In</Button></p>
        ) : (
					<SusiFormContainer>
						<Form
						title="Recover Password"
						submitLabel="Recover Password"
						cancelLabel="Reset"
						isLoading={auth.isLoading}
						onSubmit={() => auth.resetLostPassword(code, password)}
						onCancel={() => auth.resetLostPasswordRecovery()}>
							<FormField size="large">
								<Input
								label="Code"
								required={true}
								type="password"
								value={code}
								disabled={auth.isLoading}
								onChange={this.handleCodeChange} />
							</FormField>
							<FormField size="large">
								<Input
								label="Password"
								required={true}
								type="password"
								value={password}
								disabled={auth.isLoading}
								onChange={this.handlePasswordChange} />
							</FormField>
						</Form>
					</SusiFormContainer>
        )}
      </Page>
    );
  }
}

const PasswordRecoverPage: React.FC<Omit<Props, 'auth' | 'layout'>> = (props) => {
	return (
		<AuthContext.Consumer>
			{auth => (
				<LayoutContext.Consumer>
					{(layout) => (
						<PasswordRecoverPageBase auth={auth} layout={layout} {...props} />
					)}
				</LayoutContext.Consumer>
			)}
		</AuthContext.Consumer>
	);
};

export default PasswordRecoverPage;
