import React from 'react';
import classNames from 'classnames';
import './MetricNote.scss';
import { formatCurrency, formatVariance } from '../../utils';

export interface Props {
  id?: string;
  className?: string;
	label: string;
	metric: number;
	variance?: number;
	isCurrency?: boolean;
	per?: string;
}

export interface State {

}

class MetricNote extends React.Component<Props, State> {

  render() {
    const { className, label, metric, variance, isCurrency, per, ...restProps } = this.props;
    const containerClass = classNames('fourg-metric-note', {
			'fourg-metric-note--positive': ((variance !== undefined) && (variance >= 0)),
			'fourg-metric-note--negative': ((variance !== undefined) && (variance < 0)),
		}, className);
    return (
      <p className={containerClass} {...restProps}>
				<strong className="fourg-metric-note__label">{label}</strong>
				<span className="fourg-metric-note__metric">
					{`${isCurrency ? formatCurrency(metric) : metric.toLocaleString()}${per ? `/${per}` : ''}`}
				</span>
				{variance && (
					<span className="fourg-metric-note__variance">{formatVariance(variance, 2)}</span>
				)}
			</p>
    );
  }
}

export default MetricNote;
