import React from 'react';
import classNames from 'classnames';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import FormField from '../FormField/FormField';
import Form from '../Form/Form';
import FileInput from '../FileInput/FileInput';
import Attachment from '../../models/tables/Attachment';
import { Auth, AttachmentSchema } from '../../types';
import './CreateAttachmentsDialog.scss';

export interface Props extends DialogProps {
  auth: Auth;
  title: string;
  fileInputLabel?: string;
  disabled?: boolean;
  multiple?: boolean;
  onSubmit: (records: AttachmentSchema[], e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface State {

}

class CreateAttachmentsDialog extends React.Component<Props, State> {

  private fileInput = React.createRef<FileInput>();

  static defaultProps = {
    ...Dialog.defaultProps,
    onSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen } = this.props;
    const fileInput = this.fileInput.current;
    if (prevProps.isOpen !== isOpen) {
      fileInput?.reset();
    }
  }

  handleFileInputChange(value: FileList | null, e: React.ChangeEvent<HTMLInputElement>) {
    const { onSubmit } = this.props;
    if (value) {
      const records: AttachmentSchema[] = Array.from(value).map(file => ({
        ...Attachment.getDefaultRecord<AttachmentSchema>(),
        file: file,
      }));
      onSubmit(records, e);
    }
  }

  render() {
    const { multiple, title, fileInputLabel, onSubmit, disabled, auth, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-create-attachments-dialog', className);
    const { icon } = Attachment.getOptions();
    return (
      <Dialog 
      className={containerClass}
      title={title}
      {...restProps}>
        <Form className="fourg-create-attachments-dialog__form">
          <FormField size="large">
            <FileInput
            ref={this.fileInput}
            label={fileInputLabel || title}
            onChange={this.handleFileInputChange}
            icon={{ icon: icon }}
            disabled={disabled}
            multiple={multiple} />
          </FormField>
        </Form>
      </Dialog>
    );
  }
}

export default CreateAttachmentsDialog;
