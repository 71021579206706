import React from 'react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoUserSession, CodeDeliveryDetails } from 'amazon-cognito-identity-js';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import Layout from './components/Layout/Layout';
import NotificationsDialog from './components/NotificationsDialog/NotificationsDialog';
import UserFormDialog from './components/UserFormDialog/UserFormDialog';
import IconMessageDialog from './components/IconMessageDialog/IconMessageDialog';
import AboutDialog from './components/AboutDialog/AboutDialog';
import Chime from './components/Chime/Chime';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import LocationPage from './pages/LocationPage/LocationPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import SignInPage from './pages/SignInPage/SignInPage';
import PasswordLostPage from './pages/PasswordLostPage/PasswordLostPage';
import PasswordRecoverPage from './pages/PasswordRecoverPage/PasswordRecoverPage';
import PasswordResetPage from './pages/PasswordResetPage/PasswordResetPage';
import VerificationPage from './pages/VerificationPage/VerificationPage';
import UsersPage from './pages/UsersPage/UsersPage';
import CompaniesPage from './pages/CompaniesPage/CompaniesPage';
import TasksPage from './pages/TasksPage/TasksPage';
import TaskPage from './pages/TaskPage/TaskPage';
import TaskPrintPage from './pages/TaskPrintPage/TaskPrintPage';
import User from './models/tables/User';
import Notification from './models/tables/Notification';
import { mergeRecords } from './utils';
import { AuthContext, NotificationsContext, LookupContext, SocketContext } from './contexts';
import { Auth, UserSchema, NotificationSchema, Notifications, Lookup, Socket, SocketMessage, UserSettings, AttachmentSchema, ReminderSchema, CompanySchema, LocationSchema, TaskSchema, GradeSchema, ExpenseSchema, ContactSchema } from './types';
import './App.scss';

export interface Props {

}

export interface State {
  isLoading?: boolean;
  socketInterval?: number;
  socketErrorTimer?: number;
  auth: Auth;
  notifications: Notifications;
  socket: Socket;
  lookup: Lookup;
}

class App extends React.Component<Props, State> {

  private chime = React.createRef<Chime>();

  constructor(props: Props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.resetLostPassword = this.resetLostPassword.bind(this);
    this.sendLostPasswordCode = this.sendLostPasswordCode.bind(this);
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.reAuthenticate = this.reAuthenticate.bind(this);
    this.getCognitoToken = this.getCognitoToken.bind(this);
    this.getCognitoToken = this.getCognitoToken.bind(this);
    this.resetLostPasswordRecovery = this.resetLostPasswordRecovery.bind(this);
    this.handleCognitoSuccess = this.handleCognitoSuccess.bind(this);
    this.handleCognitoFailure = this.handleCognitoFailure.bind(this);
    this.handleCognitoNewPasswordRequired = this.handleCognitoNewPasswordRequired.bind(this);
    this.handleCognitoLostPasswordCodeSucess = this.handleCognitoLostPasswordCodeSucess.bind(this);
    this.handleVerificationCodeSuccess = this.handleVerificationCodeSuccess.bind(this);
    this.handleVerificationSuccess = this.handleVerificationSuccess.bind(this);
    this.handleCognitoResetLostPasswordSuccess = this.handleCognitoResetLostPasswordSuccess.bind(this);
    this.markNotificationAsRead = this.markNotificationAsRead.bind(this);
    this.markNotificationAsUnread = this.markNotificationAsUnread.bind(this);
    this.markNotificationsAsRead = this.markNotificationsAsRead.bind(this);
    this.readNotifications = this.readNotifications.bind(this);
    this.readMoreNotifications = this.readMoreNotifications.bind(this);
    this.closeNotificationsDialog = this.closeNotificationsDialog.bind(this);
    this.openNotificationsDialog = this.openNotificationsDialog.bind(this);
    this.handleNotificationsDialogClose = this.handleNotificationsDialogClose.bind(this);
    this.initWebSocket = this.initWebSocket.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.handleSocketOpen = this.handleSocketOpen.bind(this);
    this.handleSocketClose = this.handleSocketClose.bind(this);
    this.handleSocketError = this.handleSocketError.bind(this);
    this.openProfileFormDialog = this.openProfileFormDialog.bind(this);
    this.closeProfileFormDialog = this.closeProfileFormDialog.bind(this);
    this.handleProfileFormSubmit = this.handleProfileFormSubmit.bind(this);
    this.handleAboutDialogClose = this.handleAboutDialogClose.bind(this);
    this.openAboutDialog = this.openAboutDialog.bind(this);
    this.closeAboutDialog = this.closeAboutDialog.bind(this);
    this.state = {
      isLoading: false,
      auth: this.getInitialAuth(),
      notifications: this.getInitialNotifications(),
      socket: this.getInitialSocket(),
      lookup: this.getInitialLookup(),
    };
  }

	getInitialSocket() {
		const socket: Socket = {
			current: undefined,
		};
		return socket;
	}

	getInitialLookup() {
		const lookup: Lookup = {};
		return lookup;
	}

  getInitialNotifications() {
    const notifications: Notifications = {
      isLoading: false,
      isUpdating: false,
      isPaginating: false,
      notifications: [],
      total: 0,
      unreadCount: 0,
      page: 1,
      readDate: new Date(),
      readNotifications: this.readNotifications,
      readMoreNotifications: this.readMoreNotifications,
      markAsRead: this.markNotificationAsRead,
      markAsUnread: this.markNotificationAsUnread,
      markManyAsRead: this.markNotificationsAsRead,
      openDialog: this.openNotificationsDialog,
      closeDialog: this.closeNotificationsDialog,
    };
    return notifications;
  }

  getInitialAuth() {
    const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } = process.env;
    if (! REACT_APP_COGNITO_USER_POOL_ID || !REACT_APP_COGNITO_CLIENT_ID) {
      throw new Error('Required Cognito environment variables were not found.');
    }
    const cognitoUserPool = new CognitoUserPool({
      UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: REACT_APP_COGNITO_CLIENT_ID,
    });
    const auth: Auth = {
      cognitoUserPool: cognitoUserPool || undefined,
      cognitoUser: cognitoUserPool.getCurrentUser() || undefined,
      cognitoUserAtts: undefined,
      isNewPasswordRequired: false,
      isLostPasswordCodeSent: false,
      isLostPasswordReset: false,
      isLoading: false,
      user: undefined,
      isProfileFormDialogOpen: false,
      isAboutDialogOpen: false,
      signIn: this.signIn,
      signOut: this.signOut,
      resetPassword: this.resetPassword,
      resetLostPassword: this.resetLostPassword,
      sendLostPasswordCode: this.sendLostPasswordCode,
      sendVerificationCode: this.sendVerificationCode,
      verifyAccount: this.verifyAccount,
      reAuthenticate: this.reAuthenticate,
      resetLostPasswordRecovery: this.resetLostPasswordRecovery,
      getToken: this.getCognitoToken,
      openProfileFormDialog: this.openProfileFormDialog,
      closeProfileFormDialog: this.closeProfileFormDialog,
      openAboutDialog: this.openAboutDialog,
      closeAboutDialog: this.closeAboutDialog,
    };
    return auth;
  }

  componentDidMount() {
    if (Boolean(Cookies.get('remember_me'))) {
      this.reAuthenticate();
    }
  }

  getCognitoUser(username?: string) {
    const { auth } = this.state;
    let cognitoUser = auth.cognitoUser;
    if (auth.cognitoUserPool && username) {
      cognitoUser = new CognitoUser({
        Username: username,
        Pool: auth.cognitoUserPool,
      });
    }
    return cognitoUser;
  }

  signIn(username: string, password: string, rememberMe: boolean) {
		const { auth } = this.state;
    const cognitoUser = this.getCognitoUser(username);
    if (cognitoUser) {
      if (rememberMe) {
        Cookies.set('remember_me', '1');
      } else {
        Cookies.remove('remember_me');
      }
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });
      this.setState({
				auth: {
					...auth,
					cognitoUser: cognitoUser,
					isLoading: true,
				},
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: this.handleCognitoSuccess,
        onFailure: this.handleCognitoFailure,
        newPasswordRequired: this.handleCognitoNewPasswordRequired,
      });
    }
  }

  async getCognitoToken(): Promise<string> {
    return new Promise((resolve) => {
      const cognitoUser = this.getCognitoUser();
      if (cognitoUser) {
        cognitoUser.getSession((error: Error, session: CognitoUserSession) => {
          if (error) {
            this.handleCognitoFailure(error);
            resolve('');
          } else {
            const newToken = session.getIdToken().getJwtToken();
            resolve(newToken);
          }
        });
      }
    })
  }

  reAuthenticate() {
    const cognitoUser = this.getCognitoUser();
    if (cognitoUser) {
      this.setState({ isLoading: true });
      cognitoUser.getSession((error: Error, session: CognitoUserSession) => {
        if (error) {
          this.handleCognitoFailure(error);
        } else {
          this.handleCognitoSuccess(session);
        }
      });
    }
  }

  handleCognitoSuccess(session: CognitoUserSession) {
		const { auth } = this.state;
    this.setState({
			auth: {
				...auth,
				isNewPasswordRequired: false,
				cognitoUserAtts: session.getIdToken().payload,
			},
    }, () => {
			this.initWebSocket();
		});
  }

  async readUser() {
		const { auth } = this.state;
    const userID = auth.cognitoUserAtts?.sub;
    if (userID) {
      this.setState({
				isLoading: true,
				auth: {
					...auth,
					isLoading: true,
				},
      });
      try {
        const token = await this.getCognitoToken();
        const { data } = await User.readRecord<UserSchema>(token, userID);
        const settings = await User.readSettings(token, userID);
        toast.info(`Welcome back, ${data.first}`, { toastId: 'welcome' });
        this.setState({
					isLoading: false,
					auth: {
						...auth,
						user: data,
						settings: settings,
						isLoading: false,
					},
        });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
					isLoading: false,
					auth: {
						...auth,
						user: undefined,
						settings: undefined,
						isLoading: false,
					},
        });
      }
    }
  }

  handleCognitoFailure(error: Error) {
    console.error(error);
    toast.error(error.message);
    this.setState({ auth: this.getInitialAuth() });
  }

  handleCognitoNewPasswordRequired(userAttributes: any, requiredAttributes: any[]) {
		const { auth } = this.state;
    this.setState({
			isLoading: false,
			auth: {
				...auth,
				isNewPasswordRequired: true,
				cognitoUserAtts: userAttributes,
				isLoading: false,
			},
    });
  }

  resetPassword(password: string) {
		const { auth } = this.state;
    const cognitoUser = this.getCognitoUser();
    const { cognitoUserAtts } = { ...auth };
    if (cognitoUser && cognitoUserAtts) {
      delete cognitoUserAtts.email_verified;
      this.setState({
				auth: {
					...auth,
					isLoading: true,
				},
      });
      cognitoUser.completeNewPasswordChallenge(password, cognitoUserAtts, {
        onSuccess: this.handleCognitoSuccess,
        onFailure: this.handleCognitoFailure,
      });
    }
  }

  sendLostPasswordCode(username: string) {
		const { auth } = this.state;
    const cognitoUser = this.getCognitoUser(username);
    if (cognitoUser) {
      this.setState({
				auth: {
					...auth,
					cognitoUser: cognitoUser,
					isLoading: true,
				},
      });
      cognitoUser.forgotPassword({
        onSuccess: this.handleCognitoLostPasswordCodeSucess,
        onFailure: this.handleCognitoFailure,
      });
    }
  }

  handleCognitoLostPasswordCodeSucess(data: { CodeDeliveryDetails: CodeDeliveryDetails }) {
		const { auth } = this.state;
    toast.success(`Code sent to: ${data.CodeDeliveryDetails.Destination}`);
    this.setState({
			auth: {
				...auth,
				isLostPasswordCodeSent: true,
				isLoading: false,
			},
    });
  }

  handleCognitoResetLostPasswordSuccess() {
		const { auth } = this.state;
    toast.success('Lost password reset');
    this.setState({
			auth: {
				...auth,
				isLostPasswordReset: true,
				isLoading: false,
			},
    });
  }

  resetLostPassword(code: string, password: string) {
		const { auth } = this.state;
    const cognitoUser = this.getCognitoUser();
    if (cognitoUser) {
      this.setState({
				auth: {
					...auth,
					isLoading: true,
				},
      });
      cognitoUser.confirmPassword(code, password, {
        onSuccess: this.handleCognitoResetLostPasswordSuccess,
        onFailure: this.handleCognitoFailure,
      });
    }
  }

  resetLostPasswordRecovery() {
		const { auth } = this.state;
    this.setState({
			auth: {
				...auth,
				isLostPasswordCodeSent: false,
			},
    });
  }

  sendVerificationCode() {
		const { auth } = this.state;
    const cognitoUser = this.getCognitoUser();
    if (cognitoUser) {
      this.setState({
				auth: {
					...auth,
					isLoading: true,
				},
      });
      cognitoUser.getAttributeVerificationCode('email', {
        onSuccess: this.handleVerificationCodeSuccess,
        onFailure: this.handleCognitoFailure,
      });
    }
  }

  handleVerificationCodeSuccess() {
		const { auth } = this.state;
    toast.success('Verification code sent');
    this.setState({
			auth: {
				...auth,
				isLoading: false,
			},
    });
  }

  verifyAccount(code: string) {
		const { auth } = this.state;
    const cognitoUser = this.getCognitoUser();
    if (cognitoUser) {
      this.setState({
				auth: {
					...auth,
					isLoading: true,
				},
      });
      cognitoUser.verifyAttribute('email', code, {
        onSuccess: this.handleVerificationSuccess,
        onFailure: this.handleCognitoFailure,
      });
    }
  }

  handleVerificationSuccess(success: string) {
		const { auth } = this.state;
    const cognitoUser = this.getCognitoUser();
    if (cognitoUser) {
      cognitoUser.getUserAttributes((error, result) => {
        if (error) {
          this.handleCognitoFailure(error);
        } else {
          toast.success('Account verified');
					const cognitoUserAtts = { ...auth.cognitoUserAtts };
					result?.forEach(key => {
						cognitoUserAtts[key.getName()] = key.getValue();
					});
          this.setState({
						auth: {
							...auth,
							cognitoUserAtts: cognitoUserAtts,
							isLoading: false,
						},
          });
        }
      });
    }
  }

  signOut(reload: boolean = false) {
		const { socket } = this.state;
    const cognitoUser = this.getCognitoUser();
    Cookies.remove('remember_me');
    cognitoUser?.signOut();
    if (socket.current && (socket.current.readyState === WebSocket.OPEN)) {
      socket.current.close();
    }
    this.setState({
      auth: this.getInitialAuth(),
      notifications: this.getInitialNotifications(),
			lookup: this.getInitialLookup(),
			socket: this.getInitialSocket(),
    }, () => {
			if (reload) {
				window.location.reload();
			}
		});
  }

  handleSocketMessage(e: MessageEvent) {
		const { auth, lookup, notifications } = this.state;
    const rawMessage: SocketMessage | undefined = (typeof e.data === 'string') ? JSON.parse(e.data) : e.data;
    if (rawMessage) {
      if (rawMessage.type === 'notifications') {
        const { meta, data } = rawMessage as SocketMessage<NotificationSchema>;
				const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
        this.setState({
					notifications: {
						...notifications,
						notifications: this.prependNotification(data),
						total: (notifications.total + 1),
						unreadCount: (notifications.unreadCount + 1),
					},
					lookup: {
						...lookup,
						users: mergeRecords<UserSchema>(newUsers, lookup.users),
						tasks: mergeRecords<TaskSchema>(meta?.tasks, lookup.tasks),
						attachments: mergeRecords<AttachmentSchema>(meta?.attachments, lookup.attachments),
						reminders: mergeRecords<ReminderSchema>(meta?.reminders, lookup.reminders),
						companies: mergeRecords<CompanySchema>(meta?.companies, lookup.companies),
						locations: mergeRecords<LocationSchema>(meta?.locations, lookup.locations),
						grades: mergeRecords<GradeSchema>(meta?.grades, lookup.grades),
						expenses: mergeRecords<ExpenseSchema>(meta?.expenses, lookup.expenses),
						contacts: mergeRecords<ContactSchema>(meta?.contacts, lookup.contacts),
					},
        });
        this.playChime();
      } else {
        const customMessageEvent = new CustomEvent('fourg:socket-message', {
          detail: rawMessage,
        });
        window.dispatchEvent(customMessageEvent);
      }
    }
  }

  async initWebSocket() {
    if (! process.env.REACT_APP_SOCKET_URL) {
      throw new Error('Required API environment variables were not found.');
    }
    const { socket } = this.state;
    if (socket.current && (socket.current.readyState === WebSocket.OPEN)) {
      socket.current.close();
    }
    const token = await this.getCognitoToken();
    const webSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}?auth=${token}`);
    webSocket.onopen = this.handleSocketOpen;
    webSocket.onmessage = this.handleSocketMessage;
    webSocket.onclose = this.handleSocketClose;
    webSocket.onerror = this.handleSocketError;
    this.setState({
			socket: {
				...socket,
				current: webSocket,
			},
    });
  }

  pingSocket() {
    const { current } = this.state.socket;
    if (current && (current.readyState === WebSocket.OPEN)) {
      current.send(JSON.stringify({ action: 'server:ping' }));
    }
  }

  handleSocketOpen(e: Event) {
    console.info('WebSocket Opened', e);
    const { socketInterval, socketErrorTimer } = this.state;
    if (socketInterval) window.clearInterval(socketInterval);
    if (socketErrorTimer) window.clearTimeout(socketErrorTimer);
    const customOpenEvent = new CustomEvent('fourg:socket-open');
    window.dispatchEvent(customOpenEvent);
    this.setState({
      socketInterval: window.setInterval(() => this.pingSocket(), 5000),
      socketErrorTimer: undefined,
    }, () => {
			this.readData();
		});
  }

  handleSocketClose(e: Event) {
    console.info('WebSocket Closed', e);
    const { socketInterval, socketErrorTimer } = this.state;
    if (socketInterval) window.clearInterval(socketInterval);
    if (socketErrorTimer) window.clearTimeout(socketErrorTimer);
    this.setState({
      socketErrorTimer: window.setTimeout(() => this.initWebSocket(), 5000),
      socketInterval: undefined,
    });
  }

  handleSocketError(e: Event) {
    console.error('WebSocket Error', e);
    const { socketInterval, socketErrorTimer } = this.state;
    if (socketInterval) window.clearInterval(socketInterval);
    if (socketErrorTimer) window.clearTimeout(socketErrorTimer);
    this.setState({
      isLoading: false,
      socketErrorTimer: window.setTimeout(() => this.initWebSocket(), 5000),
      socketInterval: undefined,
    });
  }

  prependNotification(newNotification: NotificationSchema) {
    const { notifications } = this.state.notifications;
    const newNotifications = [newNotification, ...notifications];
    return newNotifications;
  }

  async readData() {
    this.readUser();
    this.readNotifications();
  }

  async readNotifications() {
		const { notifications, auth } = this.state;
    this.setState({
			notifications: {
				...notifications,
				isLoading: true,
			},
    });
    try {
      const token = await this.getCognitoToken();
      const readDate = new Date();
      const { defaultOrder } = Notification.getOptions();
      const { meta, data } = await Notification.readRecords<NotificationSchema>(token, {
        limit: 20,
        page: 1,
        order: defaultOrder,
        created: `-${readDate.toISOString()}`,
      });
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      this.setState({
				notifications: {
					...notifications,
					notifications: data || [],
					total: meta.total || 0,
					unreadCount: meta.unreadCount || 0,
					isLoading: false,
					page: 1,
					readDate: readDate,
				},
				lookup: {
					users: newUsers,
					tasks: meta.tasks,
					attachments: meta.attachments,
					reminders: meta.reminders,
					companies: meta.companies,
					locations: meta.locations,
					grades: meta.grades,
					expenses: meta.expenses,
					contacts: meta.contacts,
				},
      });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
				notifications: {
					...notifications,
					notifications: [],
					total: 0,
					unreadCount: 0,
					isLoading: false,
					page: 1,
					readDate: undefined,
				},
				lookup: this.getInitialLookup(),
      });
    }
  }

  async readMoreNotifications() {
		const { lookup, notifications, auth } = this.state;
    const { page, readDate } = notifications;
    this.setState({
			notifications: {
				...notifications,
				isPaginating: true,
			},
    });
    try {
      const token = await this.getCognitoToken();
      const { defaultOrder } = Notification.getOptions();
      const { meta, data } = await Notification.readRecords<NotificationSchema>(token, {
        limit: 20,
        page: (page + 1),
        order: defaultOrder,
        created: readDate ? `-${readDate.toISOString()}` : undefined,
      });
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      this.setState({
				notifications: {
					...notifications,
					notifications: mergeRecords<NotificationSchema>(data, notifications.notifications),
					total: meta.total || 0,
					unreadCount: meta.unreadCount || 0,
					isPaginating: false,
					page: (page + 1),
				},
				lookup: {
					...lookup,
					users: mergeRecords<UserSchema>(newUsers, lookup.users),
					tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
					attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
					reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
					companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
					locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
					grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
				},
      });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
				notifications: {
					...notifications,
					isPaginating: false,
				},
      });
    }
  }

  getNotification(id: NotificationSchema['id']) {
    const { notifications } = this.state.notifications;
    return notifications.find(notification => (notification.id === id));
  }

  async markNotificationAsRead(id: NotificationSchema['id']) {
		const { notifications } = this.state;
    this.setState({
			notifications: {
				...notifications,
				isUpdating: true,
			},
    });
    try {
      const token = await this.getCognitoToken();
      await Notification.markAsRead(token, id);
      toast.success(Notification.getLabel('updatedSingular'));
      const newNotification = this.getNotification(id);
      if (newNotification) {
        newNotification.read = true;
        this.setState({
					notifications: {
						...notifications,
						isUpdating: false,
						unreadCount: (notifications.unreadCount - 1),
						notifications: notifications.notifications.map(notification => {
							return (notification.id === newNotification.id) ? newNotification : notification;
						}),
					},
        });
      }
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
				notifications: {
					...notifications,
					isUpdating: false,
				},
      });
    }
  }

  async markNotificationAsUnread(id: NotificationSchema['id']) {
		const { notifications } = this.state;
    this.setState({
			notifications: {
				...notifications,
				isUpdating: true,
			},
    });
    try {
      const token = await this.getCognitoToken();
      await Notification.markAsUnread(token, id);
      toast.success(Notification.getLabel('updatedSingular'));
      const newNotification = this.getNotification(id);
      if (newNotification) {
        newNotification.read = false;
        this.setState({
					notifications: {
						...notifications,
						isUpdating: false,
						unreadCount: (notifications.unreadCount + 1),
						notifications: notifications.notifications.map(notification => {
							return (notification.id === newNotification.id) ? newNotification : notification;
						}),
					},
        });
      }
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
				notifications: {
					...notifications,
					isUpdating: false,
				},
      });
    }
  }

  async markNotificationsAsRead(ids?: NotificationSchema['id'][]) {
		const { notifications } = this.state;
    this.setState({
			notifications: {
				...notifications,
				isUpdating: true,
			},
    });
    try {
      const token = await this.getCognitoToken();
      await Notification.markManyAsRead(token, ids);
      toast.success(Notification.getLabel('updatedPlural'));
      this.setState({
				notifications: {
					...notifications,
					isUpdating: false,
					unreadCount: 0,
					notifications: notifications.notifications.map(notification => {
						let newNotification = { ...notification };
						if (ids) {
							if (ids.includes(notification.id)) {
								newNotification = { ...notification, read: true };
							}
						} else {
							newNotification = { ...notification, read: true };
						}
						return newNotification;
					}),
				},
      });
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
				notifications: {
					...notifications,
					isUpdating: false,
				},
      });
    }
  }

  closeNotificationsDialog() {
		const { notifications } = this.state;
    this.setState({
			notifications: {
				...notifications,
				isDialogOpen: false,
			},
    });
  }

  openNotificationsDialog() {
		const { notifications } =this.state;
    this.setState({
			notifications: {
				...notifications,
				isDialogOpen: true,
			},
    });
  }

  handleNotificationsDialogClose() {
    this.closeNotificationsDialog();
  }

  playChime() {
    const chime = this.chime.current;
    chime?.play();
  }

  openProfileFormDialog() {
    const { auth } = this.state;
    this.setState({
      auth: {
        ...auth,
        isProfileFormDialogOpen: true,
      },
    });
  }

  closeProfileFormDialog() {
    const { auth } = this.state;
    this.setState({
      auth: {
        ...auth,
        isProfileFormDialogOpen: false,
      },
    });
  }

  handleProfileFormSubmit(record: UserSchema, settings: UserSettings) {
    this.updateUser(record, settings);
  }

  async updateUser(record: UserSchema, settings: UserSettings, isProfileFormDialogOpen: boolean = false) {
    const { auth } = this.state;
		this.setState({
			auth: {
				...auth,
				isUpdating: true,
			},
    });
    try {
      const token = await this.getCognitoToken();
      const { data } = await User.updateRecord<UserSchema>(token, record.id, record);
      const newSettings = await User.updateSettings(token, record.id, settings);
      this.setState({
				auth: {
					...auth,
					isUpdating: false,
					user: data,
					settings: newSettings,
					isProfileFormDialogOpen: isProfileFormDialogOpen,
				},
      });
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
				auth: {
					...auth,
					isUpdating: false,
				},
      });
    }
  }

  handleAboutDialogClose() {
    this.closeAboutDialog();
  }

  closeAboutDialog() {
		const { auth } = this.state;
    this.setState({
			auth: {
				...auth,
				isAboutDialogOpen: false,
			},
    });
  }

  openAboutDialog() {
		const { auth } = this.state;
    this.setState({
			auth: {
				...auth,
				isAboutDialogOpen: true,
			},
    });
  }

  getEnforcedUserFormValues() {
    const { auth } = this.state;
    const enforcedValues: Partial<UserSchema> = {};
    if (! auth.user?.admin) {
      enforcedValues.admin = auth.user?.admin;
    }
    return enforcedValues;
  }

  render() {
    const { auth, socket, notifications, lookup, isLoading } = this.state;
    const isLoggedIn = (! isLoading && auth.user && auth.settings && socket.current);
    return (
			<AuthContext.Provider value={auth}>
				<SocketContext.Provider value={socket}>
					<NotificationsContext.Provider value={notifications}>
						<LookupContext.Provider value={lookup}>
							<BrowserRouter>
								<Layout>
									{! isLoggedIn ? (
										<Switch>
											<Route exact path="/lost-password" component={auth.isLostPasswordCodeSent ? PasswordRecoverPage : PasswordLostPage} />
											<Route component={auth.isNewPasswordRequired ? PasswordResetPage : SignInPage} />
										</Switch>
									) : ! auth.cognitoUserAtts?.email_verified ? (
										<Switch>
											<Route component={VerificationPage} />
										</Switch>
									) : (
										<Switch>
											<Route exact path="/tasks/:id/print" render={routeProps => (
												<TaskPrintPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/tasks/:id" render={routeProps => (
												<TaskPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/tasks" render={routeProps => (
												<TasksPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/companies" render={routeProps => (
												<CompaniesPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/companies/:companyID/locations/:id/:section?" render={routeProps => (
												<LocationPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/companies/:id/:section?" render={routeProps => (
												<CompanyPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/users" render={routeProps => (
												<UsersPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/dashboard/:section?" render={routeProps => (
												<DashboardPage auth={auth} socket={socket} {...routeProps} />
											)} />
											<Route exact path="/" render={() => (
												<Redirect to={'/dashboard'} />
											)} />
											<Route render={routeProps => (
												<ErrorPage status={404} {...routeProps} />
											)} />
										</Switch>
									)}
									{isLoggedIn && (
										<React.Fragment>
											<NotificationsDialog
											notifications={notifications}
											title={`${Notification.getLabel('plural')} (${notifications.unreadCount})`}
											isOpen={notifications.isDialogOpen}
											onBackdropClick={this.handleNotificationsDialogClose}
											onCloseClick={this.handleNotificationsDialogClose}
											onEscape={this.handleNotificationsDialogClose} />
											<UserFormDialog
											auth={auth}
											recordID={auth.user?.id}
											title={'My Account'}
											submitLabel={'Save'}
											deleteLabel={'Sign Out'}
											cancelLabel={'Cancel'}
											enforcedValues={this.getEnforcedUserFormValues()}
											isOpen={auth.isProfileFormDialogOpen}
											onCloseClick={() => this.closeProfileFormDialog()}
											// onBackdropClick={() => this.closeProfileFormDialog()}
											onEscape={() => this.closeProfileFormDialog()}
											onFormCancel={() => this.closeProfileFormDialog()}
											onFormSubmit={this.handleProfileFormSubmit}
											onDelete={() => this.signOut()}
											disabled={auth.isUpdating} />
											<IconMessageDialog
											isOpen={(! isLoading && (socket.current?.readyState !== WebSocket.OPEN))}
											icon={{ icon: 'wifi_off' }}
											title="Connection Lost"
											heading="It looks like you're offline"
											subheading={"The app will reconnect when it detects an internet connection."} />
											<AboutDialog
											isOpen={auth.isAboutDialogOpen}
											title={'About'}
											onBackdropClick={this.handleAboutDialogClose}
											onCloseClick={this.handleAboutDialogClose}
											onEscape={this.handleAboutDialogClose} />
										</React.Fragment>
									)}
									{isLoading && (
										<Loader position="fixed" />
									)}
									<Chime ref={this.chime} />
								</Layout>
							</BrowserRouter>
						</LookupContext.Provider>
					</NotificationsContext.Provider>
				</SocketContext.Provider>
			</AuthContext.Provider>
    );
  }
}

export default App;
