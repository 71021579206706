import mime from 'mime';
import Table from '../Table';
import { TableOptions, TableLabels, TableField, LocationSchema, ReadRecordsQuery, ReadRecordsResponse, ContactSchema, TableEndpoints, TaskSchema, AttachmentSchema, CreateAttachmentResponse, CieTradeLogSchema } from '../../types';
import { APIError } from '../../errors';
import Attachment from './Attachment';
import Company from './Company';
import Contact from './Contact';

class Location extends Table {

  static options: TableOptions = {
    name: 'Location',
    slug: 'locations',
    labelKey: 'name',
    valueKey: 'id',
    lookupKey: 'locations',
    icon: 'place',
    defaultOrder: 'name',
    hasQuickAdd: true,
  };

  static labels: TableLabels = {
    description: 'All locations in the 4G Recycling Site Manager.',
    pageTitle: 'Location Manager',
    singular: 'Location',
    plural: 'Locations',
    viewSingular: 'View Location',
    viewPlural: 'View Locations',
    selectSingular: 'Select Location',
    selectPlural: 'Select Locations',
    addSingular: 'Add Location',
    addPlural: 'Add Locations',
    editSingular: 'Edit Location',
    editPlural: 'Edit Locations',
    addedSingular: 'Location added',
    addedPlural: 'Locations added',
    updatedSingular: 'Location updated',
    updatedPlural: 'Locations updated',
    deletedSingular: 'Location deleted',
    deletedPlural: 'Locations deleted',
    archivedSingular: 'Location archived',
    archivedPlural: 'Locations archived',
    restoredSingular: 'Location restored',
    restoredPlural: 'Locations restored',
    errorFetchingSingular: 'Error fetching Location',
    errorFetchingPlural: 'Error fetching Locations',
    errorAddingSingular: 'Error adding Location',
    errorAddingPlural: 'Error adding Locations',
    errorUpdatingSingular: 'Error updating Location',
    errorUpdatingPlural: 'Error updating Locations',
    errorDeletingSingular: 'Error deleting Location',
    errorDeletingPlural: 'Error deleting Locations',
    errorArchivingSingular: 'Error archiving Location',
    errorArchivingPlural: 'Error archiving Locations',
    errorRestoringSingular: 'Error restoring Location',
    errorRestoringPlural: 'Error restoring Locations',
    notFoundSingular: 'Location not found',
    notFoundPlural: 'No Locations found',
    loadingSingular: 'Loading Location',
    loadingSingularEllipsis: 'Loading Location...',
    loadingPlural: 'Loading Locations',
    loadingPluralEllipsis: 'Loading Locations...',
    search: 'Search Locations',
    searchEllipsis: 'Search Locations...',
    filter: 'Filter Locations',
    settings: 'Locations Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'locations',
    readRecord: 'location/:id',
    createRecord: 'location',
    updateRecord: 'location/:id',
    updateRecords: 'locations',
    patchRecord: 'location/:id',
    deleteRecord: 'location/:id',
    archiveRecord: 'location/:id/archive',
    restoreRecord: 'location/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
    },
    {
      name: 'name',
      label: 'Location Name',
      type: 'unique',
      model: Location,
      required: true,
      default: '',
      isSortable: true,
			isTableColumn: true,
			isFormField: true,
      formFieldSize: 'large',
      isQuickAddField: true,
      quickAddFormFieldSize: 'large',
      isInfo: true,
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      default: '',
      options: [
        { value: '', label: 'No Type' },
        { value: 'main-office', label: 'Main Office' },
        { value: 'distribution-center', label: 'Distribution Center' },
        { value: 'equipment-provider', label: 'Equipment Provider' },
        { value: 'freight-carrier', label: 'Freight Carrier' },
        { value: 'mill', label: 'Mill' },
        { value: 'printer', label: 'Printer' },
        { value: 'recycling-plant', label: 'Recycling Plant' },
        { value: 'supply-provider', label: 'Supply Provider' },
        { value: 'waste-disposal', label: 'Waste Disposal' },
      ],
      isSortable: true,
      isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
      isInfo: true,
      infoSize: 'small',
    },
		{
      name: 'status',
      label: 'Status',
      type: 'select',
      required: true,
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
      default: 'active',
      isSortable: true,
      isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
      isInfo: true,
      infoSize: 'small',
      columnWidth: 160,
    },
		{
			name: 'schedulingContactId',
			label: 'Scheduling Contact',
			type: 'lookup-location-section',
      model: Contact,
			default: '',
      isFormField: true,
      isInfo: true,
      infoSize: 'large',
      formFieldSize: 'medium',
			options: [
				{ label: 'Unassigned', value: '' },
			],
    },
		{
			name: 'alternateContactId',
			label: 'Alternate Contact',
			type: 'lookup-location-section',
      model: Contact,
			default: '',
      isFormField: true,
      isInfo: true,
      infoSize: 'large',
      formFieldSize: 'medium',
			options: [
				{ label: 'Unassigned', value: '' },
			],
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      default: '',
      isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
      isInfo: true,
      infoSize: 'small',
      columnWidth: 140,
    },
    {
      name: 'fax',
      label: 'Fax',
      type: 'tel',
      default: '',
      isFormField: true,
      formFieldSize: 'medium',
      isInfo: true,
      infoSize: 'small',
    },
		{
      name: 'loadingHours',
      label: 'Loading Hours',
      type: 'text',
      default: '',
      isFormField: true,
      formFieldSize: 'large',
      isInfo: true,
      infoSize: 'large',
    },
    {
      name: 'notes',
      label: 'CRM Notes',
      type: 'textarea',
      default: '',
      isFormField: true,
      isInfo: true,
      formFieldSize: 'large',
    },
		{
      name: 'directions',
      label: 'Directions',
      type: 'textarea',
      default: '',
      isFormField: true,
      isInfo: true,
      formFieldSize: 'large',
    },
    {
      name: 'primary',
      label: 'Primary',
      type: 'checkbox',
      default: false,
      isSortable: true,
      isTableColumn: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'large',
    },
		{
      name: 'remittance',
      label: 'Remittance',
      type: 'checkbox',
      default: false,
      isSortable: true,
      isTableColumn: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'small',
      formFieldSize: 'large',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'address',
      default: {
        address1: '',
        address2: '',
        country: 'US',
        city: '',
        state: 'AL',
        zip: '',
      },
      isQuickAddField: true,
      isFormField: true,
      formFieldSize: 'large',
      required: true,
      isInfo: true,
      excludedFieldsQuickAdd: [
        'address1',
        'address2',
        'zip',
      ],
    },
    {
      name: 'contactsCount',
      label: 'Contacts',
      type: 'number',
      default: 0,
      readOnly: true,
      isSortable: true,
    },
    {
      name: 'attachmentsCount',
      label: 'Attachments',
      type: 'number',
      default: 0,
      readOnly: true,
      isSortable: true,
    },
		{
      name: 'isPushed',
      label: 'cieTrade',
      type: 'checkbox',
      default: false,
      isTableColumn: true,
      isInfo: true,
      infoSize: 'small',
    },
		{
			name: 'created',
			label: 'Created',
			type: 'date',
			default: '',
			readOnly: true,
			isSortable: true,
      isInfo: true,
    },
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
			default: '',
			readOnly: true,
      isSortable: true,
      isInfo: true,
    },
  ];

  static getRecordLink<T extends Record<string, any> = Record<string, any>>(record: T) {
    const { slug } = this.getOptions();
    const companyOptions = Company.getOptions();
    const companyID = (record as any as LocationSchema).companyId;
    return `/${encodeURIComponent(companyOptions.slug)}/${encodeURIComponent(companyID)}/${encodeURIComponent(slug)}/${encodeURIComponent(this.getRecordValue<T>(record))}`;
}

  static async readContacts(token: string, id: LocationSchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<ContactSchema>>{
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/contacts`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get location contacts');
    }
  }

  static async readTasks(token: string, id: LocationSchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<TaskSchema>> {
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/tasks`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get location tasks');
    }
  }

  static async readAttachments(token: string, id: LocationSchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<AttachmentSchema>> {
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/attachments`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get location attachments');
    }
  }

  static async createAttachment(token: string, id: LocationSchema['id'], record: AttachmentSchema): Promise<string> {
    const file = record.file;
    if (file) {
      const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-attachment`, {
        method: 'POST',
        body: JSON.stringify({
          title: record.title,
          description: record.description,
          contentType: file.type || mime.getType(file.name) || 'application/octet-stream',
          fileName: file.name,
        }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      switch (response.status) {
        case 200:
          const { signedUrl, id: attachmentID, filePath }: CreateAttachmentResponse = await response.json();
          await Attachment.uploadFile(attachmentID, signedUrl, file);
          await Attachment.validateUpload(token, attachmentID);
          return this.getS3URL(filePath);
        default: throw new APIError(response.status, Attachment.getLabel('errorAddingSingular'));
      }
    } else {
      throw new Error('no file');
    }
  }

  static async createAttachments(token: string, id: LocationSchema['id'], records: AttachmentSchema[]): Promise<boolean> {
    const recordsWithFiles = records.filter(record => Boolean(record.file)) as (Omit<AttachmentSchema, 'file'> & { file: File })[];
    const files: Record<string, any>[] = recordsWithFiles.map(record => ({
      title: record.title || '',
      description: record.description || '',
      contentType: record.file?.type || mime.getType(record.file?.name || '') || 'application/octet-stream',
      fileName: record.file?.name || '',
    }));
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-attachments`, {
      method: 'POST',
      body: JSON.stringify({
        files: files,
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200:
        const attachmentResponses: CreateAttachmentResponse[] = await response.json();
        const promises = attachmentResponses.map((response, i) => Attachment.uploadFile(response.id, response.signedUrl, recordsWithFiles[i].file));
        const resolvedPromises = await Promise.allSettled(promises);
        const attachmentIDs = attachmentResponses.filter((_response, i) => (resolvedPromises[i].status === 'fulfilled')).map(response => response.id);
        await Attachment.validateUploads(token, attachmentIDs);
        return true;
      default: throw new APIError(response.status, Attachment.getLabel('errorAddingSingular'));
    }
  }

	static async readCieTradeLogs(token: string, id: LocationSchema['id'], query?: ReadRecordsQuery): Promise<ReadRecordsResponse<CieTradeLogSchema>> {
    let url = `${this.getEndpoint('readRecord').replace(':id', id.toString())}/log`;
    url = query ? this.parseQuery(url, query) : url;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Could not get location cieTrade logs');
    }
  }
}

export default Location;
