import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import AttachmentCard from '../AttachmentCard/AttachmentCard';
import ActionValueByField from '../ActionValueByField/ActionValueByField';
import Editor from '../Editor/Editor';
import ActionToggle from '../ActionToggle/ActionToggle';
import Form from '../Form/Form';
import FormField from '../FormField/FormField';
import TaskCard from '../TaskCard/TaskCard';
import ReminderCard from '../ReminderCard/ReminderCard';
import GradeCard from '../GradeCard/GradeCard';
import ExpenseCard from '../ExpenseCard/ExpenseCard';
import ArtifactComparison from '../ArtifactComparison/ArtifactComparison';
import Task from '../../models/tables/Task';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import User from '../../models/tables/User';
import Attachment from '../../models/tables/Attachment';
import Reminder from '../../models/tables/Reminder';
import Grade from '../../models/tables/Grade';
import Expense from '../../models/tables/Expense';
import { LookupContext, AuthContext } from '../../contexts';
import { ActionSchema, Lookup, TaskSchema, AttachmentSchema, UserSchema, ReminderSchema, RemoveSelfActionContent, GradeSchema, ExpenseSchema } from '../../types';
import Company from '../../models/tables/Company';
import Location from '../../models/tables/Location';
import './CommentCard.scss';

export interface Props {
  id?: string;
  className?: string;
  record: ActionSchema;
  task: TaskSchema;
  disabled?: boolean;
  onDeleteAction: (record: ActionSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onEditAction: (record: ActionSchema, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: ActionSchema, e?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubtaskCardClick?: (record: TaskSchema, e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  onFormCancel: () => void;
}

export interface State {
  isEditing: boolean;
}

class CommentCard extends React.Component<Props, State> {

  private editor = React.createRef<Editor>();

  static defaultProps = {
    onFormSubmit: console.info,
    onFormCancel: console.info,
    onDeleteAction: console.info,
    onEditAction: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.handleActionsChange = this.handleActionsChange.bind(this);
    this.handleEditorEnter = this.handleEditorEnter.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      isEditing: false,
    };
  }

  resetEditor() {
    const { record } = this.props;
    const editor = this.editor.current;
    editor?.setValue(record.content);
  }

  renderAddMembersContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: Partial<TaskSchema> = JSON.parse(record.content);
    const membersCount = parsed?.members?.length || 0;
    return (
      <p>
        {parsed.members && parsed.members.map((memberID, i) => {
          const member = lookup.users?.find(user => (user.id === memberID));
          return (
            <React.Fragment key={`add-members-${memberID}`}>
              <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
              {(membersCount > 1) && ((i + 1) < membersCount) && (
                <span>{((i + 1) === (membersCount - 1)) ? ' and ' : ', '}</span>
              )}
            </React.Fragment>
          );
        })}
        <span>{(membersCount > 1) ? ` were added as members of this ${Task.getLabel('singular')}.` : ` was added as a member of this ${Task.getLabel('singular')}.`}</span>
      </p>
    );
  }

  renderRemoveMembersContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: Partial<TaskSchema> = JSON.parse(record.content);
    const membersCount = parsed?.members?.length || 0;
    return (
      <p>
        {parsed.members && parsed.members.map((memberID, i) => {
          const member = lookup.users?.find(user => (user.id === memberID));
          return (
            <React.Fragment key={`remove-members-${memberID}`}>
              <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
              {(membersCount > 1) && ((i + 1) < membersCount) && (
                <span>{((i + 1) === (membersCount - 1)) ? ' and ' : ', '}</span>
              )}
            </React.Fragment>
          );
        })}
        <span>{(membersCount > 1) ? ` were removed as members of this ${Task.getLabel('singular')}.` : ` was removed as a member of this ${Task.getLabel('singular')}.`}</span>
      </p>
    );
  }

  renderAddRemoveMembersContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { add?: UserSchema['id'][], remove?: UserSchema['id'][] } | undefined = JSON.parse(record.content);
    const addedCount = parsed?.add?.length || 0;
    const removedCount = parsed?.remove?.length || 0;
    return (
      <p>
        {(addedCount > 0) && (
          <React.Fragment>
            {parsed?.add?.map((memberID, i) => {
              const member = lookup.users?.find(user => (user.id === memberID));
              return (
                <React.Fragment key={`add-members-${memberID}`}>
                  <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
                  {(addedCount > 1) && ((i + 1) < addedCount) && (
                    <span>{((i + 1) === (addedCount - 1)) ? ' and ' : ', '}</span>
                  )}
                </React.Fragment>
              );
            })}
            <span>{(addedCount > 1) ? ` were added as members of this ${Task.getLabel('singular')}.` : ` was added as a member of this ${Task.getLabel('singular')}.`}</span>
          </React.Fragment>
        )}
        {((addedCount > 0) && (removedCount > 0)) && (
          <br />
        )}
        {(removedCount > 0) && (
          <React.Fragment>
            {parsed?.remove?.map((memberID, i) => {
              const member = lookup.users?.find(user => (user.id === memberID));
              return (
                <React.Fragment key={`remove-members-${memberID}`}>
                  <strong>{`${member ? User.getRecordLabel(member) : memberID}`}</strong>
                  {(removedCount > 1) && ((i + 1) < removedCount) && (
                    <span>{((i + 1) === (removedCount - 1)) ? ' and ' : ', '}</span>
                  )}
                </React.Fragment>
              );
            })}
            <span>{(removedCount > 1) ? ` were removed as members of this ${Task.getLabel('singular')}.` : ` was removed as a member of this ${Task.getLabel('singular')}.`}</span>
          </React.Fragment>
        )}
      </p>
    );
  }

  renderCreateContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: Partial<TaskSchema> = JSON.parse(record.content);
    return (
      <p>
        <span>{`This ${Task.getLabel('singular')} was created`}</span>
        {parsed.title && (
          <React.Fragment>
            <span>{' as '}</span>
            <strong>{parsed.title}</strong>
          </React.Fragment>
        )}
        <span>{'.'}</span>
      </p>
    );
  }

  getModelByBoard(board: string = 'peronal'): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  renderUpdateContent(lookup: Lookup) {
    const { record, task } = this.props;
    const parsed: { assignedFrom?: string } & Partial<TaskSchema> = JSON.parse(record.content);
    delete parsed.id;
    delete parsed.assignedFrom;
    const changedFields = Object.keys(parsed) as (keyof TaskSchema)[];
    const taskModel = this.getModelByBoard(task.board);
    return (
      <div>
        {(changedFields.length > 0) && changedFields.map((fieldName, i) => {
          const field = taskModel.getField<TaskSchema>(fieldName);
          const value = parsed[fieldName]/* ?.toString() */;
          return (
            <React.Fragment key={`update-${fieldName}`}>
              <strong>{field?.label || fieldName}</strong>
              {value ? (
                <React.Fragment>
                  <span>{' was changed to '}</span>
                  <strong>
                    {(! field) ? value : (
                      <ActionValueByField<TaskSchema> field={field} value={value} />
                    )}
                  </strong>
                </React.Fragment>
              ) : (
                <span>{' was removed'}</span>
              )}
              {(changedFields.length > 1) && ((i + 1) < changedFields.length) && (
                <span>{((i + 1) === (changedFields.length - 1)) ? ' and ' : ', '}</span>
              )}
            </React.Fragment>
          );
        })}
        <span>{'.'}</span>
      </div>
    );
  }

  renderArchiveContent(lookup: Lookup) {
    return (
      <p>
        <span>{`This ${Task.getLabel('singular')} was archived.`}</span>
      </p>
    );
  }

  renderRestoreContent(lookup: Lookup) {
    return (
      <p>
        <span>{`This ${Task.getLabel('singular')} was restored.`}</span>
      </p>
    );
  }

  renderAddAttachmentsContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: AttachmentSchema[] = JSON.parse(record.content);
    return (
      <React.Fragment>
        <p>
          <span>{`${parsed.length} ${(parsed.length === 1) ? `${Attachment.getLabel('singular')} was` : `${Attachment.getLabel('singular')} were`} added to this ${Task.getLabel('singular')}.`}</span>
        </p>
        {parsed.map(attachment => (
          <AttachmentCard 
          key={attachment.id}
          className="fourg-comment-card__attachment" 
          record={attachment} />
        ))}
        {this.renderArtifactInfo('Showing the attachment in its current state.')}
      </React.Fragment>
    );
  }

  renderAddAttachmentContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { id: AttachmentSchema['id'] } = JSON.parse(record.content);
    const attachment = lookup.attachments?.find(attachment => (attachment.id === parsed.id));
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Attachment.getLabel('singular')} was added to this ${Task.getLabel('singular')}.`}</span>
        </p>
        {attachment && (
          <AttachmentCard 
          className="fourg-comment-card__attachment" 
          record={attachment} />
        )}
        {this.renderArtifactInfo('Showing the attachment in its current state.')}
      </React.Fragment>
    );
  }

  renderRemoveAttachmentContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: AttachmentSchema = JSON.parse(record.content);
    const pathArray = parsed.path.split('/');
    const fileName = pathArray[(pathArray.length - 1)] || '';
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Attachment.getLabel('singular')} called `}</span>
          <strong>{parsed.title || fileName}</strong>
          <span>{` was removed from this ${Task.getLabel('singular')}.`}</span>
        </p>
      </React.Fragment>
    );
  }

  renderUpdateAttachmentContent(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { id: AttachmentSchema['id'] } = JSON.parse(record.content);
    const attachment = lookup.attachments?.find(attachment => (attachment.id === parsed.id));
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Attachment.getLabel('singular')} was edited.`}</span>
        </p>
        {attachment && (
          <AttachmentCard 
          className="fourg-comment-card__attachment" 
          record={attachment} />
        )}
        {this.renderArtifactInfo('Showing the attachment in its current state.')}
      </React.Fragment>
    );
  }

  renderComment(lookup: Lookup) {
    const { record, disabled } = this.props;
    const { isEditing } = this.state;
    return (
      <Form
      className="fourg-comment-card__form"
      variant="quiet"
      submitLabel={isEditing ? 'Update' : undefined}
      cancelLabel={isEditing ? 'Cancel' : undefined}
      onCancel={this.handleFormCancel}
      onSubmit={this.handleFormSubmit}
      disabled={disabled}>
        <FormField size="large">
          <AuthContext.Consumer>
            {auth => (
              <Editor
              auth={auth}
              ref={this.editor}
              label="Comment"
              lookup={lookup}
              value={record.content}
              readOnly={! isEditing}
              onEnter={this.handleEditorEnter}
              disabled={disabled} />
            )}
          </AuthContext.Consumer>
        </FormField>
      </Form>
    );
  }

  renderAddSubtask(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: { id: TaskSchema['id'] } = JSON.parse(record.content);
    const subtask = lookup.tasks?.find(task => (task.id === parsed.id));
    return (
      <React.Fragment>
        <p>
          <span>{`A subtask was added to this ${Task.getLabel('singular')}.`}</span>
        </p>
        {subtask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-comment-card__subtask"
          record={subtask} />
        )}
        {this.renderArtifactInfo('Showing the task in its current state.')}
      </React.Fragment>
    );
  }
  
  renderArchiveSubtask(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: { id: TaskSchema['id'] } = JSON.parse(record.content);
    const subtask = lookup.tasks?.find(task => (task.id.toString() === parsed.id.toString()));
    return (
      <React.Fragment>
        <p>
          <span>{'A subtask was archived.'}</span>
        </p>
        {subtask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-comment-card__subtask"
          record={subtask} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderRestoreSubtask(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: { id: TaskSchema['id'] } = JSON.parse(record.content);
    const subtask = lookup.tasks?.find(task => (task.id.toString() === parsed.id.toString()));
    return (
      <React.Fragment>
        <p>
          <span>{'A subtask was restored.'}</span>
        </p>
        {subtask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-comment-card__subtask"
          record={subtask} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderAssignParent(lookup: Lookup) {
    const { record, onSubtaskCardClick } = this.props;
    const parsed: Partial<TaskSchema> = JSON.parse(record.content);
    const parentTask = lookup.tasks?.find(task => (task.id.toString() === parsed.parentId?.toString()));
    return (
      <React.Fragment>
        <p>
          <span>{`This ${Task.getLabel('singular')} was made a subtask of another ${Task.getLabel('singular')}.`}</span>
        </p>
        {parentTask && (
          <TaskCard
          onClick={onSubtaskCardClick}
          className="fourg-comment-card__subtask"
          record={parentTask} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderAddReminder(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { id: ReminderSchema['id'] } = JSON.parse(record.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')} was added to this ${Task.getLabel('singular')}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderDeleteReminder(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { id: ReminderSchema['id'] } = JSON.parse(record.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')} was removed from this ${Task.getLabel('singular')}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderToggleReminder(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { id: ReminderSchema['id'], completed: ReminderSchema['completed'] } = JSON.parse(record.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')} was marked as ${parsed.completed ? 'complete' : 'incomplete'}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderReminderLapsed(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { id: ReminderSchema['id'] } = JSON.parse(record.content);
    const reminder = lookup.reminders?.find(reminder => (reminder.id === parsed.id));
    const isRecurring = Boolean(reminder?.reminder?.interval);
    return (
      <React.Fragment>
        <p>
          <span>{`A reminder from a ${isRecurring ? 'recurring ' : ''}${Reminder.getLabel('singular')}.`}</span>
        </p>
        {reminder && (
          <ReminderCard
          className="fourg-comment-card__reminder"
          record={reminder} />
        )}
        {this.renderArtifactInfo('Showing the to-do in its current state.')}
      </React.Fragment>
    );
  }

  renderRemoveSelf(lookup: Lookup) {
    const { record, task } = this.props;
    const model = this.getModelByBoard(task.board);
    const assignedToField = model.getField<TaskSchema>('assignedTo');
    const parsed: RemoveSelfActionContent = JSON.parse(record.content);
    const user = lookup.users?.find(user => (user.id === record.createdBy));
    return (
      <p>
        {parsed.removedMember && (
          <React.Fragment>
            <strong>{User.getRecordLabel(user)}</strong>
            {(assignedToField && (parsed.assignedTo !== undefined)) ? (
              <span>{` was removed as a member of this ${Task.getLabel('singular')}, and `}</span>
            ) : (
              <span>{` was removed as a member of this ${Task.getLabel('singular')}.`}</span>
            )}
          </React.Fragment>
        )}
        {(assignedToField && (parsed.assignedTo !== undefined)) && (
          <React.Fragment>
            {parsed.assignedTo ? (
              <React.Fragment>
                <strong>{assignedToField.label}</strong>
                <span>{' was changed to '}</span>
                <strong>
                  <ActionValueByField<TaskSchema> field={assignedToField} value={parsed.assignedTo} />
                  {'.'}
                </strong>
              </React.Fragment>
            ) : (
              <span><strong>{assignedToField.label}</strong> was removed.</span>
            )}
          </React.Fragment>
        )}
        
      </p>
    );
  }

	renderAddGrade(lookup: Lookup) {
    const { record } = this.props;
    const parsed: GradeSchema = JSON.parse(record.content);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${Grade.getLabel('singular')} was added to this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        {parsed && (
          <GradeCard
          className="fourg-comment-card__grade"
          record={parsed} />
        )}
        {this.renderArtifactInfo('Showing the state of the grade at the time it was added.')}
      </React.Fragment>
    );
  }

	renderUpdateGrade(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { before: GradeSchema, after: GradeSchema } = JSON.parse(record.content);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${Grade.getLabel('singular')} was updated in this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <ArtifactComparison
				before={(
					<GradeCard
					className="fourg-comment-card__grade"
					record={parsed.before} />
				)}
				after={(
					<GradeCard
					className="fourg-comment-card__grade"
					record={parsed.after} />
				)} />
        {this.renderArtifactInfo('Showing the state of the grade as "before" → "after" at the time it was updated.')}
      </React.Fragment>
    );
  }

	renderRemoveGrade(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { before: GradeSchema } = JSON.parse(record.content);
    return (
      <React.Fragment>
        <p>
          <span>{`A ${Grade.getLabel('singular')} was removed from this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <GradeCard
				className="fourg-comment-card__expense"
				record={parsed.before} />
        {this.renderArtifactInfo('Showing the state of the grade at the time it was removed.')}
      </React.Fragment>
    );
  }

	renderAddExpense(lookup: Lookup) {
    const { record } = this.props;
    const parsed: ExpenseSchema = JSON.parse(record.content);
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Expense.getLabel('singular')} was added to this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        {parsed && (
          <ExpenseCard
          className="fourg-comment-card__expense"
          record={parsed} />
        )}
        {this.renderArtifactInfo('Showing the state of the expense at the time it was added.')}
      </React.Fragment>
    );
  }

	renderUpdateExpense(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { before: ExpenseSchema, after: ExpenseSchema } = JSON.parse(record.content);
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Expense.getLabel('singular')} was updated in this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <ArtifactComparison
				before={(
					<ExpenseCard
					className="fourg-comment-card__expense"
					record={parsed.before} />
				)}
				after={(
					<ExpenseCard
					className="fourg-comment-card__expense"
					record={parsed.after} />
				)} />
        {this.renderArtifactInfo('Showing the state of the expense as "before" → "after" at the time it was updated.')}
      </React.Fragment>
    );
  }

	renderRemoveExpense(lookup: Lookup) {
    const { record } = this.props;
    const parsed: { before: ExpenseSchema } = JSON.parse(record.content);
    return (
      <React.Fragment>
        <p>
          <span>{`An ${Expense.getLabel('singular')} was removed from this ${Task.getLabel('singular')}'s P&L.`}</span>
        </p>
        <ExpenseCard
				className="fourg-comment-card__expense"
				record={parsed.before} />
        {this.renderArtifactInfo('Showing the state of the expense at the time it was removed.')}
      </React.Fragment>
    );
  }

	renderCieTradePush(lookup: Lookup) {
    return (
			<p>
				<span>{`This ${Task.getLabel('singular')}'s ${Company.getLabel('singular')} and ${Location.getLabel('singular')} were pushed to cieTrade.`}</span>
			</p>
    );
  }

  renderContentByAction(lookup: Lookup) {
    const { record } = this.props;
    switch (record.action) {
      case 'create': return this.renderCreateContent(lookup);
      case 'update': return this.renderUpdateContent(lookup);
      case 'archive': return this.renderArchiveContent(lookup);
      case 'restore': return this.renderRestoreContent(lookup);
      case 'add-remove-members': return this.renderAddRemoveMembersContent(lookup);
      case 'add-members': return this.renderAddMembersContent(lookup);
      case 'remove-members': return this.renderRemoveMembersContent(lookup);
      case 'add-attachments': return this.renderAddAttachmentsContent(lookup);
      case 'add-attachment': return this.renderAddAttachmentContent(lookup);
      case 'update-attachment': return this.renderUpdateAttachmentContent(lookup);
      case 'remove-attachment': return this.renderRemoveAttachmentContent(lookup);
      case 'comment': return this.renderComment(lookup);
      case 'add-subtask': return this.renderAddSubtask(lookup);
      case 'archive-subtask': return this.renderArchiveSubtask(lookup);
      case 'restore-subtask': return this.renderRestoreSubtask(lookup);
      case 'add-reminder': return this.renderAddReminder(lookup);
      case 'delete-reminder': return this.renderDeleteReminder(lookup);
      case 'toggle-reminder': return this.renderToggleReminder(lookup);
      case 'reminder-lapsed': return this.renderReminderLapsed(lookup);
      case 'assign-parent': return this.renderAssignParent(lookup);
      case 'remove-self': return this.renderRemoveSelf(lookup);
      case 'add-grade': return this.renderAddGrade(lookup);
      case 'update-grade': return this.renderUpdateGrade(lookup);
      case 'remove-grade': return this.renderRemoveGrade(lookup);
      case 'add-expense': return this.renderAddExpense(lookup);
      case 'update-expense': return this.renderUpdateExpense(lookup);
      case 'remove-expense': return this.renderRemoveExpense(lookup);
			case 'cietrade-push': return this.renderCieTradePush(lookup);
      default: return record.content;
    }
  }

  handleFormCancel(e: React.MouseEvent<HTMLButtonElement>) {
    const { onFormCancel } = this.props;
    this.resetEditor();
    this.setState({ isEditing: false });
    onFormCancel();
  }

  finishEdit() {
    this.setState({ isEditing: false });
  }

  setEditorValue(value: string, isForced: boolean = true) {
    const editor = this.editor.current;
    editor?.setValue(value, isForced);
  }

  handleEditorEnter(value: string) {
    const { record, disabled, onFormSubmit } = this.props;
    if (! disabled && record) {
      const newRecord = { ...record, content: value };
      onFormSubmit(newRecord);
    }
  }
  
  handleFormSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    const { record, disabled, onFormSubmit } = this.props;
    const editor = this.editor.current;
    const value = editor?.getValue() || '';
    if (! disabled && record) {
      const newRecord = { ...record, content: value };
      onFormSubmit(newRecord);
    }
  }

  handleActionsChange(value: string, e: React.MouseEvent<HTMLButtonElement>) {
    const { record, onEditAction, onDeleteAction } = this.props;
    switch (value) {
      case 'edit':
        this.setState({ isEditing: true });
        onEditAction(record, e);
        break;
      case 'delete':
        onDeleteAction(record, e);
        break;
    }
  }

  renderArtifactInfo(message: string) {
    return (
      <div className="fourg-comment-card__info">
        <Icon src={{ icon: 'info' }} label={'Info'} />
        <p>{message}</p>
      </div>
    );
  }

  render() {
    const { record, task, disabled, className, children, onSubtaskCardClick, onFormSubmit, onFormCancel, onDeleteAction, onEditAction, ...restProps } = this.props;
    const { isEditing } = this.state;
    const containerClass = classNames('fourg-comment-card', `fourg-comment-card--action-${record.action}`, className);
    return (
      <LookupContext.Consumer>
        {lookup => {
          const creator = lookup.users?.find(user => (user.id === record.createdBy));
          const creatorName = creator ? User.getRecordLabel(creator) : 'System Message';
          const avatar = creator ? User.getRecordImage(creator) : undefined;
          return (
            <div className={containerClass} {...restProps} tabIndex={disabled ? -1 : 0}>
              <Icon 
              className="fourg-comment-card__avatar"
              label={creatorName} 
              src={{ 
                icon: creator ? 'account_circle' : 'notifications', 
                cover: avatar, 
              }} />
              <div className="fourg-comment-card__content">
                <div className="fourg-comment-card__header">
                  <h3 className="fourg-comment-card__creator">{creatorName}</h3>
                  <span className="fourg-comment-card__date">
                    {new Date(record.created).toLocaleString(undefined, {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric', 
                      minute: '2-digit',
                    })}
                  </span>
                </div>
                <div className="fourg-comment-card__body">
                  {this.renderContentByAction(lookup)}
                </div>
              </div>
              {(record.action === 'comment') && (
                <AuthContext.Consumer>
                  {({ user }) => (user && (user.id === record.createdBy)) && (
                    <ActionToggle
                    variant="mini"
                    isIconOnly={true}
                    disabled={(disabled || isEditing)}
                    label={'Comment Actions'}
                    icon={{ icon: 'more_vert' }}
                    onChange={this.handleActionsChange}
                    anchor="top-right"
                    options={[
                      { value: 'edit', label: 'Edit' },
                      { value: 'delete', label: 'Delete' },
                    ]}
                    value="" />
                  )}
                </AuthContext.Consumer>
              )}
            </div>
          );
        }}
      </LookupContext.Consumer>
    );
  }
}

export default CommentCard;
