import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { UIOption } from '../../types';
import './Chip.scss';

export interface Props {
  id?: string;
  className?: string;
  label: UIOption['label'],
  value: UIOption['value'],
  isActive?: boolean;
  disabled?: boolean;
  variant?: 'default' | 'mini';
  onClick: (value: UIOption['value'], isActive: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface State {

}

class Chip extends React.Component<Props, State> {

  static defaultProps = {
    value: '',
    variant: 'default',
    onClick: console.info,
  };

  render() {
    const { disabled, onClick, isActive, label, value, variant, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-chip', `fourg-chip--variant-${variant}`, {
      'fourg-chip--active': isActive,
      'fourg-chip--disabled': disabled,
    }, className);
    return (
      <button 
      disabled={disabled}
      type="button"
      className={containerClass} 
      onClick={e => onClick(value, ! isActive, e)}
      {...restProps}>
        {isActive && (
          <Icon 
          className="fourg-chip__icon" 
          src={{ icon: 'check' }} 
          label={label} />
        )}
        <span className="fourg-chip__label">
          {label}
        </span>
      </button>
    );
  }
}

export default Chip;
