import React from 'react';
import classNames from 'classnames';
import Button, { Props as ButtonProps } from '../Button/Button';
import { Props as IconProps } from '../Icon/Icon';
import ActionList, { Props as ActionListProps } from '../ActionList/ActionList';
import ActionListItem from '../ActionListItem/ActionListItem';
import { UIOption } from '../../types';
import './ActionToggle.scss';

export interface Props {
  id?: string;
  variant?: ButtonProps['variant'];
  anchor?: ActionListProps['anchor'];
  isIconOnly?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  options: UIOption[];
  value: string;
  title?: string;
  icon?: IconProps['src'];
  onChange: (value: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  isExpanded: boolean;
}

class ActionToggle extends React.Component<Props, State> {

  private root = React.createRef<HTMLDivElement>();
  private button = React.createRef<Button>();

  static defaultProps = {
    options: [],
    onChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleWindowKeyUp = this.handleWindowKeyUp.bind(this);
    this.handleWindowClick = this.handleWindowClick.bind(this);
    this.state = {
      isExpanded: false,
    };
  }

  componentDidMount() {
    const { isExpanded } = this.state;
    if (isExpanded) {
      window.addEventListener('click', this.handleWindowClick);
      window.addEventListener('keyup', this.handleWindowKeyUp);
    } 
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isExpanded } = this.state;
    if (! prevState.isExpanded && isExpanded) {
      window.addEventListener('click', this.handleWindowClick);
      window.addEventListener('keyup', this.handleWindowKeyUp);
    }
    if (prevState.isExpanded && ! isExpanded) {
      window.removeEventListener('click', this.handleWindowClick);
      window.removeEventListener('keyup', this.handleWindowKeyUp);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
    window.removeEventListener('keyup', this.handleWindowKeyUp);
  }

  handleWindowClick(e: MouseEvent) {
    const root = this.root.current;
    if (root && (e.target instanceof Node) && ! root.contains(e.target)) {
      this.setState({ isExpanded: false });
    }
  }

  handleWindowKeyUp(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      const button = this.button.current;
      button?.focus();
      this.setState({ isExpanded: false });
    }
  }

  handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    this.setState(({ isExpanded }) => {
      return {
        isExpanded: ! isExpanded,
      };
    });
  }

  handleActionClick(newValue: string, e: React.MouseEvent<HTMLButtonElement>) {
    const { value, onChange } = this.props;
    if (newValue !== value) {
      onChange(newValue, e);
    }
    this.setState({ isExpanded: false });
  }

  getOption(value: string) {
    const { options } = this.props;
    return options.find(option => (option.value === value));
  }

  open() {
    this.setState({ isExpanded: true });
  }

  close() {
    this.setState({ isExpanded: false });
  }

  render() {
    const { title, anchor, label, value, disabled, options, onChange, className, ...restProps } = this.props;
    const { isExpanded } = this.state;
    const containerClass = classNames('fourg-action-toggle', {
      'fourg-action-toggle--expanded': isExpanded,
    }, className);
    const button = this.button.current;
    const triggerRef = button?.getInnerRef();
    return (
      <div ref={this.root} className={containerClass}>
        <Button 
        ref={this.button}
        className="fourg-action-toggle__button"
        onClick={this.handleButtonClick}
        disabled={disabled}
        title={title}
        {...restProps}>
          {label}
        </Button>
        {(options.length > 0) && (
          <ActionList 
          triggerRef={triggerRef}
          anchor={anchor}
          className="fourg-action-toggle__actions" 
          isExpanded={isExpanded}>
            {options.map(option => (
              <ActionListItem
              key={`action-${option.value}`}
              disabled={(disabled || option.disabled)}
              onClick={this.handleActionClick}
              isActive={(option.value === value)}
              {...option} />
            ))}
          </ActionList>
        )}
      </div>
    );
  }
}

export default ActionToggle;
