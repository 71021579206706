import React from 'react';
import classNames from 'classnames';
import './SidebarSectionActions.scss';

export interface Props {
  id?: string;
  className?: string;
}

export interface State {

}

class SidebarSectionActions extends React.Component<Props, State> {

  render() {
    const { className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-sidebar-section-actions', className);
    return (
      <div className={containerClass} {...restProps}>
        {children}
      </div>
    );
  }
}

export default SidebarSectionActions;
