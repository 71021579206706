import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import Icon, { Props as IconProps } from '../Icon/Icon';
import './Input.scss';

export interface Props {
  id?: string;
  className?: string;
  name?: string;
  type?: 'text' | 'email' | 'tel' | 'number' | 'password' | 'search' | 'url' | 'textarea' | 'hidden';
  variant?: 'default' | 'quiet',
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  pattern?: string;
  min?: number;
  max?: number;
  step?: number;
  label: string;
  title?: string;
  value: string;
  placeholder?: string;
  tabIndex?: number;
  autoComplete?: string;
  icon?: IconProps['src'];
  onFocus?: () => void;
  onBlur?: () => void;
  onTextAreaEnter?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChange: (value: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export interface State {
  isFocused: boolean;
  isValid: boolean;
  internalID: string;
}

class Input extends React.Component<Props, State> {

  private input = React.createRef<HTMLInputElement | HTMLTextAreaElement>();

  static defaultProps = {
    value: '',
    type: 'text',
    variant: 'default',
    onChange: console.info,
  }

  constructor(props: Props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTextAreaKeyPress = this.handleTextAreaKeyPress.bind(this);
    this.state = {
      isFocused: false,
      isValid: true,
      internalID: props.id || uniqid('Input-'),
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const input = this.input.current;
    if (value && input) {
      this.setState({ isValid: input.checkValidity() });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { id, value, required, pattern, type, min, max, step, disabled } = this.props;
    if (prevProps.id !== id) {
      this.setState({ internalID: id || uniqid('Input-') });
    }
    if ((prevProps.value !== value)
    || (prevProps.required !== required)
    || (prevProps.pattern !== pattern)
    || (prevProps.min !== min)
    || (prevProps.max !== max)
    || (prevProps.step !== step)
    || (prevProps.type !== type)) {
      const input = this.input.current;
      if (input) {
        this.setState({ isValid: input.checkValidity() });
      }
    }
    if (! prevProps.disabled && disabled) {
      this.setState({ isFocused: false });
    }
  }

  handleFocus() {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    if (onFocus) onFocus();
  }

  handleBlur() {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    if (onBlur) onBlur();
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { onChange } = this.props;
    const value = e.target.value; 
    onChange(value, e);
  }

  focus() {
    const input = this.input.current;
    input?.focus();
  }

  blur() {
    const input = this.input.current;
    input?.blur();
  }

  hasFocus() {
    const { isFocused } = this.state;
    return isFocused;
  }

  handleTextAreaKeyPress(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    const { onTextAreaEnter } = this.props;
    if (onTextAreaEnter && (e.key === 'Enter') && ! e.shiftKey) {
      e.preventDefault();
      onTextAreaEnter(e);
    }
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  isValid() {
    const input = this.input.current;
    return input?.checkValidity() || false;
  }

  render() {
    const { required, icon, variant, type, value, id, disabled, readOnly, label, onChange, onTextAreaEnter, placeholder, className, onFocus, onBlur, ...restProps } = this.props;
    const { isFocused, isValid, internalID } = this.state;
    const containerClass = classNames('fourg-input', `fourg-input--type-${type}`, `fourg-input--variant-${variant}`, {
      'fourg-input--dirty': Boolean(value || placeholder),
      'fourg-input--focused': isFocused,
      'fourg-input--disabled': disabled || readOnly,
      'fourg-input--invalid': ! isValid,
      'fourg-input--has-icon': Boolean(icon),
    }, className);
    return (
      <div className={containerClass}>
        <label className="fourg-input__label" htmlFor={internalID}>
          {`${label}${required ? '*' : ''}`}
        </label>
        <div className="fourg-input__container">
          {(type === 'textarea') ? (
            <textarea
            ref={this.input as React.RefObject<HTMLTextAreaElement>}
            id={internalID}
						readOnly={readOnly}
            className="fourg-input__input"
            value={value}
            disabled={disabled}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={placeholder}
            onKeyPress={onTextAreaEnter ? this.handleTextAreaKeyPress : undefined}
            required={required}
            {...restProps} />
          ) : (
            <input 
            ref={this.input as React.RefObject<HTMLInputElement>}
            type={type}
            id={internalID}
            className="fourg-input__input" 
            value={value}
            disabled={disabled}
						readOnly={readOnly}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange} 
            placeholder={placeholder}
            required={required}
            {...restProps} />
          )}
          {icon && (
            <Icon
            src={icon}
            className="fourg-input__icon"
            label={label} />
          )}
          <Icon 
          src={{ icon: 'warning' }} 
          className="fourg-input__warning"
          label="Warning" />
        </div>
      </div>
    );
  }
}

export default Input;
