import React from 'react';
import classNames from 'classnames';
import Toaster from '../Toaster/Toaster';
import Header from '../Header/Header';
import './Layout.scss';
import { LayoutContext } from '../../contexts';

export interface Props {
  id?: string;
  className?: string;
}

export interface State {
	hasHeader: boolean;
	isBodyScrollable: boolean;
	showHeader: () => void;
	hideHeader: () => void;
	makeBodyScrollable: () => void;
	makeBodyUnscrollable: () => void;
}

class Layout extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.showHeader = this.showHeader.bind(this);
		this.hideHeader = this.hideHeader.bind(this);
		this.makeScrollable = this.makeScrollable.bind(this);
		this.makeUnscrollable = this.makeUnscrollable.bind(this);
		this.state = {
			hasHeader: true,
			isBodyScrollable: false,
			showHeader: this.showHeader,
			hideHeader: this.hideHeader,
			makeBodyScrollable: this.makeScrollable,
			makeBodyUnscrollable: this.makeUnscrollable,
		}
	}

	showHeader() {
		this.setState({ hasHeader: true });
	}

	hideHeader() {
		this.setState({ hasHeader: false });
	}

	makeScrollable() {
		this.setState({ isBodyScrollable: true });
	}

	makeUnscrollable() {
		this.setState({ isBodyScrollable: false });
	}

  render() {
    const { className, children, ...restProps } = this.props;
    const { hasHeader, isBodyScrollable } = this.state;
    const containerClass = classNames('fourg-layout', {
			'fourg-layout--body-scrollable': isBodyScrollable,
		}, className);
    return (
			<LayoutContext.Provider value={this.state}>
				<div className={containerClass} {...restProps}>
					{hasHeader && (
						<Header />
					)}
					<div className="fourg-layout__content">
						{children}
					</div>
					<Toaster />
				</div>
			</LayoutContext.Provider>
    );
  }
}

export default Layout;
