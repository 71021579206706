import React from 'react';
import classNames from 'classnames';
import FormField, { Props as FormFieldProps } from '../FormField/FormField';
import Input, { Props as InputProps } from '../Input/Input';
import Checkbox from '../Checkbox/Checkbox';
import SelectInput from '../SelectInput/SelectInput';
import DateInput from '../DateInput/DateInput';
import LookupInput from '../LookupInput/LookupInput';
import UniqueInput from '../UniqueInput/UniqueInput';
import LookupInputNew from '../LookupInputNew/LookupInputNew';
import LookupResourceSectionInputNew from '../LookupResourceSectionInputNew/LookupResourceSectionInputNew';
import FileInput from '../FileInput/FileInput';
import ChipList from '../ChipList/ChipList';
import Editor from '../Editor/Editor';
import AddressInput from '../AddressInput/AddressInput';
import RecurrenceInput from '../RecurrenceInput/RecurrenceInput';
import Table from '../../models/Table';
import Location from '../../models/tables/Location';
import Company from '../../models/tables/Company';
import { LookupContext, AuthContext } from '../../contexts';
import { CompanySchema, LocationSchema, ReadRecordsQuery, TableField, TaskSchema } from '../../types';
import './FormFieldByField.scss';

export interface Props<T extends Record<string, any>> extends FormFieldProps {
  field: TableField<T>;
  value?: any;
  disabled?: boolean;
	readOnly?: boolean;
  variant?: 'default' | 'quiet',
  record: T;
	recordModel: typeof Table;
  formContext: 'default' | 'quick-add';
  onChange: (value: any, e?: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface State {

}

class FormFieldByField<T extends Record<string, any>> extends React.Component<Props<T>, State> {

  private input = React.createRef<any>();

  static defaultProps = {
    formContext: 'default',
    onChange: console.info,
  };

  renderInput() {
    const { field, value, disabled, variant, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <Input
      ref={this.input}
      variant={variant}
      name={field.name.toString()}
      type={field.type as InputProps['type']}
      label={field.label}
      required={field.required}
      readOnly={field.readOnly || readOnly}
      disabled={disabled}
      min={field.min}
      max={field.max}
      step={field.step}
      value={value || field.default}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    );
  }

  renderCheckbox() {
    const { field, value, disabled, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <Checkbox
      ref={this.input}
      name={field.name.toString()}
      label={field.label}
      required={field.required}
      readOnly={field.readOnly || readOnly}
      disabled={disabled}
      checked={Boolean(value || field.default)}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    );
  }

  renderDate() {
    const { field, value, disabled, variant, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <DateInput
      ref={this.input}
      variant={variant}
      name={field.name.toString()}
      label={field.label}
      required={field.required}
      readOnly={field.readOnly || readOnly}
      disabled={disabled}
      value={value || field.default}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    );
  }

  renderRecurrence() {
    const { field, value, disabled, variant, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <RecurrenceInput
      ref={this.input}
      variant={variant}
      name={field.name.toString()}
      label={field.label}
      required={field.required}
      readOnly={field.readOnly || readOnly}
      disabled={disabled}
      value={value || field.default}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    );
  }

  renderSelect() {
    const { field, value, disabled, readOnly, /* variant,  */onChange, onFocus, onBlur } = this.props;
    const firstOptionValue = field.options ? field.options[0].value : '';
    return (
      <SelectInput
      ref={this.input}
      // variant={variant}
      name={field.name.toString()}
      label={field.label}
      options={field.options}
      required={field.required}
      disabled={disabled}
			readOnly={field.readOnly || readOnly}
      value={value || field.default || firstOptionValue}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    );
  }

	renderSelectInput() {
    const { field, value, disabled, readOnly, /* variant, */ onChange, onFocus, onBlur } = this.props;
    const firstOptionValue = field.options ? field.options[0].value : '';
    return (
      <SelectInput
      ref={this.input}
      // variant={variant}
      name={field.name.toString()}
      label={field.label}
      options={field.options}
      required={field.required}
			readOnly={field.readOnly || readOnly}
      disabled={disabled}
      value={value || field.default || firstOptionValue}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
			isCreatable={true} />
    );
  }

  renderUnique() {
    const { record, field, value, disabled, variant, readOnly, onChange, onFocus, onBlur } = this.props;
		return (
      <AuthContext.Consumer>
        {auth => field.model && (
          <UniqueInput
          ref={this.input}
          auth={auth}
          variant={variant}
          name={field.name.toString()}
          label={field.label}
          required={field.required}
          readOnly={field.readOnly || readOnly}
          disabled={disabled}
          model={field.model}
          companyID={record.companyId || undefined}
          value={value?.toString() || field.default?.toString()}
          ignoreIds={field.model.getRecordValue<T>(record).toString()}
					record={record}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur} />
        )}
      </AuthContext.Consumer>
    );
  }

  renderLookup() {
    const { field, value, disabled, variant, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <AuthContext.Consumer>
        {auth => (
          <LookupContext.Consumer>
            {lookup => field.model && (
              <LookupInput
              ref={this.input}
              auth={auth}
              lookup={lookup}
              variant={variant}
              name={field.name.toString()}
              label={field.label}
              required={field.required}
              readOnly={field.readOnly || readOnly}
              disabled={disabled}
              model={field.model}
              value={value?.toString() || field.default?.toString()}
              options={field.options}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur} />
            )}
          </LookupContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
  }

	renderLookupInput() {
    const { field, value, disabled, readOnly, /* variant,  */onChange, onFocus, onBlur } = this.props;
    return (
      <AuthContext.Consumer>
        {auth => (
          <LookupContext.Consumer>
            {lookup => field.model && (
              <LookupInputNew
              ref={this.input}
              auth={auth}
              lookup={lookup}
              name={field.name.toString()}
              label={field.label}
              required={field.required}
              readOnly={field.readOnly || readOnly}
              disabled={disabled}
              model={field.model}
              value={value?.toString() || field.default?.toString()}
              options={field.options}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur} />
            )}
          </LookupContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
  }

  renderLookupResourceSection() {
    const { record, recordModel, field, value, disabled, /* variant,  */readOnly, onChange, onFocus, onBlur } = this.props;
    const recordModelOptions = recordModel.getOptions<T>();
		const fieldModelOptions = field.model?.getOptions();
		const isTask = ['Task', 'NewBusinessTask', 'CustomerServiceTask', 'FinanceTask', 'PersonalTask', 'TargetTask'].includes(recordModelOptions.name);
		let resourceID: CompanySchema['id'] | LocationSchema['id'] | undefined = record.id;
		let resourceModel = (recordModel as typeof Company | typeof Location);
		let query: ReadRecordsQuery | undefined = undefined;

		if (isTask) {
			const taskRecord = (record as unknown as TaskSchema);
			query = {
				board: (taskRecord.board === 'new-business') ? taskRecord.board : undefined,
			};
			if (fieldModelOptions?.name === 'Location') {
				resourceID = taskRecord.companyId;
				resourceModel = Company;
			}
		}

		return (
      <AuthContext.Consumer>
        {auth => (
          <LookupContext.Consumer>
            {lookup => field.model && (
              <LookupResourceSectionInputNew
              ref={this.input}
              auth={auth}
              lookup={lookup}
              // variant={variant}
              name={field.name.toString()}
              label={field.label}
              required={field.required}
              readOnly={field.readOnly || readOnly}
              disabled={disabled}
              model={field.model}
              resourceID={resourceID}
							resourceModel={resourceModel}
              value={value?.toString() || field.default?.toString()}
              options={field.options || []}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
							query={query} />
            )}
          </LookupContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
  }

  renderFile() {
    const { field, disabled, variant, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <FileInput
      ref={this.input}
      variant={variant}
      name={field.name.toString()}
      label={field.label}
      required={field.required}
      readOnly={field.readOnly || readOnly}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    );
  }

  renderEditor() {
    const { field, value, disabled, variant, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <AuthContext.Consumer>
        {auth => (
          <LookupContext.Consumer>
            {lookup => (
              <Editor
              ref={this.input}
              auth={auth}
              variant={variant}
              name={field.name.toString()}
              label={field.label}
              required={field.required || readOnly}
              readOnly={field.readOnly}
              disabled={disabled}
              value={value || field.default}
              lookup={lookup}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur} />
            )}
          </LookupContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
  }

  renderAddress() {
    const { formContext, field, value, disabled, readOnly, variant, onChange, onFocus, onBlur } = this.props;
    return (
      <AddressInput
      ref={this.input}
      variant={variant}
      name={field.name.toString()}
      label={field.label}
      required={field.required}
      readOnly={field.readOnly || readOnly}
      disabled={disabled}
      value={value || field.default}
      isExpandable={field.isExpandable}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      excludedFields={(formContext === 'quick-add') ? field.excludedFieldsQuickAdd : field.excludedFields} />
    );
  }

	renderChipList() {
    const { field, value, disabled, readOnly, onChange, onFocus, onBlur } = this.props;
    return (
      <ChipList
      ref={this.input}
      name={field.name.toString()}
      label={field.label}
      required={field.required}
			options={field.options}
      readOnly={field.readOnly || readOnly}
      disabled={disabled}
      value={value || field.default}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    );
  }

  renderByType() {
    const { field } = this.props;
    switch (field.type) {
      case 'editor': return this.renderEditor();
      case 'select': return this.renderSelect();
      case 'select-input': return this.renderSelectInput();
      case 'lookup-input': return this.renderLookupInput();
      case 'lookup': return this.renderLookup();
      case 'lookup-company-section': return this.renderLookupResourceSection();
      case 'lookup-location-section': return this.renderLookupResourceSection();
      case 'file': return this.renderFile();
      case 'date': return this.renderDate();
      case 'recurrence': return this.renderRecurrence();
      case 'address': return this.renderAddress();
      case 'checkbox': return this.renderCheckbox();
      case 'unique': return this.renderUnique();
      case 'chip-list': return this.renderChipList();
      default: return this.renderInput();
    }
  }

  resetValidity() {
    const input = this.input.current;
    if (input && input.resetValidity) {
      input.resetValidity();
    }
  }

  isValid() {
    let isValid = true;
    const input = this.input.current;
    if (input && input.isValid) {
      isValid = input.isValid();
    }
    return isValid;
  }

  render() {
    const { formContext, field, className } = this.props;
    const containerClass = classNames('fourg-form-field-by-field', `fourg-form-field-by-field--type-${field.type}`, `fourg-form-field-by-field--name-${field.name.toString()}`, className);
    return (
      <FormField
      size={(formContext === 'quick-add') ? field.quickAddFormFieldSize : field.formFieldSize}
      className={containerClass}>
        {this.renderByType()}
      </FormField>
    );
  }
}

export default FormFieldByField;
