import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import Loader from '../Loader/Loader';
import Form from '../Form/Form';
import FormFieldByField from '../FormFieldByField/FormFieldByField';
import Table from '../../models/Table';
import { TableField, Auth } from '../../types';
import './FormDialog.scss';

export interface Props<T extends Record<string, any> = Record<string, any>> extends DialogProps {
  auth: Auth;
  model: typeof Table,
  title: string;
  recordID?: string;
  enforcedValues?: Partial<T>;
  submitLabel: string;
  secondaryLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSecondary: (record: T, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (record: T, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State<T extends Record<string, any> = Record<string, any>> {
  isLoading: boolean;
  record: T;
}

class FormDialog<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State<T>> {

  private formFields: React.RefObject<FormFieldByField<T>>[] = [];

  static defaultProps = {
    ...Dialog.defaultProps,
    onFormCancel: console.info,
    onFormSecondary: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props<T>) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    const { model } = props;
    this.state = {
      isLoading: false,
      record: model.getDefaultRecord<T>(),
    };
  }

  componentDidMount() {
    const { recordID, isOpen } = this.props;
    if (isOpen && recordID) {
      this.readRecord();
    }
  }

  componentDidUpdate(prevProps: Props<T>) {
    const { recordID, isOpen } = this.props;
    if (prevProps.isOpen && ! isOpen) {
      this.setDefaultRecord();
    }
    if (! prevProps.isOpen && isOpen) {
      if (recordID) {
        this.readRecord();
      } else {
        this.setDefaultRecord();
      }
    }
  }

  async readRecord() {
    const { auth, model, recordID } = this.props;
    if (recordID) {
      this.setState({ isLoading: true });
      try {
        const token = await auth.getToken();
        const { data } = await model.readRecord<T>(token, recordID);
        this.setState({
          isLoading: false,
          record: data,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isLoading: false,
          record: model.getDefaultRecord<T>(),
        });
      }
    }
  }

  setDefaultRecord() {
    const { enforcedValues, model } = this.props;
    this.setState({
      record: {
        ...model.getDefaultRecord<T>(),
        ...enforcedValues,
      },
    });
    this.resetValidity();
  }

  handleFieldChange(field: TableField<T>, value: any) {
    const { record } = this.state;
    if (record) {
      this.setState({
        record: {
          ...record,
          [field.name]: value,
        }
      });
    }
  }

  isFieldEnforced(field: TableField<T>) {
    const { enforcedValues } = this.props;
    return enforcedValues && Object.keys(enforcedValues).includes(field.name.toString());
  }

  resetValidity() {
    if (this.formFields.length > 0) {
      this.formFields.forEach(formField => formField.current?.resetValidity());
    }
  }

  render() {
    const { enforcedValues, disabled, isOpen, title, auth, model, recordID, className, submitLabel, secondaryLabel, cancelLabel, onFormCancel, onFormSubmit, onFormSecondary, ...restProps } = this.props;
    const { record, isLoading } = this.state;
    const containerClass = classNames('fourg-form-dialog', className);
    const fields = model.getFieldsBy<T>('isFormField', true);
    return (
      <Dialog
      className={containerClass}
      title={title}
      isOpen={isOpen}
      {...restProps}>
        {(fields.length > 0) && (
          <Form
          className="fourg-form-dialog__form"
          submitLabel={submitLabel}
          secondaryLabel={secondaryLabel}
          disabled={disabled || isLoading || ! isOpen}
          cancelLabel={cancelLabel}
          onCancel={onFormCancel}
          onSubmit={e => onFormSubmit(record, e)}
          onSecondary={e => onFormSecondary(record, e)}>
            {fields.map((field, i) => (
              <FormFieldByField<T>
              ref={formField => this.formFields[i] = { current: formField }}
              record={record}
							recordModel={model}
              key={`form-field-${field.name.toString()}`}
              field={field}
              value={record[field.name]}
              disabled={disabled || isLoading || ! isOpen || this.isFieldEnforced(field)}
              onChange={(value, e) => this.handleFieldChange(field, value)} />
            ))}
          </Form>
        )}
        {isLoading && (
          <Loader
          position="absolute"
          className="fourg-form-dialog__loader" />
        )}
      </Dialog>
    );
  }
}

export default FormDialog;
