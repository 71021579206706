import React from 'react';
import classNames from 'classnames';
import './TabBar.scss';
import { UIOption } from '../../types';
import Tab from '../Tab/Tab';

export interface Props {
  id?: string;
  className?: string;
  options: UIOption[];
  value: string;
  disabled?: boolean;
  onChange: (value: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  indicatorStyle?: React.CSSProperties;
  windowResizeTimer?: number;
}

class TabBar extends React.Component<Props, State> {

  private activeTab = React.createRef<Tab>();

  static defaultProps = {
    options: [],
    value: '',
    onChange: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.setIndicatorStyle = this.setIndicatorStyle.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.state = {
      indicatorStyle: undefined,
      windowResizeTimer: undefined,
    };
  }

  componentDidMount() {
    this.setIndicatorStyle();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setIndicatorStyle();
    }
  }

	componentWillUnmount() {
		const { windowResizeTimer } = this.state;
    if (windowResizeTimer) window.clearTimeout(windowResizeTimer);
		window.removeEventListener('resize', this.handleWindowResize);
	}

  handleWindowResize() {
    const { windowResizeTimer } = this.state;
    if (windowResizeTimer) window.clearTimeout(windowResizeTimer);
    this.setState({
      windowResizeTimer: window.setTimeout(this.setIndicatorStyle, 250),
    });
  }

  handleChange(newValue: string, e: React.MouseEvent<HTMLButtonElement>) {
    const { onChange, value } = this.props;
    if (value !== newValue) {
      onChange(newValue, e);
    }
  }

  setIndicatorStyle() {
    const activeTab = this.activeTab.current;
    const activeTabAction = activeTab?.getActionRef().current;
    if (activeTabAction) {
      const { clientWidth, offsetLeft } = activeTabAction;
      this.setState({
        indicatorStyle: {
          width: clientWidth,
          left: offsetLeft,
        },
      });
    }
  }

  render() {
    const { disabled, onChange, value, options, className, ...restProps } = this.props;
    const { indicatorStyle } = this.state;
    const containerClass = classNames('fourg-tab-bar', className);
    return (
      <nav className={containerClass} {...restProps}>
        <div className="fourg-tab-bar__content">
          <ul className="fourg-tab-bar__tabs">
            {options.map(option => (
              <Tab
              ref={(option.value === value) ? this.activeTab : undefined}
              key={`tab-${option.value}`}
              disabled={(disabled || option.disabled)}
              value={option.value}
              count={option.count}
              isActive={(option.value === value)}
              onClick={this.handleChange}>{option.label}</Tab>
            ))}
          </ul>
          <hr
          aria-hidden={true}
          role="presentation"
          className="fourg-tab-bar__indicator"
          style={indicatorStyle} />
        </div>
      </nav>
    );
  }
}

export default TabBar;
