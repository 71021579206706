import React from 'react';
import classNames from 'classnames';
import Loader from '../Loader/Loader';
import Icon from '../Icon/Icon';
import Select from '../Select/Select';
import Input, { Props as InputProps } from '../Input/Input';
import Table from '../../models/Table';
import Company from '../../models/tables/Company';
import CieTradeCounterparty from '../../models/tables/CieTradeCounterparty';
import CieTradeLocation from '../../models/tables/CieTradeLocation';
import { Auth, ReadRecordsQuery, ReadRecordsResponse, CompanySchema, UIOption, CieTradeCounterpartySchema, CieTradeLocationSchema, LocationSchema } from '../../types';
import { mergeRecords } from '../../utils';
import './UniqueInput.scss';

export interface Props<T extends Record<string, any> = Record<string, any>> extends InputProps {
  auth: Auth;
  order?: string;
  filter?: string;
  limit?: number;
  board?: string;
  ignoreSubtasks?: boolean,
  ignoreIds?: string;
  archived?: boolean;
	record?: T;
  companyID?: CompanySchema['id'];
  model: typeof Table;
  searchDelay: number;
}

export interface State<T extends Record<string, any> = Record<string, any>> {
  records: T[];
  isLoading: boolean;
  total: number;
  searchTimer?: number;
  isUnique: boolean;
}

class UniqueInput<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State<T>> {

  private input = React.createRef<Input>();
  private checkbox = React.createRef<HTMLInputElement>();
  private select = React.createRef<Select>();

  static defaultProps = {
    limit: -1,
    searchDelay: 500,
    autoComplete: 'off',
  };

  constructor(props: Props<T>) {
    super(props);
    this.handleSearchTimer = this.handleSearchTimer.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.state = {
      records: [],
      isLoading: false,
      total: 0,
      isUnique: this.isUnique(props.value, []),
    };
  }

  componentDidUpdate(prevProps: Props<T>, prevState: State<T>) {
    const { value, onChange } = this.props;
    const { isUnique, isLoading } = this.state;
    if ((prevState.isUnique !== isUnique)
		|| (prevState.isLoading !== isLoading)) {
      onChange(value, {} as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    }
  }

  handleSearchTimer() {
    const { value } = this.props;
    if (value) {
      this.readRecords();
    } else {
      this.setState({
        records: [],
        total: 0,
        isUnique: this.isUnique(value, []),
      });
    }
  }

  async readRecordsByModel(token: string, query: ReadRecordsQuery) {
    const { model, companyID } = this.props;
    const modelOptions = model.getOptions();
    switch (modelOptions.name) {
      case 'Location': return await this.readLocationsAndAddresses(token, companyID || 0, query) as any as ReadRecordsResponse<T>;
			case 'Company': return await this.readCompaniesAndCounterparties(token, query) as any as ReadRecordsResponse<T>;
      default: return await model.readRecords<T>(token, query);
    }
  }

	async readCompaniesAndCounterparties(token: string, query: ReadRecordsQuery): Promise<ReadRecordsResponse<CompanySchema>> {
		const { record, ignoreIds } = this.props;
		const companyRecord = record as any as CompanySchema;
		const ignoreIdsArray: string[] = ignoreIds?.split(',') || [];
		const companiesResponse = await Company.readRecords<CompanySchema>(token, query);
		const counterpartiesResponse = companyRecord.isPushed ? await CieTradeCounterparty.readRecords<CieTradeCounterpartySchema>(token, query) : { data: [], meta: {} };
		const filteredCounterparties = counterpartiesResponse.data.filter((c) => ! ignoreIdsArray.includes(c.id?.toString() || ''));
		const counterpartiesAsCompanies = filteredCounterparties.map((c) => CieTradeCounterparty.toCompanySchema(c));
		const mergedRecords = mergeRecords(counterpartiesAsCompanies, companiesResponse.data);
		return {
			data: mergedRecords,
			meta: {
				total: mergedRecords.length,
				limit: -1,
				page: 1,
			},
		}
	}

	async readLocationsAndAddresses(token: string, companyID: number, query: ReadRecordsQuery): Promise<ReadRecordsResponse<LocationSchema>> {
		const { record, value } = this.props;
		const locationRecord = record as any as LocationSchema;
		const cieTradeLocationName = locationRecord.counterpartyId ? locationRecord.name : undefined;
		const companyResponse = companyID ? await Company.readRecord<CompanySchema>(token, companyID.toString()) : undefined;
		const locationsResponse = await Company.readLocations(token, companyID, query);
		const addressesResponse = locationRecord.isPushed ? await CieTradeLocation.readRecords<CieTradeLocationSchema>(token, { ...query, cieTradeID: companyResponse?.data?.counterpartyId }) : { data: [], meta: {} };
		const filteredAddresses = addressesResponse.data
			.filter((l) => (l.label.toLowerCase().trim().includes(value.toLowerCase().trim())))
			.filter((l) => (l.label.toLowerCase().trim() !== cieTradeLocationName?.toLowerCase().trim()));
		const addressesAsLocations = filteredAddresses.map((l) => CieTradeLocation.toLocationSchema(l));
		const mergedRecords = mergeRecords(addressesAsLocations, locationsResponse.data);
		return {
			data: mergedRecords,
			meta: {
				total: mergedRecords.length,
				limit: -1,
				page: 1,
			},
		}
	}

  resetValidity() {
    const input = this.input.current;
    input?.resetValidity();
  }

  isValid() {
    const input = this.input.current;
    const checkbox = this.checkbox.current;
    return input?.isValid() && checkbox?.checkValidity();
  }

  async readRecords() {
    const { value, archived, auth, model, order, filter, limit, board, ignoreIds, ignoreSubtasks } = this.props;
    const { defaultOrder, defaultFilter } = model.getOptions<T>();
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await this.readRecordsByModel(token, {
        limit: limit,
        search: value,
        order: order || defaultOrder,
        filter: filter || defaultFilter,
        board: board,
        ignoreSubtasks: ignoreSubtasks,
        ignoreIds: ignoreIds,
        archived: archived,
      });
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
        isUnique: this.isUnique(value, data),
      });
      const select = this.select.current;
      select?.open();
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        records: [],
        total: 0,
        isUnique: this.isUnique(value, []),
      });
    }
  }

  isUnique(value: InputProps['value'], records: T[]) {
    const { model } = this.props;
    let isUnique = true;
		const found = records.find(r => (model.getRecordLabel(r).toLowerCase().trim() === value.toLowerCase().trim()));
    if (Boolean(found)) {
      isUnique = false;
    }
    return isUnique;
  }

  handleChange(value: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { onChange, searchDelay } = this.props;
    const { searchTimer } = this.state;
    if (searchTimer) window.clearTimeout(searchTimer);
    const newTimer = window.setTimeout(() => this.handleSearchTimer(), searchDelay);
    this.setState({ searchTimer: newTimer });
    onChange(value, e);
  }

  getOption(record: T) {
    const { model } = this.props;
    const { icon } = model.getOptions<T>();
    const option: UIOption = {
      value: model.getRecordValue<T>(record).toString(),
      label: this.getOptionLabel(record),
      icon: {
        icon: icon,
        cover: model.getRecordImage<T>(record),
      },
    };
    return option;
  }

	getOptionLabel(record: T): string {
		const { model } = this.props;
		let label = model.getRecordLabel<T>(record);
		const id = (record as any).id;
		if (id && (typeof id === 'string') && id.startsWith('cieTrade')) {
			label = `cieTrade: ${label}`;
		}
		return label;
	}

  getOptions() {
    const { model, value } = this.props;
    const { records } = this.state;
    const newOptions: UIOption[] = [];
    records.forEach(record => {
      if (model.getRecordValue<T>(record).toString() !== value) {
        newOptions.push(this.getOption(record));
      }
    });
    return newOptions;
  }

  handleSelectClick() {
    const input = this.input.current;
    input?.focus();
  }

  handleInputBlur() {
    const { onBlur } = this.props;
    const select = this.select.current;
    select?.close();
    if (onBlur) onBlur();
  }

  render() {
    const { label, required, disabled, readOnly, variant, companyID, value, onChange, auth, order, filter, limit, board, ignoreSubtasks, ignoreIds, archived, model, searchDelay, className, children, onBlur, ...restProps } = this.props;
    const { isUnique, isLoading } = this.state;
    const containerClass = classNames('fourg-unique-input', {
			'fourg-unique-input--disabled': disabled || readOnly,
		}, className);
    const modelOptions = model.getOptions<T>();
    const selectOptions = this.getOptions();
    return (
      <div className={containerClass}>
        <div className="fourg-unique-input__content">
          <Input
          readOnly={readOnly}
          className="fourg-unique-input__input"
          ref={this.input}
          value={value}
          onChange={this.handleChange}
          variant={variant}
          disabled={disabled}
          required={required}
          label={label}
          onBlur={this.handleInputBlur}
          {...restProps} />
          <Select
          variant={variant}
          className="fourg-unique-input__select"
          ref={this.select}
          disabled={disabled}
          label={label}
          value={''}
          tabIndex={-1}
          readOnly={true}
          options={selectOptions}
          onChange={() => {}}
          isListOverlaid={false}
          onClick={this.handleSelectClick}
          icon={{ icon: modelOptions.icon }}
          {...restProps} />
          <input
          ref={this.checkbox}
          className="fourg-unique-input__validator"
          type="checkbox"
          checked={(isUnique && ! isLoading)}
          required={true}
          tabIndex={-1}
					readOnly={readOnly}
          onChange={() => {}} />
          {isLoading && (
            <Loader
            className="fourg-unique-input__loader"
            size={18} />
          )}
          {(! isLoading && value) && (
            <Icon
            className={classNames('fourg-unique-input__validation-icon', {
              'fourg-unique-input__validation-icon--unavailable': ! isUnique,
              'fourg-unique-input__validation-icon--available': isUnique,
            })}
            label={isUnique ? 'Available' : 'Unavailable'}
            src={{ icon: isUnique ? 'done' : 'close' }} />
          )}
        </div>
      </div>
    );
  }
}

export default UniqueInput;
