import React from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import AutoParagraph from '../AutoParagraph/AutoParagraph';
import ActionToggle from '../ActionToggle/ActionToggle';
import Checkbox from '../Checkbox/Checkbox';
import Icon from '../Icon/Icon';
import RecurrenceSpan from '../RecurrenceSpan/RecurrenceSpan';
import Reminder from '../../models/tables/Reminder';
import User from '../../models/tables/User';
import { LookupContext } from '../../contexts';
import { ReminderSchema, UIOption, UserSchema } from '../../types';
import './ReminderCard.scss';

export interface Props {
  id?: string;
  className?: string;
  variant: 'default' | 'mini';
  record: ReminderSchema;
  disabled?: boolean;
  onCompletedChange?: (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  onActionsChange?: (value: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class ReminderCard extends React.Component<Props, State> {

  static defaultProps = {
    variant: 'default',
  };

  render() {
    const { disabled, record, variant, onCompletedChange, onActionsChange, className, children, ...restProps } = this.props;
    const isCompletable = Boolean(onCompletedChange);
    const isRecurring = Boolean(record.reminder?.interval);
		const warnings = Reminder.getWarnings(record);
    const containerClass = classNames('fourg-reminder-card', `fourg-reminder-card--variant-${variant}`, {
      'fourg-reminder-card--completable': isCompletable,
      'fourg-reminder-card--complete': record.completed,
      'fourg-reminder-card--disabled': disabled,
      'fourg-reminder-card--error': ((warnings.length > 0) && ! record.completed),
      'fourg-reminder-card--recurring': isRecurring,
    }, className);
    const completedField = Reminder.getField('completed');
    const checkboxID = uniqid(`reminder-${record.id}-completed-`);
    const userOptions = User.getOptions();
    const completeTitle = `Mark as Incomplete`;
    const incompleteTitle = `Mark as Complete`;
    const labelTitle = record.completed ? completeTitle : incompleteTitle;
    const checkedIcon = isRecurring ? { icon: 'update_disabled' } : undefined;
    const uncheckedIcon = isRecurring ? { icon: 'update' } : undefined;
    return (
      <LookupContext.Consumer>
        {({ users }) => {
          const assignee = record.assignedTo ? users?.find(user => (User.getRecordValue<UserSchema>(user).toString() === record.assignedTo)) : undefined;
          const assigneeName = assignee ? User.getRecordLabel(assignee) : undefined;
          return (
            <div className={containerClass} {...restProps}>
							<div className="fourg-reminder-card__inside">
								<Checkbox 
								className="fourg-reminder-card__checkbox"
								id={checkboxID}
								disabled={(! isCompletable || disabled)}
								title={completedField?.label}
								uncheckedLabel={incompleteTitle}
								checkedLabel={completeTitle}
								checked={record.completed}
								onChange={onCompletedChange}
								checkedIcon={checkedIcon}
								uncheckedIcon={uncheckedIcon} />
								{assignee && (
									<Icon 
									className="fourg-reminder-card__avatar" 
									src={{ icon: userOptions.icon, cover: User.getRecordImage<UserSchema>(assignee) }}
									label={assigneeName || ''} />
								)}
								<div className="fourg-reminder-card__content">
									<label
									htmlFor={isCompletable ? checkboxID : undefined} 
									className="fourg-reminder-card__title"
									title={isCompletable ? labelTitle : undefined}>
										{record.title}
									</label>
									{record.dueDate && (
										<span className="fourg-reminder-card__date">
											{new Date(record.dueDate).toLocaleString()}
										</span>
									)}
									{record.reminder && (
										<RecurrenceSpan 
										className="fourg-reminder-card__date" 
										value={record.reminder} />
									)}
									{record.description && (
										<AutoParagraph 
										className="fourg-reminder-card__description" 
										text={record.description} />
									)}
								</div>
								{onActionsChange && (
									<ActionToggle
									className="fourg-reminder-card__actions"
									disabled={disabled}
									label={'Actions'}
									isIconOnly={true}
									icon={{ icon: 'more_vert' }}
									value=""
									variant={'mini'}
									anchor="top-right"
									options={[
										{ label: 'Edit', value: 'edit' },
										{ label: 'Delete', value: 'delete' },
									]}
									onChange={onActionsChange} />
								)}
							</div>
							{(warnings.length > 0 && ! record.completed) && (
								<div className="fourg-reminder-card__warnings" title={warnings.join('\n')}>
									<Icon src={{ icon: 'warning_amber' }} label={'Warnings'} />
									<p>{`${warnings.length.toLocaleString()} ${warnings.length === 1 ? 'Warning' : 'Warnings'}`}</p>
								</div>
							)}
            </div>
          );
        }}
      </LookupContext.Consumer>
    );
  }
}

export default ReminderCard;
