import React from 'react';
import classNames from 'classnames';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import LookupMultiple from '../LookupMultiple/LookupMultiple';
import FormField from '../FormField/FormField';
import Form from '../Form/Form';
import Table from '../../models/Table';
import { AuthContext } from '../../contexts';
import './LookupDialog.scss';

export interface Props extends DialogProps {
  model: typeof Table,
  value: string[];
  disabled?: boolean;
  limit?: number;
  order?: string;
  filter?: string;
  onFormCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormSubmit: (value: string[], added: string[], removed: string[], e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  internalValue: string[],
}

class LookupDialog<T extends Record<string, any> = Record<string, any>> extends React.Component<Props, State> {

  private lookupMultiple = React.createRef<LookupMultiple<T>>();

  static defaultProps = {
    ...Dialog.defaultProps,
    value: [],
    onFormCancel: console.info,
    onFormSubmit: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleLookupMultipleChange = this.handleLookupMultipleChange.bind(this);
    this.state = {
      internalValue: props.value,
    };
  }

  componentDidMount() {
    const { isOpen } = this.props;
    const lookupMultiple = this.lookupMultiple.current;
    if (isOpen) {
      lookupMultiple?.readRecords();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { value, isOpen } = this.props;
    const lookupMultiple = this.lookupMultiple.current;
    if (! prevProps.isOpen && isOpen) {
      this.setState({ internalValue: value });
      lookupMultiple?.readRecords();
    }
    if (prevProps.isOpen && ! isOpen) {
      lookupMultiple?.reset();
    }
  }

  handleLookupMultipleChange(value: string[]) {
    this.setState({ internalValue: value });
  }

  render() {
    const { limit, order, filter, disabled, title, isOpen, onFormSubmit, onFormCancel, /* onChange,  onActionClick,*/ value, model, className, ...restProps } = this.props;
    const { internalValue } = this.state;
    const containerClass = classNames('fourg-lookup-dialog', className);
    const added = internalValue.filter(selected => ! value.includes(selected));
    const removed = value.filter(selected => ! internalValue.includes(selected));
    return (
      <Dialog
      title={title}
      isOpen={isOpen}
      className={containerClass}
      {...restProps}>
        <Form
        className="fourg-lookup-dialog__form"
        submitLabel={'Update'}
        cancelLabel={'Cancel'}
        onSubmit={e => onFormSubmit(value, added, removed, e)}
        onCancel={onFormCancel}
				disabled={disabled}>
          <FormField size="large">
            <AuthContext.Consumer>
              {auth => (
                <LookupMultiple<T>
                auth={auth}
                ref={this.lookupMultiple}
                loadOnMount={false}
                className="fourg-lookup-dialog__lookup-multiple"
                disabled={(disabled || ! isOpen)}
                model={model}
                onChange={this.handleLookupMultipleChange}
                value={internalValue}
                label={title}
                limit={limit}
                order={order}
                filter={filter} />
              )}
            </AuthContext.Consumer>
          </FormField>
        </Form>
      </Dialog>
    );
  }
}

export default LookupDialog;
